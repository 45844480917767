import { v4 as uuidv4 } from 'uuid';

export const CHARACTER_STRENGTHS_MEASURE = 'CHARACTER_STRENGTHS_MEASURE';
export const FLOURISHING_MEASURE = 'FLOURISHING_MEASURE';
export const GRATITUDE_MEASURE = 'GRATITUDE_MEASURE';
export const MEANING_MEASURE = 'MEANING_MEASURE';
export const COMMUNITY_MEASURE = 'COMMUNITY_MEASURE';

export const MEASURES = [
  {
    type: FLOURISHING_MEASURE,
    title: 'Flourishing',
    slug: 'flourishing',
    img: 'flourishing.svg',
  },
  {
    type: MEANING_MEASURE,
    title: 'Meaning',
    slug: 'meaning',
    img: 'meaning.svg',
  },
  {
    type: GRATITUDE_MEASURE,
    title: 'Gratitude',
    slug: 'gratitude',
    img: 'gratitude.svg',
  },
  {
    type: CHARACTER_STRENGTHS_MEASURE,
    title: 'Character Strengths',
    slug: 'character-strengths',
    img: 'character.svg',
  },
  // { type: COMMUNITY_MEASURE, title: 'Community' },
];

export const MEASURES_WO_CHARACTER = MEASURES.filter(
  (m) => m.type !== CHARACTER_STRENGTHS_MEASURE,
);

export type Measure = {
  id: string;
  cuuid: string; //client generated uuid
  sessionId?: string; //
  type: string;
  startedAt: number;
  stoppedAt: number;
  completed: boolean;
  payload: { responses: any[] };
  isIntake?: boolean;
  isBaseline?: boolean;
  campaign?: string;
};
export type MeasureState = Measure[];

// export type TempMeasure = {
//   isIntake: boolean;
//   inProgress: boolean;
// } & Measure;

export const createMeasure = ({
  type,
  isIntake = false,
  isBaseline = false,
}: {
  type: string;
  isIntake?: boolean;
  isBaseline?: boolean;
}): Measure => {
  console.log('Creating temp measure...');
  return {
    isIntake,
    isBaseline,
    type,
    cuuid: uuidv4(), //client-side generated uuid
    id: '', //generated on the server
    sessionId: '',
    startedAt: Date.now(), // moment().utc().valueOf(),
    stoppedAt: 0,
    completed: false,
    payload: { responses: [] },
  };
};
