export const END_SESSION_REQUESTED = 'END_SESSION_REQUESTED';
export const END_SESSION_SUCCEEDED = 'END_SESSION_SUCCEEDED';
export const END_SESSION_FAILED = 'END_SESSION_FAILED';

export type EndSessionAction = {
  type: typeof END_SESSION_REQUESTED;
  payload: { id: string; stoppedAt: number };
};
export const endSessionAction = (payload: {
  id: string;
  stoppedAt: number;
}): EndSessionAction => ({
  type: END_SESSION_REQUESTED,
  payload,
});
