import { SIGN_OUT_REQUESTED } from './account_types';

export type AccountSignOutAction = {
  type: typeof SIGN_OUT_REQUESTED;
  payload: {};
};

export const signOut = (): AccountSignOutAction => {
  return { type: SIGN_OUT_REQUESTED, payload: {} };
};
