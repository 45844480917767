import { Link } from 'react-router-dom';
import { EXERCISE_GROUPS } from '../../text/activities';

/**
 * This view is for the 'Welcome' Page
 * @param param0
 * @returns
 */
export const ActivitiesCTA = ({ about }: { about?: boolean }) => {
  return (
    <div className=''>
      {/* {!about && (
        <Helmet>
          <title>Activities - About</title>
        </Helmet>
      )} */}

      <ActivityHero />
      {/*  */}
      <div className='-mt-24 relative max-w-7xl mx-auto'>
        <ul className='ml-4 xl:ml-0 pb-10 flex overflow-x-auto space-x-4'>
          {Object.keys(EXERCISE_GROUPS).map((exercise) => {
            return (
              <ExerciseCard
                exercise={EXERCISE_GROUPS[exercise]}
                key={exercise}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const ExerciseCard = ({ exercise }) => {
  // const activities = exercise.activities
  //   .map((activity) => activity.header.replace(' Activity', ''))
  //   .join(', ');
  return (
    <div
      className='mx4 flex-shrink-0 flex flex-col bg-white text-center rounded-lg shadow transform transition hover:ease-in-out hover:duration-300 hover:shadow-xl'
      style={{ width: 285 }}>
      <div className='flex-shrink-0'>
        <img
          className='h-48 w-full object-cover object-center'
          src={exercise.imgCard}
          alt=''
        />
      </div>
      <div className='px-4 flex-1 p-2 flex flex-col justify-between'>
        <div className='flex-1'>
          <h1 className='my-2 text-2xl leading-7 border-b'>
            {exercise.header}
          </h1>
          {/* <p className='text-xs text-gray-500 font-bold'>{activities}</p> */}
          {/* <ul className='text-gray-600 text-xs font-bold space-y-2'>
            {exercise?.activities.map((activity) => (
              <li key={activity.header}>{activity.header}</li>
            ))}
          </ul> */}
          <p className='mt-3 text-sm leading-6 text-gray-500 whitespace-pre-line'>
            {exercise.desc}
          </p>
        </div>
      </div>
      <Link
        className='block m-5 py-3 wfull border border-burgundy text-xs rounded-full'
        // to={`/about${exercise.href}`}
        to='/about/activities'>
        {' '}
        Go to exercises{' '}
      </Link>
    </div>
  );
};

const ActivityHero = () => {
  return (
    <div
      className='py-10 w-full relative bg-burgundy lg:bg-transparent bg-right bg-cover text-white '
      style={{ backgroundImage: 'url(/welcome-dot-bg.svg)' }}>
      {/*  */}
      <div className='lg:grid grid-cols-1 lg:grid-cols-12 mx-auto max-w-7xl text-left'>
        {/*  */}
        <header className='px-4 xl:px-0 lg:col-span-7 py-5'>
          <h1 className='text-4xl leading-160 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl'>
            We want to help you Flourish
          </h1>

          <div className='mt-5 text-left'>
            <h2 className='mt-5 leading-160 font-bold'>
              Wide Selection of Activities!
            </h2>
            <ul className='mt-5 max-w-2xl text-sm leading-160 md:mt-5 listdisc -space-y-1'>
              <li className='text-white'>
                12 total activities grouped into 4 categories
                {/* 12 activities grouped into 4 categories */}
              </li>
              <li className='text-white'>
                Each activity designed to unfold over the course of 6 weeks
              </li>
              <li className='text-white'>
                See results by measuring your levels of flourishing before,
                during, and after
              </li>
              <li className='text-white'>
                Scientifically evaluated and found to be effective for improving
                levels of flourishing
              </li>
            </ul>

            <h2 className='mt-5 font-bold'>Free!</h2>
            <ul className='mt-3 max-w-md text-sm leading-160 md:mt-5 listdisc'>
              <li className='text-white'>
                We intend to always keep this app free to promote flourishing.
              </li>
              <li className='text-white'>
                However, some activities require the purchase of a book or use
                of a third-party website.
              </li>
            </ul>
          </div>

          <div className='mt-14' />
          <div className='h-2 w-48 bg-white rounded-full' />
          <p className='mt-10 text-3xl text-left text-white font-kenoky font-light leading-10'>
            Try our exercises
          </p>
        </header>
      </div>
      <div className='my-20' />
    </div>
  );
};
