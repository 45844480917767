import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
// import reportWebVitals from './reportWebVitals';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

if (process.env.NODE_ENV !== 'production') {
  // AppToRender = hot(module)(App);
}
// const HotApp = hot(module)(App);

// const render = () =>
//   ReactDOM.render(
//     // <AppToRender style={{ height: '100%' }} />,
//     <App />,
//     document.getElementById('root'),
//   );

// render();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// FIXME: once the cache issue gets figured out allow service workers again
serviceWorker.unregister();
// serviceWorker.register();
