import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  CreateMeasureAction,
  CREATE_ANON_MEASURE_REQUESTED,
  CREATE_MEASURE_FAILED,
  CREATE_MEASURE_REQUESTED,
  CREATE_MEASURE_SUCCEEDED,
  FetchMeasuresAction,
  FETCH_MEASURES_FAILED,
  FETCH_MEASURES_REQUESTED,
  FETCH_MEASURES_SUCCEEDED,
} from '.';
import {
  doCreateMeasure,
  doFetchMeasure,
  doFetchMeasures,
} from '../../firebase';
import { doCreateAnonMeasure } from '../../firebase/create_anon_measure';
import { doFetchRecentMeasures } from '../../firebase/fetch_recent_measures';
import {
  FetchMeasureAction,
  FETCH_MEASURE_FAILED,
  FETCH_MEASURE_REQUESTED,
  FETCH_MEASURE_SUCCEEDED,
} from './fetch_measure_action';
import {
  FetchRecentMeasuresAction,
  FETCH_RECENT_MEASURES_FAILED,
  FETCH_RECENT_MEASURES_REQUESTED,
  FETCH_RECENT_MEASURES_SUCCEEDED,
} from './fetch_recent_measures_action';

function* createAnonMeasure(action: CreateMeasureAction) {
  try {
    const measure = yield call(doCreateAnonMeasure, action);
    if (measure.hasError && measure.hasError === true) {
      yield put({ type: CREATE_MEASURE_FAILED, payload: measure.error });
    } else {
      // yield put({ type: CREATE_MEASURE_SUCCEEDED, payload: measure });
    }
  } catch (e) {
    yield put({ type: CREATE_MEASURE_FAILED, payload: e.message });
  }
}

function* createMeasure(action: CreateMeasureAction) {
  try {
    const measure = yield call(doCreateMeasure, action);
    if (measure.hasError && measure.hasError === true) {
      yield put({ type: CREATE_MEASURE_FAILED, payload: measure.error });
    } else {
      yield put({ type: CREATE_MEASURE_SUCCEEDED, payload: measure });
    }
  } catch (e) {
    yield put({ type: CREATE_MEASURE_FAILED, payload: e.message });
  }
}

function* fetchMeasure(action: FetchMeasureAction) {
  try {
    if (typeof action.payload.id === 'undefined') {
      throw new Error('Undefined Measure.id');
    }
    const measure = yield call(doFetchMeasure, action);
    if (measure.hasError && measure.hasError === true) {
      yield put({ type: FETCH_MEASURE_FAILED, payload: measure.error });
    } else {
      yield put({ type: FETCH_MEASURE_SUCCEEDED, payload: measure });
    }
  } catch (e) {
    yield put({ type: FETCH_MEASURE_FAILED, payload: e.message });
  }
}

function* fetchMeasures(action: FetchMeasuresAction) {
  try {
    const results = yield call(doFetchMeasures, action);
    if (results.hasError && results.hasError === true) {
      yield put({ type: FETCH_MEASURES_FAILED, payload: results.error });
    } else {
      yield put({ type: FETCH_MEASURES_SUCCEEDED, payload: results });
    }
  } catch (e) {
    yield put({ type: FETCH_MEASURES_FAILED, payload: e.message });
  }
}

function* fetchRecentMeasures(action: FetchRecentMeasuresAction) {
  try {
    const results = yield call(doFetchRecentMeasures, action);
    if (results.hasError && results.hasError === true) {
      yield put({ type: FETCH_RECENT_MEASURES_FAILED, payload: results.error });
    } else {
      yield put({ type: FETCH_RECENT_MEASURES_SUCCEEDED, payload: results });
    }
  } catch (e) {
    yield put({ type: FETCH_RECENT_MEASURES_FAILED, payload: e.message });
  }
}

export function* watchMeasure() {
  yield takeEvery(FETCH_MEASURES_REQUESTED, fetchMeasures);
  yield takeEvery(FETCH_MEASURE_REQUESTED, fetchMeasure);
  yield takeEvery(FETCH_RECENT_MEASURES_REQUESTED, fetchRecentMeasures);
  yield takeEvery(CREATE_ANON_MEASURE_REQUESTED, createAnonMeasure);
  yield takeEvery(CREATE_MEASURE_REQUESTED, createMeasure);
}

export function* measureSaga() {
  if (process.env.NODE_ENV !== 'production') {
    console.log('running measure saga');
  }
  yield all([watchMeasure()]);
}
