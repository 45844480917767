import { Session } from './session_types';

export const CREATE_SESSION_REQUESTED = 'CREATE_SESSION_REQUESTED';
export const CREATE_SESSION_SUCCEEDED = 'CREATE_SESSION_SUCCEEDED';
export const CREATE_SESSION_FAILED = 'CREATE_SESSION_FAILED';

export type CreateSessionAction = {
  type: typeof CREATE_SESSION_REQUESTED;
  payload: Session;
};

export const createSessionAction = (payload: Session): CreateSessionAction => ({
  type: CREATE_SESSION_REQUESTED,
  payload,
});
