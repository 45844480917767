import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  doCreateSession,
  doEndSession,
  doFetchActiveSessions,
  doFetchSession,
} from '../../firebase';
import { doFetchCurrentSession } from '../../firebase/fetch_current_session';
import { doFetchSessions } from '../../firebase/fetch_sessions';
import {
  CreateSessionAction,
  CREATE_SESSION_FAILED,
  CREATE_SESSION_REQUESTED,
  CREATE_SESSION_SUCCEEDED,
} from './create_section_action';
import {
  EndSessionAction,
  END_SESSION_FAILED,
  END_SESSION_REQUESTED,
  END_SESSION_SUCCEEDED,
} from './end_session_action';
import {
  FetchActiveSessionsAction,
  FETCH_ACTIVE_SESSIONS_FAILED,
  FETCH_ACTIVE_SESSIONS_REQUESTED,
  FETCH_ACTIVE_SESSIONS_SUCCEEDED,
} from './fetch_active_sessions_action';
import {
  FetchCurrentSessionAction,
  FETCH_CURRENT_SESSION_FAILED,
  FETCH_CURRENT_SESSION_REQUESTED,
  FETCH_CURRENT_SESSION_SUCCEEDED,
} from './fetch_current_session_action';
import {
  FetchSessionsAction,
  FETCH_SESSIONS_FAILED,
  FETCH_SESSIONS_REQUESTED,
  FETCH_SESSIONS_SUCCEEDED,
} from './fetch_sessions_action';
import {
  FetchSessionAction,
  FETCH_SESSION_FAILED,
  FETCH_SESSION_REQUESTED,
  FETCH_SESSION_SUCCEEDED,
} from './fetch_session_action';

function* createSessionSaga(action: CreateSessionAction) {
  try {
    const result = yield call(doCreateSession, action);
    if (result.hasError && result.hasError === true) {
      yield put({ type: CREATE_SESSION_FAILED, payload: result.error });
    } else {
      yield put({ type: CREATE_SESSION_SUCCEEDED, payload: result });
    }
  } catch (error) {
    yield put({ type: CREATE_SESSION_FAILED, payload: error.message });
  }
}

function* endSessionSaga(action: EndSessionAction) {
  try {
    const result = yield call(doEndSession, action);
    if (result.hasError && result.hasError === true) {
      yield put({ type: END_SESSION_FAILED, payload: result.error });
    } else {
      yield put({ type: END_SESSION_SUCCEEDED, payload: result.data });
    }
  } catch (error) {
    yield put({ type: END_SESSION_FAILED, payload: error.message });
  }
}

function* fetchActiveSessionsSaga(action: FetchActiveSessionsAction) {
  try {
    const results = yield call(doFetchActiveSessions, action);
    if (results.hasError && results.hasError === true) {
      yield put({ type: FETCH_ACTIVE_SESSIONS_FAILED, payload: results.error });
    } else {
      yield put({ type: FETCH_ACTIVE_SESSIONS_SUCCEEDED, payload: results });
    }
  } catch (e) {
    yield put({ type: FETCH_ACTIVE_SESSIONS_FAILED, payload: e.message });
  }
}

function* fetchCurrentSessionSaga(action: FetchCurrentSessionAction) {
  try {
    const result = yield call(doFetchCurrentSession, action);
    if (result.hasError && result.hasError === true) {
      yield put({ type: FETCH_CURRENT_SESSION_FAILED, payload: result.error });
    } else {
      yield put({ type: FETCH_CURRENT_SESSION_SUCCEEDED, payload: result });
    }
  } catch (e) {
    yield put({ type: FETCH_CURRENT_SESSION_FAILED, payload: e.message });
  }
}

function* fetchSessionSaga(action: FetchSessionAction) {
  try {
    const result = yield call(doFetchSession, action);
    if (result.hasError && result.hasError === true) {
      yield put({ type: FETCH_SESSION_FAILED, payload: result.error });
    } else {
      yield put({ type: FETCH_SESSION_SUCCEEDED, payload: result });
    }
  } catch (e) {
    yield put({ type: FETCH_SESSION_FAILED, payload: e.message });
  }
}

function* fetchSessionsSaga(action: FetchSessionsAction) {
  try {
    const results = yield call(doFetchSessions, action);
    if (results.hasError && results.hasError === true) {
      yield put({ type: FETCH_SESSIONS_FAILED, payload: results.error });
    } else {
      yield put({ type: FETCH_SESSIONS_SUCCEEDED, payload: results });
    }
  } catch (error) {
    yield put({ type: FETCH_SESSIONS_FAILED, payload: error.message });
  }
}

export function* watchSessions() {
  yield takeEvery(FETCH_ACTIVE_SESSIONS_REQUESTED, fetchActiveSessionsSaga);
  yield takeEvery(FETCH_CURRENT_SESSION_REQUESTED, fetchCurrentSessionSaga);
  yield takeEvery(CREATE_SESSION_REQUESTED, createSessionSaga);
  yield takeEvery(END_SESSION_REQUESTED, endSessionSaga);
  yield takeEvery(FETCH_SESSION_REQUESTED, fetchSessionSaga);
  yield takeEvery(FETCH_SESSIONS_REQUESTED, fetchSessionsSaga);
}

export function* sessionsSaga() {
  if (process.env.NODE_ENV !== 'production') {
    console.log('running sessions saga');
  }
  yield all([watchSessions()]);
}
