// import { FetchAccountAction } from '../stores/account/fetch_account';
import { httpsCallable } from 'firebase/functions';
import FirebaseClient from './firebase_client';

export async function doFetchAccount() {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: FETCH ACCOUNT REQUESTED');
    // console.log(action);
    console.groupEnd();
  }

  try {
    const results = (await httpsCallable(
      FirebaseClient.functions,
      'fetchAccount',
    )()) as any;
    const { user } = results.data;

    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: FETCH ACCOUNT SUCCEEDED');
      console.log('fetch results ', user);
      console.groupEnd();
    }
    //
    return user;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: FETCH ACCOUNT FAILED');
      console.error('Error', error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
}
