import { Session } from './session_types';

export const DELETE_NEW_SESSION = 'DELETE_NEW_SESSION';
export const NEW_SESSION = 'NEW_SESSION';
export const UPDATE_NEW_SESSION = 'UPDATE_NEW_SESSION';

export const newSessionAction = ({ type }) => ({
  type: NEW_SESSION,
  payload: { type },
});

export const updateNewSessionAction = (payload: Session) => ({
  type: UPDATE_NEW_SESSION,
  payload,
});

export const deleteNewSessionAction = () => ({
  type: typeof DELETE_NEW_SESSION,
  payload: {
    id: '',
    cuuid: '',
    type: '',
    startedAt: 0,
    stoppedAt: 0,
    entries: [],
    notifications: { app: false, email: false },
    baseline: {},
    baselineMeasures: [],
    baselineReflection: '',
    isActive: false,
    canDoEntry: false,
    completed: false,
    currentEntryNumber: 0,
    currentWeek: 0,
    scheduledDaysOfTheWeek: [0],
  },
});
