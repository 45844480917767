export const FETCH_LATEST_REQUESTED = 'FETCH_LATEST_REQUESTED';
export const FETCH_LATEST_SUCCEEDED = 'FETCH_LATEST_SUCCEEDED';
export const FETCH_LATEST_FAILED = 'FETCH_LATEST_FAILED';

export type FetchLatestAction = {
  type: typeof FETCH_LATEST_REQUESTED;
  payload: {};
};

/**
 * We fire one action that will trigger one return with possible sessions, entries, measures, etc. Those individual reducers then need to handle the possible returned data.
 */
export const fetchLatestAction = (): FetchLatestAction => ({
  type: FETCH_LATEST_REQUESTED,
  payload: {},
});
