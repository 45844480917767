import { httpsCallable } from 'firebase/functions';
import { FetchSessionAction } from '../stores/session/fetch_session_action';
import FirebaseClient from './firebase_client';

/**
 * Fetches a Session by ID
 * Returns complete session including baseline and entry values
 * @param action
 */
export async function doFetchSession(action: FetchSessionAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: FETCH SESSION REQUESTED');
    console.log(action);
    console.groupEnd();
  }

  try {
    const result = await httpsCallable(
      FirebaseClient.functions,
      'fetchSession',
    )(JSON.stringify({ id: action.payload.id }));
    // if (result.data.hasError) throw new Error(result.data);
    const session = result.data;
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: FETCH SESSION SUCCEEDED');
      console.log(session);
      console.groupEnd();
    }
    return session;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: FETCH SESSION FAILED');
      console.error(error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
}
