import React from 'react';
import { Link } from 'react-router-dom';
import { PrimaryButton } from './buttons/PrimaryButton';
import { SecondaryButton } from './buttons/SecondaryButton';

export const CallToAction = () => {
  return (
    <div>
      <div className='max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
        <h2 className='text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10'>
          Ready to dive in? And start flourishing today?
          <br />
        </h2>
        <div className='mt-8 flex justify-center'>
          <div className='inline-flex rounded-md shadow'>
            <Link to='/sign-in'>
              <PrimaryButton text='Sign in' />
            </Link>
          </div>
          <div className='ml-3 inline-flex'>
            <Link to='/sign-up'>
              <SecondaryButton text='Sign up' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
