import { Helmet } from 'react-helmet';
import {
  GRATITUDE_ACTIVITY,
  SAVORING_ACTIVITY,
  BEST_SELF_ACTIVITY,
  CHARACTER_STRENGTHS_ACTIVITY,
  KINDNESS_ACTIVITY,
  VOLUNTEER_ACTIVITY,
  JOB_CRAFTING_ACTIVITY,
  MARRIAGE_ACTIVITY,
  RELIGION_ACTIVITY,
  DEPRESSION_ACTIVITY,
  ANXIETY_ACTIVITY,
  FORGIVENESS_ACTIVITY,
} from '../../stores/activity/activity';
import {
  FLOURISHING_MEASURE,
  GRATITUDE_MEASURE,
  CHARACTER_STRENGTHS_MEASURE,
  MEANING_MEASURE,
} from '../../stores/measure/measure_type';

export const Science = () => {
  return (
    <div className='relative pb-10 overflow-hidden'>
      <Helmet>
        <title>Science of Flourishing</title>
      </Helmet>

      <header className='py-20 bg-white'>
        <div className='mx-auto max-w-7xl'>
          <h1 className='px-4 xl:px-0 text-5xl leading-160'>
            Science of <span className='text-burgundy'>Flourishing</span>{' '}
          </h1>
          <p className='mt-5 px-4 xl:px-0 text-sm leading-160'>
            Below, you'll find major publications for each of the activities and
            measures. This is a non-exhaustive list.
          </p>
        </div>
      </header>

      <main className='px-4'>
        <div className='py-10 mx-auto max-w-7xl'>
          <h2 className='mt-10 font-bold'>Activities</h2>
          <ul className='mt-10 space-y-10'>
            {scienceActivities
              .sort((a, b) => (a.type < b.type ? -1 : 1))
              .map((activity) => {
                return (
                  <div key={activity.type} className=''>
                    <h3 className='font-bold'>{activity.title}</h3>
                    <h4 className='text-base text-not-black'>
                      Outcomes: {activity.outcomes}
                    </h4>
                    <ul className='mt-8 space-y-5'>
                      {activity.refs.map((ref, i) => (
                        <li key={i} className='text-sm text-not-black'>
                          {ref}
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
          </ul>

          <h2 className='mt-10 font-bold'>Measures</h2>
          <ul className='mt-10 space-y-10'>
            {scienceMeasures
              .sort((a, b) => (a.type < b.type ? -1 : 1))
              .map((measure) => {
                return (
                  <div key={measure.type} className=''>
                    <h3 className='font-bold'>{measure.title}</h3>

                    <ul className='mt-8 space-y-5'>
                      {measure.refs.map((ref, i) => (
                        <li key={i} className='text-sm text-not-black'>
                          {ref}
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
          </ul>
        </div>
      </main>
    </div>
  );
};

export const scienceActivities = [
  {
    type: GRATITUDE_ACTIVITY,
    title: 'Gratitude Activity',
    outcomes: ['Happiness, Depression, Sleep'],
    refs: [
      `Emmons, R. A., & McCullough, M. E. (2003). Counting blessings versus burdens: An experimental investigation of gratitude and subjective well-being in daily   life. Journal   of   Personality   and   Social Psychology, 84,377-389.`,
      `Davis, D. E., Choe, E., Meyers, J., Wade, N., Varjas, K., Gifford,  A.,  Quinn,  A.,  Hook,  J.  N.,  Van  Tongeren, D.R.,  Griffin,  B.  J.,  &  Worthington,  E.  L.  (2016). Thankful  for  the  little  things:  a  meta-analysis  of gratitude    interventions. Journal    of    Counseling Psychology, 63,20-31.`,
    ],
  },
  {
    type: SAVORING_ACTIVITY,
    title: 'Savoring Activity',
    outcomes: ['Happiness, Life Satisfaction'],
    refs: [
      `Bryant,  F.  B.  &  Veroff,  J.  (2007): Savoring:  A  New Model    of    Positive    Experiences.    Mahwah,    NJ: Lawrence Erlbaum Associates.`,
      `Smith, J. L., Harrison, P. R., Kurtz, J. L., & Bryan, F. B. (2014). Nurturing the capacity to savor: interventions to enhance the enjoyment of positive experiences. In: A.C.  Parks  and  S.M.  Schueller  (eds.). The  Wiley Blackwell   Handbook   of   Positive   Psychological Interventions.  John  Wiley  and  Sons:  West  Sussex, U.K. p. 42-65.`,
    ],
  },
  {
    type: BEST_SELF_ACTIVITY,
    title: 'Imagining Your Best Possible Self Activity',
    outcomes: ['Happiness,Life Satistifaction, Optimisim'],
    refs: [
      `King, L. A. (2001). The health benefits of writing about life   goals.Personality   and   Social   Psychology Bulletin, 27,798-807.`,
      `Malouff,   J.   M.   &   Schutte,   N.   S.   (2017).   Can psychological  interventions  increase  optimism?  A meta-analysis. The  Journal  of  Positive  Psychology, 12(6),594-604.`,
    ],
  },
  {
    type: CHARACTER_STRENGTHS_ACTIVITY,
    title: 'Character Strengths Activity',
    outcomes: ['Happiness, Life Satistifaction, Depression'],
    refs: [
      `Seligman, M. E. P., Steen,T. A., Park, N., & Peterson, C.  (2005).  Positive  psychology  progress:  empirical validation  of  interventions. American  Psychologist, 60,410–421..`,
      `Schutte, N. S., & Malouff, J. M. (2019). The impact of signature  character  strengths  interventions:  A  meta-analysis. Journal  of  Happiness  Studies,  20(4),1179-1196.`,
      `Website: https://www.authentichappiness.sas.upeen.edu/testcenter`,
    ],
  },
  {
    type: KINDNESS_ACTIVITY,
    title: 'Kindness Activity',
    outcomes: ['Happiness, Engagement'],
    refs: [
      `Lyubomirsky, S., Sheldon, K. M., & Schkade, D. (2005). Pursuing  happiness:  the  architecture  of  sustainable change. Review of General Psychology, 9,111-131.`,
      `Curry, O. S., Rowland, L. A., Van Lissa, C. J., Zlotowitz, S., McAlaney, J., & Whitehouse, H. (2018). Happy to help?  A  systematic  review  and  meta-analysis  of  the effects  of  performing  acts  of  kindness  on  the  well-being  of  the  actor. Journal  of  Experimental  Social Psychology, 76,320–9.`,
    ],
  },
  {
    type: VOLUNTEER_ACTIVITY,
    title: 'Volunteering Activity',
    outcomes: ['Hpapiness, Social Connection, Health'],
    refs: [
      `Schreier,  H.  M.,  Schonert-Reichl,  K.  A.,  &  Chen,  E. (2013).  Effect  of  volunteering  on  risk  factors  for cardiovascular disease in adolescents: A randomized controlled trial. JAMA Pediatrics, 167(4),327-332.`,
      `Okun,  M.  A.,  Yeung,  E.  W.,  &  Brown,  S.  (2013). Volunteering by older adults and risk of mortality: A meta-analysis. Psychology and Aging, 28(2),564-577.`,
    ],
  },
  {
    type: JOB_CRAFTING_ACTIVITY,
    title: 'Job Crafting Activity',
    outcomes: ['Engagement'],
    refs: [
      `McKee-Ryan, F.,Song, Z., Wanberg, C. R., & Kinicki, A.  J.  (2005)  Psychological  and  physical  well-being during    unemployment:    A    meta-analytic    study. Journal of Applied Psychology, 90,53–76.`,
      `Frederick, D. E. & VanderWeele, T. J. (2018). A meta-analysis  of  the  association  of  job-crafting  and  work engagement. Preprintavailable at:https://osf.io/2xtk3/. Techical Report.`,
    ],
  },
  {
    type: MARRIAGE_ACTIVITY,
    title: 'Marriage Activity',
    outcomes: [
      'Meaning, Depression, Longevity, Life Satistifaction, Depression',
    ],
    refs: [
      `Doss, B. D., Cicila, L. N., Georgia, E. J., Roddy, M. K., Nowlan,  K.  M.,  Benson,  L.  A.,  &  Christensen,  A. (2016).  A  randomized  controlled  trial  of  the  web-based     OurRelationship     program:     Effects     on relationship  and  individual  functioning. Journal  of Consulting and Clinical Psychology. 84:285-296.`,
      `Manzoli,  L.,  Villari,  P.,  Pirone,  G.  M.,  &  Boccia,  A. (2007). Marital Status and Mortality in the Elderly: A Systematic    Review    and    Meta-Analysis.    Social Science & Medicine, 64,77-94.`,
    ],
  },
  {
    type: RELIGION_ACTIVITY,
    title: 'Religious Service Attendance Activity',
    outcomes: ['Depression, Suicide, Longevity'],
    refs: [
      `Li,  S.,  Okereke,  O.  I.,  Chang,  S.-C.,  Kawachi,  I.,  & VanderWeele,   T.   J.   (2016a).   Religious   service attendance   and   depression   among   women –a prospective   cohort   study. Annals   of   Behavioral Medicine, 50,876-884.`,
      `Chida,   Y.,   Steptoe,   A.,   &   Powell,   L.   H.   (2009). Religiosity/spirituality  and  mortality:  A    systematic quantitative review. Psychotherapy and Psychosomatics, 78(2),81–90.`,
    ],
  },
  {
    type: DEPRESSION_ACTIVITY,
    title: 'Depression Activity',
    outcomes: ['Depression'],
    refs: [
      `Burns, D. (1999). Feeling Good: The new mood therapy(Rev. ed.). New York: Avon.`,
      `Anderson, L., Lewis, G., Araya, R., Elgie, R., Harrison, G., Proudfoot, J., Schmidt, U., Sharp, D., Weightman, A.,  &  Williams,  C.  (2005).  Self-help  books  for depression: how can practitioners and patients make the right choice?British Journal of General Practice, 55,387-392.`,
    ],
  },
  {
    type: ANXIETY_ACTIVITY,
    title: 'Anxiety Activity',
    outcomes: ['Anxiety'],
    refs: [
      `Barlow, D. H. & Craske M. G. (2007). Mastery of Your Anxiety  and  Panic.  4th  Edition.  New  York:  Oxford University Press.`,
      `Hecker, J. E., Loses, M. C., Fritzler, B. K., & Fink, C. M. (1996).     Self-directed     versus     therapist-directed cognitive  behavioral  treatment  for  panic  disorder. Journal of Anxiety Disorders, 10,253-265.`,
    ],
  },
  {
    type: FORGIVENESS_ACTIVITY,
    title: 'Forgiveness Activity',
    outcomes: ['Depression, Anxiety, Hope'],
    refs: [
      `Harper, Q., Worthington, E. L., Griffin, B. J., Lavelock, C.  R.,  Hook,  J.  N.,  Vrana,  S.  R.,  &  Greer,  C.  L. (2014).    Efficacy    of    a    workbook    to    promote forgiveness:   a   randomized   controlled   trial   with university  students. Journal  of  Clinical  Psychology, 70,1158–1169.`,
      `Wade, N. G., Hoyt,W. T., Kidwell, J. E., & Worthington E.    L.    (2014).    Efficacy    of    psychotherapeutic interventions   to   promote   forgiveness:   a   meta-analysis. Journal    of    Consulting    and    Clinical Psychology, 82,154-170.`,
    ],
  },
];

export const scienceMeasures = [
  {
    type: FLOURISHING_MEASURE,
    title: 'Flourishing Measure',
    refs: [
      `Website: https://hfh.fas.harvard.edu/measuring-flourishing`,
      `VanderWeele, T.J. (2017). On the promotion of human flourishing. Proceedings of the National Academy of Sciences, U.S.A., 31:8148-8156.`,
    ],
  },
  {
    type: GRATITUDE_MEASURE,
    title: 'Gratitude Measure',
    refs: [
      `Website: http://www.midss.org/content/gratitude-questionaire-gq-6`,
      `Gray, S. A., Emmons, R. A., & Morrison, A. (2001 August). Distinguishing gratitude from indebtedness in affect and action tendencies. Poster presented at the annual meeting of the American Psychological Association, San Francisco, CA.`,
      `McCullough, M. E., Emmons, R. A., & Tsang, J. (2002). The grateful disposition: A conceptual and empirical topography. Journal of Personality and Social Psychology, 82, 112-127.`,
      `McCullough, M. E., Tsang, J., & Emmons, R. A. (2004). Gratitude in intermediate affective terrain: Links of grateful moods to individual differences and daily emotional experience. Journal of Personality and Social Psychology, 86, 295-309`,
    ],
  },
  {
    type: CHARACTER_STRENGTHS_MEASURE,
    title: 'Character Strengths Measure',
    refs: [
      `Website: https://www.viacharacter.org/character-strengths`,
      `Research Findings: https://www.viacharacter.org/research/findings`,
    ],
  },
  {
    type: MEANING_MEASURE,
    title: 'Meaning Measure',
    refs: [
      `Website: https://hfh.fas.harvard.edu/other-well-being-measures`,
      `Hanson, J.A. and VanderWeele, T.J. (2020). The Comprehensive Measure of Meaning: psychological and philosophical foundations. In: M. Lee, L.D. Kubzansky, and T.J. VanderWeele (Eds.). Measuring Well-Being: Interdisciplinary Perspectives from the Social Sciences and the Humanities. Oxford University Press, forthcoming.`,
    ],
  },
];
