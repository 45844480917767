import { Measure } from '../../stores/measure/measure_type';
import { MEASURE_TEXT } from '../../text/measures';

export const CharacterStrengthsResult = ({ measure }: { measure: Measure }) => {
  const text = MEASURE_TEXT[measure.type];
  return (
    <div className='mt-10 px-4 xl:px-0 mx-auto max-w-7xl'>
      <header>
        <div className='relative flex items-center justify-center h-24 w-24 bg-burgundy rounded-full shadow'>
          <img
            className='p-2 h-20 w-20'
            src='/icons/character-white.svg'
            alt=''
          />
        </div>

        <h1 className='my-5 text-burgundy text-5xl leading-160'>
          {text?.title} Results
        </h1>

        <p className='mt-5 text-burgundy leading-160'>
          {new Date(measure.stoppedAt).toDateString()}
        </p>
      </header>

      <div className='mt-10 space-y-5'>
        <p>{text?.instructions}</p>
        <p>{text?.prompt}</p>
      </div>

      <h2 className='my-10 text-burgundy text-2xl font-bold leading-160'>
        Your Top Character Strengths
      </h2>

      <ul className='space-y-5'>
        {measure.payload.responses
          .sort((a, b) => (a < b ? -1 : 1))
          .map((response, i) => (
            <li className='text-burgundy leading-160' key={i}>
              {response}
            </li>
          ))}
      </ul>
      <div className='pb-20' />
    </div>
  );
};
