export const IsLoading = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div className='pt-10 grid grid-cols-1 place-items-center'>
        Loading ...
      </div>
    );
  }
  return null;
};
