import { AppState } from '../types';
import { accountInitialState } from './account/account_reducer';
import { createMetaState } from './meta/meta';
import { createNetwork } from './network/network_reducer';
import { createEmptySession } from './session/new_session_reducer';

if (process.env.NODE_ENV !== 'production') {
  console.group('DEFAULT STORE BUILDING');
  console.groupEnd();
}

const defaultStore: AppState = {
  account: accountInitialState,
  sessions: [],
  newSession: createEmptySession(),
  measures: [],
  meta: createMetaState(),
  nav: { header: '' },
  network: createNetwork(),
}; // as DeepPartial<PersistPartial>;

if (process.env.NODE_ENV !== 'production') {
  console.group('DEFAULT STORE BUILDING');
  console.log(defaultStore);
  console.groupEnd();
}

export { defaultStore };
