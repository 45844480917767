import { UserCredential } from 'firebase/auth';

export const DELETE_ACCOUNT_REQUESTED = 'DELETE_ACCOUNT_REQUESTED';
export const DELETE_ACCOUNT_SUCCEEDED = 'DELETE_ACCOUNT_SUCCEEDED';
export const DELETE_ACCOUNT_FAILED = 'DELETE_ACCOUNT_FAILED';

export const SIGN_OUT_REQUESTED = 'SIGN_OUT_REQUESTED';
export const SIGN_OUT_SUCCEEDED = 'SIGN_OUT_SUCCEEDED';
export const SIGN_OUT_FAILED = 'SIGN_OUT_FAILED';

export const SIGN_IN_GOOGLE_REQUESTED = 'SIGN_IN_GOOGLE_REQUESTED';
export const SIGN_IN_GOOGLE_SUCCEEDED = 'SIGN_IN_GOOGLE_SUCCEEDED';
export const SIGN_IN_GOOGLE_FAILED = 'SIGN_IN_GOOGLE_FAILED';

export const SIGN_IN_WITH_GOOGLE_NATIVE_REQUESTED =
  'SIGN_IN_WITH_GOOGLE_NATIVE_REQUESTED';
export const SIGN_IN_WITH_GOOGLE_NATIVE_SUCCEEDED =
  'SIGN_IN_WITH_GOOGLE_NATIVE_SUCCEEDED';
export const SIGN_IN_WITH_GOOGLE_NATIVE_FAILED =
  'SIGN_IN_WITH_GOOGLE_NATIVE_FAILED';

export const SIGN_IN_WITH_CREDENTIAL_REQUESTED =
  'SIGN_IN_WITH_CREDENTIAL_REQUESTED';
export const SIGN_IN_WITH_CREDENTIAL_SUCCEEDED =
  'SIGN_IN_WITH_CREDENTIAL_SUCCEEDED';
export const SIGN_IN_WITH_CREDENTIAL_FAILED = 'SIGN_IN_WITH_CREDENTIAL_FAILED';

export const SIGN_IN_ATTEMPT = 'SIGN_IN_ATTEMPT';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const IS_SIGNED_IN = 'IS_SIGNED_IN';

export const USER_FETCH_REQUESTED = 'USER_FETCH_REQUESTED';
export const EMAIL_ACCOUNT = 'EMAIL_ACCOUNT';
export const FACEBOOK_ACCOUNT = 'FACEBOOK_ACCOUNT';
export const GOOGLE_ACCOUNT = 'GOOGLE_ACCOUNT';

export type AccountState = {
  uid: string;
  fullname: string;
  email: string;
  isEmailVerified: boolean;
  isSignedIn: boolean;
  lastSignedIn?: Date;
  photoUrl: string;
  allowAppNotification: boolean;
  expoToken?: string;
  type: '' | typeof EMAIL_ACCOUNT | typeof GOOGLE_ACCOUNT;
  signInError: {
    hasError: boolean;
    error: string;
  };
  weeklyEmailReminders: boolean;
};

export type AccountAction =
  | AccountSignInAction
  | AccountSignOutAction
  | AccountDeleteAction;

export type AccountDeleteAction = {
  type: string;
  payload: {};
};

export type AccountSignInAction =
  | AccountSignInWithEmailAction
  | AccountSignInWithGoogleAction
  | AccountSignInSucceededAction;

export type AccountSignInSucceededAction = {
  type:
    | typeof SIGN_IN_WITH_CREDENTIAL_SUCCEEDED
    | typeof SIGN_IN_GOOGLE_SUCCEEDED;
  payload: UserCredential;
};

export type AccountSignInWithEmailAction = {
  type: string;
  payload: AccountSignInWithEmailPayload;
};
export type AccountSignInWithEmailPayload = {
  email: string;
  password: string;
};

export type AccountSignInWithGoogleAction = {
  type: string;
  payload: {};
};
export type AccountSignInWithGooglePayload = {
  email: string;
  password: string;
};

export type AccountSignOutAction = {
  type: typeof SIGN_OUT_REQUESTED | typeof SIGN_OUT_SUCCEEDED;
  payload: {};
};
