import { useSelector } from 'react-redux';
import { AppState } from '../../types';
import { Redirect, useHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import { SignInUpForm } from '../../components/SignInUp';

export const SignInUpPage = () => {
  const history = useHistory();
  const type = history.location.pathname.includes('sign-in')
    ? 'sign-in'
    : 'sign-up';
  const title = type
    .replace('-', ' ')
    .split(' ')
    .map((word) => {
      let words = word.split('');
      words[0] = words[0].toUpperCase();
      return words.join('');
    })
    .join(' ');
  const account = useSelector((state: AppState) => state.account);

  if (account.isSignedIn) {
    return <Redirect to='/' />;
  }

  return (
    <div className='min-h-screen'>
      <Helmet>
        <title className='uppercase'>{title}</title>
      </Helmet>
      <SignInUpForm type={type} />
    </div>
  );
};
