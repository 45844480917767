/**
 * This file contains a set of util functions for determing if entries are upcoming, available, etc
 *
 */
import { Measure } from '../stores/measure/measure_type';
import { Session } from '../stores/session/session_types';

//TODO: write tests for this

export function checkIsUpcoming(session) {
  let yesterday = new Date();
  let nextWeek = new Date();
  yesterday.setDate(yesterday.getDate() - 1); //yesterday in INT
  nextWeek.setDate(nextWeek.getDate() + 7); //yesterday in INT
  const matches = session.entries
    .filter(
      (entry) =>
        !entry.completed &&
        entry.scheduledDate >= yesterday &&
        entry.scheduledDate <= nextWeek,
    )
    .map((scheduled) => ({ type: session.type, ...scheduled }));
  return matches;
}

export function checkIsAvailable(session) {
  let tomorrow = new Date();
  let lastWeek = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1); //yesterday in INT
  lastWeek.setDate(lastWeek.getDate() - 7); //yesterday in INT
  const matches = session.entries
    .filter(
      (entry) =>
        !entry.completed &&
        entry.scheduledDate <= tomorrow &&
        entry.scheduledDate >= lastWeek,
    )
    .map((scheduled) => ({ type: session.type, ...scheduled }));
  return matches;
}

export function checkIsAvailableOrUpcoming(session) {
  let nextWeek = new Date();
  let lastWeek = new Date();
  nextWeek.setDate(nextWeek.getDate() + 7); //
  lastWeek.setDate(lastWeek.getDate() - 7); //
  const matches = session.entries
    .filter(
      (entry) =>
        !entry.completed &&
        entry.scheduledDate <= nextWeek &&
        entry.scheduledDate >= lastWeek,
    )
    .map((scheduled) => ({ type: session.type, ...scheduled }));
  return matches;
}

export function isAvailable(entry) {
  if (entry.completed) {
    return false;
  }
  let today = new Date();
  let lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7); //
  return entry.scheduledDate <= today && entry.scheduledDate >= lastWeek;
}

/**
 * Check to see if we should suggest the user to (re)take the Flourishing Measure
 * We suggest if the measure was last taken more than 30 days ago
 * @param measure
 */
export function checkFlourishing(measure: Measure) {
  // suggestRetakeFlourishing = !moment(flourishing[0].startedAt).isAfter(
  //   moment().subtract(1, 'month'),
  // );
  let oneMonthAgo = new Date();
  oneMonthAgo.setDate(oneMonthAgo.getDate() - 30); //
  return measure.stoppedAt < oneMonthAgo.getTime();
}

// const nextEntry = session.entries.find(entry => {
//   return moment(entry.scheduledDate).isBetween(
//     yesterday,
//     nextWeek,
//     'day',
//     '()',
//   );
// });
export function findNextEntry(session: Session) {
  //FIXME: the date object get mutated with the date methods
  let d = new Date();
  const yesterday = d.setDate(d.getDate() - 1); //yesterday in INT
  const nextWeek = d.setDate(d.getDate() + 7); //yesterday in INT
  return session.entries.find(
    (entry) =>
      !entry.completed &&
      entry.scheduledDate >= yesterday &&
      entry.scheduledDate <= nextWeek,
  );
}
