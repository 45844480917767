// import '@firebase/auth';
// import * as firebase from 'firebase/app';
import { signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { FirebaseClient } from '.';
import firebase from './firebase_client';

// https://firebase.google.com/docs/auth/web/manage-users
// https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user

export const doChangePassword = async ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string;
  newPassword: string;
}) => {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: CHANGE PASSWORD');
    console.groupEnd();
  }

  try {
    const email = firebase.auth.currentUser?.email;
    if (!email) throw new Error('No email');
    const userCredential = await signInWithEmailAndPassword(
      FirebaseClient.auth,
      email,
      currentPassword,
    );
    if (process.env.NODE_ENV !== 'production') {
      console.log(userCredential);
    }
    const currentUser = firebase.auth.currentUser;
    if (currentUser === null) {
      throw Error('User not found');
    }
    await updatePassword(currentUser, newPassword);
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: CHANGE PASSWORD');
      console.groupEnd();
    }
    return { hasError: false };
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: CHANGE PASSWORD FAILED');
      console.error('Error: ', error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
};
