/**
 * AppActivity Page
 * - shown when SignedIn and Activity is not Active
 *
 */
import { TEXT } from '../../text/activities';
import { ErrorBoundary } from '../../router/ErrorBoundary';
import { ACTIVITY_IMAGES } from '../../assets/activity_images';
import { getUrlFromType } from '../../utils/get_url_from_type';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { newSessionAction } from '../../stores/session/new_session_action_creators';
import { useEffect } from 'react';
import { DisclosurePanel } from '../about/Activities';
import { Stepper } from './intake/Stepper';
import { AppState } from '../../types';

export const AppActivity = ({ type }: { type: string }) => {
  const isSignedIn = useSelector((state: AppState) => state.account.isSignedIn);
  const dispatch = useDispatch();
  const {
    instructions,
    hasBaseline,
    header,
    general,
    quotation,
    science,
    exercise,
  } = TEXT[type];

  const activityUrl = getUrlFromType(type, true);

  const toUrl = hasBaseline
    ? `/app/activities/${activityUrl}/new/baseline`
    : `/app/activities/${activityUrl}/new/reflection`;

  console.log(toUrl);

  const img = ACTIVITY_IMAGES[type];

  useEffect(() => {
    dispatch(newSessionAction({ type }));
  }, [dispatch, type]);

  return (
    <ErrorBoundary>
      <div className='relative min-h-screen'>
        <Helmet>
          <title></title>
        </Helmet>

        <div
          className='relative h-96 w-full bg-center bg-cover '
          style={{ backgroundImage: `url(${img})` }}>
          <div className='absolute h-full w-full bg-black opacity-50' />
          <div className='h-64 flex flex-col justify-center'>
            <p className='relative px-2 xl:px-0 mx-auto max-w-7xl text-white text-xs leading-160 md:text-base md:leading-160 text-center whitespace-pre-wrap'>
              {quotation}
            </p>
          </div>
        </div>

        <div className='relative z-10 px-4 md:px-20 py-10 -mt-32 mx-auto max-w-7xl grid grid-cols-1 place-item-center gap-8 bg-white text-center'>
          <header>
            <h2 className='text-burgundy text-sm leading-160'>{exercise}</h2>
            <h1 className='text-burgundy text-5xl leading-160'>{header}</h1>
          </header>
          <div className='space-y-5'>
            <p className='text-sm leading-160 whitespace-pre-line'>{general}</p>
            <p className='text-sm leading-160 whitespace-pre-line'>
              {/* {instructions} */}
            </p>
          </div>
          {/* TODO: Extract this button for use in the TODO controller/parent pattern */}
          <Link
            className='mx-auto h-14 w-300 py-2 flex items-center justify-center border border-burgundy text-burgundy rounded-full'
            to={toUrl}>
            <span>Start Activity</span>
          </Link>
          {/* TODO: extract this stepper into a component; and make this whole intake make more sense. there should be one parent/controller component that wraps these individual components; the header, header text, and position on the screen don't change between 'pages' */}
          <Stepper step={'instructions'} hasBaseline={hasBaseline} />

          {/* <section className='mt-10'>
            <h2>Science</h2>
            <ul className='space-y-3'>
              {science.map((text, i) => {
                return (
                  <li className='text-sm text-not-black' key={i}>
                    {text}
                  </li>
                );
              })}
            </ul>
          </section> */}
        </div>
      </div>
      {!isSignedIn && (
        <section>
          <DisclosurePanel
            title='Overview'
            type={type}
            section='instructions'
            defaultOpen={true}
          />
          <DisclosurePanel
            title='Getting Started'
            type={type}
            section='gettingStarted'
          />
          <DisclosurePanel
            title='First Entry'
            type={type}
            section='firstEntry'
          />
          <DisclosurePanel
            title='Weekly Instructions'
            type={type}
            section='weeklyInstructions'
          />
          <DisclosurePanel
            title='Science Behind the Activity'
            type={type}
            section='science'
          />
        </section>
      )}
      <div className='py-14' />
    </ErrorBoundary>
  );
};
