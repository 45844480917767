import React from 'react';
import { Helmet } from 'react-helmet';

export const Updates = () => {
  return (
    <div className='relative pb-10 overflow-hidden'>
      <Helmet>
        <title>Updates</title>
      </Helmet>
      <header className='py-20 bg-white'>
        <div className='mx-auto max-w-7xl px-4 xl:px-0'>
          <h1 className='text-5xl leading-160'>
            The <span className='text-burgundy'>Flourishing App</span> Updates
          </h1>
          <dl className='my-4 flex space-x-2'>
            <dt className=''>Version</dt>
            <dd className='font-bold'>2.0.0-beta</dd>
          </dl>
        </div>
      </header>
      <main className='px-4 xl:px-0 overflow-hidden'>
        <div className='relative max-w-7xl mx-auto py-16'>
          <p>Questions can be directed to flourishingapp at gmail dot com</p>
          <div className='mt-10 space-y-10'>
            {/*  */}
            <div className='space-y-10'>
              {/*  */}
              <h2 className='font-bold border-b'>Planned Features</h2>
              <ul className='space-y-2'>
                <li>
                  Welcome survey to help you select which activity to start
                </li>
                <li>
                  Additional questions for the new activity intake as well as
                  additional questions for the weekly activity entries
                </li>
                <li>Weekly email reminders of activities</li>
              </ul>
            </div>

            <h2 className='font-bold border-b'>Updates</h2>

            <div>
              <h2>April 2022</h2>
              <p>Moble Apps - Public Beta</p>
              <p>
                We've released public betas for the Flourishing App designed for
                <a
                  className='underline'
                  href='https://apps.apple.com/us/app/flourishing-app/id1493593636?platform=iphone'
                  target='_blank'
                  rel='noopener noreferrer'>
                  {' '}
                  Apple iOS{' '}
                </a>
                and
                <a
                  className='underline'
                  href='https://play.google.com/store/apps/details?id=com.neurofoo.flourishing.app&hl=en_US&gl=US'
                  target='_blank'
                  rel='noopener noreferrer'>
                  {' '}
                  Android{' '}
                </a>
              </p>
            </div>

            <div>
              <h2>September 2021</h2>
              <p>V2 Public Beta</p>
              <p>
                Released a public beta of V2.0 for feedback at:{' '}
                <a
                  className='underline'
                  href='https://hfprogram--v2-4uzvryc3.web.app/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  https://hfprogram--v2-4uzvryc3.web.app/
                </a>
              </p>
            </div>

            <div>
              <h2>November 17, 2020</h2>
              <p>Update user Account page.</p>
              <p>
                Add option to be notified weekly of activities in progress via
                email. To enable this visit the account page.
              </p>
            </div>

            <div>
              <h2>November 1, 2020</h2>
              <p>
                Changing how the Flourishing Measure is scored. Instead of
                showing the total score, we are now showing the average.
              </p>
            </div>

            <div>
              <h2>October 20, 2020</h2>
              <p>V1.0.0-beta launched</p>
            </div>

            <div>
              <h2>October 1, 2020</h2>
              <p>
                Version 1.0 of the Flourishing App is in developement and set to
                launch end of October 2020. The new version includes improved
                user interfaces and better descriptions of the activities and
                measures. A mobile app version will be launched this fall as
                well.
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
