import { Link } from 'react-router-dom';
import {
  BEST_SELF_ACTIVITY,
  GRATITUDE_ACTIVITY,
  SAVORING_ACTIVITY,
} from '../../stores/activity/activity';
import { Measure } from '../../stores/measure/measure_type';
import { FLOURISHING_MEASURE_SURVEY_QUESTIONS } from '../../text/measures';

const DOMAINS = [
  'Happiness and Life Satisfaction',
  'Mental and Physical Health',
  'Meaning and Purpose',
  'Character and Virtue',
  'Close Social Relationship',
  'Financial and Material Stabity',
];

export const FlourishingResult = ({ measure }: { measure: Measure }) => {
  // const score = (
  //   measure.payload.responses.reduce((v, s) => v + s, 0) / 12
  // ).toFixed(1);
  const score = (
    measure.payload.responses.reduce((sum: number, v) => {
      const val = typeof v === 'string' ? 0 : v;
      return sum + val;
    }, 0) / 12
  ).toFixed(1);

  const questions = FLOURISHING_MEASURE_SURVEY_QUESTIONS;
  const domains = [0, 2, 4, 6, 8, 10]
    .map((i, n) => {
      let s = measure.payload.responses[i] + measure.payload.responses[i + 1];
      s = s / 2;
      return {
        domain: n,
        value: s,
        q1: questions[i].q,
        q2: questions[i + 1].q,
        a1: measure.payload.responses[i],
        a2: measure.payload.responses[i + 1],
        anchorsQ1: [questions[i].a[0].text, questions[i].a[10].text],
        anchorsQ2: [questions[i + 1].a[0].text, questions[i + 1].a[10].text],
      };
    })
    .sort((a, b) => b.value - a.value);
  console.log(domains);

  return (
    <div className='px-5 xl:px-0 py-10 mx-auto max-w-7xl'>
      <div className='relative flex items-center justify-center h-24 w-24 bg-burgundy rounded-full shadow'>
        <img
          className='p-2 h-20 w-20'
          src='/icons/flourishing-white.svg'
          alt=''
        />
      </div>

      <header className='md:flex items-baseline justify-between border-b text-burgundy space-y-5 md:space-y-0 '>
        <h1 className='mt-10 text-5xl text-burgundy'>
          Your Flourishing Measure Results
        </h1>
        <h2 className='text-xl'>
          Overall Score: <span className='text-2xl font-bold'>{score}</span> /10
        </h2>
      </header>

      <div className='mt-5' />
      <p className='text-burgundy'>
        {new Date(measure.stoppedAt).toDateString()}
      </p>
      <div className='mt-5' />

      {/* <section className='mt-10 flex items-center space-x-5'>
        <h2>
          Your Average <br /> Flourishing Score
        </h2>
        <h2 className='text-4xl font-bold'>{score} / 10</h2>
      </section> */}

      <section className='mt-20 space-y-10'>
        <ul className='space-y-10'>
          {domains.map((domain) => {
            const title = DOMAINS[domain.domain];
            const suggest = SUGGESTED[domain.domain];
            return (
              <div className='space-y-5' key={domain.domain}>
                <header className='border-b flex justify-between font-bold'>
                  <h2 className='text-base md:text-xl'>{title}</h2>
                  <p className='text-sm md:text-xl font-bold'>
                    <span className='text-2xl font-bold'>{domain.value}</span>
                    /10{' '}
                  </p>
                </header>
                {/* TODO: questions for this domain */}
                <div className='space-y-2'>
                  <Answer
                    answer={domain.a1}
                    anchor={domain.anchorsQ1.join(', ')}
                    question={domain.q1}
                  />
                  <Answer
                    answer={domain.a2}
                    anchor={domain.anchorsQ2.join(', ')}
                    question={domain.q2}
                  />
                </div>
                <ul className='flex flex-wrap space-x-2.5 items-center'>
                  <p className='text-burgundy '>Suggested activities:</p>
                  {suggest.recommend.map((item, i) => (
                    <Link
                      className='text-burgundy underline'
                      to={item?.to}
                      key={i}>
                      {item?.text}
                    </Link>
                  ))}
                </ul>
              </div>
            );
          })}
        </ul>
      </section>
    </div>
  );
};

const Answer = ({ answer, anchor, question }) => (
  <div className='flex flex-wrap items-center space-x-5 text-sm md:text-base'>
    <p>{question}</p>
    <AnswerIcon answer={answer} />
    <p>{`(${anchor})`}</p>
  </div>
);

const AnswerIcon = ({ answer }) => (
  <div className='relative flex justify-center items-center h-10 w-10 bg-burgundy text-white text-center leading-160 rounded-full'>
    <span>{answer}</span>
  </div>
);

const ACTIVITIES = {
  GRATITUDE_ACTIVITY: { text: 'Gratitude', to: '/about/activities/gratitude' },
  SAVORING_ACTIVITY: { text: 'Savoring', to: '/about/activities/savoring' },
  BEST_SELF_ACTIVITY: { text: 'Best Self', to: '/about/activities/best-self' },
  CHARACTER_STRENGTHS_MEASURE: {
    text: 'Character Strengths',
    to: '/about/activities/character-strengths',
  },
  KINDNESS_ACTIVITY: {
    text: 'Kindness',
    to: '/about/activities/kindness',
  },
  VOLUNTEER_ACTIVITY: {
    text: 'Volunteering',
    to: '/about/activities/volunteer',
  },
  JOB_CRAFTING_ACTIVITY: {
    text: 'Job Crafting',
    to: '/about/activities/job-crafting',
  },
  RELIGION_ACTIVITY: {
    text: 'Religious Service Attendance',
    to: '/about/activities/religion',
  },
  MARRIAGE_ACTIVITY: { text: 'Marriage', to: '/about/activities/marriage' },
  DEPRESSION_ACTIVITY: {
    text: 'Depression',
    to: '/about/activities/depression',
  },
  ANXIETY_ACTIVITY: { text: 'Anxiety', to: '/about/activities/anxiety' },
  FORGIVENESS_ACTIVITY: {
    text: 'Forgiveness',
    to: '/about/activities/forgiveness',
  },
};

const SUGGESTED = {
  0: {
    recommend: [
      ACTIVITIES[GRATITUDE_ACTIVITY],
      ACTIVITIES[SAVORING_ACTIVITY],
      ACTIVITIES[BEST_SELF_ACTIVITY],
    ],
  },
  1: {
    recommend: [
      ACTIVITIES.DEPRESSION_ACTIVITY,
      ACTIVITIES.ANXIETY_ACTIVITY,
      ACTIVITIES.FORGIVENESS_ACTIVITY,
    ],
  },
  2: {
    recommend: [
      ACTIVITIES.BEST_SELF_ACTIVITY,
      ACTIVITIES.CHARACTER_STRENGTHS_MEASURE,
    ],
  },
  3: {
    recommend: [
      ACTIVITIES.CHARACTER_STRENGTHS_MEASURE,
      ACTIVITIES.KINDNESS_ACTIVITY,
      ACTIVITIES.VOLUNTEER_ACTIVITY,
    ],
  },
  4: {
    recommend: [
      ACTIVITIES.MARRIAGE_ACTIVITY,
      ACTIVITIES.RELIGION_ACTIVITY,
      ACTIVITIES.VOLUNTEER_ACTIVITY,
      ACTIVITIES.KINDNESS_ACTIVITY,
    ],
  },
  5: {
    recommend: [
      ACTIVITIES.JOB_CRAFTING_ACTIVITY,
      ACTIVITIES.CHARACTER_STRENGTHS_MEASURE,
      ACTIVITIES.BEST_SELF_ACTIVITY,
    ],
  },
};
