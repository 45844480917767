import { v4 as uuidv4 } from 'uuid';

import { Entry } from '../stores/session/session_types';

/**
 * Make the schedule of entries for an activity session
 * @param startedAt
 * @param daysOfWeek
 */
export const makeScheduledEntries = (daysOfWeek: number[]): Entry[] => {
  const today = new Date();
  const startDayOfWeek = today.getDay();

  // figure out the new dates corresponding to the day of the week(s) selected
  // e.g., if today is thursday and i selected sunday, then i need sunday's date
  // this is basically a mapreduce projection problem
  const todayNumber: number = new Date().getDay();

  if (process.env.NODE_ENV !== 'production') {
    console.group('MAKE SCHEDULE');
    console.log('Today', today.toLocaleString());
    console.log('start date', startDayOfWeek);
    console.log('days of week', daysOfWeek, typeof daysOfWeek[0]);
    console.log('Today number: ', todayNumber);
    console.groupEnd();
  }

  let scheduledEntries: Entry[] = [];

  for (let d = 0; d < daysOfWeek.length; d++) {
    const dayToFind = daysOfWeek[d];

    if (process.env.NODE_ENV !== 'production') {
      console.log('Day to Find', dayToFind, 'Today', todayNumber);
    }

    let daysToAdd = 0;

    if (todayNumber === dayToFind) {
      daysToAdd = 7;
    } else if (dayToFind < todayNumber) {
      daysToAdd = 7 - todayNumber + dayToFind;
      if (process.env.NODE_ENV !== 'production') {
        console.log('A. Days To Add', daysToAdd, todayNumber, dayToFind);
      }
    } else {
      daysToAdd = dayToFind - todayNumber;
      if (process.env.NODE_ENV !== 'production') {
        console.log('B. Days To Add', daysToAdd);
      }
    }
    //
    // const baseDay = moment(today).add(daysToAdd, 'days');
    let baseDay = new Date();
    baseDay.setDate(baseDay.getDate() + daysToAdd);

    if (process.env.NODE_ENV !== 'production') {
      console.log('Found first day: ', baseDay.toLocaleString(), dayToFind);
    }

    // After finding the starting day, project out for the scheduled days
    for (let i = 0; i < 6; i++) {
      // const newDate = moment(baseDay).add(i, 'weeks');
      let newDate = new Date(baseDay);
      newDate.setDate(newDate.getDate() + 7 * i);
      const newScheduledEntry: Entry = {
        payload: {},
        startedAt: 0,
        stoppedAt: 0,
        uuid: uuidv4(),
        scheduledDate: newDate.valueOf(),
        completed: false,
        weekNumber: i,
        entryNumber: 0, //will be set later after the sort
      };
      if (process.env.NODE_ENV !== 'production') {
        console.log(new Date(newScheduledEntry.scheduledDate).toDateString());
      }
      scheduledEntries.push(newScheduledEntry);
    }
  }

  // Sort entries b/c might be out of order due to gratitude activity
  scheduledEntries = scheduledEntries.sort(
    (a, b) => a.scheduledDate - b.scheduledDate,
  );

  // Finally, we set the entry numbers now that the order is fixed
  scheduledEntries = scheduledEntries.map((entry, i) => ({
    ...entry,
    entryNumber: i,
  }));

  if (process.env.NODE_ENV !== 'production') {
    console.log('Full Schedule');
    scheduledEntries.map((s) =>
      // console.log(moment(s.scheduledDate).toLocaleString()),
      console.log(new Date(s.scheduledDate).toDateString()),
    );
  }

  return scheduledEntries;
};
