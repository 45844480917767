import { isUndefined, unionWith } from 'lodash';
import {
  CREATE_MEASURE_SUCCEEDED,
  FETCH_MEASURES_SUCCEEDED,
  FETCH_MEASURE_SUCCEEDED,
  FETCH_RECENT_MEASURES_SUCCEEDED,
} from '.';
import { FETCH_HISTORY_SUCCEEDED } from '../other/fetch_history_action';
import { FETCH_LATEST_SUCCEEDED } from '../other/fetch_latest_action';
import { CREATE_SESSION_SUCCEEDED } from '../session/create_section_action';
import { FETCH_ACTIVE_SESSIONS_SUCCEEDED } from '../session/fetch_active_sessions_action';
import { Session } from '../session/session_types';
import { Measure, MeasureState } from './measure_type';

export const initialMeasureState = [];

// TODO: need some middleware to keep the store sorted instead of having code for each of the cases below

export function measureReducer(
  state: MeasureState | undefined,
  action: any, //MeasureAction
): MeasureState {
  if (isUndefined(state)) {
    return [];
  }

  switch (action.type) {
    //
    case FETCH_ACTIVE_SESSIONS_SUCCEEDED:
      // TODO: need to pull out the measures from here
      const activeSessionsWithMeasures = action.payload.filter(
        (session: Session) => session.baselineMeasures.length > 0,
      );
      const activeSessionsMeasures: Measure[] = [];
      activeSessionsWithMeasures.forEach((session: Session) => {
        session.baselineMeasures.forEach((measure: Measure) => {
          activeSessionsMeasures.push(measure);
        });
      });
      if (process.env.NODE_ENV !== 'production') {
        console.group('MEASURE REDUCER: FETCH ACTIVE SESSIONS SUCCEEDED');
        console.log(activeSessionsMeasures);
        console.groupEnd();
      }
      // if (activeSessionsMeasures.length === 0) {
      //   return state;
      // }
      return unionWith(
        activeSessionsMeasures,
        state,
        (server, local) => server.id === local.id,
      );

    case CREATE_MEASURE_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: NEW MEASURE ENTRY SUCCEEDED');
        console.log(action);
        console.groupEnd();
      }
      return unionWith(
        action.payload,
        state,
        (server, local) => server.id === local.id,
      ).sort((a, b) => b.startedAt - a.startedAt);

    case CREATE_SESSION_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('MEASURE REDUCER: CREATE SESSION ENTRY SUCCEEDED');
        console.groupEnd();
      }
      return unionWith(
        action.payload.measures,
        state,
        (server, local) => server.id === local.id,
      ).sort((a, b) => b.startedAt - a.startedAt);

    case FETCH_HISTORY_SUCCEEDED:
      return unionWith(
        action.payload.measures,
        state,
        (server, local) => server.id === local.id,
      ).sort((a, b) => b.startedAt - a.startedAt);

    case FETCH_LATEST_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: FETCH LATEST SUCCEEDED');
        console.groupEnd();
      }
      return unionWith(
        action.payload.measures,
        state,
        (server, local) => server.id === local.id,
      ).sort((a, b) => b.startedAt - a.startedAt);

    case FETCH_MEASURE_SUCCEEDED:
      return unionWith(
        [action.payload],
        state,
        (server, local) => server.id === local.id,
      ).sort((a, b) => b.startedAt - a.startedAt);

    case FETCH_MEASURES_SUCCEEDED:
      return unionWith(
        action.payload,
        state,
        (server, local) => server.id === local.id,
      ).sort((a, b) => b.startedAt - a.startedAt);

    case FETCH_RECENT_MEASURES_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: FETCH RECENT MEASURES SUCCEEDED');
        console.groupEnd();
      }
      return unionWith(
        action.payload,
        state,
        (server, local) => server.id === local.id,
      ).sort((a, b) => b.startedAt - a.startedAt);

    default:
      // @ts-ignore
      return state;
  }
}
