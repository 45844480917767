import { ACTIVITY_IMAGES } from '../assets/activity_images';
import {
  ANXIETY_ACTIVITY,
  BEST_SELF_ACTIVITY,
  CHARACTER_STRENGTHS_ACTIVITY,
  DEPRESSION_ACTIVITY,
  FORGIVENESS_ACTIVITY,
  GRATITUDE_ACTIVITY,
  JOB_CRAFTING_ACTIVITY,
  KINDNESS_ACTIVITY,
  MARRIAGE_ACTIVITY,
  RELIGION_ACTIVITY,
  SAVORING_ACTIVITY,
  VOLUNTEER_ACTIVITY,
} from '../stores/activity/activity';

const imgCharacter = require('../assets/character.svg');
const imgGratitude = require('../assets/gratitude.svg');
const imgJobCraftiing = require('../assets/job_crafting.svg');
const imgKindness = require('../assets/kindness.svg');
const imgSavoring = require('../assets/savoring.svg');
const imgSelf = require('../assets/self.svg');
const imgAnxiety = require('../assets/anxiety.svg');
const imgDepression = require('../assets/depression.svg');
const imgForgiveness = require('../assets/forgiveness.svg');
const imgMarriage = require('../assets/marriage.svg');
const imgReligion = require('../assets/religion.svg');
const imgVolunteer = require('../assets/volunteer.svg');

export const COGNITIVE_EXERCISE = 'Cognitive Exercise';
export const BEHAVIORAL_EXERCISE = 'Behavioral Exercise';
export const RELATIONSHIPS_EXERCISE = 'Relationships Exercise';
export const DISTRESS_EXERCISE = 'Improving Psychological Distress Exercise';

export const COGNITIVE = 'cognitive';
export const BEHAVIORAL = 'behavioral';
export const RELATIONSHIPS = 'relationships';
export const DISTRESS = 'distress';

export const GETTING_STARTED = `The first thing that we recommend is to select the day (or days) of the week on which you want to do this activity. It's best to write down the days or add them to your calendar. A core aspect of these activities is writting down your thoughts. If you are doing the activity in the app, we provide forms to write and save your thoughts. If you are doing the exercise on your own, it is best to have a notebook or word document dedicated to this activity.`;

export const EXERCISE_GROUPS = {
  [COGNITIVE]: {
    type: COGNITIVE_EXERCISE,
    header: 'Cognitive Exercises',
    desc: 'These activities are an orientation of the mind to what is good in either the past (gratitude), the present (savoring), or the future (imagining).',

    href: '/exercises/cognitive',
    // img: 'https://images.unsplash.com/photo-1511203466129-824e631920d7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1498&q=80',
    // img: '/images/cognitive-exercises-header.png',
    img: '/images/cognitive-header-wide.png',
    imgCard: '/images/cognitive-header-square.png',
    // img: '/images/cognitive-header.png',
    activities: [
      {
        type: GRATITUDE_ACTIVITY,
        header: 'Gratitude Activity',
        href: '/activities/gratitude',
        icon: imgGratitude,
        img: ACTIVITY_IMAGES.GRATITUDE_ACTIVITY,
        desc: 'The Gratitude Activity is focused on repeated self-reflection on that which you are grateful. \n\nYou’ll select three days of the week where you’ll commit to taking time out of your day to sit and identify three things for which you are grateful and then writing about them.',
      },
      {
        type: SAVORING_ACTIVITY,
        header: 'Savoring Activity',
        href: '/activities/savoring',
        icon: imgSavoring,
        desc: 'The Savoring Activity is focused on developing your capacity to attend to, appreciate, and enhance the positive experiences in your life. \n\nYou’ll select one day of the week to be especially focused on this practice. On that day, you’ll also return to this app and write about your experiences as well as your goals, plans, and actions for how to savor more in the forthcoming week',
        img: ACTIVITY_IMAGES.SAVORING_ACTIVITY,
      },
      {
        type: BEST_SELF_ACTIVITY,
        header: 'Best Possible Self Activity',
        href: '/activities/best-self',
        icon: imgSelf,
        img: ACTIVITY_IMAGES.BEST_SELF_ACTIVITY,
        desc: 'The Imagining Your Best Possible Self Activity is focused on developing a well-defined sense of just who you imagine your best possible self to be. \n\nTo do this, you’ll select one day of the week to return to this application for a simple exercise that is centered around, well, just seriously imagining your best possible self and writing about who this person is.',
      },
    ],
  },
  [BEHAVIORAL]: {
    type: BEHAVIORAL_EXERCISE,
    header: 'Behavioral Exercises',
    desc: `These activities are oriented towards seeking what is good in oneself (character/virtue), for others (acts of kindness), and for the community (volunteering).`,
    descLong: '',
    href: '/exercises/behavioral',
    img: '/images/behavioral-header-wide.png',
    imgCard: '/images/behavioral-header-square.png',
    activities: [
      {
        type: CHARACTER_STRENGTHS_ACTIVITY,
        header: 'Character Strengths Activity',
        href: '/activities/character-strengths',
        icon: imgCharacter,
        img: ACTIVITY_IMAGES.CHARACTER_STRENGTHS_ACTIVITY,
        desc: 'In the Character Strengths Activity, you will first identify your top five (out of twenty-four) character strengths using the Values in Action survey. \n\nAfter having identified your top five strengths, you will commit to finding new ways to use them. You’ll select one day of the week to return to this app and write about your experiences as well as  your goals, plans, and actions for how to use your strengths in the upcoming week.',
      },
      {
        type: KINDNESS_ACTIVITY,
        header: 'Kindness Activity',
        href: '/activities/kindness',
        icon: imgKindness,
        img: ACTIVITY_IMAGES.KINDNESS_ACTIVITY,
        desc: 'The Kindness Activity is focused on improving your natural tendency to behave kindly by committing to a deliberate routine of regularly and consciously performing kind acts. You’ll select one day of the week on which you will commit to doing five kind acts. Each week, on that day, you will check back in to reflect on and write about your actions.',
      },
      {
        type: VOLUNTEER_ACTIVITY,
        header: 'Volunteering Activity',
        href: '/activities/volunteer',
        icon: imgVolunteer,
        img: ACTIVITY_IMAGES.VOLUNTEER_ACTIVITY,
        desc: 'The Volunteering Activity is focused on increasing your connection with your community and others. You’ll commit to finding a new volunteer activity and spending two hours per week for six weeks on that activity. You’ll select one day of the week to check back in here and write about your experiences.',
      },
    ],
  },
  [RELATIONSHIPS]: {
    type: RELATIONSHIPS_EXERCISE,
    header: 'Relationship Exercises',
    headerLong: 'Engaging in Relationships and Community Commitments',
    desc: 'These activities are oriented towards recognizing how work, marriage, and religious community can all positively promote not only happiness, but also health, meaning and purpose, character, and a sense of having good relationships.',
    href: '/exercises/relationships',
    img: '/images/relational-header-wide.png',
    imgCard: '/images/relational-header-square.png',
    activities: [
      {
        type: JOB_CRAFTING_ACTIVITY,
        header: 'Job Crafting Activity',
        href: '/activities/job-crafting',
        icon: imgJobCraftiing,
        img: ACTIVITY_IMAGES.JOB_CRAFTING_ACTIVITY,
        desc: 'You’ll develop a job crafting plan to take more proactive control over your work, work environment, work relationships, and how you think meaningfully about your work. \n\nYou will then commit to enacting that plan over the next six weeks. Each week, you’ll check back in to reflect on how you have enacted your plan, what has gone well and what hasn’t, and to think about how you’ll enact your plan better, if needed, over the next week.',
      },
      {
        type: MARRIAGE_ACTIVITY,
        header: 'Marriage Activity',
        href: '/activities/marriage',
        icon: imgMarriage,
        img: ACTIVITY_IMAGES.MARRIAGE_ACTIVITY,
        desc: 'This is for those who are married and wish to improve their relationship and commitment. You’ll commit to working through an online resource over six weeks. Each week, you’ll check back in to reflect upon and write about your experiences.',
      },
      {
        type: RELIGION_ACTIVITY,
        header: 'Religious Service Attendance Activity',
        href: '/activities/religion',
        icon: imgReligion,
        img: ACTIVITY_IMAGES.RELIGION_ACTIVITY,
        desc: 'For those who already attend religious services or are interested in doing so. You will commit to attending a religious service at least once per week (if new) or three times per week (if you already attend). Once per week you will reflect upon and write about your experiences in this app.',
      },
    ],
  },
  [DISTRESS]: {
    type: DISTRESS_EXERCISE,
    // header: 'Improving Psychological Distress',
    header: 'Psychological Distress',
    desc: 'These activities are essentially oriented towards the good in removing or recovering from what are sometimes conceived of as three central negative emotions: sadness, fear, and anger.',
    href: '/exercises/distress',
    img: '/images/psychological-header-wide.png',
    imgCard: '/images/psychological-header-square.png',
    activities: [
      {
        type: DEPRESSION_ACTIVITY,
        header: 'Depression Activity',
        href: '/activities/depression',
        icon: imgDepression,
        img: ACTIVITY_IMAGES.DEPRESSION_ACTIVITY,
        desc: 'You will use David Burns’ “Feeling Good” book, which has been tested in various randomized trials and shown to have an effect on alleviating depression. The book is available for purchase in numerous bookstores or online. You’ll commit to working through the book over six weeks. Each week, you’ll check back in to reflect upon your experiences and expectations. \n\nThe book is not a substitute for a trained therapist, and any severe form of depression should be addressed with the help of a professional. However, the existing evidence suggests that the book may be helpful in at least alleviating more mild depressive symptoms.',
      },
      {
        type: ANXIETY_ACTIVITY,
        header: 'Anxiety Activity',
        href: '/activities/anxiety',
        icon: imgAnxiety,
        img: ACTIVITY_IMAGES.ANXIETY_ACTIVITY,
        desc: 'You will use a popular and current self-help book on cognitive behavioral therapy for anxiety, “Mastery of Your Anxiety and Panic,” by David Barlow and Michelle Craske, which was evaluated in a small randomized trial for its effectiveness both on its own and when complemented by in-person sessions with a therapist; the use of the book on its own was found to be nearly as effective. The book is available for purchase in numerous bookstores or online. Each week, you’ll check back in to reflect upon your experiences and expectations. \n\nThe book is not a substitute for a trained therapist, and anxiety disorders should be addressed with the help of a professional.',
      },
      {
        type: FORGIVENESS_ACTIVITY,
        header: 'Forgiveness Activity',
        href: '/activities/forgiveness',
        icon: imgForgiveness,
        img: ACTIVITY_IMAGES.FORGIVENESS_ACTIVITY,
        desc: 'In order to promote forgiveness, you’ll use a workbook intervention that has been shown to be effective in a small randomized trial. Each week, you’ll check back in to reflect upon your experiences and write about your experiences and expectations.',
      },
    ],
  },
};

export const IN_PROGRESS_HEADER = `Below you can see the next available entries, the day of the week that you set aside, you schedule, the completed entries, as well as the baselines that you completed when you started this activity. If you missed an entry, don't worry, you have until one week from when you scheduled it to take it.`;

export const COMPLETED_HEADER = `Below you can see the next your completed entries, as well as the baselines that you completed when you started this activity.`;

export const TEXT = {
  GRATITUDE_ACTIVITY: {
    exercise: COGNITIVE_EXERCISE,
    header: 'Gratitude Activity',
    quotation: `“In ordinary life, we hardly realize that we receive a great deal more than we give, and that it is only with gratitude that life becomes rich.”\n Dietrich Bonhoeffer`,
    general: `Gratitude (being thankful or grateful) is a state of full appreciation for the things we have. We may be grateful for our health, our friends and family, and many other things.

    Simple exercises have been developed to help us increase our gratitude. These exercises consist of taking time to reflect upon and write about any number of things in life for which we are grateful. Research has found such exercises tend to increase not only our own gratitude, but also our more general feelings of psychological well-being.
    
    In this activity, you will select three days of the week to complete the exercise of self-reflection and writing, aided by reminders on those days to return to this app. You will be asked to commit to this practice for six weeks. 

    You can create an account or sign in if you want to keep track of your progress.`,

    instructions: `This exercise takes six weeks. During this time you'll work on improving your gratitude by simply setting aside some time three times a week (on different days) to do a simple exercise that consists of reflecting on that which you are grateful and then writing about it.\n\nYou can create an account or sign in if you want to use this app to do this exercise and keep track of your progress. Otherwise, you can just use the information below on your own.`,

    gettingStarted: GETTING_STARTED,

    hasBaseline: true,
    firstEntry: `Please take a few moments to clear your mind.
    
    There are many things in our lives, both large and small, that we might be grateful about. Think back over the past week. Please take a few moments to think about three such things for which you are grateful. When you are ready, please write about them in a notebook.`,

    inProgressOrCompleted: `Gratitude (or being thankful or grateful) is a state or feeling in which we are fully appreciative of that which we have. We may be grateful for our health, our friends and family, and many other things.`,

    weeklyInstructions: `As part of your commitment to working on improving your expression of gratitude, you've opted to return to this app today and complete a short writing exercise. Please take a few moments to clear your mind. 
    
    There are many things in our lives, both large and small, that we might be grateful about. Think back over the past week. Please take a few moments to think about three such things for which you are grateful. When you are ready, please write about them in the form provided below.`,

    science: [
      'Improvements in: Happiness, Depression, Sleep Emmons, R. A., & McCullough, M. E. (2003). Counting blessings versus burdens: An experimental investigation of gratitude and subjective well-being in daily life. Journal of Personality and Social Psychology, 84,377-389. Davis, D. E.  Choe, E., Meyers, J., Wade, N., Varjas, K., Gifford, A., Quinn, A.,Hook, J. N., Van Tongeren, D.R., Griffin, B. J., & Worthington, E. L. (2016).',
      'Thankful for the little things: a meta- analysis of gratitude interventions. Journal of Counseling Psychology, 63,20-31.',
    ],
  },
  SAVORING_ACTIVITY: {
    exercise: COGNITIVE_EXERCISE,
    header: 'Savoring Activity',
    quotation: `“He who distinguishes the true savor of his food can never be a glutton; he who does not cannot be otherwise." \nHenry David Thoreau`,

    general: `Savoring can be described as the capacity to attend to, appreciate, and enhance the positive experiences in our lives. By learning to savor – to recognize and rest in the good – we can enhance our experiences and enjoyment thereof.

Some of the exercises to enhance and promote savoring that have been developed are quite simple and require only that we develop our capacity for thinking about positive events. For example, we can heighten our focus on and awareness of a positive experience as it unfolds, such as enjoying a walk or eating dinner.

Over the course of the next six weeks you'll work on improving your savoring abilities by setting aside some time each week to practice savoring in a focused way, trying to recognize the good in some activity, and then writing about that experience.
.`,

    instructions: `This exercise takes six weeks to complete. Over the course of the next six weeks you'll work on improving your savoring abilities by setting aside some time each week to practice savoring in a focused way and then writing about that experience. 
    
    To do this, on the selected day each week you will commit to paying closer attention while doing some activity, such as having a meal or going for a walk, to your present experience and try to recognize the good in it.
    `,
    gettingStarted: GETTING_STARTED,

    hasBaseline: true,
    firstEntry: `You get your first chance to practice savoring right now. First, make sure that you are someplace comfortable and take a few moments to clear your mind. Then, think back over your past week, your day up until now, and your current surroundings. As you reflect, try and notice as many positive things around yourself as possible. Spend a few minutes doing this, then write down those positive things, your experience of them, and how you recognize the good in them.`,

    weeklyInstructions: `On the day that you selected to do the activity, it's good to start the day with remembering your goal. As part of this, make sure that you are someplace comfortable and take a few moments to clear your mind. Then, think back over your past week, your day up until now, and your surroundings. As you reflect, try and notice as many positive things around yourself as possible. Throughout the rest of your day, try to keep this same intention in mind and notice things.
    
    Then, during some point either during the day or in the evening, spend time reflecting on your day, experiences savoring, writing down those positive things that you noticed, your experience of them, and how you recognize the good in them. 
    `,
    science: [
      `Bryant, F. B. & Veroff, J. (2007): Savoring: A New Model of Positive Experiences. Mahwah, NJ: Lawrence Erlbaum Associates.`,
      `Smith, J. L., Harrison, P. R., Kurtz, J. L., & Bryan, F. B. (2014). Nurturing the capacity to savor: interventions to enhance the enjoyment of positive experiences. In: A.C. Parks and S.M. Schueller (eds.). The Wiley Blackwell Handbook of Positive Psychological Interventions. John Wiley and Sons: West Sussex, U.K. p. 42-65.`,
    ],
  },
  BEST_SELF_ACTIVITY: {
    exercise: COGNITIVE_EXERCISE,
    header: 'Imagining Your Best Possible Self Activity',
    quotation: `"There is only one corner of the universe you can be certain of improving, and that's your own self." \nAldous Huxley, Writer and Philosopher`,
    general: `This activity consists of taking time to imagine your best possible self. You will begin this activity by taking some time to imagine your best possible self and briefly writing about your thoughts. Research has shown that even such a simple exercise as this can help to increase your well-being. 

    After completing this first exercise, you can complete an (optional) reflection entry. We find it helps to write down your expectations of what you want from this experience. This entry will be saved so that you can return to look at it over the next six weeks while completing this activity. Your entry will also be stored as part of your history.

    Then, you will select one day to set aside each week when you will return to this app to reflect upon what you wrote and how much closer you have come to be this best possible self. You will also have the opportunity each week to repeat this exercise of imagining your best possible self.
`,
    instructions: `Over the course of the next six weeks you'll work on improving your flourishing by doing a simple activity that involves imagining your best possible self. Each week you’ll set aside one day to do the simple exercise of sitting down, imagining your best possible self, and then writing about who that person is. You'll have a chance to do this for the first time in just a moment below.
    
    After completing this first exercise, you can then complete an (optional) reflection entry. We find it helps to write down your expectations, what you want from this experience, down now. This entry will be saved so that you can return to look at it over the next six weeks while completing this activity. Your entry will also be stored as part of your history.`,

    gettingStarted: GETTING_STARTED,

    hasBaseline: true,
    firstEntry: `Think about your life in the future. Imagine that everything has gone as well as it possibly could. You have worked hard and succeeded at accomplishing all of your life goals. Think of this as the realization of all of your life dreams. Now, write about what you imagined.`,

    weeklyInstructions: `You've committed to the practice of imagining your best possible self once a week for six weeks. You've scheduled today to return to this app and complete this weekly short writing exercise. Please take a few moments to clear your mind. When you are ready, think about your life in the future. Imagine that everything has gone as well as it possibly could. You have worked hard and succeeded at accomplishing all of your life goals. Think of this as the realization of all of your life dreams. Now, write about what you imagined in the box below.`,

    science: [
      `King, L. A. (2001). The health benefits of writing about life goals.Personality and Social Psychology Bulletin, 27,798-807.`,
      `Malouff, J. M. & Schutte, N. S. (2017). Can psychological interventions increase optimism? A meta-analysis. The Journal of Positive Psychology, 12(6),594-604.`,
    ],
  },

  CHARACTER_STRENGTHS_ACTIVITY: {
    exercise: BEHAVIORAL_EXERCISE,
    header: 'Character Strengths Activity',
    quotation: `"It's not the honors and not the titles and not the power that is of ultimate importance. It's what resides inside." \nFred Rogers, American TV Host`,
    general: `Aristotle argued that happiness is attained by action in accord with virtue. While the empirical study of virtues is relatively new, there is now reasonably good evidence that the exercise of virtue does indeed, perhaps unsurprisingly, contribute to greater happiness.

    One particular track of research has identified twenty-four character strengths that we all have to varying degrees. Research has also found that an activity in which you work on using your top five strengths in new ways increases happiness and decreases depressive symptoms with results lasting at least six months.

    In this activity, you’ll first identify your top five character strengths, and the degree of each strength, using the Values in Action Survey of Character Strengths (please be aware that this involves visiting and registering at an external website). 

    Over the course of the next six weeks, you will commit to exercising one of these top five strengths in a new and different way, every day. You’ll check back in here with this app once a week to reflect on what you’ve been doing.
`,

    instructions: `In order to start this activity, you first need to identify your top five character strengths by taking a survey on an external website. To continue, please go to https://www.authentichappiness.sas.upenn.edu/questionnaires/brief-strengths-test and create an account. After completing the survey and seeing your strengths, please come back here to enter your results below.`,

    gettingStarted: GETTING_STARTED,

    hasBaseline: true,
    firstEntry: `In order to start this activity, you first need to identify your top five character strengths by taking a survey on an external website. To continue, please go to https://www.authentichappiness.sas.upenn.edu/questionnaires/brief-strengths-test and create an account. After completing the survey and seeing your strengths, please come back here to enter your results below.`,

    weeklyInstructions: `You've been using your top five character strengths in new ways over the past weeks. Please take a few moments to reflect on how you have been doing this. Have you found unusual ways to use your strengths? How have you gone about trying to find new ways? How might you build on what you have done going forward? When ready, please briefly write down some reflections in the box below.`,

    science: [
      `Seligman, M. E. P., Steen,T. A., Park, N., & Peterson, C. (2005). Positive psychology progress: empirical validation of interventions. American Psychologist, 60,410–421..`,
      `Schutte, N. S., & Malouff, J. M. (2019). The impact of signature character strengths interventions: A meta-analysis. Journal of Happiness Studies, 20(4),1179-1196.`,
      `Website: https://www.authentichappiness.sas.upeen.edu/testcenter`,
    ],
  },

  KINDNESS_ACTIVITY: {
    exercise: BEHAVIORAL_EXERCISE,
    header: 'Kindness Activity',
    quotation: `“Kindness in words creates confidence. Kindness in thinking creates profoundness. Kindness in giving creates love.” \nLao Tzu, Ancient Chinese Philosopher and Writer`,
    general: `A number of studies suggest that acts of kindness which promote the well-being of others can also increase our own sense of well-being.

    Some trials suggest that committing each week to carrying out acts of kindness that we would not ordinarily do can increase our happiness and life satisfaction, and make us feel more engaged, less anxious, and more connected. Such acts of kindness also often encourage and propagate similar acts of kindness among others.

    Over the course of the next six weeks, you'll commit to taking one day out of the week and performing three to five acts of kindness that you may not normally do. You’ll then return to this app to write about your experiences.
`,
    instructions: `Over the course of the next six weeks you'll commit to taking one day out of the week and doing three to five acts of kindness that you may not normally do. You’ll then return to this app to write about your experience.
    
    This activity does not have a specific first exercise that you must do at this time. Instead, you can complete an (optional) reflection entry next. We find it helps to write down your expectations, what you want from this experience. This entry will be saved so that you can return to look at it over the next six weeks while completing this activity. Your entry will also be stored as part of your history.
    
    Then, you will have the option in the next step to take any of the included measures. This is a good way to track your progress both in this activity and overall.
    
    Finally, you’ll select the day of the week on which you would like to do this activity and set how and if you want to be notified on that day.`,

    gettingStarted: GETTING_STARTED,

    hasBaseline: false,
    firstEntry: `This activity doesn't have a specific first entry. You can do the normal entry as a first entry if you want.`,

    weeklyInstructions: `You've committed to practicing acts of kindness throughout the week, including five acts of kindness today that you would not normally do. As part of your commitment you've opted to return to this app today to reflect upon and write about your experiences. Please take a few moments to clear your mind. When you are ready, think back over the past week. Have you noticed yourself engaging in more acts of kindness than usual? Did you follow through on your five acts for today? If not, do you still plan to do them later in the day? What were those acts? Did you miss an opportunity to carry out an act of kindness? If so, how might you go about performing such an act in the future given the chance? When you are ready, please write down your thoughts in the box below.
`,
    science: [
      `Lyubomirsky, S., Sheldon, K. M., & Schkade, D. (2005). Pursuing happiness: the architecture of sustainable change. Review of General Psychology, 9,111-131.`,
      `Curry, O. S., Rowland, L. A., Van Lissa, C. J., Zlotowitz, S., McAlaney, J., & Whitehouse, H. (2018). Happy to help? A systematic review and meta-analysis of the effects of performing acts of kindness on the well-being of the actor. Journal of Experimental Social Psychology, 76,320–9.`,
    ],
  },

  VOLUNTEER_ACTIVITY: {
    exercise: BEHAVIORAL_EXERCISE,
    header: `Volunteering Activity`,
    quotation: `“What is the essence of life? To serve others and to do good.” \nAristotle`,
    general: `Studies have indicated that volunteering is associated with improvements in various aspects of well-being. Regularly participating in various volunteering activities and organizations demonstrates a commitment to repeated acts of kindness, generally directed at the important goal of improving the lives of others. Volunteer organizations also can provide a powerful sense of social connection and a common purpose, both of which also contribute to our own well-being.

    Over the course of the next six weeks you'll commit to doing a new volunteer experience for at least two hours per week. If you have volunteered in the past, you might consider restarting what it is that you did. If you haven’t volunteered before or are looking for a new opportunity you may find this site useful for finding opportunities: https://www.volunteermatch.org/.`,

    instructions: `Over the course of the next six weeks you'll commit to doing a new volunteer experience for at least two hours per week. If you have volunteered in the past, you might consider restarting what it is that you did. If you haven’t volunteered before or are looking for a new opportunity you may find this site https://www.volunteermatch.org/ useful for finding opportunities. 
    
    As part of the starting this activity, on the next screen you’ll write down what it is that you plan to do.
    
    After completing this first exercise, you can then complete an (optional) reflection entry. We find it helps to write down your expectations, what you want from this experience, down now. This entry will be saved so that you can return to look at it over the next six weeks while completing this activity. Your entry will also be stored as part of your history.
    
    Then, you will have the option in the next step to take any of the included measures. This is a good way to track your progress both in this activity and overall.
    
    Finally, you’ll select the day of the week on which you would like to do this activity and set how and if you want to be notified on that day.`,

    gettingStarted: GETTING_STARTED,

    firstEntry: `Please write down what volunteer activity you will commit to do.`,

    weeklyInstructions: `You've committed to volunteering for two hours per week for the six-week duration of this activity. As part of your commitment, you opted to return to this today app to reflect upon and briefly write about your experiences. Take a few moments to clear your mind. When you are ready, please reflect on your volunteer experience. What did you do? How did you feel before and after your volunteer work? Have you found it easy or difficult to keep your commitment? Have you noticed changes in yourself during the week outside of your volunteer time? If so, what have you noticed? When you are ready, please write down your thoughts in the box below..`,

    science: [
      `Schreier, H. M., Schonert-Reichl, K. A., & Chen, E. (2013). Effect of volunteering on risk factors for cardiovascular disease in adolescents: A randomized controlled trial. JAMA Pediatrics, 167(4),327-332.`,
      `Okun, M. A., Yeung, E. W., & Brown, S. (2013). Volunteering by older adults and risk of mortality: A meta-analysis. Psychology and Aging, 28(2),564-577.`,
    ],
  },

  JOB_CRAFTING_ACTIVITY: {
    exercise: RELATIONSHIPS_EXERCISE,
    header: `Job Crafting`,
    quotation: `“Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work. And the only way to do great work is to love what you do. If you haven't found it yet, keep looking. Don't settle. As with all matters of the heart, you'll know when you find it.” \nSteve Jobs, founder of Apple`,
    general: `Work, conceived of as the sustained effort and contribution to meet the needs and desires of humanity,has been shown to positively affect numerous health and well-being outcomes. Work is often today viewed simply as paid employment, but  it can also encompass activities like caring for children or substantial and sustained volunteering efforts to accomplish some good.

    In this activity, you will have the chance to implement what is known as job crafting. Job crafting is a behavior that we all already do to some degree. This activity is meant to help you become more conscious of this behavior.The idea of job crafting is that one reflects upon one’s work environment and takes actions at work to try (i) to structure one’s tasks so that they can be done more effectively, or so that tedious tasks are no longer necessary and more challenging tasks are made available; (ii) to have better, more meaningful, or more effective social interactions at work; and (iii) to find meaning and purpose in the work being done. 

    The first step, which you will complete below, is to develop a job crafting plan. This is a basic outline of how you will go about increasing your job crafting. Then, over the next six weeks you will commit to implementing that plan. You will check back weekly and write about both how you have been doing as well as what you might be able to do better in the upcoming week.
`,

    instructions: `Job crafting is a behavior that we all already do to some degree. This activity is meant to help you become more conscious of this behavior. You'll commit to a six-week plan to improve your job crafting.
    
    The first step, which you will complete below, is to develop a job crafting plan. This is a basic outline of how you will go about increasing your job crafting.
    
    After completing this first exercise, you can then complete an (optional) reflection entry. We find it helps to write down your expectations, what you want from this experience, down now. This entry will be saved so that you can return to look at it over the next six weeks while completing this activity. Your entry will also be stored as part of your history.
    
    Then, you will have the option in the next step to take any of the included measures. This is a good way to track your progress both in this activity and overall.
    Finally, you’ll select the day of the week on which you would like to do this activity and set how and if you want to be notified on that day.`,
    gettingStarted: GETTING_STARTED,

    hasBaseline: true,
    firstEntry: `Job crafting involves reflecting upon one’s work environment and taking actions at work to try (i) to structure one’s tasks so that they can be done more effectively, or so that tedious tasks are no longer necessary, or so that more challenging tasks are available; (ii) to have better, more meaningful, or more effective social interactions at work; and (iii) to find meaning and purpose in the work being done.

    Take a few minutes to think about each of those three areas and how you might better ‘craft’ those areas of your work. When you are ready, please write down a summary of your plan.`,

    weeklyInstructions: `You've committed to implementing a job crafting plan over the course of this activity that you set for yourself. As part of your commitment, you opted to return to this app and write about how your job crafting is going. If you don't recall your job crafting plan, please go back to the previous screen, click on 'Baseline Entry,' and re-read what you wrote before returning here. Then take a few moments to clear your mind. When you are ready, please think back over the past week. Were you able to put into action any parts of your original plan? What worked? What didn't? Did you find new ways to craft your job? When ready, please write down a brief reflection in the box below. `,

    science: [
      `McKee-Ryan, F.,Song, Z., Wanberg, C. R., & Kinicki, A. J. (2005) Psychological and physical well-being during unemployment: A meta-analytic study. Journal of Applied Psychology, 90,53–76.`,
      `Frederick, D. E. & VanderWeele, T. J. (2018). A meta-analysis of the association of job-crafting and work engagement. Preprintavailable at:https://osf.io/2xtk3/. Techical Report.`,
    ],
  },

  MARRIAGE_ACTIVITY: {
    exercise: RELATIONSHIPS_EXERCISE,
    header: `Marriage Activity`,
    quotation: `“You know you’re in love when you can’t fall asleep because reality is finally better than your dreams.” \nDr. Seuss`,
    general: `Marriage and marriage quality is an important determinant of happiness in life and many other aspects of human flourishing.

    Over the last decade, research on relationship education and marriage counseling has become increasingly based on evidence as to what actually improves relationship quality. Some of this evidence has been incorporated into marriage counseling practices. However, many couples may feel that they lack the money, time, resources, motivation, or need, to seek out a marriage counselor or therapist. 

    As an alternative, easy to use and accessible online relationship education programs have recently been developed to enhance marriage and relationship quality and to help couples work through marital difficulties. A recent randomized trial found one such online program, OurRelationship, which consists of about eight hours of exercises for a couple, to have fairly substantial effects on relationship satisfaction and relationship confidence, as well as lower rates of individual depression and anxiety, and better quality of life. The program is available online at https://www.ourrelationship.com and could potentially be used quite broadly with tremendous potential to contribute to human well-being.

    In this activity, which is aimed at those who are already married, you’ll create an account at the OurRelationship website and commit to using it for six weeks and completing the eight hour program. Each week, you’ll select one day in which to check back in here and to write about your experiences.
`,
    instructions: `This activity is focused on strengthening your marriage and is aimed at those who are already married. Over the course of the next six weeks, you'll commit to working through the online site OurRelationship that has proven effective in increasing marriage wellbeing.
    
    The first step is to create an account at the OurRelationship website. If you have not already created an account, please make sure to do so after finishing the next series of screens.
    
    This activity does not have a specific first exercise that you must do at this time. Instead, you can complete an (optional) reflection entry next. We find it helps to write down your expectations, what you want from this experience. This entry will be saved so that you can return to look at it over the next six weeks while completing this activity. Your entry will also be stored as part of your history.
    
    Then, you will have the option in the next step to take any of the included measures. This is a good way to track your progress both in this activity and overall.
    
    Finally, you’ll select the day of the week on which you would like to do this activity and set how and if you want to be notified on that day.`,
    gettingStarted: GETTING_STARTED,

    hasBaseline: false,
    firstEntry: `There is no specific base entry. Just need to buy the book/sign up for the website.`,

    weeklyInstructions: `You've committed to working through the online resource "OurRelationship.com". As part of your commitment, you opted to come back to this app today and complete a weekly short writing exercise. Take a few moments to clear your mind. When you are ready, think about  any new insights you have had? Have you been able to transfer those insights into actions in your marriage?  If not, how might you plan to do so in the future? When you are ready, please briefly write down your thoughts in the box below.`,
    science: [
      `Doss, B. D., Cicila, L. N., Georgia, E. J., Roddy, M. K., Nowlan, K. M., Benson, L. A., & Christensen, A. (2016). A randomized controlled trial of the web-based OurRelationship program: Effects on relationship and individual functioning. Journal of Consulting and Clinical Psychology. 84:285-296.`,
      `Manzoli, L., Villari, P., Pirone, G. M., & Boccia, A. (2007). Marital Status and Mortality in the Elderly: A Systematic Review and Meta-Analysis. Social Science & Medicine, 64,77-94.`,
    ],
  },

  RELIGION_ACTIVITY: {
    exercise: RELATIONSHIPS_EXERCISE,
    header: `Religious Service Attendence`,
    quotation: `“[Religious] fellowship lives and exists by the intercession of its members for one another, or it collapses. I can no longer condemn or hate a brother for whom I pray, no matter how much trouble he causes me.” \nDietrich Bonhoeffer`,
    general: `A considerable body of research has suggested that religious service attendance powerfully affects health and other aspects of our flourishing such as meaning in life, character, and close social relationships. Specifically, research has indicated that those who attend religious services at least weekly are about 30% less likely to die over a 15 year follow-up, about 30% less likely to become depressed, and over five times less likely to commit suicide. Other evidence suggests that religious service attendance is associated with subsequently greater meaning in life, greater social connection and support, and lower likelihood of divorce.

    One interesting aspect of these associations is that it seems to be service attendance, rather than religious or spiritual identity or private practices, that most strongly predicts health. Something about the communal religious experience seems to be very powerful.

    This activity is for those who are already religious or those who would like to explore religion further. If you are religious, you’ll commit to more frequent and consistent attendance--at least once per week--of whatever the main activity for your religion is. If you are not religious, but have an interest in religion and would like to explore religion, then you’ll commit to finding one or more religious communities near you and attending their services weekly. In both cases, you’ll select one day of the week in which to check back in here to reflect on and write about your experiences.
`,

    instructions: `In this activity, you'll commit to attending a religious service at least once per week. If you already attend once per week, you might want to commit to increasing that even further. If you do not have a particular service or event, the following resources may be helpful in locating something in your area.
    
    Meetup.com is a popular site for group activities that often has groups of various religious denominations and spiritual related groups.
    
    For Christian services, the website https://church.org is helpful for finding congregations in your area.
    
    Once you have a service in mind, you'll be asked to write down what that service is and what you intended to do in the next step.
    
    After completing this first exercise, you can then complete an (optional) reflection entry. We find it helps to write down your expectations, what you want from this experience, down now. This entry will be saved so that you can return to look at it over the next six weeks while completing this activity. Your entry will also be stored as part of your history.
    
    Then, you will have the option in the next step to take any of the included measures. This is a good way to track your progress both in this activity and overall.
    
    Finally, you’ll select the day of the week on which you would like to do this activity and set how and if you want to be notified on that day.`,

    gettingStarted: GETTING_STARTED,

    hasBaseline: true,
    firstEntry: `Please write down what religious attendance you will do.

    If you do not have a particular service or event, the following resources may be helpful in locating something in your area.
    
    Meetup.com is a popular site for group activities that often has groups of various religious denominations and spiritual related groups.
    
    For Christian services, the website https://church.org is helpful for finding congregations in your area.`,

    weeklyInstructions: `You've committed to engaging in religious service attendance each week over the course of the next six weeks. As part of your commitment, you opted to return to this app today and reflect upon your experiences. Take a few moments to clear your mind. Think about the past week and how often you have been able to attend your selected religious service. What have the experiences meant to you? What new insights about yourself, others, and the world have you gained? Have you connected with anyone, or met anyone new? Has anything particularly touched or moved you? Have you found yourself behaving differently outside of the services? If so, how? When you are ready, please write down your thoughts in the box below.`,

    science: [
      `Li, S., Okereke, O. I., Chang, S.-C., Kawachi, I., & VanderWeele, T. J. (2016a). Religious service attendance and depression among women –a prospective cohort study. Annals of Behavioral Medicine, 50,876-884.`,
      `Chida, Y., Steptoe, A., & Powell, L. H. (2009). Religiosity/spirituality and mortality: A systematic quantitative review. Psychotherapy and Psychosomatics, 78(2),81–90.`,
    ],
  },

  DEPRESSION_ACTIVITY: {
    exercise: DISTRESS_EXERCISE,
    header: `Depression Activity`,
    quotation: `“Depression is the most unpleasant thing I have ever experienced. . . . It is that absence of being able to envisage that you will ever be cheerful again. The absence of hope. That very deadened feeling, which is so very different from feeling sad. Sad hurts but it’s a healthy feeling. It is a necessary thing to feel. Depression is very different.” \nJK Rowling`,
    general: `Depression is one of the most common mental health issues. There are now numerous treatment options that have proven effective and which include pharmacological treatments as well as different types of talk therapy. Cognitive behavioral therapy, which is often considered the gold standard, focuses on changing the thought processes and behaviors that contribute to depression.

    While cognitive behavioral therapy is most often conducted with a therapist, there are now several self-help solutions. One particularly popular self-help book, “Feeling Good” by David Burns, has been tested in randomized trials and has been shown to be effective at alleviating depressive symptoms. The book is not a substitute for a trained therapist, and any severe form of depression should be addressed with the help of a professional. However, the existing evidence suggests that the book may be helpful in at least alleviating more mild depressive symptoms.

    In this activity, you will commit to acquiring a copy of “Feeling Good” and working through it over the course of six weeks. You’ll select one day of the week on which to check back in here and to reflect on and write about your experiences.`,

    instructions: `This activity is focused on helping you work through a workbook that has proved effective in helping to alleviate and manage depression. The first step is to make sure that you have a copy (or will have a copy soon) of the book ("Feeling Good").
    
    This activity does not have a specific first exercise that you must do at this time. Instead, you can complete an (optional) reflection entry next. We find it helps to write down your expectations, what you want from this experience. This entry will be saved so that you can return to look at it over the next six weeks while completing this activity. Your entry will also be stored as part of your history.
    
    Then, you will have the option in the next step to take any of the included measures. This is a good way to track your progress both in this activity and overall.
    
    Finally, you’ll select the day of the week on which you would like to do this activity and set how and if you want to be notified on that day.`,

    gettingStarted: GETTING_STARTED,

    hasBaseline: false,
    firstEntry: `There is no specific first entry other than getting a copy of the book.`,
    weeklyInstructions: `You've committed to working through the "Feeling Good" workbook during this activity. As part of your commitment, you opted to return to this app today and reflect upon your experiences. Take a few moments to clear your mind. Think back over the past week. How have you worked through the book? What have you noticed about your depression and your own awareness of it? Have you had any breakthroughs or insights? What have you learned that you will apply going forward? Please briefly write down some of your thoughts in the box below.`,
    science: [
      `Burns, D. (1999). Feeling Good: The new mood therapy(Rev. ed.). New York: Avon.`,
      `Anderson, L., Lewis, G., Araya, R., Elgie, R., Harrison, G., Proudfoot, J., Schmidt, U., Sharp, D., Weightman, A., & Williams, C. (2005). Self-help books for depression: how can practitioners and patients make the right choice?British Journal of General Practice, 55,387-392.`,
    ],
  },

  ANXIETY_ACTIVITY: {
    exercise: DISTRESS_EXERCISE,
    header: `Anxiety Activity`,
    quotation: `“You can’t stop the waves, but you can learn to surf.” \nJon Kabat-Zinn, Founder of Mindfulness-Based Stress Reduction`,
    general: `Anxiety is one of the most common mental health issues. There are now numerous treatment options that have proven effective and which include pharmacological treatments as well as different types of therapy. Cognitive behavioral therapy, which is often considered the gold standard, focuses on changing the thought processes and behaviors that contribute to anxiety. While cognitive behavioral therapy is most often done with a therapist, there are now several self-help solutions. Do-it-yourself, self-help, workbook, and computer or internet-based cognitive behavioral therapy interventions are also available and have been shown in randomized trials to be effective in alleviating anxiety, and in some contexts can be almost as effective as face-to-face cognitive behavioral therapy.

    In this activity, you’ll commit to acquiring a copy of “Mastery of Your Anxiety and Panic” by David Barlow and Michelle Craske and to working through it over the course of six weeks.. You’ll select one day of the week on which to check back in here and to write about your experiences via directed writing prompts.`,
    instructions: `This activity is focused on helping you work through a workbook that has proved effective in helping to alleviate and manage anxiety. The first step is to make sure that you have a copy (or will have a copy soon) of the book (Mastery of Your Anxiety and Panic).
    
    This activity does not have a specific first exercise that you must do at this time. Instead, you can complete an (optional) reflection entry next. We find it helps to write down your expectations, what you want from this experience. This entry will be saved so that you can return to look at it over the next six weeks while completing this activity. Your entry will also be stored as part of your history.
    
    Then, you will have the option in the next step to take any of the included measures. This is a good way to track your progress both in this activity and overall.
    
    Finally, you’ll select the day of the week on which you would like to do this activity and set how and if you want to be notified on that day.`,

    gettingStarted: GETTING_STARTED,

    hasBaseline: false,
    firstEntry: `This activity has no specific baseline entry.`,

    weeklyInstructions: `You've committed to working through the "Mastery of Your Anxiety and Panic" workbook during this activity. As part of your commitment, you opted to return to this app today and reflect upon your experiences. Take a few moments to clear your mind. Think back over the past week. How have you worked through the workbook? What new insights, if any, into anxiety have you had? What ways, if any, have you learned to cope with anxiety? Please spend a few minutes thinking about these and then write down a short response in the box below.`,
    science: [
      `Barlow, D. H. & Craske M. G. (2007). Mastery of Your Anxiety and Panic. 4th Edition. New York: Oxford University Press.`,
      `Hecker, J. E., Loses, M. C., Fritzler, B. K., & Fink, C. M. (1996). Self-directed versus therapist-directed cognitive behavioral treatment for panic disorder. Journal of Anxiety Disorders, 10,253-265.`,
    ],
  },

  FORGIVENESS_ACTIVITY: {
    exercise: DISTRESS_EXERCISE,
    header: `Forgiveness Activity`,
    quotation: `“Resentment is like drinking poison and then hoping it will kill your enemies.” Nelson Mandela`,
    general: `Forgiving others for wrongs they have done to you is generally associated with better mental health, a greater sense of hope, and possibly better physical health. Unfortunately, and too often, we find that forgiving someone for a substantial wrong is difficult and takes considerable time.

    Activities have been developed to promote forgiveness and have been found to be effective in randomized trials. Such interventions have been shown to be effective not only in increasing forgiveness but also in decreasing depression, decreasing anxiety, and increasing hope. Most of the interventions require many sessions with a trained therapist or counselor. Fortunately, a recent workbook ("Paths to Forgiveness") to promote forgiveness has been developed and has been shown to be effective.

    This activity is focused on learning, practicing, and developing your ability to forgive.  Over the course of the next six weeks,, you’ll commit to using the "Paths to Forgiveness" workbook, which is freely available online. You’ll check back in here weekly to write about your experiences via directed prompts.`,
    instructions: `This activity is focused on learning, practicing, and developing your ability to forgive. Over the course of the next six weeks, you'll commit to working through a workbook that has proven effective in doing so.
    
    The first step is to make sure that you have a copy (or will have a copy soon) of the book “The Paths to Forgiveness.”
    
    This activity does not have a specific first exercise that you must do at this time. Instead, you can complete an (optional) reflection entry next. We find it helps to write down your expectations, what you want from this experience. This entry will be saved so that you can return to look at it over the next six weeks while completing this activity. Your entry will also be stored as part of your history.
    
    Then, you will have the option in the next step to take any of the included measures. This is a good way to track your progress both in this activity and overall.
    
    Finally, you’ll select the day of the week on which you would like to do this activity and set how and if you want to be notified on that day.`,

    gettingStarted: GETTING_STARTED,

    hasBaseline: false,
    firstEntry: `This activity has no specific baseline entry/instruction.`,

    weeklyInstructions: `You've committed to working through the "Paths to Forgiveness" workbook during this activity in order to help grow in your capacity to forgive. As part of your commitment you opted to return to this app today and reflect upon your experiences. Take a few moments to clear your mind. When you are ready,think back over the past week. Have you found yourself seeing any opportunities for forgiveness? Has a section of the workbook spoken to you in particular and given you new insights? If so, what are those insights and how have you been able to apply them in your daily life? How will you continue to do so going forward? If you have been struggling to forgive in past weeks, do you feel like you have been making any progress? Please take a few moments to think and then write down some brief reflections in the box below.`,
    science: [
      `Harper, Q., Worthington, E. L., Griffin, B. J., Lavelock, C. R., Hook, J. N., Vrana, S. R., & Greer, C. L. (2014). Efficacy of a workbook to promote forgiveness: a randomized controlled trial with university students. Journal of Clinical Psychology, 70,1158–1169.`,
      `Wade, N. G., Hoyt,W. T., Kidwell, J. E., & Worthington E. L. (2014). Efficacy of psychotherapeutic interventions to promote forgiveness: a meta-analysis. Journal of Consulting and Clinical Psychology, 82,154-170.`,
    ],
  },
  activity: {
    exercise: ``,
    header: ``,
    quotation: ``,
    general: ``,
    instructions: ``,
    gettingStarted: GETTING_STARTED,
    firstEntry: ``,
    weeklyInstructions: ``,
    science: [],
  },
};

export const COMMON_ACTIVITY_REFLECTION_INSTRUCTIONS = [
  `We encourage you to write down your expectations for each activity. You have come to this activity for some reason, so take a few minutes to think deeply about what that reason was and what you would like to get out of this exercise during the next six weeks. When you are ready, please write down some of your thoughts. Your response will be saved so that you can view it later.`,
];
