import { RouterProps, RouteComponentProps } from 'react-router';

import { getTypeFromUrl } from '../../utils/get_type_from_url';
import { ACTIVITIES } from '../../stores/activity/activity';
import { ActiveActivitySession } from './active/ActiveActivitySession';
import { AppActivity } from './AppActivity';
import { ErrorBoundary } from '../v2/ErrorBoundary';
import { useActiveSession } from '../../api/firebase/hooks/use_active_session';
import { isEmpty } from '@firebase/util';

type Props = {} & RouterProps & RouteComponentProps<{ type: string }>;

export const AppActivityContainer = (props: Props) => {
  const params = props.match.params;
  const type = getTypeFromUrl(params.type, props.match.path) || '';

  const { data: session, isLoading } = useActiveSession({ type });
  // console.log('session', session);

  const activity = ACTIVITIES[type];
  const { title } = activity;

  // if (isLoading) {
  //   return (
  //     <div className='pt-10 grid grid-cols-1 place-items-center'>
  //       Loading ...
  //     </div>
  //   );
  // }

  if (!!session && !isEmpty(session)) {
    return (
      <ErrorBoundary>
        <ActiveActivitySession session={session} title={title} />
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <AppActivity type={type} />
    </ErrorBoundary>
  );
};
