import { Action, combineReducers, Reducer } from 'redux';
import { AppState } from '../types';
import { accountReducer } from './account/account_reducer';
import { SIGN_OUT_SUCCEEDED } from './account/account_types';
import { defaultStore } from './default_store';
import { measureReducer } from './measure/measure_reducer';
import { metaReducer } from './meta/meta_reducer';
import { navReducer } from './nav/nav_reducer';
import { networkReducer } from './network/network_reducer';
import { newSessionReducer } from './session/new_session_reducer';
import { sessionsReducer } from './session/sessions_reducer';

export { defaultStore };

const appReducer = combineReducers({
  account: accountReducer,
  measures: measureReducer,
  meta: metaReducer,
  nav: navReducer,
  network: networkReducer,
  newSession: newSessionReducer,
  sessions: sessionsReducer,
});

export const rootReducer: Reducer<AppState, Action> = (
  // state: AppState = defaultStore,
  state: AppState | undefined,
  action: Action,
): AppState => {
  if (typeof state === 'undefined') return defaultStore;
  if (process.env.NODE_ENV === 'development' && action.type === 'RESET_APP') {
    console.group('RESETTING APP');
    console.log(defaultStore);
    console.groupEnd();
    // state = undefined;
    return defaultStore;
  }
  if (
    process.env.NODE_ENV === 'development' &&
    action.type === 'DEV_CLEAR_DATA'
  ) {
    //Clear everything except the account data for dev purposes
    const newState = {
      ...defaultStore,
      account: {
        ...state.account,
      },
    };
    return newState;
  }
  if (action.type === SIGN_OUT_SUCCEEDED) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('SIGN OUT AND CLEAR LOCAL DATA');
      console.log(defaultStore);
      console.groupEnd();
    }
    return defaultStore;
  }

  return appReducer(state, action);
};
