import { httpsCallable } from 'firebase/functions';
import { FetchMeasuresAction } from '../stores/measure/fetch_measures_action';
import FirebaseClient from './firebase_client';

/**
 * Fetch an array of measures
 * @param action
 */
export async function doFetchMeasures(action: FetchMeasuresAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: FETCH MEASURES REQUESTED');
    console.log(action);
    console.groupEnd();
  }

  try {
    const results = await httpsCallable(
      FirebaseClient.functions,
      'fetchMeasures',
    )(JSON.stringify(action.payload));
    const measureEntries = results.data;
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: FETCH MEASURES SUCCEEDED');
      console.log(measureEntries);
      console.groupEnd();
    }
    return measureEntries;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: FETCH MEASURES FAILED');
      console.error(error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
}
