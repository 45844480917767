export const FETCH_ACTIVE_SESSIONS_REQUESTED =
  'FETCH_ACTIVE_SESSIONS_REQUESTED';
export const FETCH_ACTIVE_SESSIONS_SUCCEEDED =
  'FETCH_ACTIVE_SESSIONS_SUCCEEDED';
export const FETCH_ACTIVE_SESSIONS_FAILED = 'FETCH_ACTIVE_SESSIONS_FAILED';

export type FetchActiveSessionsAction = {
  type: typeof FETCH_ACTIVE_SESSIONS_REQUESTED;
  payload: {};
};

export const fetchActiveSessionsAction = (): FetchActiveSessionsAction => ({
  type: FETCH_ACTIVE_SESSIONS_REQUESTED,
  payload: {},
});
