import '@firebase/firestore';
import '@firebase/functions';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import FirebaseClient from '../firebase/firebase_client';

export const doCreateAccountWithEmail = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: CREATE ACCOUNT WITH EMAIL REQUESTED');
    console.groupEnd();
  }

  try {
    const userCredential = await createUserWithEmailAndPassword(
      FirebaseClient.auth,
      email,
      password,
    );
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: CREATE ACCOUNT WITH EMAIL SUCCEEDED');
      console.log(userCredential, userCredential?.user);
      console.groupEnd();
    }
    // Check if email is authenticated
    if (!userCredential?.user?.emailVerified) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Sending email verification');
      }
      await sendEmailVerification(userCredential.user);
    }

    return userCredential;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: CREATE ACCOUNT WITH EMAIL FAILED');
      console.log('Error: ', error.code);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
};
