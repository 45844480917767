import React from 'react';
import { Link } from 'react-router-dom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import { AppState } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../stores/account/account_sign_out_action';
import { Popover } from '@headlessui/react';

export const MobileNavBar = () => {
  const account = useSelector((state: AppState) => state.account);
  const { isSignedIn } = account;
  const dispatch = useDispatch();
  return (
    // <nav className='bg-white shadow'>
    <nav className='lg:hidden fixed z-40 top-0 w-full'>
      <Popover className=''>
        {({ open }) => {
          return (
            <>
              <Popover.Button className='fixed z-50 top-4 right-4 p-2 mr-5 bg-white rounded-full'>
                {open ? (
                  <XIcon className='p-2 h-10 w-10 text-burgundy' />
                ) : (
                  <MenuIcon className='p-2 h-10 w-10 text-burgundy' />
                )}
              </Popover.Button>

              <Popover.Panel className='fixed z-40 pb-10 top-0 left-0 h-screen w-full bg-burgundy text-white overflow-y-auto'>
                <Popover.Button className='absolute top-8 left-4'>
                  <Link to='/'>
                    <img
                      className=''
                      src='/icons/f-white.svg'
                      alt='white capital F'
                    />
                  </Link>
                </Popover.Button>
                <div className='mt-24 px-10 grid grid-cols-1 place-items-center gap-8 text-center'>
                  {!isSignedIn &&
                    [
                      { text: 'About', to: '/' },
                      { text: 'Activities', to: '/about/activities' },
                      { text: 'Measures', to: '/about/measures' },
                    ].map((item, i) => {
                      return (
                        <Popover.Button
                          className={`${
                            i < 2 ? 'border-b border-white' : null
                          } w-full pb-6`}
                          // style={{ width: 250 }}
                          key={item.to}>
                          <Link className='px-10 block text-2xl' to={item.to}>
                            {item.text}
                          </Link>
                        </Popover.Button>
                      );
                    })}

                  <div className='mt-4' />
                  {!isSignedIn && (
                    <div className=''>
                      <Link to='/sign-in'>
                        <Popover.Button className='block h-11 w-300 bg-white text-burgundy rounded-full'>
                          Sign in
                        </Popover.Button>
                      </Link>
                      <div className='mt-4' />
                      <Link className='' to={'/sign-up'}>
                        <Popover.Button className='block h-11 w-300 border border-white rounded-full'>
                          Sign up
                        </Popover.Button>
                      </Link>
                    </div>
                  )}
                  {isSignedIn && (
                    <>
                      {[
                        { text: 'Home', to: '/' },
                        { text: 'Activities', to: '/about/activities' },
                        { text: 'Measures', to: '/about/measures' },
                        { text: 'History', to: '/history' },
                        { text: 'Account', to: '/account' },
                        { text: 'About', to: '/about' },
                      ].map((item, i) => {
                        return (
                          <Popover.Button
                            className={`${
                              i < 5 ? 'border-b border-white' : null
                            } w-full pb-6`}
                            // style={{ width: 250 }}
                            key={item.to}>
                            <Link className='px-10 block text-2xl' to={item.to}>
                              {item.text}
                            </Link>
                          </Popover.Button>
                        );
                      })}
                      <button
                        className={`block h-11 w-300 bg-white text-burgundy rounded-full`}
                        // style={{ width: 250 }}
                        key={'sign-out'}
                        onClick={() => dispatch(signOut())}>
                        Sign out
                      </button>
                    </>
                  )}
                </div>
              </Popover.Panel>
            </>
          );
        }}
      </Popover>
    </nav>
  );
};

/**
 *  Profile dropdown panel, show/hide based on dropdown state.
 *  Entering: "transition ease-out duration-200"
 *    From: "transform opacity-0 scale-95"
 *    To: "transform opacity-100 scale-100"
 *  Leaving: "transition ease-in duration-75"
 *    From: "transform opacity-100 scale-100"
 *    To: "transform opacity-0 scale-95"
 */
const ProfileDropdownPanel = ({ account }) => {
  const dispatch = useDispatch();
  return (
    <div className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10'>
      <div
        className='py-1 rounded-md bg-white shadow-xs'
        role='menu'
        aria-orientation='vertical'
        aria-labelledby='user-menu'>
        <Link
          to='/account'
          className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'
          role='menuitem'>
          Account
        </Link>
        <div
          onClick={() => dispatch(signOut())}
          className='block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'
          role='menuitem'>
          Sign out
        </div>
      </div>
    </div>
  );
};
