import { signInWithEmailAndPassword, updateEmail } from 'firebase/auth';
import { FirebaseClient } from '../firebase';
import firebase from '../firebase/firebase_client';

export const doChangeEmail = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: CHANGE EMAIL');
    console.groupEnd();
  }

  try {
    // re-auth user to be sure
    const user = firebase.auth.currentUser;
    console.log('Current user', user);

    // user?.email
    const userCredential = await signInWithEmailAndPassword(
      FirebaseClient.auth,
      user?.email || '',
      password,
    );
    // await user?.reauthenticateWithCredential(userCredential);

    if (!FirebaseClient.auth.currentUser) {
      console.error('User not logged in');
      return;
    }
    await updateEmail(FirebaseClient.auth.currentUser, email);
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: CHANGE EMAIL SUCCEEDED');
      console.groupEnd();
    }
    return { hasError: false };
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: CHANGE EMAIL FAILED');
      console.error('Error: ', error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
};
