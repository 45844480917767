import { Helmet } from 'react-helmet';
const policies = [
  {
    text: 'Cookie Policy',
    url: 'https://app.termly.io/document/cookie-policy/67bf59f0-0097-443f-a06d-3d5f2ea41480',
  },
  {
    text: 'Privacy Policy',
    url: 'https://app.termly.io/document/privacy-policy/fa8b2e61-10ca-46c1-8cd0-01f9cbfa0f4b',
  },
  {
    text: 'Terms of Service',
    url: 'https://app.termly.io/document/terms-of-use-for-website/64def3ec-9c62-4837-81cc-a642638d7422',
  },
];
export const PrivacyPolicy = () => {
  return (
    <div className='relative pb-10 overflow-hidden'>
      <Helmet>
        <title>Policies and Terms</title>
      </Helmet>
      <header className='py-20 bg-white'>
        <div className='mx-auto max-w-7xl'>
          <h1 className='px-4 xl:px-0 text-5xl leading-160'>
            The <span className='text-burgundy'>Flourishing App</span> Policies
            and Terms
          </h1>
        </div>
      </header>

      <main className='px-4'>
        <div className='py-10 mx-auto max-w-7xl'>
          <h2 className='font-bold'>Contact</h2>
          <p>Questions can be directed to flourishingapp at gmail dot com</p>
          <h2 className='mt-5 font-bold'>Policies</h2>
          <ul className='mt-2 space-y-5'>
            {policies.map((policy) => (
              <li key={policy.url} className=''>
                <a
                  className='text-not-black underline'
                  href={policy.url}
                  target='_blank'
                  rel='noopener noreferrer'>
                  {policy.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </main>
    </div>
  );
};
