export const FETCH_RECENT_MEASURES_REQUESTED =
  'FETCH_RECENT_MEASURES_REQUESTED';
export const FETCH_RECENT_MEASURES_SUCCEEDED =
  'FETCH_RECENT_MEASURES_SUCCEEDED';
export const FETCH_RECENT_MEASURES_FAILED = 'FETCH_RECENT_MEASURES_FAILED';

export type FetchRecentMeasuresAction = {
  type: typeof FETCH_RECENT_MEASURES_REQUESTED;
  payload: any;
};

export const fetchRecentMeasuresAction = (
  payload: any
): FetchRecentMeasuresAction => ({
  type: FETCH_RECENT_MEASURES_REQUESTED,
  payload,
});
