/************
 * TODO: use the firebase client delete account stuff
 * https://firebase.google.com/docs/auth/web/manage-users#set_a_users_email_address
 * then use the hook (onDelete) that I can get in the cloud functions to recursively delete from firestore
 */
import { httpsCallable } from 'firebase/functions';
import { FirebaseClient } from '../firebase';

export async function doDeleteAccount() {
  console.group('FIREBASE: DELETE ACCOUNT REQUESTED');
  console.groupEnd();

  try {
    const result = await httpsCallable(
      FirebaseClient.functions,
      'deleteAccount',
    )();
    console.group('FIREBASE: DELETE ACCOUNT SUCCEEDED');
    const deletedInfo = result.data;
    console.log(deletedInfo);
    console.groupEnd();

    return deletedInfo;
  } catch (error) {
    console.group('FIREBASE: DELETE ACCOUNT FAILED');
    console.log(error);
    console.groupEnd();
    return { hasError: true, error };
  }
}
