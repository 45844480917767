import { useState } from 'react';
import { Link } from 'react-router-dom';

import { StoppedAtDateItem } from '../../../components/DateItem';
import { getTitle } from '../../../utils';
import { getIcon } from '../../../utils/get_icon';
import { getUrlFromType } from '../../../utils/get_url_from_type';
import { Session } from '../../../stores/session/session_types';
import { Helmet } from 'react-helmet';
import {
  CHARACTER_STRENGTHS_MEASURE,
  Measure,
} from '../../../stores/measure/measure_type';
import { RadioGroup } from '@headlessui/react';
import { useMeasureHistory } from '../../../api/firebase/hooks/use_measure_history';
import { useActivityHistory } from '../../../api/firebase/hooks/use_activity_history';
import { ACTIVITY_IMAGES } from '../../../assets/activity_images';
import { MEASURE_TEXT } from '../../../text/measures';
import { IsLoading } from '../../../components/IsLoading';

const tabs = [{ text: 'Activities' }, { text: 'Measures' }];

export const HistoryPage = () => {
  const [selected, setSelected] = useState(tabs[0]);

  const { data: activities, isLoading: activitiesLoading } =
    useActivityHistory();
  const { data: measures, isLoading: measuresLoading } = useMeasureHistory();

  const isLoading = activitiesLoading || measuresLoading;

  if (isLoading) {
    return <IsLoading isLoading={true} />;
  }

  return (
    <div>
      <Helmet>
        <title>History</title>
      </Helmet>

      <header className='py-20 bg-white'>
        <div className='mx-auto max-w-7xl px-4 xl:px-0'>
          <h1 className='text-5xl leading-160 text-burgundy'>History</h1>
          <p className='text-sm leading-160'>
            Here, you'll find all of your completed activities and measures.
          </p>
        </div>
      </header>

      <main className='relative py-8 px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-7xl'>
          {/* Tabs */}
          <RadioGroup
            className='py-4 flex flex-row space-x-5'
            value={selected}
            onChange={setSelected}>
            {tabs.map((item) => {
              return (
                <RadioGroup.Option
                  className='cursor-pointer'
                  key={item.text}
                  value={item}>
                  {({ active, checked }) => {
                    // console.log('checked', checked);
                    // className =
                    //   'block m-5 py-3 wfull border border-burgundy text-xs rounded-full';
                    return (
                      <div
                        className={`${
                          checked
                            ? 'bg-burgundy text-white'
                            : 'bg-white text-burgundy'
                        } flex justify-center w-32 md:w-60 py-3 text-sm leading-160 rounded-full border border-burgundy`}>
                        <RadioGroup.Label>{item.text}</RadioGroup.Label>
                      </div>
                    );
                  }}
                </RadioGroup.Option>
              );
            })}
          </RadioGroup>

          {/* History List Outlet */}
          {/* <div>Showing: {toShow}</div> */}
          <div className='my-4' />
          {selected.text.toLowerCase() === 'activities' ? (
            //@ts-ignore
            <ActivitiesList data={activities} />
          ) : null}
          {selected.text.toLowerCase() === 'measures' ? (
            // @ts-ignore
            <MeasuresList data={measures} />
          ) : null}
        </div>
      </main>
    </div>
  );
};

/**
 * Should be able to use this for measures too
 */
const ActivitiesList = ({ data }: { data: Session[] }) => {
  return (
    <ul className='space-y-2'>
      {data?.map((d) => {
        // const icon = getIcon(d.type);
        const icon = ACTIVITY_IMAGES[d.type];
        const title = getTitle(d.type);
        const url = getUrlFromType(d.type, true);
        const toUrl = `/app/activities/${url}/sessions/${d.id}`;
        // TODO: as an exercise make reduce from stratch
        const nCompletedEntries = d.entries.reduce(
          (acc, entry) => (entry.completed ? acc + 1 : acc),
          0,
        );
        return (
          <Card
            key={d.id}
            type='activity'
            toUrl={toUrl}
            title={title}
            icon={icon}
            nCompletedEntries={nCompletedEntries}
            startedAt={d.startedAt}
            stoppedAt={d.stoppedAt}
          />
        );
      })}
    </ul>
  );
};

const MeasuresList = ({ data }: { data: Measure[] }) => {
  return (
    <ul className='space-y-2'>
      {data?.map((d) => {
        // const icon = getIcon(d.type);
        const { iconWhite: icon } = MEASURE_TEXT[d.type];
        const title = getTitle(d.type);
        const url = getUrlFromType(d.type, false);
        const toUrl = `/app/measures/${url}/entries/${d.id}`;

        let score, strengths;
        if (d.type === CHARACTER_STRENGTHS_MEASURE) {
          strengths = d.payload.responses;
        } else {
          // score = d.payload.responses.reduce((sum, cur) => sum + cur, 0);
          score = (
            d.payload.responses.reduce((sum: number, v) => {
              const val = typeof v === 'string' ? 0 : v;
              return sum + val;
            }, 0) / d.payload.responses.length
          ).toFixed(1);
        }

        return (
          <Card
            key={d.id}
            type='measure'
            toUrl={toUrl}
            title={`${title} Measure`}
            icon={icon}
            stoppedAt={d.stoppedAt}
            score={score}
            strengths={strengths}
          />
        );
      })}
    </ul>
  );
};

const Card = ({
  type,
  toUrl,
  icon,
  title,
  nCompletedEntries,
  startedAt,
  stoppedAt,
  score,
  strengths,
}: {
  type: string;
  toUrl: string;
  icon: string;
  title: string;
  nCompletedEntries?: number;
  startedAt?: number;
  stoppedAt: number;
  score?: number;
  strengths?: string;
}) => {
  return (
    <li className='bg-white shadow hover:shadow-xl'>
      <Link to={toUrl} className='block'>
        <div className='flex items-center px-4 py-4 sm:px-6'>
          <div className='min-w-0 flex-1 flex items-center'>
            <div className='flex-shrink-0 flex items-center justify-center bg-burgundy h-14 w-14 rounded-full overflow-hidden'>
              {type === 'activity' ? (
                <img
                  className='h-full w-full object-center object-cover'
                  src={icon}
                  alt=''
                />
              ) : (
                <img
                  className='bg-burgundy mx-auto p-4 object-cover'
                  src={icon}
                  alt=''
                />
              )}
            </div>
            <div className='min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4'>
              <div>
                <div className='text-sm leading-5 font-medium text-gray-600 truncate'>
                  {title}
                </div>
                {typeof nCompletedEntries !== 'undefined' && (
                  <div className='mt-2 flex items-center text-sm leading-5 text-gray-500'>
                    <span className='truncate'>
                      Completed {nCompletedEntries} Entries
                    </span>
                  </div>
                )}
                {typeof score !== 'undefined' && (
                  <div className='mt-2 flex items-center text-sm leading-5 text-gray-500'>
                    <span className='truncate'>Score {score}</span>
                  </div>
                )}
                {typeof strengths !== 'undefined' && (
                  <div className='mt-2 flex items-center text-sm leading-5 text-gray-500'>
                    <span className='truncate'>{`${strengths}`}</span>
                  </div>
                )}
              </div>
              <div className='hidden md:block'>
                <div>
                  {startedAt && (
                    <div className='text-sm leading-5 text-gray-500'>
                      Started on: <StoppedAtDateItem date={startedAt} />
                    </div>
                  )}
                  <div className='mt-2 text-sm leading-5 text-gray-500'>
                    Completed on: <StoppedAtDateItem date={stoppedAt} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <svg
              className='h-5 w-5 text-gray-400'
              viewBox='0 0 20 20'
              fill='currentColor'>
              <path
                fillRule='evenodd'
                d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                clipRule='evenodd'
              />
            </svg>
          </div>
        </div>
      </Link>
    </li>
  );
};
