import { all, call, put, takeEvery } from 'redux-saga/effects';
import { doFetchHistory } from '../../firebase/fetch_history';
import { doFetchLatest } from '../../firebase/fetch_latest';
import {
  FetchHistoryAction,
  FETCH_HISTORY_FAILED,
  FETCH_HISTORY_REQUESTED,
  FETCH_HISTORY_SUCCEEDED,
} from './fetch_history_action';
import {
  FetchLatestAction,
  FETCH_LATEST_FAILED,
  FETCH_LATEST_REQUESTED,
  FETCH_LATEST_SUCCEEDED,
} from './fetch_latest_action';

function* fetchHistory(action: FetchHistoryAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('SAGA: FETCH HISTORY REQUESTED?');
    console.log(action);
    console.groupEnd();
  }
  try {
    const results = yield call(doFetchHistory, action);
    if (process.env.NODE_ENV !== 'production') {
      console.group('SAGA: FETCH HISTORY SUCCEEDED');
      console.groupEnd();
    }

    yield put({ type: FETCH_HISTORY_SUCCEEDED, payload: results });
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('SAGA ERROR: FETCH HISTORY FAILED');
      console.log('### ERRROR ###', error);
      console.groupEnd();
    }
    yield put({ type: FETCH_HISTORY_FAILED, message: error.message });
  }
}

function* fetchLatest(action: FetchLatestAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('SAGA: CREATE SESSION REQUESTED?');
    console.log(action);
    console.groupEnd();
  }
  try {
    const result = yield call(doFetchLatest, action);
    if (process.env.NODE_ENV !== 'production') {
      console.group('SAGA: FETCH LATEST SUCCEEDED');
      // console.log('RESULT', result);
      console.groupEnd();
    }

    yield put({ type: FETCH_LATEST_SUCCEEDED, payload: result });
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('SAGA ERROR: FETCH LATEST FAILED');
      console.log('### ERRROR ###', error);
      console.groupEnd();
    }
    yield put({ type: FETCH_LATEST_FAILED, message: error.message });
  }
  console.groupEnd();
}

export function* watchOther() {
  yield takeEvery(FETCH_LATEST_REQUESTED, fetchLatest);
  yield takeEvery(FETCH_HISTORY_REQUESTED, fetchHistory);
}

export function* otherSaga() {
  if (process.env.NODE_ENV !== 'production') {
    console.log('running other saga');
  }
  yield all([watchOther()]);
}
