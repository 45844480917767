import { sendEmailVerification } from 'firebase/auth';
import { FirebaseClient } from '../firebase';
import firebase from '../firebase/firebase_client';

export const doVerifyEmail = async () => {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: SEND VERIFICATION EMAIL');
    console.groupEnd();
  }
  if (!FirebaseClient.auth.currentUser) {
    console.error('User not logged in');
    return;
  }

  try {
    // re-auth user to be sure
    const user = firebase.auth.currentUser;
    if (process.env.NODE_ENV !== 'production') {
      console.log('Current user', user);
    }

    await sendEmailVerification(FirebaseClient.auth.currentUser);
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: SEND VERIFICATION EMAIL SUCCEEDED');
      console.groupEnd();
    }
    return { hasError: false };
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: SEND VERIFICATION EMAIL FAILED');
      console.error('Error: ', error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
};
