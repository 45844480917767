import { MeasureForm } from './MeasureForm';
import { RouterProps, RouteComponentProps, useHistory } from 'react-router';
import { mapMeasure } from '../../utils/get_type_from_url';
import { useMutation } from 'react-query';
import { Measure } from '../../stores/measure/measure_type';
import { httpsCallable } from 'firebase/functions';
import { FirebaseClient } from '../../firebase';
import { getUrlFromType } from '../../utils/get_url_from_type';

type Props = {} & RouterProps & RouteComponentProps<{ measure: string }>;

export const AnonMeasure = (props: Props) => {
  const history = useHistory();
  const type = mapMeasure.get(props.match.params.measure);
  const slug = getUrlFromType(type || '', false); //FIXME:

  const mutation = useMutation(
    async (measure: Measure) => {
      console.log('Measure', measure);

      const { data } = (await httpsCallable(
        FirebaseClient.functions,
        'createAnonMeasure_v2',
      )({ measure })) as { data: { measure: Measure } };
      console.log(data);

      return data.measure;
    },
    {
      onSuccess: (measure: Measure) => {
        console.log('on success', measure);
        history.push(`/anon/results/${slug}/${measure.id}`);
        // Redirect to completed anon measure page
        // flourishinp.app/anon/results/:type/:id
      },
    },
  );

  if (!type) {
    return <div>Error</div>;
  }

  return (
    <div className='mx-auto max-w-7xl'>
      <MeasureForm type={type} mutation={mutation} />
    </div>
  );
};
