//@ts-nocheck
/**
 * Network Reducers
 *
 * These reducers and patterns are meant to help handle network io.
 * Show if a network request is busy, complete, etc in order to show appropriate state
 *
 */
import { isUndefined } from 'lodash';
import {
  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_SUCCEEDED,
} from '../../auth/reset_password_action';
import {
  SIGN_IN_WITH_EMAIL_FAILED,
  SIGN_IN_WITH_EMAIL_REQUESTED,
  SIGN_IN_WITH_EMAIL_SUCCEEDED,
} from '../../auth/sign_in_with_email_action';
import { Network } from '../../types/network';
import {
  SIGN_OUT_FAILED,
  SIGN_OUT_REQUESTED,
  SIGN_OUT_SUCCEEDED,
} from '../account/account_types';
import {
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_REQUESTED,
  CHANGE_PASSWORD_SUCCEEDED,
} from '../account/change_password_action';
import {
  SEND_EMAIL_VERIFICATION_FAILED,
  SEND_EMAIL_VERIFICATION_REQUESTED,
  SEND_EMAIL_VERIFICATION_SUCCEEDED,
} from '../account/send_email_verification_action';
import {
  CREATE_MEASURE_FAILED,
  CREATE_MEASURE_REQUESTED,
  CREATE_MEASURE_SUCCEEDED,
  FETCH_MEASURES_FAILED,
  FETCH_MEASURES_REQUESTED,
  FETCH_MEASURES_SUCCEEDED,
  FETCH_MEASURE_FAILED,
  FETCH_MEASURE_REQUESTED,
  FETCH_MEASURE_SUCCEEDED,
} from '../measure';
import {
  FETCH_RECENT_MEASURES_FAILED,
  FETCH_RECENT_MEASURES_REQUESTED,
  FETCH_RECENT_MEASURES_SUCCEEDED,
} from '../measure/fetch_recent_measures_action';
import {
  FETCH_HISTORY_FAILED,
  FETCH_HISTORY_REQUESTED,
  FETCH_HISTORY_SUCCEEDED,
} from '../other/fetch_history_action';
import {
  FETCH_LATEST_FAILED,
  FETCH_LATEST_REQUESTED,
  FETCH_LATEST_SUCCEEDED,
} from '../other/fetch_latest_action';
import {
  CREATE_ENTRY_FAILED,
  CREATE_ENTRY_REQUESTED,
  CREATE_ENTRY_SUCCEEDED,
} from '../session/create_entry_action';
import {
  CREATE_SESSION_FAILED,
  CREATE_SESSION_REQUESTED,
  CREATE_SESSION_SUCCEEDED,
} from '../session/create_section_action';
import {
  END_SESSION_FAILED,
  END_SESSION_REQUESTED,
  END_SESSION_SUCCEEDED,
} from '../session/end_session_action';
import {
  FETCH_CURRENT_SESSION_FAILED,
  FETCH_CURRENT_SESSION_REQUESTED,
  FETCH_CURRENT_SESSION_SUCCEEDED,
} from '../session/fetch_current_session_action';
import {
  FETCH_SESSION_FAILED,
  FETCH_SESSION_REQUESTED,
  FETCH_SESSION_SUCCEEDED,
} from '../session/fetch_session_action';
import { NEW_SESSION } from '../session/new_session_action_creators';

export const createNetwork = (): Network => ({
  endSession: '',
  newEntry: '',
  changePasswordIsBusy: false,
  changePasswordSucceeded: false,
  createAccountWithEmailIsBusy: false,
  createEntryIsBusy: false,
  createEntryIsFinished: false,
  createMeasureIsBusy: false,
  createMeasureIsFinished: false,
  createSessionIsBusy: false,
  createSessionIsFinished: false,
  endSessionIsBusy: false,
  endSessionIsFinished: false,
  fetchActiveSessionsIsBusy: false,
  fetchCurrentSessionIsBusy: false,
  fetchHistoryIsBusy: false,
  fetchLatestIsBusy: false,
  fetchMeasureIsBusy: false,
  fetchMeasuresIsBusy: false,
  fetchRecentMeasures: false,
  fetchSessionIsBusy: false,
  resetPasswordIsBusy: false,
  sendVerificationEmailIsBusy: false,
  sendVerificationEmailIsFinished: false,
  signInWithEmailIsBusy: false,
  signOutIsBusy: false,
});

export function networkReducer(
  state: Network | undefined,
  action: any,
): Network {
  if (isUndefined(state)) {
    return createNetwork();
  }

  switch (action.type) {
    //
    case CHANGE_PASSWORD_REQUESTED:
      return {
        ...state,
        changePasswordIsBusy: true,
        changePasswordSucceeded: false,
      };

    case CHANGE_PASSWORD_FAILED:
    case CHANGE_PASSWORD_SUCCEEDED:
      return {
        ...state,
        changePasswordIsBusy: false,
        changePasswordSucceeded: true,
      };

    case CREATE_ENTRY_REQUESTED:
      return {
        ...state,
        createEntryIsBusy: true,
        createEntryIsFinished: false,
      };

    case CREATE_ENTRY_SUCCEEDED:
    case CREATE_ENTRY_FAILED:
      return {
        ...state,
        createEntryIsBusy: false,
        createEntryIsFinished: true,
      };

    case CREATE_MEASURE_REQUESTED:
      return {
        ...state,
        createMeasureIsBusy: true,
        createMeasureIsFinished: false,
      };

    case CREATE_MEASURE_SUCCEEDED:
    case CREATE_MEASURE_FAILED:
      return {
        ...state,
        createMeasureIsFinished: true,
        createMeasureIsBusy: false,
      };

    case CREATE_SESSION_REQUESTED:
      return {
        ...state,
        createSessionIsBusy: true,
      };

    case CREATE_SESSION_SUCCEEDED:
    case CREATE_SESSION_FAILED:
      return {
        ...state,
        createSessionIsBusy: false,
        createSessionIsFinished: true,
      };

    case END_SESSION_REQUESTED:
      return {
        ...state,
        endSession: 'sendingToServer',
        endSessionIsBusy: true,
      };

    case END_SESSION_SUCCEEDED:
    case END_SESSION_FAILED:
      return {
        ...state,
        endSession: 'savedToServer',
        endSessionIsBusy: false,
      };

    case FETCH_CURRENT_SESSION_REQUESTED:
      return { ...state, fetchCurrentSessionIsBusy: true };

    case FETCH_CURRENT_SESSION_SUCCEEDED:
    case FETCH_CURRENT_SESSION_FAILED:
      return { ...state, fetchCurrentSessionIsBusy: false };

    case FETCH_LATEST_REQUESTED:
      return { ...state, fetchLatestIsBusy: true };

    case FETCH_LATEST_SUCCEEDED:
    case FETCH_LATEST_FAILED:
      return { ...state, fetchLatestIsBusy: false };

    case FETCH_HISTORY_REQUESTED:
      return {
        ...state,
        fetchHistoryIsBusy: true,
      };

    case FETCH_HISTORY_SUCCEEDED:
    case FETCH_HISTORY_FAILED:
      return {
        ...state,
        fetchHistoryIsBusy: false,
      };

    case FETCH_MEASURE_REQUESTED:
      return {
        ...state,
        fetchMeasureIsBusy: true,
      };

    case FETCH_MEASURE_SUCCEEDED:
    case FETCH_MEASURE_FAILED:
      return {
        ...state,
        fetchMeasureIsBusy: false,
      };

    case FETCH_MEASURES_REQUESTED:
      return {
        ...state,
        fetchMeasuresIsBusy: true,
      };

    case FETCH_MEASURES_SUCCEEDED:
    case FETCH_MEASURES_FAILED:
      return {
        ...state,
        fetchMeasuresIsBusy: false,
      };

    case FETCH_RECENT_MEASURES_REQUESTED:
      return {
        ...state,
        fetchRecentMeasures: true,
      };

    case FETCH_RECENT_MEASURES_SUCCEEDED:
    case FETCH_RECENT_MEASURES_FAILED:
      return {
        ...state,
        fetchRecentMeasures: false,
      };

    case FETCH_SESSION_REQUESTED:
      return {
        ...state,
        fetchSessionIsBusy: true,
      };

    case FETCH_SESSION_SUCCEEDED:
    case FETCH_SESSION_FAILED:
      return {
        ...state,
        fetchSessionIsBusy: false,
      };

    case NEW_SESSION:
      return {
        ...state,
        createSessionIsFinished: false,
      };

    case RESET_PASSWORD_REQUESTED:
      return {
        ...state,
        resetPasswordIsBusy: true,
      };

    case RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        resetPasswordIsBusy: false,
      };

    case SEND_EMAIL_VERIFICATION_REQUESTED:
      return {
        ...state,
        sendVerificationEmailIsBusy: true,
        sendVerificationEmailIsFinished: false,
      };

    case SEND_EMAIL_VERIFICATION_FAILED:
    case SEND_EMAIL_VERIFICATION_SUCCEEDED:
      return {
        ...state,
        sendVerificationEmailIsBusy: false,
        sendVerificationEmailIsFinished: true,
      };

    case SIGN_IN_WITH_EMAIL_REQUESTED:
      return {
        ...state,
        signInWithEmailIsBusy: true,
      };

    case SIGN_IN_WITH_EMAIL_SUCCEEDED:
    case SIGN_IN_WITH_EMAIL_FAILED:
      return {
        ...state,
        signInWithEmailIsBusy: false,
      };

    case SIGN_OUT_REQUESTED:
      return {
        ...state,
        signOutIsBusy: true,
      };

    case SIGN_OUT_SUCCEEDED:
    case SIGN_OUT_FAILED:
      return {
        ...state,
        signOutIsBusy: false,
      };

    default:
      return state;
  }

  // SHOULD NEVER GET HERE
}
