import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps, RouterProps } from 'react-router';

import { updateNewSessionAction } from '../../../stores/session/new_session_action_creators';
import { Session } from '../../../stores/session/session_types';
import { AppState } from '../../../types';
import { getTypeFromUrl } from '../../../utils/get_type_from_url';
import { getUrlFromType } from '../../../utils/get_url_from_type';
import { Form, Formik, Field } from 'formik';
import { TEXT } from '../../../text/activities';
import { CHARACTER_STRENGTHS_ACTIVITY } from '../../../stores/activity/activity';
import {
  CharacterStrengthsForm,
  createCompletedMeasure,
} from '../../v2/measures/NewMeasureEntry';
import { ACTIVITY_IMAGES } from '../../../assets/activity_images';
import { Stepper } from './Stepper';

const validateText = (text: string) => {
  let error;
  if (text.length < 1) {
    error = 'Too few words';
  }
  return error;
};

type Props = {} & RouterProps &
  RouteComponentProps<{ type: string; cmd: string }>;

export const NewActivitySessionBaseline = (props: Props) => {
  const [startedAt] = useState(Date.now());
  const [text, setText] = useState('');
  const [responses, setResponses] = useState([]);
  const [completed, setCompleted] = useState(false);
  const newSession = useSelector((state: AppState) => state.newSession);
  const dispatch = useDispatch();
  const params = props.match.params;
  const type = getTypeFromUrl(params.type, props.match.path) || '';

  const activityUrl = getUrlFromType(type, true);
  const { firstEntry, header } = TEXT[type];

  const handleCharacterStrengthsSubmit = (responses) => {
    setResponses(responses);
  };

  // Need a branch for different types of baselines
  useEffect(() => {
    // TODO: make sure that length validation occurs in Formik, prior to here
    if (text.length > 0) {
      const updatedSession: Session = {
        ...newSession,
        baseline: { [type]: text },
      };
      dispatch(updateNewSessionAction(updatedSession));
      setCompleted(true);
    }
  }, [text]);

  //Character Strengths
  // FIXME: how did I do this? there's something special with CS?
  // it has to be a full measure object
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Resposnes', responses);
    }
    if (type === CHARACTER_STRENGTHS_ACTIVITY && responses.length > 0) {
      const completedMeasure = createCompletedMeasure({
        type,
        startedAt,
        responses,
      });
      completedMeasure.isBaseline = true;
      const updatedSession: Session = {
        ...newSession,
        baseline: { [type]: completedMeasure },
      };
      dispatch(updateNewSessionAction(updatedSession));
      setCompleted(true);
    }
  }, [dispatch, newSession, responses, startedAt, type]);

  if (newSession.type === '') {
    return <Redirect to='/' />;
  }

  const img = ACTIVITY_IMAGES[type];

  //If completed, go to next page
  if ((text.length > 0 || responses.length > 0) && completed) {
    // FIXME: this seems to screw up the browser history stack
    // yep. this acts like a 3xx and overwrites the browser history
    return (
      <Redirect push to={`/app/activities/${activityUrl}/new/reflection`} />
    );
  }

  if (type === CHARACTER_STRENGTHS_ACTIVITY) {
    return (
      <div>
        <h1>{`${header} Baseline`}</h1>
        <p className='whitespace-pre-line'>{firstEntry}</p>
        <p>
          To find your character strengths you will need to visit an external
          website where you will also be required to create an account before
          being able to take the needed survey. To continue, please go to
          https://www.authentichappiness.sas.upenn.edu/questionnaires/brief-strengths-test.
          After completing the survey and seeing your strengths, please come
          back here to enter in your results below.
        </p>
        <CharacterStrengthsForm
          handleCancel={() => {}}
          handleSubmit={handleCharacterStrengthsSubmit}
        />
      </div>
    );
  }

  return (
    <div className='relative min-h-screen pb-32'>
      {/* <Helmet><title></title></Helmet> */}
      <img
        className='h-96 w-full object-cover object-center'
        src={img}
        alt=''
      />
      <div className='relative z-10 px-4 md:px-20 py-10 mx-auto max-w-7xl grid grid-cols-1 place-item-center -mt-32 bg-white text-center'>
        <header className='space-y-5'>
          <h1 className='text-burgundy text-5xl leading-160'>{header}</h1>
          <p className='text-sm leading-160 whitespace-pre-line'>
            {firstEntry}
          </p>
        </header>

        <Formik
          initialValues={{ response: newSession.baseline[type] || '' }}
          onSubmit={({ response }) => {
            setText(response);
          }}>
          {({ errors, touched, isValidating }) => (
            <Form className='py-10' translate='yes'>
              <Field
                name='response'
                as='textarea'
                className='form-textarea'
                validate={validateText}
              />
              {/* TODO: display word count */}
              {errors.response && touched.response && (
                <div className='py-10 text-red-700'>{errors.response}</div>
              )}
              {/*  */}
              <div className='mt-10 flex justify-center'>
                <button
                  type='submit'
                  className='inline-flex justify-center py-2 px-20 border border-burgundy text-sm leading-5 font-medium rounded-full text-burgundy'>
                  Save & Next
                </button>
              </div>
              <div className='mt-5' />
              <Stepper step='baseline' hasBaseline={true} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
