import { v4 as uuidv4 } from 'uuid';
import { Session } from './session_types';

export const createEmptySession = (type = ''): Session => ({
  id: '',
  cuuid: uuidv4(),
  type,
  isActive: false,
  completed: false,
  currentEntryNumber: 0,
  currentWeek: 0,
  canDoEntry: false,
  startedAt: Date.now(),
  stoppedAt: 0,
  notifications: {
    email: false,
    app: false,
  },
  entries: [],
  scheduledDaysOfTheWeek: [],
  baseline: [],
  baselineMeasures: [],
  baselineReflection: '',
});

export function newSessionReducer(
  state: Session | undefined,
  action: any,
): Session {
  if (typeof state === 'undefined') {
    return createEmptySession();
  }

  switch (action.type) {
    case 'NEW_SESSION':
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: NEW SESSION');
        console.groupEnd();
      }
      return createEmptySession(action.payload.type);

    case 'UPDATE_NEW_SESSION':
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: UPDATE NEW SESSION');
        console.log(action);
        console.groupEnd();
      }
      return { ...state, ...action.payload };

    case 'CLEAR_NEW_SESSION':
      return createEmptySession();

    default:
      return state;
  }
}
