import {
  CREATE_MEASURE_REQUESTED,
  CREATE_MEASURE_SUCCEEDED,
} from '../measure/create_measure_action';
import { FETCH_HISTORY_SUCCEEDED } from '../other/fetch_history_action';
import { CREATE_ENTRY_SUCCEEDED } from '../session/create_entry_action';
import { CREATE_SESSION_SUCCEEDED } from '../session/create_section_action';
import { NEW_SESSION } from '../session/new_session_action_creators';
import { createMetaState, MetaState } from './meta';

export const metaReducer = (
  state: MetaState | undefined,
  action: any, //MetaAction
): MetaState => {
  if (state === undefined) {
    return createMetaState();
  }
  switch (action.type) {
    case 'SET_COOKIE_BANNER':
      return { ...state, cookieBannerSeen: true };

    case FETCH_HISTORY_SUCCEEDED:
      return {
        ...state,
        lastMeasureResult: action.payload.lastMeasureResult || 0,
        lastSessionResult: action.payload.lastSessionResult || 0,
        nSessions: action.payload.nSessions || 0,
        nDoneSessions: action.payload.nDoneSessions || 0,
        nMeasures: action.payload.nMeasures || 0,
      };
    case NEW_SESSION:
      return { ...state, intakeInProgress: true };

    case CREATE_SESSION_SUCCEEDED:
      return { ...state, intakeInProgress: false };

    case 'NEW_ENTRY':
      return { ...state, entryInProgress: true };

    case CREATE_ENTRY_SUCCEEDED:
      return { ...state, entryInProgress: false };

    case CREATE_MEASURE_REQUESTED:
      return { ...state, entryInProgress: true };

    case CREATE_MEASURE_SUCCEEDED:
      return { ...state, entryInProgress: false };

    case 'SET_HISTORY_SHOW_PREF':
      return { ...state, historyShowPref: action.payload.pref };

    default:
      return state;
  }
};
