import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../types';
import { WelcomePage } from './Welcome';
import { Dashboard } from './dashboard/Dashboard';

export const HomePage = () => {
  const isSignedIn = useSelector((s: AppState) => s.account.isSignedIn);
  if (isSignedIn) {
    return <Dashboard />;
  }

  return <WelcomePage />;
};
