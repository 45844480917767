import { httpsCallable } from 'firebase/functions';
import { WeeklyEmailRemindersAction } from '../stores/account/weekly_email_reminders_action';
import FirebaseClient from './firebase_client';

export async function setWeeklyEmailReminders(
  action: WeeklyEmailRemindersAction,
) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: WEEKLY EMAIL REMINDERS REQUESTED');
    console.log(action);
    console.groupEnd();
  }

  try {
    const result = (await httpsCallable(
      FirebaseClient.functions,
      'setWeeklyEmailReminders',
    )(JSON.stringify(action.payload.status))) as any;
    if (result.data.hasError) throw new Error(result.data);

    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: WEEKLY EMAIL REMINDERS SUCCEEDED');
      console.log(result.data);
      console.groupEnd();
    }

    return result.data;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: WEEKLY EMAIL REMINDERS FAILED');
      console.error(error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
}
