export const WEEKLY_EMAILS_REQUESTED = 'WEEKLY_EMAILS_REQUESTED';
export const WEEKLY_EMAILS_SUCCEEDED = 'WEEKLY_EMAILS_SUCCEEDED';
export const WEEKLY_EMAILS_FAILED = 'WEEKLY_EMAILS_FAILED';

export type WeeklyEmailRemindersAction = {
  type: typeof WEEKLY_EMAILS_REQUESTED;
  payload: { status: boolean };
};

export const setWeeklyEmailRemindersAction = ({
  status,
}: {
  status: boolean;
}): WeeklyEmailRemindersAction => ({
  type: WEEKLY_EMAILS_REQUESTED,
  payload: { status },
});
