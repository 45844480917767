import { ArrowLeftIcon } from '@heroicons/react/outline';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouterProps } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useSession } from '../../../api/firebase/hooks/use_session';
import { BackToSessionButton } from '../../../components/buttons/BackToSession';
import { EntryPageContainer } from '../../../components/containers/EntryPageContainer';
import { ScheduledForDateItem } from '../../../components/DateItem';
import { CHARACTER_STRENGTHS_ACTIVITY } from '../../../stores/activity/activity';

// import { DateItem } from '../../../../components/DateItem';
// import { TEXT } from '../../DEPRECATED_text/activities/text';
import { TEXT } from '../../../text/activities';
import { getTitle } from '../../../utils/get_title';
import { getTypeFromUrl } from '../../../utils/get_type_from_url';
import { getUrlFromType } from '../../../utils/get_url_from_type';

type Props = {} & RouterProps &
  RouteComponentProps<{ type: string; sid: string; eid: string }>;

export const CompletedBaselineEntry = (props: Props) => {
  const { match } = props;
  const params = match.params;
  const { sid } = params;
  const type = getTypeFromUrl(props.match.params.type, props.match.path) || '';

  const { data: session, isLoading } = useSession({ id: sid });

  const instructions = TEXT[type].firstEntry;
  const typeUrl = getUrlFromType(type, true);
  const title = getTitle(type);
  const to = session?.isActive
    ? `/app/activities/${typeUrl}`
    : `/app/activities/${typeUrl}/sessions/${sid}/`;

  if (isLoading) {
    return (
      <div className='grid grid-cols-1 place-items-center pt-10'>
        Loading...
      </div>
    );
  }

  let entry = session && session.baseline[type];

  // TODO: FIXME:
  if (type === CHARACTER_STRENGTHS_ACTIVITY) {
    const responses = entry.payload.responses;
    return (
      <EntryPageContainer header='Baseline Entry' subheader='Completed'>
        <div className='prose'>
          <h2>Instructions</h2>
          <p>{instructions}</p>
          <h2>Your Strength</h2>
          <ul className='bg-cool-gray-50 mt-2 py-2 px-2'>
            {responses.map((response) => (
              <li key={response}>{response}</li>
            ))}
          </ul>
        </div>
        <BackToSessionButton toUrl={to} />
      </EntryPageContainer>
    );
  }

  return (
    <div className='min-h-screen'>
      <Helmet>
        <title>Completed Baseline</title>
      </Helmet>
      <main className='mx-auto max-w-7xl px-2 py-0 sm:p-0 '>
        <header className='py-10 flex space-x-5 items-center'>
          <Link
            to={to}
            className='inline-block bg-white rounded-full shadow cursor-pointer'>
            <ArrowLeftIcon className='p-2 h-12 w-12 text-burgundy' />
          </Link>
          <div className='h-12 w-12 flex justify-center items-center bg-burgundy text-white'>
            B
          </div>
          <h2>Baseline Entry</h2>
        </header>
        {/*  */}
        <div className='py-20 bg-white text-center'>
          <div className='mx-auto max-w-5xl'>
            <h2 className='text-sm'>
              {new Date(session.startedAt).toDateString()}
            </h2>
            <h1 className='text-burgundy text-5xl'>{title} Entry</h1>

            <div className='mt-10'>
              <p>{instructions}</p>
              {/* <h2>Response</h2> */}
            </div>
            {/* FIXME: check for array or string */}
            <div className='py-20 w-full bg-gray-50'>
              <p className='mt-10'>{entry}</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
