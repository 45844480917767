export const FETCH_HISTORY_REQUESTED = 'FETCH_HISTORY_REQUESTED';
export const FETCH_HISTORY_SUCCEEDED = 'FETCH_HISTORY_SUCCEEDED';
export const FETCH_HISTORY_FAILED = 'FETCH_HISTORY_FAILED';

export type FetchHistoryAction = {
  type: typeof FETCH_HISTORY_REQUESTED;
  payload: {
    lastMeasureResult: number;
    lastSessionResult: number;
  };
};

export const fetchHistoryAction = ({
  lastMeasureResult,
  lastSessionResult,
}: {
  lastMeasureResult: number;
  lastSessionResult: number;
}): FetchHistoryAction => ({
  type: FETCH_HISTORY_REQUESTED,
  payload: {
    lastMeasureResult,
    lastSessionResult,
  },
});
