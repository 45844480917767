import { RouterProps } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';

import { ACTIVITY_TEXT } from '../../../text/in_app_text';
import { ScheduledForDateItem } from '../../../components/DateItem';
import { Entry } from '../../../stores/session/session_types';
import { getTitle } from '../../../utils/get_title';
import { getTypeFromUrl } from '../../../utils/get_type_from_url';
import { getUrlFromType } from '../../../utils/get_url_from_type';
import { Helmet } from 'react-helmet';
import { useSession } from '../../../api/firebase/hooks/use_session';
import { ArrowLeftIcon } from '@heroicons/react/outline';

type Props = {} & RouterProps &
  RouteComponentProps<{ type: string; sid: string; eid: string }>;

export const CompletedActivitySessionEntry = (props: Props) => {
  const { match } = props;
  const params = match.params;
  const { sid, eid } = params;
  const type = getTypeFromUrl(props.match.params.type, props.match.path) || '';

  const typeUrl = getUrlFromType(type, true);
  const title = getTitle(type);

  const instructions = ACTIVITY_TEXT[type].newEntryInstructions;
  // const prompt = ACTIVITY_TEXT[type].newEntryPrompt;

  const { data: session, isLoading } = useSession({ id: sid });

  const entry =
    (session?.entries.find((entry: Entry) => entry.uuid === eid) as Entry) ||
    undefined;

  // if (typeof session === 'undefined') {
  //   console.group('RENDER ERRROR: SESSION NOT FOUND');
  //   console.error('sessionId', sid);
  //   console.groupEnd();
  //   return <Redirect to='/404' />;
  // }

  const to = session?.isActive
    ? `/app/activities/${typeUrl}`
    : `/app/activities/${typeUrl}/sessions/${sid}/`;

  if (isLoading) {
    return (
      <div className='grid grid-cols-1 place-items-center pt-10'>
        Loading...
      </div>
    );
  }
  // TODO: if no entry, return a 404 / error
  // @ts-ignore
  const entryNumber = entry?.entryNumber + 1;

  return (
    <div className='bg-gray-100 min-h-screen'>
      <Helmet>
        <title>New Entry</title>
      </Helmet>
      <main className='mx-auto max-w-7xl px-2 py-0 sm:p-0 '>
        <header className='py-10 flex space-x-5 items-center'>
          <Link
            to={to}
            className='inline-block bg-white rounded-full shadow cursor-pointer'>
            <ArrowLeftIcon className='p-2.5 h-12 w-12 text-burgundy' />
          </Link>
          <div className='h-12 w-12 flex justify-center items-center bg-burgundy text-white'>
            {entryNumber}
          </div>
          <h2>{title} Entry</h2>
        </header>
        {/*  */}
        <div className='py-20 bg-white text-center'>
          <div className='mx-auto max-w-5xl'>
            <ScheduledForDateItem date={entry?.scheduledDate} />
            <h1 className='text-burgundy text-5xl'>{title} Entry</h1>

            <div className='mt-10'>
              <p>{instructions}</p>
              {/* <h2>Response</h2> */}
            </div>
            {/* FIXME: check for array or string */}
            <div className='py-20 w-full bg-gray-50'>
              <p className='mt-10'>{entry?.payload?.responses?.join('\n')}</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
