import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import FirebaseClient from '../firebase/firebase_client';

const provider = new GoogleAuthProvider();

/**
 *
 * @deprecated
 */
export async function doSignInWithGoogle() {
  console.group('FIREBASE: SIGN IN WITH GOOGLE REQUESTED');
  console.groupEnd();
  try {
    const results = signInWithPopup(FirebaseClient.auth, provider);
    console.group('FIREBASE: SIGN IN WITH GOOGLE SUCCEEDED');
    console.groupEnd();
    return results;
  } catch (error) {
    console.group('FIREBASE ERROR: SIGN IN WITH GOOGLE FAILED');
    console.log('### ERROR ###', error);
    console.groupEnd();
    return { hasError: true, error };
  }
}
