import firebase from '../../firebase/firebase_client';

export const AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED';
export const USER_SYNC_REQUESTED = 'USER_SYNC_REQUESTED';
export const USER_SYNC_SUCCEEDED = 'USER_SYNC_SUCCEEDED';
export const USER_SYNC_FAILED = 'USER_SYNC_FAILED';

export type AuthStateAction = {
  type: typeof AUTH_STATE_CHANGED;
  payload: {
    user: typeof firebase.auth.currentUser;
  };
};

export type SyncUserAction = {
  type: typeof USER_SYNC_REQUESTED;
  // payload: {
  //   user: typeof firebase.auth.currentUser;
  // };
};

export const authStateChangeAction = (user: any): AuthStateAction => ({
  type: AUTH_STATE_CHANGED,
  payload: user,
});

export const syncUserAction = (): SyncUserAction => ({
  type: USER_SYNC_REQUESTED,
  // payload: user,
});
