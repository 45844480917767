import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { AppState } from '../../types';
import { createAccountWithEmailAction } from '../../auth/create_account_with_email_action';
import { resetPasswordAction } from '../../auth/reset_password_action';
import { signInAccountWithEmailAction } from '../../auth/sign_in_with_email_action';
import { Redirect } from 'react-router';
import { signInWithGoogle } from '../../stores/account';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { BasicLoader } from '../../components/loaders/BasicLoader';
import { Helmet } from 'react-helmet';
import { useMutation } from 'react-query';
import { Spinner } from '../../components/Spinner';
import { sendPasswordResetEmail } from '@firebase/auth';
import { FirebaseClient } from '../../firebase';

interface Values {
  email: string;
}

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState({ hasError: false, error: '' });
  const [msg, setMsg] = useState({ hasMsg: false, msg: '' });

  const account = useSelector((state: AppState) => state.account);
  const network = useSelector((state: AppState) => state.network);
  if (process.env.NODE_ENV !== 'production') {
    console.group('NETWORK SIGN IN');
    console.log(network);
    console.groupEnd();
  }

  // FIXME: need to make sure the email lifecycle works
  const handleResetPassword = useMutation(
    async (email: string) => {
      if (email.length === 0) {
        return alert('Please fill in your email.');
      }
      return await sendPasswordResetEmail(FirebaseClient.auth, email);
      // dispatch(resetPasswordAction({ email }));
    },
    {
      onSuccess: () => {
        setMsg({ hasMsg: true, msg: 'Reset email successfully sent.' });
      },
      onError: (error: any) => {
        setError({ hasError: true, error: error?.message || `${error}` });
      },
    },
  );

  // const handleSignInWithEmailButtonPress = () => {
  //   if (email.length === 0 || password.length === 0) {
  //     return alert('Please fill in all fields.');
  //   }
  //   dispatch(signInAccountWithEmailAction({ email, password }));
  // };

  if (account.isSignedIn) {
    return <Redirect to='/' />;
  }

  return (
    <div className='not-gray min-h-screen flex flex-col  py-4 sm:px-6 lg:px-8'>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <h2 className='mt-6 text-center text-3xl leading-160 text-burgundy'>
          Reset Password
        </h2>
      </div>

      {/* Use formik instead */}
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <Formik
            initialValues={{
              email: '',
            }}
            onSubmit={(
              values: Values,
              { setSubmitting }: FormikHelpers<Values>,
            ) => {
              const { email } = values;
              handleResetPassword.mutate(email);
              // dispatch(resetPasswordAction({ email }));
            }}>
            <Form translate='yes'>
              <div className='md:space-y-5'>
                <label
                  htmlFor='email'
                  className='block text-xs md:text-base leading-160'>
                  Email address
                </label>
                <div className='mt-1 rounded-full shadow-sm'>
                  <Field
                    id='email'
                    name='email'
                    type='email'
                    placeholder='hello@flourishing.app'
                    autoComplete='username'
                    required
                    className='appearance-none block w-full px-3 py-2 border border-burgundy rounded-full placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                  />
                </div>
              </div>
              {error.hasError && <div>{error.error}</div>}

              {msg.hasMsg && <div>{msg.msg}</div>}
              <button
                type='submit'
                className='mt-2 w-full flex justify-center py-2 px-4 border border-transparent text-xs md:text-base leading-160 rounded-full text-white bg-burgundy'>
                {handleResetPassword.isLoading && <Spinner />}
                Send reset email
              </button>
              {/* <button type='submit'>Submit</button> */}
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

const Error = () => {
  return (
    <div className='fixed z-10 inset-0 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        {/* <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    --> */}
        <div className='fixed inset-0 transition-opacity'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen'></span>
        &#8203;
        {/* <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    --> */}
        <div
          className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-headline'>
          <div className='sm:flex sm:items-start'>
            <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
              <svg
                className='h-6 w-6 text-red-600'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                />
              </svg>
            </div>
            <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
              <h3
                className='text-lg leading-6 font-medium text-gray-900'
                id='modal-headline'>
                Deactivate account
              </h3>
              <div className='mt-2'>
                <p className='text-sm leading-5 text-gray-500'>
                  Are you sure you want to deactivate your account? All of your
                  data will be permanently removed from our servers forever.
                  This action cannot be undone.
                </p>
              </div>
            </div>
          </div>
          <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
            <span className='flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto'>
              <button
                type='button'
                className='inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5'>
                Deactivate
              </button>
            </span>
            <span className='mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto'>
              <button
                type='button'
                className='inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5'>
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
