export const FETCH_ACCOUNT_REQUESTED = 'FETCH_ACCOUNT_REQUESTED';
export const FETCH_ACCOUNT_SUCCEEDED = 'FETCH_ACCOUNT_SUCCEEDED';
export const FETCH_ACCOUNT_FAILED = 'FETCH_ACCOUNT_FAILED';

export type FetchAccountAction = {
  type: typeof FETCH_ACCOUNT_REQUESTED;
  payload: {};
};

export const fetchAccountAction = (): FetchAccountAction => ({
  type: FETCH_ACCOUNT_REQUESTED,
  payload: {},
});
