import {
  ANXIETY_ACTIVITY,
  BEHAVORIAL_ACTIVITY,
  BEST_SELF_ACTIVITY,
  CHARACTER_STRENGTHS_ACTIVITY,
  COGNITIVE_ACTIVITY,
  DEPRESSION_ACTIVITY,
  FORGIVENESS_ACTIVITY,
  GRATITUDE_ACTIVITY,
  JOB_CRAFTING_ACTIVITY,
  KINDNESS_ACTIVITY,
  MARRIAGE_ACTIVITY,
  RELATIONAL_ACTIVITY,
  RELIGION_ACTIVITY,
  SAVORING_ACTIVITY,
  VOLUNTEER_ACTIVITY,
  WORKBOOK_ACTIVITY,
} from '../stores/activity/activity';
import {
  CHARACTER_STRENGTHS_MEASURE,
  FLOURISHING_MEASURE,
  GRATITUDE_MEASURE,
  MEANING_MEASURE,
} from '../stores/measure/measure_type';

/**
 * WELCOME/ABOUT PAGE TEXT *****************************************************
 */
export const AUTH_PAGE_HEADER = [
  `The Flourishing App is brought to you by the The Human Flourishing Program at Harvard University's Institute for Quantitative Social Sciences.`,
];
export const WELCOME_PAGE_HEADER = [
  `Our flourishing spans many more areas of our lives and states of our being than what is often popularly talked about. Flourishing does include happiness as well as our physical and mental health, yet it is much more than that. Flourishing also includes other aspects of our lives such as meaning and purpose, our character and virtue, and our close social relationships.\n`,

  `Disciplines across the academy are opening up to and realizing this more expansive definition of flourishing. A major part of the work that we do at The Program on Human Flourishing is to synthesize the best knowledge from across the academy as it relates to flourishing. As part of this work, we developed this application that makes available twelve activities that have been shown to improve aspects of your flourishing in an easy to use format. This application is free to use and only requires an account so that you can store your information. You may also download a PDF that contains instructions to do all activities included here. `,
];
export const WELCOME_PAGE_DOMAINS_ABOUT = [
  `There are six distinct domains of flourishing we cover here. There are ones that are perhaps more obvious in our current society. There are also domains that are perhaps less obvious to our current societal thinking. `,
];
export const WELCOME_PAGE_DOMAINS_DESCRIPTION = [
  {
    key: 0,
    type: 'happy',
    header: 'Happiness and Life Satisfaction',
    desc: `Our subjective experiences and evaluations about just how happy and satisfied we think we are.`,
    // desc: `The first domain is our general sense of happiness and life satisfaction. This encompasses our own subjective experiences and evaluations about just how happy and satisfied we think that we are.
    // `,
  },
  {
    key: 1,
    type: 'mental',
    header: 'Mental and Physical Health',
    desc: `Generally being free from or successfully dealing with the various mental and physical ailments that may come our way throughout our lives.`,
  },
  {
    key: 2,
    type: 'meaning',
    header: 'Meaning and Purpose',
    desc: `How we view our individual purpose within the world as well as our greater sense of the meaning of it all.`,
  },
  {
    key: 3,
    type: 'character',
    header: 'Character and Virtue',
    desc: `The traits and values that make us who we are and guiding principles in how we act towards ourselves and others.`,
  },
  {
    key: 4,
    type: 'social',
    header: 'Close Social Relationships',
    desc: `Reflecting on our friendships, family, and relationships and whether they are as satisfying as we would want them to be.
`,
  },
  {
    key: 5,
    type: 'financial',
    header: 'Financial and Material Stability',
    desc: `Ensuring that we need not worry about safety, food, housing, or meeting our monthly living expenses.`,
  },
];

export const WELCOME_PAGE_IMPROVING_FLOURISHING = [
  `Many standard activities aimed at improving our flourishing require special resources, such as trained therapists, health care providers, and may also require significant expense and time. Fortunately, there are now a number of tested activities that are effectively do-it-yourself and can be implemented on one’s own and essentially without cost. Other activities require only a simple do-it-yourself workbook and many of these likewise have been evaluated in randomized trials and found to have effects on various flourishing outcomes. There are also various relational and institutional commitments that have also been shown to have substantial effects on our flourishing. For more information about the science behind these activities, please see the Science Page.\n\n`,

  `In this app, we present twelve activities that span four general categories: cognitive exercises, behavioral exercises, relational exercises, and workbook based exercises.\n`,

  `Each activity takes six weeks to complete. Most activities require that you select one day of the week to check back in with this app and complete a simple exercise. As part of these exercises, there is generally a writing component in which you write about your experiences related to the activity via guided prompts. Activities can be done concurrently and you can do the activities as often as you like.\n`,
];

export const WELCOME_PAGE_ACTIVITY_GROUPS = [
  {
    title: 'Cognitive Exercises',
    type: COGNITIVE_ACTIVITY,
    about: [
      `Cognitive exercises are those activities that require only that you set aside time throughout your day or week for sustained self-reflection and inquiry.\n`,

      `The three activities included here are gratitude, savoring, and imagining one’s best possible self. These three can in some sense be viewed respectively as an orientation of the mind to what is good in either the past (gratitude), the present (savoring), or the future (imagining). `,
    ],
    activities: [
      {
        type: GRATITUDE_ACTIVITY,
        about: `The Gratitude Activity is focused on repeated self-reflection on that for which you are grateful. You’ll select three days of the week when you’ll commit to taking time out of your day to sit and identify three things for which you are grateful and then write about them.`,
      },
      {
        type: SAVORING_ACTIVITY,
        about: `The Savoring Activity is focused on developing your capacity to attend to, appreciate, and enhance the positive experiences in your life. You’ll select one day of the week to be especially focused on this practice. On that day, you’ll also return to this app and write about your experiences as well as about your goals, plans, and actions for how to savor more in the forthcoming week. `,
      },
      {
        type: BEST_SELF_ACTIVITY,
        about: `The Imaging Your Best Possible Self Activity is focused on developing a well-defined sense of just who you imagine your best possible self to be. To do this, you’ll select one day of the week in which to return to this application for a simple exercise that is centered around, well, just seriously imagining your best possible self and writing about who this person is. `,
      },
    ],
  },
  {
    type: BEHAVORIAL_ACTIVITY,
    title: 'Behavioral Exercises',
    about: [
      `The behavioral exercises are those that require, in addition to self-reflection, some outward manifestation of those thoughts (i.e., behavior).\n`,
      `These three exercises or activities are the use of character strengths, acts of kindness towards others, and volunteering in the community. These three in some sense can be viewed as actions oriented towards seeking what is good in oneself (character/virtue), for others (acts of kindness), and for the community (volunteering). `,
    ],
    activities: [
      {
        type: CHARACTER_STRENGTHS_ACTIVITY,
        about: `In the Character Strengths Activity, you will first identify your top five (out of twenty-four) character strengths using the Values in Action survey. Researchers have identified twenty-four character strengths such as creativity and bravery that span six areas (wisdom, courage, humanity, justice, temperance, and transcendence). After having identified your top five strengths, you will commit to finding new ways to use them. You’ll select one day of the week in which to return to this app to check back in and write about your experiences as well as about your goals, plans, and actions for how to use your strengths in the upcoming week. `,
      },
      {
        type: KINDNESS_ACTIVITY,
        about: `The Kindness Activity is focused on improving your tendency to do kind acts by a focused practice of doing kind acts. You’ll select one day of the week on which you will commit to doing five kind acts. Each week, on that day, you will check back in to reflect on and write about your actions. `,
      },
      {
        type: VOLUNTEER_ACTIVITY,
        about: `The Volunteering Activity is focused on increasing your connection with your community and others. You’ll commit to finding a new volunteer activity and committing two hours per week for six weeks to that activity. You’ll select one day of the week to check back in here and to write about your experiences. `,
      },
    ],
  },
  {
    type: RELATIONAL_ACTIVITY,
    title: 'Relational Exercises',
    about: [
      `In addition to positive psychology interventions, considerable research has shown that various relational and institutional commitments contribute profoundly to different aspects of flourishing and well-being. Work, marriage and religious community can all positively promote not only happiness, but also health, meaning and purpose, character, and a sense of having good relationships. `,
    ],
    activities: [
      {
        type: JOB_CRAFTING_ACTIVITY,
        about: `In the Job Crafting Activity, you’ll develop a job crafting plan to take more proactive control over your work, work environment, work relationships, and how you think meaningfully about your work. You will then commit to enacting that plan over the next six weeks. Each week, you’ll check back in to reflect on how you have enacted your plan, what went well and what hasn’t, and to think about how you’ll enact your plan better, if needed, over the next week. `,
      },
      {
        type: MARRIAGE_ACTIVITY,
        about: `The Marriage Activity is for those who are married and wish to improve their relationship and commitment. You’ll commit to working through an online resource over six weeks. Each week, you’ll check back in to reflect upon your experiences. `,
      },
      {
        type: RELIGION_ACTIVITY,
        about: `In the Religious Service Attendance Activity, you will commit to attending a religious service weekly for the next six weeks. Each week, you’ll check back in to reflect upon your experiences. `,
      },
    ],
  },
  {
    type: WORKBOOK_ACTIVITY,
    title: 'Workbook Exercises',
    about: [
      `The fourth set of activities use three different book and workbook interventions that can help address various forms of psychological distress. These three book or workbook interventions address depression, anxiety, and forgiveness. They are essentially oriented towards the good in removing or recovering from what are sometimes conceived of as three central negative emotions: sadness, fear, and anger. `,
    ],
    activities: [
      {
        type: DEPRESSION_ACTIVITY,
        about: `For the Depression Activity you will have the chance to use David Burns’ “Feeling Good” book, which has been tested in various randomized trials and shown to have an effect on alleviating depression. The book is available for purchase in numerous bookstores or online. You’ll commit to working through the book over six weeks. Each week, you’ll check back in to reflect upon your experiences and expectations. The book is not a substitute for a trained therapist, and any severe form of depression should be addressed with the help of a professional.  However, the existing evidence suggests that the book may be helpful in at least alleviating more mild depressive symptoms. `,
      },
      {
        type: ANXIETY_ACTIVITY,
        about: `For the Anxiety Activity, you will have the chance to use a popular and current self-help book on cognitive behavioral therapy for anxiety, “Mastery of Your Anxiety and Panic,” by David Barlow and Michelle Craske, which was evaluated in a small randomized trial and its use was compared to the use of the book complemented by in-person sessions with a therapist, and the use of the book on its own was found to be nearly as effective. The book is available for purchase in numerous bookstores or online. Each week, you’ll check back in to reflect upon your experiences and expectations. The book is not a substitute for a trained therapist, and anxiety disorders should be addressed with the help of a professional. `,
      },
      {
        type: FORGIVENESS_ACTIVITY,
        about: `For the Forgiveness Activity, you’ll have the chance to use a workbook intervention to promote forgiveness that has been shown to be effective in a small randomized trial. Each week, you’ll check back in to reflect upon your experiences and expectations. `,
      },
    ],
  },
];
/**
 * ACTIVITY SPECIFIC TEXT ******************************************************
 */
export const ACTIVITIES_PAGE_HEADER = [
  `Activities are the core of this program. Each activity has been scientifically tested and found to help improve some aspect of your flourishing. To learn more please click the below link. `,
];
export const COMMON_ACTIVITY_INSTRUCTIONS_NO_BASELINE = [
  `This activity does not have a specific first exercise that you must do at this time. Instead, you can complete an (optional) reflection entry next. We find it helps to write down your expectations, what you want from this experience. This entry will be saved so that you can return to look at it over the next six weeks while completing this activity. Your entry will also be stored as part of your history.\n`,

  `Then, you will have the option in the next step to take any of the included measures. This is a good way to track your progress both in this activity and overall.\n`,

  `Finally, you’ll select the day of the week on which you would like to do this activity and set how and if you want to be notified on that day. \n`,
];

export const COMMON_ACTIVITY_INSTRUCTIONS_BASELINE = [
  `After completing this first exercise, you can then complete an (optional) reflection entry. We find it helps to write down your expectations, what you want from this experience, down now. This entry will be saved so that you can return to look at it over the next six weeks while completing this activity. Your entry will also be stored as part of your history.\n`,

  `Then, you will have the option in the next step to take any of the included measures. This is a good way to track your progress both in this activity and overall.\n`,

  `Finally, you’ll select the day of the week on which you would like to do this activity and set how and if you want to be notified on that day.\n`,
];

export const COMMON_ACTIVITY_REFLECTION_INSTRUCTIONS = [
  `As part of the activities, we encourage you to write down your expectations for this activity.\n`,

  `You have come to this activity and exercise for some reason, please take a few minutes to think deeply about what that reason was and is and what you would like to get out of this exercise. When you are ready, please take a few moments to write down some of your thoughts. Your response will be save so that you can view it later.\n`,
];

export const NEW_SESSION_NEVER_TAKEN_FLOURISHING_MEASURE = [
  `You have never taken the Flourishing Measure. We encourage you do take it now. You may select it from the below menu. `,
];
export const NEW_SESSION_TAKEN_FLOURISHING_MEASURE_RECENT = [
  `You last took the Flourishing Measure on: {moment(flourishing?.startedAt).format('ddd MMM YYYY')}. If it has been longer than two months, we encourage you to take it again now. `,
];
export const NEW_SESSION_IF_GRATITUDE_ACTIVITY = [
  `For the Gratitude Activity we strongly recommend that you take the Gratitude Measure below. `,
];
export const NEW_SESSION_ABOUT_MEASURES_HEADER = [
  `Measures are used to help inform you about your current states as well as to help you track your progress as you use this app over time. All the measures are optional and taking them (or not) does not impact your ability to do the activity. `,
];
export const NEW_SESSION_SCHEDULE_HEADER_IF_GRATITUDE = [
  `Please select three days of the week on which to return to this app and do your exercise. `,
];

export const NEW_SESSION_SCHEDULE_HEADER = [
  `Please select one day of the week on which to return to this app and do your exercise. `,
];

export const NEW_SESSION_NOTIFICATIONS_HEADER = [
  `Please select any methods by which you would like to be notified on the day of your scheduled activity. `,
];

export const ACTIVITY_TEXT = {
  [ANXIETY_ACTIVITY]: {
    title: 'Anxiety',
    quotation: [
      `“You can’t stop the waves, but you can learn to surf.”\nJon Kabat-Zinn, Founder of Mindfulness-Based Stress Reduction`,
      `Jon Kabat-Zinn`,
    ],
    about: [
      `Anxiety is one of the most common mental health issues. There are now numerous treatment options that have proven effective and which include pharmacological treatments as well as different types of therapy. Cognitive behavioral therapy, which is often considered the gold standard, focuses on changing the thought processes and behaviors that contribute to anxiety. While cognitive behavioral therapy is most often done with a therapist, there are now several self-help solutions. Do-it-yourself, self-help, workbook, and computer- or internet-based cognitive behavioral therapy interventions are also available and have been shown in randomized trials to also be effective in alleviating anxiety, and in some contexts almost as effective as face-to-face cognitive behavioral therapy.\n`,

      `In this activity, you’ll commit, over the course of six weeks, to working through “Mastery of Your Anxiety and Panic” by David Barlow and Michelle Craske. You’ll select one day of the week on which to check back in here and to write about your experiences via directed writing prompts.\n`,
    ],
    instructions: [
      `This activity is focused on helping you work through a workbook that has proved effective in helping to alleviate and manage anxiety. The first step is to make sure that you have a copy (or will have a copy soon) of the book (Mastery of Your Anxiety and Panic).\n`,

      ...COMMON_ACTIVITY_INSTRUCTIONS_NO_BASELINE,
    ],
    hasBaseline: false,
    newEntryInstructions: [
      `You've committed to working through the "Mastery of Your Anxiety and Panic" workbook during this activity. As part of your commitment you've scheduled today to return to this app and reflect upon your experiences. Please take a few moments to clear your mind and center yourself. When you are reading, please continue to the next paragraph.\n`,

      `Think back over the past week. How have your worked through the workbook? What new insights, if any, into anxiety have you had? What ways, if any, have you learned to cope with anxiety? Please spend a few minutes thinking about these and then write down a short response in the provided form.`,
    ],
    newEntryPrompt: [``],
  },
  [BEST_SELF_ACTIVITY]: {
    title: 'Best Self',
    quotation: [
      `"There is only one corner of the universe you can be certain of improving, and that's your own self."\nAldous Huxley, Writer and Philosopher`,
      `Aldous Huxley`,
    ],
    about: [
      `This activity consists of taking time to imagine your best possible self. Research has shown that even such a simple exercise as spending time reflecting on, imagining, and writing about your best possible self is able to help increase your well-being.\n`,

      `You will begin this activity by taking some time to imagine your best possible self and briefly writing about your thoughts. Then, over  the next six weeks, once per week, you will return to this app to reflect  upon what you wrote and how much closer you have come to be this best  possible self as well as to repeat this exercise of imagining your best possible self.\n`,
    ],
    instructions: [
      `Over the course of the next six weeks you'll work on improving your flourishing by doing a simple activity that involves imagining your best possible self. Each week you’ll set aside one day to do the simple exercise of sitting down, imagining your best possible self, and then writing about who that person is. You'll have a chance to do this for the first time in just a moment below.\n`,

      ...COMMON_ACTIVITY_INSTRUCTIONS_BASELINE,
    ],
    hasBaseline: true,
    baselineEntryInstructions: [
      `Think about your life in the future. Imagine that everything has gone as well as it possibly could. You have worked hard and succeeded at accomplishing all of your life goals. Think of this as the realization of all of your life dreams. Now, write about what you imagined.\n`,
    ],
    newEntryInstructions: [
      `You've committed to practicing imagining your best possible self once a week for six weeks. You've scheduled today to return to this app and complete this exercise. Please take a few moments to clear your mind. When you are ready, please continue to the next paragraph.\n`,

      `Think about your life in the future. Imagine that everything has gone as well as it possibly could. You have worked hard and succeeded at accomplishing all of your life goals. Think of this as the realization of all of your life dreams. Now, write about what you imagined in the provided form.`,
    ],
    newEntryPrompt: [``],
  },
  [CHARACTER_STRENGTHS_ACTIVITY]: {
    title: 'Character Strengths',
    quotation: [
      `"It's not the honors and not the titles and not the power that is of ultimate importance. It's what resides inside."\nFred Rogers, American TV Host`,
      `Fred Rogers, American TV Host`,
    ],
    about: [
      `Aristotle argued that happiness is attained by action in accord with virtue. While the empirical study of virtues is relatively new, there is now reasonably good evidence that the exercise of virtue does indeed, perhaps unsurprisingly, contribute to greater happiness.\n `,

      `One particular track of research has identified twenty-four character strengths that we all have to varying degrees. Research has also found that an activity in which you work on using your top five strengths in new ways increases happiness and decreases depressive symptoms with results lasting at least six months.\n`,

      `In this activity, you’ll first identify your top five character strengths using the Values in Action Survey of Character Strengths. You’ll then commit to using these strengths in new ways over the next six weeks. You will begin by taking a survey to identify one’s top five character strengths and then using one of these top five strengths in a new and different way, every day, for six weeks. You’ll check back in here with this app once a week to reflect on what you’ve been doing. Registration at an external website to take the Values in Action Survey is required to complete this activity.`,
    ],
    instructions: [
      `The Character Strengths activity consists in you using your top five character strengths in new ways over the course of the next six weeks.\n`,

      `The first step in this activity is to obtain your top five character strengths. The Character Strengths measure identifies your top five character strengths as well as the degree of each strength.\n`,

      ...COMMON_ACTIVITY_INSTRUCTIONS_BASELINE,
    ],
    hasBaseline: true,
    // FIXME: this baseline instruction isn't appearing correctly
    baselineEntryInstructions: [
      `Your first step is to obtain your top five character strengths. To do this you will need to visit an external website where you will also be required to create an account before being able to take the needed survey. To continue, please go to https://www.authentichappiness.sas.upenn.edu/questionnaires/brief-strengths-test. After completing the survey and seeing your strengths, please come back here to enter in your results below.`,
    ],
    newEntryInstructions: [
      `You've been using your top five character strengths in new ways over the past weeks. Please take a few moments to reflect on how you have been doing this. Have you found unusual ways to use your strengths? How have you gone about trying to find new ways? How might you build on what you have done going forward? When ready, please briefly write down some reflections in the provided form.`,
    ],
    newEntryPrompt: [''],
  },
  [DEPRESSION_ACTIVITY]: {
    title: 'Depression',
    quotation: [
      `“Depression is the most unpleasant thing I have ever experienced. . . . It is that absence of being able to envisage that you will ever be cheerful again. The absence of hope. That very deadened feeling, which is so very different from feeling sad. Sad hurts but it’s a healthy feeling. It is a necessary thing to feel. Depression is very different.”\nJK Rowling`,
      `JK Rowling`,
    ],
    about: [
      `Depression is one of the most common mental health issues. There are now numerous treatment options that have proven effective and which include pharmacological treatments as well as different types of talk therapy. Cognitive behavioral therapy, which is often considered the gold standard, focuses on changing the thought processes and behaviors that contribute to depression.\n`,

      `While cognitive behavioral therapy is most often done with a therapist, there are now several self-help solutions. One particularly popular self-help book, “Feeling Good” by David Burns, has itself been tested in randomized trials and been shown to be effective at alleviating depressive symptoms. The book is not a substitute for a trained therapist, and any severe form of depression should be addressed with the help of a professional.  However, the existing evidence suggests that the book may be helpful in at least alleviating more mild depressive symptoms \n`,

      `In this activity, you will commit to working through “Feeling Good” over the course of six weeks. You’ll select one day of the week on which to check back in here and to reflect on and write about your experiences.`,
    ],
    instructions: [
      `This activity is focused on helping you work through a workbook that has proved effective in helping to alleviate and manage depression. The first step is to make sure that you have a copy (or will have a copy soon) of the book ("Feeling Good").\n`,

      ...COMMON_ACTIVITY_INSTRUCTIONS_NO_BASELINE,
    ],
    hasBaseline: false,
    newEntryInstructions: [
      `You've committed to working through the "Feeling Good" workbook during this activity. As part of your commitment you've scheduled today to return to this app and reflect upon your experiences. Please take a few moments to clear your mind. When you are ready, please continue to the next paragraph\n. `,

      `Think back over the past week. How have your worked through the book? What have you noticed about your depression and your own awareness of it? Have you had any breakthroughs or insights? What have you learned that you will apply going forward? Please briefly write down some of your thoughts.`,
    ],
    newEntryPrompt: [``],
  },
  [FORGIVENESS_ACTIVITY]: {
    title: 'Forgiveness',
    quotation: [
      `“Resentment is like drinking poison and then hoping it will kill your enemies.”\nNelson Mandela`,
      `Nelson Mandela`,
    ],
    about: [
      `Forgiving others for wrongs they have done to you is, generally, associated with better mental health, greater hope, and possibly better physical health. Unfortunately, and too often we find that forgiving someone for a substantial wrong is difficult and takes considerable time.\n`,

      `Activities have been developed to promote forgiveness and have been found to be effective in randomized trials. Such interventions have been shown to be effective not only in increasing forgiveness but also in decreasing depression, decreasing anxiety, and increasing hope. Most of the interventions require many sessions with a trained therapist or counselor. Fortunately, a recent workbook ("Paths to Forgiveness") to promote forgiveness has been developed and has been shown to be effective.\n`,

      `In this activity, you’ll commit to using this workbook ("Paths to Forgiveness"), which is freely available online , over the next six weeks. You’ll check back in here weekly to write about your experiences via directed prompts. `,
    ],
    instructions: [
      `This activity is focused on learning, practicing, and developing your ability to forgive. Over the course of the next six weeks, you'll commit to working through a workbook that has proven effective in doing so.\n`,

      `The first step is to make sure that you have a copy (or will have a copy soon) of the book “The Paths to Forgiveness.\n”`,

      ...COMMON_ACTIVITY_INSTRUCTIONS_NO_BASELINE,
    ],
    hasBaseline: false,
    newEntryInstructions: [
      `You've committed to working through the "Paths to Forgiveness" workbook during this activity in order to help grow in forgiveness. As part of your commitment you've scheduled today to return to this app and reflect upon your experiences. Please take a few moments to clear your mind. When you are ready, please continue to the next paragraph\n.
    `,

      `Thinking back over the past week, have you found yourself seeing opportunities for forgiveness? Has a section of the workbook spoken to you and given you new insights? If so, what are those insights and how have you been able, or will you apply them in your daily life? If you have been struggling to forgive, have you been making progress? Please take a few moments to think and then write down some brief reflections in the provided form. 
      `,
    ],
    newEntryPrompt: [``],
  },
  [GRATITUDE_ACTIVITY]: {
    title: 'Gratitude',
    quotation: [
      `“In ordinary life, we hardly realize that we receive a great deal more than we give, and that it is only with gratitude that life becomes rich.”\nDietrich Bonhoeffer`,
      `Dietrich Bonhoeffer`,
    ],
    about: [
      `Gratitude (or being thankful or grateful) is a state or feeling in which we are fully appreciative of that which we have. We may be grateful for our health, our friends and family, and many other things.\n`,

      `Simple exercises have been developed to help us increase our gratitude. These exercises consist in taking time to reflect upon some number of things in life for which you are grateful and writing these down. Research has found these sorts of exercises tend to increase not only our own gratitude but also our more general feelings of psychological well-being.\n`,

      `In this activity, you will select three days of the week on which you want to complete the needed exercise of self-reflection and writing. On those days, you’ll be reminded to return to this app to do the needed entries.\n`,
    ],
    instructions: [
      `Over the course of the next six weeks you'll work on improving your gratitude by simply setting aside some time on three different days of the week to do a simple exercise that consists of reflecting on that which you are grateful and then writing about it. You’ll do your first exercise as the next step.\n`,

      ...COMMON_ACTIVITY_INSTRUCTIONS_BASELINE,
    ],
    hasBaseline: true,
    baselineEntryInstructions: [
      `You get your first chance to practice reflecting on that which you are grateful now. There are many things in our lives, both large and small, that we might be grateful about. Think back over the past week. When you are ready, write about three such things for which you are grateful in the provided space below. `,
    ],
    newEntryInstructions: [
      `You've committed to working on improving your expression of gratitude in this activity. As part of your commitment you've scheduled today to return to this app and do a focus writing exercise on gratitude. Please take a few moments to clear your mind. When you are ready, please continue to the next paragram.\n`,

      `There are many things in our lives, both large and small, that we might be grateful about. Think back over the past week. Please take a few moments to think about three such things for which you are grateful. When you are ready, please write about them in the provide form below. 
      `,
    ],
    newEntryPrompt: [``],
  },
  [JOB_CRAFTING_ACTIVITY]: {
    title: 'Job Crafting',
    quotation: [
      `“Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work. And the only way to do great work is to love what you do. If you haven't found it yet, keep looking. Don't settle. As with all matters of the heart, you'll know when you find it.”\nSteve Jobs, founder of Apple`,
      `Steve Jobs, founder of Apple`,
    ],
    about: [
      `Work and the decision to work, to join others in contributing to meeting the needs and desires of humanity, has been shown to, positively affect numerous health and well-being outcomes. Work is often today viewed simply as paid employment, but work, conceived of as the sustained effort and contribution to meet the needs and desires of humanity, can also be constituted by care for children or more substantial and sustained volunteering to accomplish some good.\n`,

      `In this activity, you will have the chance to implement what is known as job crafting. The idea of job crafting is that one reflects upon one’s work environment and takes actions at work to try (i) to structure one’s tasks so that they can be done more effectively, or so that tedious tasks are no longer necessary, or so that more challenging tasks are available; (ii) to have better, more meaningful, or more effective social interactions at work; and (iii) to find meaning and purpose in the work being done. You will first develop a job crafting plan. Then, over the next six weeks you will commit to implementing that plan. You will checkback in weekly and write about both how you have been doing as well as what you might be able to do better in the upcoming week. `,
    ],
    instructions: [
      `Job crafting is a behavior that we all already do to some degree. This activity is meant to help you become more conscious of this behavior. You'll commit to a six-week plan to improve your job crafting.\n`,

      `The first step, which you will complete below, is to develop a job crafting plan. This is a basic outline of how you will go about increasing your job crafting.\n`,

      ...COMMON_ACTIVITY_INSTRUCTIONS_BASELINE,
    ],
    hasBaseline: true,
    baselineEntryInstructions: [
      `The idea of job crafting is that one reflects upon one’s work environment and takes actions at work to try (i) to structure one’s tasks so that they can be done more effectively, or so that tedious tasks are no longer necessary, or so that more challenging tasks are available; (ii) to have better, more meaningful, or more effective social interactions at work; and (iii) to find meaning and purpose in the work being done.\n`,

      `Take a few minutes to think about each of those three areas and how you might better ‘craft’ those areas of your work. When you are ready, please write down a summary of your plan. `,
    ],
    newEntryInstructions: [
      `You've committed to implementing a job crafting plan that you set for yourself over the course of this activity. As part of your commitment you've scheduled today to return to this app and write about how your job crafting is going. If you don't recall your job crafting plan, please first go back to the previous screen, click on 'Baseline Entry' and re-read what you wrote and then return here. If you do remember, or after returning, please take a few moments to clear your mind. When you are ready, please continue to the next paragraph.\n`,

      `In your job crafting plan, you wrote about how you wanted to ‘craft’ your work. Think back over the past week. Were you able to put into action parts of your original plan? What worked? What didn't? Did you find new areas and ways to craft your job? Please take a few moments to reflect on these. When ready, please write down a brief reflection in the provided form.  
      `,
    ],
    newEntryPrompt: [``],
  },
  [KINDNESS_ACTIVITY]: {
    title: 'Kindness',
    quotation: [
      `“Kindness in words creates confidence. Kindness in thinking creates profoundness. Kindness in giving creates love.”\nLao Tzu, Ancient Chinese Philosopher and Writer`,
      `Lao Tzu`,
    ],
    about: [
      `Acts of kindness, helping those in need, and going out of one’s way to be of assistance can, of course, increase the well-being of others. A number of studies also suggest that not only do such acts of kindness increase others’ well-being, but that they increase our own sense of well-being.\n`,

      `Some trials suggest that committing to trying to carry out several acts of kindness (that one would not ordinarily otherwise do) each week, over the course of several weeks, can increase our happiness and life satisfaction, and make us feel more engaged, less anxious, and more connected. Such acts of kindness, moreover, in addition to promoting the well-being of yourself and others, also often encourages and helps propagate similar acts of kindness among others.\n`,

      `In this activity, you’ll commit to selecting one day of the week  on which to do three to five acts of kindness that you would not normally do. On those days, you’ll also check in here to write about your experiences. `,
    ],
    instructions: [
      `Over the course of the next six weeks you'll commit to taking one day out of the week and doing three to five acts of kindness that you may not normally do. You’ll then return to this app to write about your experience.\n`,

      ...COMMON_ACTIVITY_INSTRUCTIONS_NO_BASELINE,
    ],
    hasBaseline: false,
    newEntryInstructions: [
      `You've committed to practicing acts of kindness throughout the week as well as to doing five acts of kindness today that you would not normally do. As part of your commitment you've scheduled today to return to this app to reflect upon and write about your experiences. Please take a few moments to clear your mind. When you are ready, please continue below.\n`,

      `Think back over the past week, have you noticed yourself doing more than your usual acts of kindness? Today you had committed to doing five acts of kindness that you would not normally do. Did you do those five acts? If not, do you still plan to do them yet today? What were those acts? Did you miss an opportunity to do an act of kindness? If so, how might you go about performing such an act in the future given the chance? Please take a few moments to think about all of this. When you are ready, please write down your thoughts in the form provided. 
      `,
    ],
    newEntryPrompt: [``],
  },
  [MARRIAGE_ACTIVITY]: {
    title: 'Marriage',
    quotation: [
      `“You know you’re in love when you can’t fall asleep because reality is finally better than your dreams.”\nDr. Seuss`,
      `Dr. Seuss`,
    ],
    about: [
      `Marriage and marriage quality is an important determinant of happiness in life and many other aspects of human flourishing and easy-to-use resources to strengthen relationship and marriage quality have tremendous potential to contribute to human well-being.\n`,

      `Over the last decade, research on relationship education and marriage counseling has become increasingly based on evidence as to what actually improves relationship quality. Some of this evidence has been incorporated into marriage counseling practices. However, many couples may feel that they lack the money, time, resources, motivation, or need, to seek out a marriage counselor or therapist. Recently, easy to use and accessible online relationship education programs have been developed to enhance marriage and relationship quality and to work through marital difficulties. Some of these have begun to be tested in randomized trials. A recent randomized trial found one such online program, OurRelationship, which consists of about eight hours of exercises for a couple, to have fairly substantial effects on relationship satisfaction and relationship confidence, as well as lower rates of individual depression and anxiety, and better quality of life. The program is available online at https://www.ourrelationship.com" and could potentially be used quite broadly.\n`,

      `In this activity, you’ll commit to using the online resource OurRelationship for six weeks and completing the eight hour program. Each week, you’ll select one day in which to check back in here and to write about your experiences. `,
    ],
    instructions: [
      `This activity is focused on strengthening your marriage and is aimed at those who are already married. Over the course of the next six weeks, you'll commit to working through the online site OurRelationship that has proven effective in increasing marriage wellbeing.\n`,
      `The first step is to create an account at the OurRelationship website. If you have not already created an account, please make sure to do so after finishing the next series of screens.\n`,

      ...COMMON_ACTIVITY_INSTRUCTIONS_NO_BASELINE,
    ],
    hasBaseline: false,
    newEntryInstructions: [
      `You've committed to working through the online resource "OurRelationship.com". As part of your commitment you selected today to come back to this app and do a weekly writing exercise. Please take a few moments to clear your mind. When you are ready, please continue below.\n`,

      `You've been working on and reflecting on your marriage with the help of OurRelationship.com. How has that been going? What new insights have you seen? Have you been able to transfer those insights into actions in your marriage? What are your plans for transferring what you have learned into concrete actions in your relationship? Please take a few moments to reflect on these as well as your overall experience. When you are ready, please briefly write down your thoughts in the provided form. 
      `,
    ],
    newEntryPrompt: [``],
  },
  [RELIGION_ACTIVITY]: {
    title: 'Religion',
    quotation: [
      `“[Religious] fellowship lives and exists by the intercession of its members for one another, or it collapses. I can no longer condemn or hate a brother for whom I pray, no matter how much trouble he causes me.”\nDietrich Bonhoeffer`,
      `Dietrich Bonhoeffer`,
    ],
    about: [
      `A considerable body of research has suggested that religious service attendance powerfully affects health and other aspects of our flourishing such as meaning in life, character, and close social relationships. Specifically, research has indicated that those who attend religious services at least weekly are about 30% less likely to die over a 15 year follow-up, are about 30% less likely to become depressed and are over five times less likely to commit suicide. Other evidence suggests that religious service attendance is associated with subsequently greater meaning in life, greater social connection and support, and lower likelihood of divorce.\n`,

      `One interesting aspect of the associations is that it seems to be service attendance, rather than religious or spiritual identity, or private practices that most strongly predicts health. Something about the communal religious experience seems very powerful.\n`,

      `This activity is for those who are already religious or those who would like to explore religion further . If you are religious, you’ll commit to either attending again or attending more often, and consistently, whatever it is that is the main activity for your religion. If you are not religious, but have an interest in religion and would like to explore religion, then you’ll commit to finding one or more religious communities near you and attending their services weekly. In both cases, you’ll select one day of the week in which to check back in here to reflect on and write about your experiences. `,
    ],
    instructions: [
      `In this activity, you'll commit to attending a religious service at least once per week. If you already attend once per week, you might want to commit to increasing that even further. If you do not have a particular service or event, the following resources may be helpful in locating something in your area.\n`,

      `Meetup.com is a popular site for group activities that often has groups of various religious denominations and spiritual related groups.\n`,

      `For Christian services, the website https://church.org is helpful for finding congregations in your area.\n`,

      `Once you have a service in mind, you'll be asked to write down what that service is and what you intended to do in the next step.\n`,

      ...COMMON_ACTIVITY_INSTRUCTIONS_BASELINE,
    ],
    hasBaseline: true,
    baselineEntryInstructions: [
      `Please write down what your plans are for religious service attendance. `,
    ],
    newEntryInstructions: [
      `You've committed to engaging in religious service attendance each week over the course of the next six weeks. As part of your commitment, you've selected today to return to this app and reflect upon your experiences. Please take a few moments to clear your mind and then please continue to the next paragraph.\n`,

      `How often have you been able to attend your selected religious service? Please take a few moments to think about the past week and your attendance. What have the experiences meant to you? What new insights about yourself, others, and the world, if any, have you gained? Have you connected with anyone, or met anyone new? Has anything particularly touched or moved you? Have you found yourself behaving differently outside of the services? If so, how? Please take a few moments to reflect upon these questions as well as your general experience. When you are ready, please write down your thoughts in the form provided. 
      `,
    ],
    newEntryPrompt: [``],
  },
  [SAVORING_ACTIVITY]: {
    title: 'Savoring',
    quotation: [
      `“He who distinguishes the true savor of his food can never be a glutton; he who does not cannot be otherwise."\nHenry David Thoreau`,
      `Henry David Thoreau`,
    ],
    about: [
      `Savoring is sometimes described as the capacity to attend to, appreciate, and enhance the positive experiences in our lives. Our pleasure or enjoyment is in some sense a resting in what is good and by learning to savor – to recognize the good – we can enhance our experiences and enjoyments.\n`,

      `Some of the exercises to enhance and promote savoring that have been developed are quite simple and require only that we develop our capacity of thinking about positive events. We can do this by learning to recognize what is good in the present situation. For example, we can heighten our focus on and awareness of a present positive experience such as enjoying a walk or dinner.\n`,

      `In this activity, you’ll commit to increasing your savoring by setting one day out of the week to do a simple exercise. The exercise is for you to set aside some time and to then notice as many positive things around yourself as possible and then to write down what you noticed.`,
    ],
    instructions: [
      `Over the course of the next six weeks you'll work on improving your savoring abilities by setting aside some time each week to practice savoring in a focused way and then writing about that experience. To do this, on the selected day each week you will commit to paying closer attention while doing some activity, such as having a meal or going for a walk, to your present experience and try to recognize the good in it. You’ll have your first chance at doing this in just a moment.\n`,

      ...COMMON_ACTIVITY_INSTRUCTIONS_BASELINE,
    ],
    hasBaseline: true,
    baselineEntryInstructions: [
      `You get your first chance to practice savoring. First, make sure that you are someplace comfortable and take a few moments to clear your mind. Either in the time you have right now, or some other time or activity that is coming up today , try and notice as many positive things around yourself as possible. Spend a few minutes doing this, then when ready write down those positive things that you noticed, your experience of them, and how you recognize the good in them. `,
    ],
    newEntryInstructions: [
      `You've been practicing savoring and recognizing the good in your everyday life. You've selected this day to return to this app to contemplate and write about your experiences. Please take a few moments to clear your mind. When you are ready please proceed below.\n`,

      `First, make sure that you are someplace comfortable and take a few moments to clear your mind. Either in the time you have right now, or some other time or activity that is coming up today, try and notice as many positive things around yourself as possible. Spend a few minutes doing this, then when ready write down those positive things that you noticed, your experience of them, and how you recognize the good in them in the form provided below. 
      `,
    ],
    newEntryPrompt: [``],
  },
  [VOLUNTEER_ACTIVITY]: {
    title: 'Volunteering',
    quotation: [
      `“What is the essence of life? To serve others and to do good.”\nAristotle`,
      `Aristotle`,
    ],
    about: [
      `Studies have indicated that volunteering is associated with improvements in various aspects of well-being. In some sense, volunteering and regularly participating in various volunteering activities and organizations is a commitment to repeated acts of kindness, generally directed to an important goal of improving the lives of others. Volunteer organizations also can provide a powerful sense of social connection, and a common purpose.\n`,

      `In this activity, you’ll commit to finding a new volunteering opportunity and commit to doing it for two hours per week for six weeks. Each week you’ll check back in with this app to write about your experience and expectations.\n`,
    ],
    instructions: [
      `Over the course of the next six weeks you'll commit to doing a new volunteer experience for at least two hours per week. If you have volunteered in the past, you might consider restarting what it is that you did. If you haven’t volunteered before or are looking for a new opportunity you may find this site https://www.volunteermatch.org/ useful for finding opportunities.\n`,

      `As part of the starting this activity, on the next screen you’ll write down what it is that you plan to do.`,

      ...COMMON_ACTIVITY_INSTRUCTIONS_BASELINE,
    ],
    hasBaseline: true,
    baselineEntryInstructions: [
      `Please write down what volunteer activity you will commit to doing over the next six weeks.`,
    ],
    newEntryInstructions: [
      `You've committed to volunteering for two hours per week during this activity. As part of your commitment, you selected today to return to this app to reflect upon and briefly write about your experiences. Please take a few moments to clear your mind. When you are ready, please proceed below.\n`,

      `You've been volunteering throughout the week. Please take a few moments to reflect on your volunteer experience. What did you do? How did you feel before and after your volunteer work? Have you found it easy or difficult to keep your commitment? Have you noticed changes in yourself during the week outside of your volunteer time? If so, what have you noticed? Please take a few moments to think about these questions as well as your overall experience. When you are ready, please write down your thoughts in the form provided. 
      `,
    ],
    newEntryPrompt: [``],
  },
};

export const scienceActivities = [
  {
    type: GRATITUDE_ACTIVITY,
    title: 'Gratitude Activity',
    outcomes: ['Happiness, Depression, Sleep'],
    refs: [
      `Emmons, R. A., & McCullough, M. E. (2003). Counting blessings versus burdens: An experimental investigation of gratitude and subjective well-being in daily   life. Journal   of   Personality   and   Social Psychology, 84,377-389.`,
      `Davis, D. E., Choe, E., Meyers, J., Wade, N., Varjas, K., Gifford,  A.,  Quinn,  A.,  Hook,  J.  N.,  Van  Tongeren, D.R.,  Griffin,  B.  J.,  &  Worthington,  E.  L.  (2016). Thankful  for  the  little  things:  a  meta-analysis  of gratitude    interventions. Journal    of    Counseling Psychology, 63,20-31.`,
    ],
  },
  {
    type: SAVORING_ACTIVITY,
    title: 'Savoring Activity',
    outcomes: ['Happiness, Life Satisfaction'],
    refs: [
      `Bryant,  F.  B.  &  Veroff,  J.  (2007): Savoring:  A  New Model    of    Positive    Experiences.    Mahwah,    NJ: Lawrence Erlbaum Associates.`,
      `Smith, J. L., Harrison, P. R., Kurtz, J. L., & Bryan, F. B. (2014). Nurturing the capacity to savor: interventions to enhance the enjoyment of positive experiences. In: A.C.  Parks  and  S.M.  Schueller  (eds.). The  Wiley Blackwell   Handbook   of   Positive   Psychological Interventions.  John  Wiley  and  Sons:  West  Sussex, U.K. p. 42-65.`,
    ],
  },
  {
    type: BEST_SELF_ACTIVITY,
    title: 'Imagining Your Best Possible Self Activity',
    outcomes: ['Happiness,Life Satistifaction, Optimisim'],
    refs: [
      `King, L. A. (2001). The health benefits of writing about life   goals.Personality   and   Social   Psychology Bulletin, 27,798-807.`,
      `Malouff,   J.   M.   &   Schutte,   N.   S.   (2017).   Can psychological  interventions  increase  optimism?  A meta-analysis. The  Journal  of  Positive  Psychology, 12(6),594-604.`,
    ],
  },
  {
    type: CHARACTER_STRENGTHS_ACTIVITY,
    title: 'Character Strengths Activity',
    outcomes: ['Happiness, Life Satistifaction, Depression'],
    refs: [
      `Seligman, M. E. P., Steen,T. A., Park, N., & Peterson, C.  (2005).  Positive  psychology  progress:  empirical validation  of  interventions. American  Psychologist, 60,410–421..`,
      `Schutte, N. S., & Malouff, J. M. (2019). The impact of signature  character  strengths  interventions:  A  meta-analysis. Journal  of  Happiness  Studies,  20(4),1179-1196.`,
      `Website: https://www.authentichappiness.sas.upeen.edu/testcenter`,
    ],
  },
  {
    type: KINDNESS_ACTIVITY,
    title: 'Kindness Activity',
    outcomes: ['Happiness, Engagement'],
    refs: [
      `Lyubomirsky, S., Sheldon, K. M., & Schkade, D. (2005). Pursuing  happiness:  the  architecture  of  sustainable change. Review of General Psychology, 9,111-131.`,
      `Curry, O. S., Rowland, L. A., Van Lissa, C. J., Zlotowitz, S., McAlaney, J., & Whitehouse, H. (2018). Happy to help?  A  systematic  review  and  meta-analysis  of  the effects  of  performing  acts  of  kindness  on  the  well-being  of  the  actor. Journal  of  Experimental  Social Psychology, 76,320–9.`,
    ],
  },
  {
    type: VOLUNTEER_ACTIVITY,
    title: 'Volunteering Activity',
    outcomes: ['Hpapiness, Social Connection, Health'],
    refs: [
      `Schreier,  H.  M.,  Schonert-Reichl,  K.  A.,  &  Chen,  E. (2013).  Effect  of  volunteering  on  risk  factors  for cardiovascular disease in adolescents: A randomized controlled trial. JAMA Pediatrics, 167(4),327-332.`,
      `Okun,  M.  A.,  Yeung,  E.  W.,  &  Brown,  S.  (2013). Volunteering by older adults and risk of mortality: A meta-analysis. Psychology and Aging, 28(2),564-577.`,
    ],
  },
  {
    type: JOB_CRAFTING_ACTIVITY,
    title: 'Job Crafting Activity',
    outcomes: ['Engagement'],
    refs: [
      `McKee-Ryan, F.,Song, Z., Wanberg, C. R., & Kinicki, A.  J.  (2005)  Psychological  and  physical  well-being during    unemployment:    A    meta-analytic    study. Journal of Applied Psychology, 90,53–76.`,
      `Frederick, D. E. & VanderWeele, T. J. (2018). A meta-analysis  of  the  association  of  job-crafting  and  work engagement. Preprintavailable at:https://osf.io/2xtk3/. Techical Report.`,
    ],
  },
  {
    type: MARRIAGE_ACTIVITY,
    title: 'Marriage Activity',
    outcomes: [
      'Meaning, Depression, Longevity, Life Satistifaction, Depression',
    ],
    refs: [
      `Doss, B. D., Cicila, L. N., Georgia, E. J., Roddy, M. K., Nowlan,  K.  M.,  Benson,  L.  A.,  &  Christensen,  A. (2016).  A  randomized  controlled  trial  of  the  web-based     OurRelationship     program:     Effects     on relationship  and  individual  functioning. Journal  of Consulting and Clinical Psychology. 84:285-296.`,
      `Manzoli,  L.,  Villari,  P.,  Pirone,  G.  M.,  &  Boccia,  A. (2007). Marital Status and Mortality in the Elderly: A Systematic    Review    and    Meta-Analysis.    Social Science & Medicine, 64,77-94.`,
    ],
  },
  {
    type: RELIGION_ACTIVITY,
    title: 'Religious Service Attendance Activity',
    outcomes: ['Depression, Suicide, Longevity'],
    refs: [
      `Li,  S.,  Okereke,  O.  I.,  Chang,  S.-C.,  Kawachi,  I.,  & VanderWeele,   T.   J.   (2016a).   Religious   service attendance   and   depression   among   women –a prospective   cohort   study. Annals   of   Behavioral Medicine, 50,876-884.`,
      `Chida,   Y.,   Steptoe,   A.,   &   Powell,   L.   H.   (2009). Religiosity/spirituality  and  mortality:  A    systematic quantitative review. Psychotherapy and Psychosomatics, 78(2),81–90.`,
    ],
  },
  {
    type: DEPRESSION_ACTIVITY,
    title: 'Depression Activity',
    outcomes: ['Depression'],
    refs: [
      `Burns, D. (1999). Feeling Good: The new mood therapy(Rev. ed.). New York: Avon.`,
      `Anderson, L., Lewis, G., Araya, R., Elgie, R., Harrison, G., Proudfoot, J., Schmidt, U., Sharp, D., Weightman, A.,  &  Williams,  C.  (2005).  Self-help  books  for depression: how can practitioners and patients make the right choice?British Journal of General Practice, 55,387-392.`,
    ],
  },
  {
    type: ANXIETY_ACTIVITY,
    title: 'Anxiety Activity',
    outcomes: ['Anxiety'],
    refs: [
      `Barlow, D. H. & Craske M. G. (2007). Mastery of Your Anxiety  and  Panic.  4th  Edition.  New  York:  Oxford University Press.`,
      `Hecker, J. E., Loses, M. C., Fritzler, B. K., & Fink, C. M. (1996).     Self-directed     versus     therapist-directed cognitive  behavioral  treatment  for  panic  disorder. Journal of Anxiety Disorders, 10,253-265.`,
    ],
  },
  {
    type: FORGIVENESS_ACTIVITY,
    title: 'Forgiveness Activity',
    outcomes: ['Depression, Anxiety, Hope'],
    refs: [
      `Harper, Q., Worthington, E. L., Griffin, B. J., Lavelock, C.  R.,  Hook,  J.  N.,  Vrana,  S.  R.,  &  Greer,  C.  L. (2014).    Efficacy    of    a    workbook    to    promote forgiveness:   a   randomized   controlled   trial   with university  students. Journal  of  Clinical  Psychology, 70,1158–1169.`,
      `Wade, N. G., Hoyt,W. T., Kidwell, J. E., & Worthington E.    L.    (2014).    Efficacy    of    psychotherapeutic interventions   to   promote   forgiveness:   a   meta-analysis. Journal    of    Consulting    and    Clinical Psychology, 82,154-170.`,
    ],
  },
];

export const scienceMeasures = [
  {
    type: FLOURISHING_MEASURE,
    title: 'Flourishing Measure',
    refs: [
      `Website: https://hfh.fas.harvard.edu/measuring-flourishing`,
      `VanderWeele, T.J. (2017). On the promotion of human flourishing. Proceedings of the National Academy of Sciences, U.S.A., 31:8148-8156.`,
    ],
  },
  {
    type: GRATITUDE_MEASURE,
    title: 'Gratitude Measure',
    refs: [
      `Website: http://www.midss.org/content/gratitude-questionaire-gq-6`,
      `Gray, S. A., Emmons, R. A., & Morrison, A. (2001 August). Distinguishing gratitude from indebtedness in affect and action tendencies. Poster presented at the annual meeting of the American Psychological Association, San Francisco, CA.`,
      `McCullough, M. E., Emmons, R. A., & Tsang, J. (2002). The grateful disposition: A conceptual and empirical topography. Journal of Personality and Social Psychology, 82, 112-127.`,
      `McCullough, M. E., Tsang, J., & Emmons, R. A. (2004). Gratitude in intermediate affective terrain: Links of grateful moods to individual differences and daily emotional experience. Journal of Personality and Social Psychology, 86, 295-309`,
    ],
  },
  {
    type: CHARACTER_STRENGTHS_MEASURE,
    title: 'Character Strengths Measure',
    refs: [
      `Website: https://www.viacharacter.org/character-strengths`,
      `Research Findings: https://www.viacharacter.org/research/findings`,
    ],
  },
  {
    type: MEANING_MEASURE,
    title: 'Meaning Measure',
    refs: [
      `Website: https://hfh.fas.harvard.edu/other-well-being-measures`,
      `Hanson, J.A. and VanderWeele, T.J. (2020). The Comprehensive Measure of Meaning: psychological and philosophical foundations. In: M. Lee, L.D. Kubzansky, and T.J. VanderWeele (Eds.). Measuring Well-Being: Interdisciplinary Perspectives from the Social Sciences and the Humanities. Oxford University Press, forthcoming.`,
    ],
  },
];
