import { doc, getDoc } from '@firebase/firestore';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { FirebaseClient } from '../../firebase';
import {
  CHARACTER_STRENGTHS_MEASURE,
  FLOURISHING_MEASURE,
} from '../../stores/measure/measure_type';
import { unpackSnapshot } from '../../utils/unpack_snapshot';
import { CharacterStrengthsResult } from './CompletedCharacterStrengthsResult';
import { FlourishingResult } from './CompletedFlourishingResult';
import { MeasureResult } from './CompletedMeasureResult';

export const CompletedAnonMeasure = () => {
  const { id } = useParams<{ id: string; slug: string }>();

  const { data, isLoading } = useQuery(['measure', { id }], async () => {
    const ref = doc(FirebaseClient.db, 'anonMeasures', id);
    const snapshot = await getDoc(ref);
    const measure = unpackSnapshot({ snapshot });
    return measure;
  });

  if (isLoading) {
    return (
      <div className='mt-10 grid grid-cols-1 place-items-center'>
        loading...
      </div>
    );
  }
  if (data.type === FLOURISHING_MEASURE) {
    return <FlourishingResult measure={data} />;
  }

  if (data.type === CHARACTER_STRENGTHS_MEASURE) {
    return <CharacterStrengthsResult measure={data} />;
  }
  return <MeasureResult measure={data} />;
};
