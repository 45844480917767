/**
 * Give a measure list, filters for the last measure of each measure type
 *
 */

import {
  CHARACTER_STRENGTHS_MEASURE,
  FLOURISHING_MEASURE,
  GRATITUDE_MEASURE,
  MEANING_MEASURE,
  Measure,
} from '../stores/measure/measure_type';

// function filter(measures, type) {
//   return (
//     measures
//       .filter((m) => m.type === type)
//       .sort((a, b) => a.stoppedAt - b.stoppedAt)[0] || undefined
//   );
// }

export function filterLatestMeasures(
  measures: Measure[],
): { type: string; measure?: Measure }[] {
  let latest = [
    { type: FLOURISHING_MEASURE },
    { type: GRATITUDE_MEASURE },
    { type: MEANING_MEASURE },
    { type: CHARACTER_STRENGTHS_MEASURE },
  ];

  return latest.map((m) => ({
    type: m.type,
    measure:
      measures
        .filter((measure) => m.type === measure.type)
        .sort((a, b) => a.stoppedAt - b.stoppedAt)[0] || undefined,
  }));
}
