import { Transition } from '@headlessui/react';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const MeasureCard = ({
  measure,
  to,
}: {
  measure: { title: string; about: string; to: string; iconWhite: string };
  to: string;
}) => {
  // console.log('measure', measure);
  const [state, setState] = useState('closed');

  const title = measure.title.replace(' Measure', ' \nMeasure');
  // console.log('title', title);

  return (
    <div className='relative mx-auto'>
      <div className='bg-burgundy w-200 grid grid-cols-1 place-items-center text-gray-100 text-center shadow-xl hover:shadow-2xl'>
        <img
          role='button'
          className={`absolute top-4 right-4 opacity-30 hover:opacity-100 ${
            state === 'open' ? 'opacity-100' : 'opacity-30'
          }`}
          src={
            // state === 'closed'
            // ? `/icons/info-transparent-bg.svg`
            `/icons/info-white-bg.svg`
          }
          alt=''
          onClick={() => setState(state === 'closed' ? 'open' : 'closed')}
        />

        <img className='h-20 my-10' src={`${measure.iconWhite}`} alt='' />
        <h2 className='mb-5 text-base leading-6 whitespace-pre-wrap'>
          {title}
        </h2>

        <Transition
          show={state === 'open'}
          enter='transform transition duration-500 ease-in bg-burgundy text-white'
          enterFrom='translate -translate-y-4 opacity-100 text-burgundy'
          enterTo='translate translate-y-0 opacity-100 text-white'
          leave='transform transition duration-500 ease-in-out'
          leaveFrom='translate translate-y-0 opacity-100 text-white'
          leaveTo='translate -translate-y-10 opacity-0 text-burgundy'>
          <p className='p-2 text-xs leading-5 text-white'>{measure.about}</p>
        </Transition>

        <Link
          to={to}
          className='grid grid-cols-1 place-items-center -mb-10 bg-white h-14 w-14 rounded-full'>
          <ArrowRightIcon className='h-8 w-8 text-burgundy rounded-full' />
        </Link>
      </div>
    </div>
  );
};
