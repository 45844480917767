import { RouterProps, RouteComponentProps, useHistory } from 'react-router';
import { mapMeasure } from '../../../utils/get_type_from_url';
import { useMutation } from 'react-query';
import { getUrlFromType } from '../../../utils/get_url_from_type';
import { Measure } from '../../../stores/measure/measure_type';
import { MeasureForm } from '../../measures/MeasureForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../types';
import { updateNewSessionAction } from '../../../stores/session/new_session_action_creators';

type Props = {} & RouterProps & RouteComponentProps<{ measure: string }>;

/**
 * This Page is for Measures done during new activity session intake
 * - see activities/intake/measure for intake measures
 * - see about/anonmeasure for anonymous measures
 * @param props
 * @returns
 */
export const NewActivityMeasureForm = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const type = mapMeasure.get(props.match.params.measure);
  const slug = getUrlFromType(type || '', false); //FIXME:
  const newSession = useSelector((state: AppState) => state.newSession);

  const mutation = useMutation(
    async (measure: Measure) => {
      // console.log('Measure', measure);

      measure.isBaseline = true;
      let added = false;
      const updatedBaselineMeasures = newSession.baselineMeasures.filter(
        (measure) => {
          if (measure.type === type) {
            added = true;
            return measure;
          }
          return measure;
        },
      );
      if (!added) {
        updatedBaselineMeasures.push(measure);
      }
      const updatedNewSession = {
        ...newSession,
        baselineMeasures: updatedBaselineMeasures,
      };
      dispatch(updateNewSessionAction(updatedNewSession));

      return measure;
    },
    {
      onSuccess: (measure: Measure) => {
        // console.log('on success', measure);
        history.push(`/app/activities/${slug}/new/measures/`);
      },
    },
  );

  if (!type) {
    return <div>Error</div>;
  }

  return (
    <div className='mx-auto max-w-7xl'>
      <MeasureForm type={type} mutation={mutation} />
    </div>
  );
};
