import { httpsCallable } from 'firebase/functions';
import { FetchCurrentSessionAction } from '../stores/session/fetch_current_session_action';
import FirebaseClient from './firebase_client';

export async function doFetchCurrentSession(action: FetchCurrentSessionAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: FETCH CURRENT SESSION REQUESTED');
    console.log(action);
    console.groupEnd();
  }

  const { type } = action.payload;

  try {
    const result = (await httpsCallable(
      FirebaseClient.functions,
      'fetchCurrentSession',
    )(JSON.stringify({ type }))) as any;
    const session = result.data;
    // TODO: make sure i'm grabbing the .date file for all returned firebase obj
    if (session.hasError && session.hasError === true) {
      if (process.env.NODE_ENV !== 'production') {
        console.group('FIREBASE ERROR: FETCH CURRENT SESSION FAILED');
        console.error(session.error);
        console.groupEnd();
      }
      return { hasError: true, error: session.error };
    }
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: FETCH CURRENT SESSION SUCCEEDED');
      console.log(result);
      console.groupEnd();
    }
    return result.data;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: FETCH CURRENT SESSION FAILED');
      console.error(error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
}
