import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  UserCredential,
} from 'firebase/auth';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SIGN_IN_WITH_EMAIL_SUCCEEDED } from '../auth/sign_in_with_email_action';
import { FirebaseClient } from '../firebase';
import { signInWithGoogle } from '../stores/account';
import { SIGN_IN_SUCCESS } from '../stores/account/account_types';
import { Spinner } from './Spinner';

interface Values {
  email: string;
  password: string;
}

// TODO: make this the general component for sign-in/sign-up and error handling for forgotten passwords, etc; creating a new password would be done via a different set of screens/views

export const SignInUpForm = ({
  type = 'sign-in',
  isCTA = false,
}: {
  type: string;
  isCTA?: boolean;
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState({ hasError: false, error: '' });

  // TODO: create account via email
  const createAccountWithEmailMutation = useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      const response = await createUserWithEmailAndPassword(
        FirebaseClient.auth,
        email,
        password,
      );
      return response;
    },
    {
      onSuccess: (response: UserCredential) => {
        if (!response?.user?.emailVerified) {
          sendEmailVerification(response.user);
        }
        dispatch({ type: SIGN_IN_WITH_EMAIL_SUCCEEDED, payload: response });
        dispatch({ type: SIGN_IN_SUCCESS, payload: response });
      },
      onError: (error: any) => {
        console.error('onError', error);
        // FIXME: finish this pattern
        setError({
          hasError: true,
          error: error?.message || error,
        });
      },
    },
  );

  // TODO: sign in via email
  const emailSignInMutation = useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      const response = await signInWithEmailAndPassword(
        FirebaseClient.auth,
        email,
        password,
      );
      // TODO: account reducer stuff
      console.log('Sign in with email', response);

      return response;
    },
    {
      onSuccess: (response) => {
        console.log('onSuccess', response);
        dispatch({ type: SIGN_IN_WITH_EMAIL_SUCCEEDED, payload: response });
        dispatch({ type: SIGN_IN_SUCCESS, payload: response });
      },
      onError: (error: any) => {
        console.error('onError', error);
        // FIXME: finish this pattern
        setError({
          hasError: true,
          error: error?.message || error,
        });
      },
    },
  );

  return (
    <div className='relative pb-10 lg:h-800'>
      <img
        className='absolute z-0 hscreen min-h-screen md:h-800 inset-0 min-w-full lg:min-w-1/2 object-top md:object-right'
        src='/images/photosynthesis.png'
        alt='plant leaves with light shinning through'
      />
      {/* TODO: only show this on the sign-in/up pages not as a CTA */}
      {!isCTA && (
        <img
          className='absolute z-10 top-5 left-5'
          src='/icons/f-burgundy.svg'
          alt='burgundy lowercase "f" letter'
        />
      )}
      {/* White Form  */}
      <div className='relative h700 z-10 pt-24 mx-4 lg:mx-auto max-w-7xl grid grid-cols-1 lg:grid-cols-12 place-items-center text-burgundy'>
        <div className='px-9 py-10 lg:col-start-5 lg:col-span-8 bg-white w-full h-full lg:p-20 shadow'>
          <h1 className='text-3xl leading-9 lg:text-6xl'>
            Let's flourish together
          </h1>
          <div className='pt-5' />
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={(values: Values) => {
              const { email, password } = values;
              if (type === 'sign-in') {
                emailSignInMutation.mutate({ email, password });
              }
              if (type === 'sign-up') {
                createAccountWithEmailMutation.mutate({ email, password });
              }
            }}>
            <Form className='space-y-5' translate='yes'>
              <div className='md:space-y-5'>
                <label
                  htmlFor='email'
                  className='block text-xs md:text-base leading-160'>
                  Email address
                </label>
                <div className='mt-1 rounded-full shadow-sm'>
                  <Field
                    id='email'
                    name='email'
                    type='email'
                    placeholder='hello@flourishing.app'
                    autoComplete='username'
                    required
                    className='appearance-none block w-full px-3 py-2 border border-burgundy rounded-full placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                  />
                </div>
              </div>

              <div className='md:space-y-5'>
                <label
                  htmlFor='password'
                  className='block text-xs md:text-base leading-160'>
                  Password
                </label>
                <div className='mt-1 rounded-full shadow-sm'>
                  <Field
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    required
                    className='appearance-none block w-full px-3 py-2 border border-burgundy rounded-full placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                  />
                </div>
              </div>
              {/* <button type='submit'>Submit</button> */}
              {error.hasError && <div>{error.error}</div>}
              <button
                type='submit'
                className='mt-2 w-full flex justify-center py-2 px-4 border border-transparent text-xs md:text-base leading-160 rounded-full text-white bg-burgundy'>
                {(createAccountWithEmailMutation.isLoading ||
                  emailSignInMutation.isLoading) && <Spinner />}
                {type === 'sign-in' ? 'Sign in' : 'Sign up'}
              </button>
              {type === 'sign-in' && (
                <div className='flex space-x-2'>
                  <p className='text-sm text-not-black'>Forget password?</p>
                  <Link
                    className='text-sm text-not-black underline'
                    to='/reset-password'>
                    Request password reset
                  </Link>
                </div>
              )}
            </Form>
          </Formik>
          {/* Continue with Google */}
          <div className='mt-6'>
            <div className='relative'>
              <div className='relative flex justify-center'>
                <span className='px-2 text-xs md:text-base leading-160 bg-white'>
                  Or
                </span>
              </div>
            </div>
            <div className='mt-4'>
              <button
                className='relative flex items-center bg-white px-2 rounded-full border border-burgundy w-full'
                onClick={() => dispatch(signInWithGoogle())}>
                <img
                  className='absolute left-2 p-2 h-10 w-10'
                  src='/google.svg'
                  alt='Google Logo'
                />
                <p className='flex-grow p-2 text-xs md:text-base leading-160 text-burgundy'>
                  Continue with Google
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
