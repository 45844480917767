import { Helmet } from 'react-helmet';

import { MEASURE_TEXT } from '../../text/measures';
import { MeasureCard } from '../../components/measures/MeasureCard';
import { Link } from 'react-router-dom';

export const MeasuresAbout = ({ about }: { about?: boolean }) => {
  return (
    <div>
      {!about && (
        <Helmet>
          <title>Measures - About</title>
        </Helmet>
      )}
      <main className='pt-16 pb-20'>
        <header className='relative mx-auto max-w-5xl w-full pt-6 pb-20 px-4 lg:px-0 text-center'>
          <h1 className='text-4xl leading-160 md:text-6xl md:leading-160 text-burgundy'>
            Measure & track your progress
          </h1>
          <p className='mt-10 text-base leading-160'>
            The four measures below can be used to help you track your levels of
            flourishing and progress as you do activities.
          </p>
          <p className='mt-10 text-base leading-160'>
            If you are unsure of where to begin, we recommend first doing the{' '}
            <Link className='underline' to='/about/measures/flourishing'>
              Flourishing Measure{' '}
            </Link>
            .
          </p>
        </header>

        <div className='mx-auto max-w-5xl grid grid-cols-1 mdgrid-cols-2 lg:grid-cols-4 gap-12 place-items-top bg-transparent'>
          {Object.keys(MEASURE_TEXT).map((k) => {
            const measure = MEASURE_TEXT[k];
            const to = `/about${measure.href}`;
            return <MeasureCard key={measure.href} measure={measure} to={to} />;
          })}
        </div>
      </main>
    </div>
  );
};
