import { collection, getDocs, query, where } from 'firebase/firestore';
import { useQuery } from 'react-query';
import { FirebaseClient } from '../../../firebase';
import { unpackSnapshotArray } from '../../../utils/unpack_snapshot_array';

export const useActiveSessions = () => {
  return useQuery(
    ['activeSessions'],
    async () => {
      const uid = FirebaseClient.auth.currentUser?.uid;
      if (!uid) throw new Error('User not logged in.');

      const ref = collection(FirebaseClient.db, '/users', uid, '/sessions');
      const q = query(ref, where('isActive', '==', true));
      const snapshots = await getDocs(q);

      // const snapshots = await FirebaseClient.db
      //   .collection('users')
      //   .doc(uid)
      //   .collection('sessions')
      //   .where('isActive', '==', true)
      //   .get();
      // console.log('snapshots', snapshots.size);

      if (snapshots.size === 0) {
        return [];
      }

      const data = unpackSnapshotArray({ snapshots });
      // console.log('data', data);

      return data;
    },
    { enabled: !!FirebaseClient.auth.currentUser?.uid },
  );
};
// const { data, isLoading } = useQuery(['measure', { id }], async () => {
//   const ref = doc(FirebaseClient.db, '/users', uid, '/measures', id);
//   const snapshot = await getDoc(ref);
//   const measure = unpackSnapshot({ snapshot });
//   return measure;
// });
