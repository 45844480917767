import React from 'react';
import { Helmet } from 'react-helmet';

const policies = [
  {
    text: 'Cookie Policy',
    url: 'https://app.termly.io/document/cookie-policy/67bf59f0-0097-443f-a06d-3d5f2ea41480',
  },
  {
    text: 'Privacy Policy',
    url: 'https://app.termly.io/document/privacy-policy/fa8b2e61-10ca-46c1-8cd0-01f9cbfa0f4b',
  },
  {
    text: 'Terms of Service',
    url: 'https://app.termly.io/document/terms-of-use-for-ios-app/bee07160-de0b-4386-91e8-635bf61c87cf',
  },
];

const tech = [
  { text: 'React', url: 'https://reactjs.org' },
  { text: 'Redux', url: 'https://redux.js.org' },
  { text: 'Lodash', url: 'https://lodash.com' },
  { text: 'Redux Persist', url: 'https://github.com/rt2zz/redux-persist' },
  { text: 'TailwindUI', url: 'https://tailwindui.com/' },
  { text: 'TailwindCSS', url: 'https://tailwindcss.com/' },
  { text: 'Formik', url: 'https://formik.org/' },
  { text: 'React Router', url: 'https://reacttraining.com/react-router/web/' },
  { text: 'Expo', url: 'https://expo.io/' },
  { text: 'React Native', url: 'https://reactnative.dev/' },
  { text: 'React Navigation', url: 'https://reactnavigation.org/' },
  { text: 'Sentry', url: 'https://sentry.io/' },
  {
    text: 'React Native Elements',
    url: 'https://react-native-elements.github.io/',
  },
  { text: 'Google Cloud Platform', url: 'http://cloud.google.com/' },
  { text: 'Firebase', url: 'https://firebase.google.com/' },
  { text: 'Firestore', url: 'https://cloud.google.com/firestore/' },
  {
    text: 'Google Analytics',
    url: 'https://analytics.google.com/analytics/web/',
  },
  { text: 'The Noun Project', url: 'https://thenounproject.com/' },
  { text: 'HeroIcons', url: 'https://heroicons.com/' },
  { text: 'Unsplash', url: 'https://unsplash.com/' },
  { text: 'React Query', url: 'https://react-query.tanstack.com/' },
].sort((a, b) => (a.text < b.text ? -1 : 1));

export const CreditsPage = () => {
  return (
    <div className='relative pb-10 overflow-hidden'>
      <Helmet>
        <title>Credits</title>
      </Helmet>

      <header className='py-20 bg-white'>
        <div className='mx-auto max-w-7xl'>
          <h1 className='px-4 xl:px-0 text-5xl leading-160'>
            The <span className='text-burgundy'>Flourishing App</span> Credits
          </h1>
        </div>
      </header>

      <main className='px-4'>
        <div className='py-10 mx-auto max-w-7xl'>
          <h2 className='font-bold'>Funding</h2>
          <p className=''>
            The Flourishing App is made possible through the support and
            partnership with{' '}
            <a
              className='underline'
              href='https://hfh.fas.harvard.edu/'
              target='_blank'
              rel='noopener noreferrer'>
              Human Flourishing Program
            </a>
            .
          </p>

          <br />
          <p>
            The Flourishing app is administered by{' '}
            <a
              className='underline'
              href='https://flourishingtechnologies.com'
              target='_blank'
              rel='noopener noreferrer'>
              Flourishing Technologies
            </a>
            , which does so on a not for profit basis.
          </p>

          <hr className='my-4' />

          <h2 className='font-bold'>Contact</h2>
          <p>Questions can be directed to flourishingapp at gmail dot com</p>

          <hr className='my-4' />

          <h2 className='font-bold'>Policies</h2>
          <ul className='mt-2 space-y-5'>
            {policies.map((policy) => (
              <li key={policy.url} className=''>
                <a
                  className='text-not-black underline'
                  href={policy.url}
                  target='_blank'
                  rel='noopener noreferrer'>
                  {policy.text}
                </a>
              </li>
            ))}
          </ul>

          <hr className='my-10' />

          <h2 className='font-bold'>Design and Development Credits</h2>
          <div className='space-y-5'>
            <p>
              Program, software, and overall structure designed, developed, and
              maintained by{' '}
              <a
                className='underline'
                href='https://defrederick.com'
                target='_blank'
                rel='noopener noreferrer'>
                Donald E Frederick
              </a>
              .
            </p>

            <p>Brand & Product Designer: Paco Puylaert</p>
            <p className='truncate'>
              Original conceptual and scientific validity assessment were done
              by Prof. Tyler J VanderWeele, Ph.D.
            </p>
            <p className='ml-5 text-sm'>
              VanderWeele, T. J. (2019). Activities for Flourishing: An
              Evidence-Based Guide. Journal of Positive Psychology and
              Wellbeing, 1-13. Retrieved from{' '}
              <a
                className='underline'
                href='https://journalppw.com/index.php/JPPW/article/view/163'
                target='_blank'
                rel='noopener noreferrer'>
                https://journalppw.com/index.php/JPPW/article/view/163
              </a>
            </p>
          </div>

          <hr className='my-10' />

          <h2 className='my-5 font-bold'>Software</h2>
          <ul className='space-y-3'>
            {tech.map((item) => {
              return (
                <li key={item.url} className=''>
                  <a
                    className='underline text-not-black'
                    href={item.url}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {item.text}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </main>
    </div>
  );
};
