import { getUrlFromType } from '../../utils/get_url_from_type';
import { Session, Entry } from '../../stores/session/session_types';
import {
  checkIsAvailableOrUpcoming,
  checkIsUpcoming,
  isAvailable,
} from '../../utils/date_checks';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useActiveSessions } from '../../api/firebase/hooks/use_active_sessions';
import { getTitle } from '../../utils/get_title';
import { IsLoading } from '../../components/IsLoading';
import { EntryListItem } from '../activities/components/Entries';

function getAvailableOrUpcomingEntries(session: Session) {
  const matches = checkIsAvailableOrUpcoming(session);
  return matches;
}

export const Dashboard = () => {
  const { data: activeSessions, isLoading: isLoadingActive } =
    useActiveSessions();

  let upcoming: Entry[] = [];
  activeSessions
    ?.filter((session) => session.isActive)
    .forEach((session) => {
      const matches = checkIsUpcoming(session);
      upcoming.push(...matches);
    });
  upcoming = upcoming.sort((a, b) => a.scheduledDate - b.scheduledDate);

  if (isLoadingActive) {
    return <IsLoading isLoading={true} />;
  }

  if (activeSessions?.length === 0) {
    return (
      <main className='relative px-4 xl:px-0 py-2 mx-auto max-w-7xl'>
        {/* Header */}
        <header className='px-4 xl:px-0 pt-10 mx-auto max-w-7xl'>
          <h1 className='text-burgundy text-4xl leading-160 md:text-6xl md:leading-160'>
            Welcome
          </h1>
        </header>
        <div className='px-4 xl:px-0 py-4 space-y-2'>
          <p className='text-sm leading-160'>
            It looks like you have no activities in progress.
          </p>
          <p className='text-sm leading-160'>
            You may explore available activities{' '}
            <Link className='underline' to='/about/activities'>
              here
            </Link>
            .
          </p>
          <p className='text-sm leading-160'>
            If you are unsure of where to start, we recommend first taking the{' '}
            <Link className='underline' to='/app/measures/flourishing'>
              Flourishing Measure
            </Link>
          </p>
        </div>
      </main>
    );
  }

  return (
    <div className='overflow-hidden'>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <main className='relative py-2'>
        {/* Header */}
        <header className='px-4 xl:px-0 pt-10 mx-auto max-w-7xl'>
          <h1 className='text-burgundy text-4xl leading-160 md:text-6xl md:leading-160'>
            Progress Overview
          </h1>
          <p className='text-sm leading-160'>
            Get an overview of your current and upcoming activities.
          </p>
        </header>

        <div className='py-10' />

        {/* Sessions List */}
        <ul className='relative mx-auto max-w-7xl space-y-10'>
          {activeSessions?.map((session) => (
            <SessionPanel session={session} key={session.id} />
          ))}
        </ul>

        <div className='py-20' />
      </main>
    </div>
  );
};

/**
 * Displays information about an Active Activity Session
 * @param param0
 * @returns
 */
const SessionPanel = ({ session }) => {
  const title = getTitle(session.type);
  const nCompletedEntries = session.entries.reduce(
    (sum, entry) => (entry.completed ? sum + 1 : sum),
    0,
  );
  const nEntries = session.entries.length;
  const progress = nCompletedEntries / nEntries;

  // FIXME: fix entries that are being shown
  const upcomingEntries = getAvailableOrUpcomingEntries(session);
  const slug = getUrlFromType(session.type, true);

  return (
    <div
      className='relative px-4 md:px-24 py-10 bg-white overflow-x-auto'
      key={session.id}>
      <header>
        <h3 className='text-sm leading-160'>
          Status: <strong>in progress</strong>
        </h3>
        <Link
          className='text-burgundy text-3xl md:text-4xl font-kenoky font-light leading-160'
          to={`/app/activities/${slug}`}>
          {title} Activity
        </Link>
        <div className='mt-4 space-y-2'>
          <p className='text-sm leading-160'>
            Started on:{' '}
            <strong> {new Date(session.startedAt).toLocaleDateString()}</strong>
          </p>

          <p className='text-sm leading-160'>
            Progress:{' '}
            <strong>
              {nCompletedEntries} / {nEntries}
            </strong>
          </p>
        </div>
        <div
          id='progress'
          className='my-4 overflow-hiddern bg-not-gray h-4 mb-4 flex rounded-full'>
          <div
            style={{ width: `${100 * progress}%` }}
            className='shadow-none flex flex-col bg-burgundy rounded-full'
          />
        </div>
      </header>

      {/* Upcoming Entries List */}
      <ol className='pt-4 space-y-5'>
        {upcomingEntries.map((entry: Entry) => {
          const status = isAvailable(entry) ? 'available' : 'scheduled';

          const to =
            status === 'available'
              ? `/app/activities/${slug}/sessions/${session.id}/entries/${entry.uuid}/new`
              : `/app/activities/${slug}`;

          if (status === 'available') {
            return (
              <Link key={entry.uuid} className='block' to={to}>
                <EntryListItem
                  session={session}
                  entry={entry}
                  status={status}
                />
              </Link>
            );
          }

          return (
            <EntryListItem
              key={entry.uuid}
              session={session}
              entry={entry}
              status={status}
            />
          );
        })}
      </ol>
      <div className='flex justify-center pt-10 pb-8'>
        <Link className='text-sm leading-160' to={`/app/activities/${slug}`}>
          See all
        </Link>
      </div>
    </div>
  );
};
