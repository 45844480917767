import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { AppState } from '../types';

export const PolicyBanner = () => {
  const [seen, setSeen] = useState(false);
  const dispatch = useDispatch();
  const { cookieBannerSeen } = useSelector((state: AppState) => state.meta);

  useEffect(() => {
    if (seen) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Setting localstorage');
      }
      localStorage.setItem('policy-banner-seen', 'true');
    }
  }, []);

  if (cookieBannerSeen) {
    return null;
  }

  return (
    <div className='fixed z-20 bottom-0 inset-x-0 pb-2 sm:pb-5'>
      <div className='max-w-screen-xl mx-auto px-2 sm:px-6 lg:px-8'>
        <div className='p-2 rounded-lg bg-burgundy shadow-lg sm:p-3'>
          <div className='flex items-center justify-between flex-wrap'>
            <div className='w-0 flex-1 flex items-center'>
              <span className='flex p-2 rounded-lg bg-burgundy'>
                <svg
                  className='h-6 w-6 text-white'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                  />
                </svg>
              </span>
              <p className='ml-3 font-medium text-white '>
                <span className=''>
                  We use cookies to help improve the site.{' '}
                  <Link to='/privacy' className='underline'>
                    Learn more.
                  </Link>
                </span>
              </p>
            </div>
            <div className='order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto'>
              <button
                onClick={() => dispatch({ type: 'SET_COOKIE_BANNER' })}
                className='w-full sm:w-auto px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-burgundy bg-white  focus:outline-none focus:shadow-outline transition ease-in-out duration-150'>
                Got it!
              </button>
            </div>
            <div className='order-2 flex-shrink-0 sm:order-3 sm:ml-2'>
              <button
                type='button'
                className='-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500 transition ease-in-out duration-150'
                aria-label='Dismiss'></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
