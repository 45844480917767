export const Stepper = ({ step, hasBaseline }) => {
  // steps: instructions baseline reflection measures schedule

  const steps: any[] = [];
  if (hasBaseline) {
    // 5 steps
    const stepNumber = {
      instructions: 0,
      baseline: 1,
      reflection: 2,
      measures: 3,
      schedule: 4,
    }[step];
    for (let i = 0; i < 5; i++) {
      if (i <= stepNumber) {
        steps.push({ step: i, state: 'seen' });
      } else {
        steps.push({ step: i, state: 'todo' });
      }
    }
  } else {
    // 4 steps
    const stepNumber = {
      instructions: 0,
      reflection: 1,
      measures: 2,
      schedule: 3,
    }[step];
    for (let i = 0; i < 4; i++) {
      if (i <= stepNumber) {
        steps.push({ step: i, state: 'seen' });
      } else {
        steps.push({ step: i, state: 'todo' });
      }
    }
  }

  return (
    <ul className='flex justify-center space-x-1'>
      {/* need a 'step' state, which the parent/controller/container can maintain */}
      {steps.map((step) => (
        <div
          key={step.step}
          className={`${
            step.state === 'seen' ? 'bg-burgundy' : 'bg-gray-100'
          } h-2 w-2 rounded-full`}></div>
      ))}
    </ul>
  );
};
