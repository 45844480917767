import { useState } from 'react';
import {
  BEHAVIORAL_EXERCISE,
  COGNITIVE,
  COGNITIVE_EXERCISE,
  EXERCISE_GROUPS,
  RELATIONSHIPS_EXERCISE,
  TEXT,
} from '../../text/activities';
import Helmet from 'react-helmet';
import { Disclosure, Tab, Transition } from '@headlessui/react';
import { getUrlFromType } from '../../utils/get_url_from_type';
import { Link, useHistory } from 'react-router-dom';
import { AppState } from '../../types';
import { useSelector } from 'react-redux';
import { useQueryParams } from '../../utils/use_query_params';
import { getTypeFromUrl } from '../../utils/get_type_from_url';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const images = Object.values(EXERCISE_GROUPS).map((group) => group.img);

// TODO: set activities based upon url

const groupToIndex = (group: string) => {
  if (group === 'cognitive') {
    return 0;
  }
  if (group === 'behavioral') {
    return 1;
  }
  if (group === 'relationships') {
    return 2;
  }
  if (group === 'distress') {
    return 3;
  }
  return 0;
};

const groups = ['cognitive', 'behavioral', 'relationships', 'distress'];
const activities = {
  gratitude: 0,
  savoring: 1,
  'best-self': 2,
};

/**
 *
 * @returns
 */
export const ActivitiesAbout = () => {
  const history = useHistory();
  let query = useQueryParams();
  let selectedGroup = query.get('group');
  const defaultIndex = !!selectedGroup ? groupToIndex(selectedGroup) : 0;
  const isSignedIn = useSelector((s: AppState) => s.account.isSignedIn);

  // const activity = query.get('activity');
  // const activityType = !!activity
  //   ? getTypeFromUrl(activity, history.location.pathname)
  //   : '';
  // console.log('activity', activityType);

  // let selectedActivity = query.get('activity');
  // FIXME: how does this work?
  let [categories] = useState(EXERCISE_GROUPS);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [selectedActivity, selectActivity] = useState<any>(
    EXERCISE_GROUPS[COGNITIVE].activities[0],
    // EXERCISE_GROUPS[COGNITIVE].activities.find(
    //   (activity) => activity.type === activityType,
    // ),
  );
  // console.log('categories', categories);
  const handleSelectActivity = (activity) => {
    selectActivity(activity);
    //update queryparams
    console.log('history', history.location, activity);
    const slug = getUrlFromType(activity.type, true);

    history.push(
      `/about/activities?group=${groups[selectedTabIndex]}&activity=${slug}`,
    );
  };

  const handleChange = (index) => {
    // TODO: push to history
    // ?group=GROUP&activity=ACTIVITY
    history.push(`/about/activities?group=${groups[index]}`);
    setSelectedTabIndex(index);
    console.log('onChange', index);
  };

  return (
    <div className='relative pb-28 bg-gray-100 overflow-hidden'>
      <Helmet>
        <title>About Activities</title>
      </Helmet>

      <main className='relative w-full'>
        <img
          className='h-64 w-full object-top object-cover'
          src={images[selectedTabIndex]}
          alt=''
        />

        <section className='relative z-10 mx-auto max-w-7xl -mt-24'>
          {/*  */}
          <Tab.Group
            defaultIndex={defaultIndex}
            onChange={(index) => handleChange(index)}>
            <Tab.List className='relative block pt-4 md:pt-14 bg-white rounded-t-2xl mx-auto max-w-7xl px-24 '>
              <div className='relative z-20 md:flex md:space-x-1 space-y-1 md:space-y-0'>
                {Object.keys(categories).map((category) => (
                  <Tab
                    key={category}
                    className={({ selected }) =>
                      classNames(
                        'w-full py-2.5 text-2xl leading-5 rounded-full capitalize font-kenoky',
                        'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-burgundy ring-white ring-opacity-60',
                        selected
                          ? 'bg-burgundy text-white shadow'
                          : 'bg-white text-burgundy',
                      )
                    }>
                    {category}
                  </Tab>
                ))}
              </div>
            </Tab.List>
            {/*  */}
            <Tab.Panels className=''>
              {Object.values(categories).map((group, idx) => (
                <Tab.Panel
                  key={idx}
                  className={classNames('', 'focus:outline-none')}>
                  <div className='bg-white rounded-b-xl'>
                    <div className='px-4 xl:px-0 py-10 mx-auto max-w-5xl'>
                      <Header type={group.type} />
                    </div>
                  </div>
                  {/* Card List */}
                  <ul className='relative py-20 px-4 xl:px-0 flex gridlg:grid-cols-3 gap-8 bg-gray-100 overflow-x-auto'>
                    {group.activities.map((activity, i) => {
                      // console.log(
                      //   'Check',
                      //   activity?.type,
                      //   selectedActivity?.type,
                      //   activity?.type === selectedActivity?.type,
                      // );
                      console.log(
                        'check',
                        selectedActivity?.type,
                        activity.type,
                      );

                      return (
                        <Card
                          key={activity.href}
                          activity={activity}
                          selected={selectedActivity?.type === activity.type}
                          // selected={selectedActivity?.type === activity?.type}
                          handleSelect={selectActivity}
                          // handleSelect={handleSelectActivity}
                        />
                      );
                    })}
                  </ul>
                  {/*  */}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </section>
        {!isSignedIn && (
          <section>
            <DisclosurePanel
              title='Overview'
              type={selectedActivity?.type}
              section='instructions'
              defaultOpen={true}
            />
            <DisclosurePanel
              title='Getting Started'
              type={selectedActivity?.type}
              section='gettingStarted'
            />
            <DisclosurePanel
              title='First Entry'
              type={selectedActivity?.type}
              section='firstEntry'
            />
            <DisclosurePanel
              title='Weekly Instructions'
              type={selectedActivity?.type}
              section='weeklyInstructions'
            />
            <DisclosurePanel
              title='Science Behind the Activity'
              type={selectedActivity?.type}
              section='science'
            />
          </section>
        )}
      </main>
    </div>
  );
};

export const DisclosurePanel = ({
  title,
  type,
  section,
  defaultOpen = false,
}: {
  title: string;
  type: string;
  section: string;
  defaultOpen?: boolean;
}) => {
  // console.log('find me', title, type, section);
  const text = TEXT[type]?.[section];

  return (
    <div className='bg-burgundy border-b border-white'>
      <Disclosure
        as='div'
        defaultOpen={defaultOpen}
        className='mx-auto max-w-7xl mt-0'>
        {({ open }) => (
          <div className='mx-auto max-w-7xl'>
            <Disclosure.Button className='flex items-center space-x-5 w-full px-4 py-8 text-2xl text-left text-white font-kenoky focus:outline-none focus-visible:ring focus-visible:ring-burgundy focus-visible:ring-opacity-75'>
              <img
                className={`transition duration-500 ease-in-out ${
                  open ? 'transform rotate-180' : ''
                } mr-10 w-10 h-10 text-white`}
                src='/icons/northwest-arrow.svg'
                alt='arrow point to the upper left'
              />

              <h1 className='ml-5 text-3xl leading-160 font-light'>{title}</h1>
            </Disclosure.Button>
            <Transition
              enter='transform transition duration-500 ease-in bg-burgundy text-white'
              enterFrom='translate -translate-y-4 opacity-100 text-burgundy'
              enterTo='translate translate-y-0 opacity-100 text-white'
              leave='transform transition duration-500 ease-in-out'
              leaveFrom='translate translate-y-0 opacity-100 text-white'
              leaveTo='translate -translate-y-10 opacity-0 text-burgundy'>
              <Disclosure.Panel
                className='md:ml-20 bg-burgundy'
                style={{ maxWidth: 700 }}>
                {Array.isArray(text) ? (
                  /* {TEXT[type]?.[section]} */
                  <ul className='space-y-4'>
                    {text.map((t, i) => (
                      <p
                        key={i}
                        className='px-4 md:px-0 md:ml-10 textsm leading-160 text-white whitespace-pre-line'>
                        {t}
                      </p>
                    ))}
                  </ul>
                ) : (
                  <p className='px-4 md:px-0 md:ml-10 textsm leading-160 text-white whitespace-pre-line'>
                    {text}
                  </p>
                )}
                <div className='pb-20' />
              </Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
    </div>
  );
};

const Header = ({ type }: { type: string }) => {
  // console.log('header', type);

  if (type === COGNITIVE_EXERCISE) {
    return (
      <p>
        <span className='font-bold'>Cognitive exercises </span>
        are those activities that require only that you set aside time
        throughout your day or week for sustained self-reflection and inquiry.
        The three activities included here are gratitude, savoring, and
        imagining one’s best possible self. These three can in some sense be
        viewed respectively as an orientation of the mind to what is good in
        either the past (gratitude), the present (savoring), or the future
        (imagining).
      </p>
    );
  }
  if (type === BEHAVIORAL_EXERCISE) {
    return (
      <p>
        <span className='font-bold'>Behavioral exercises</span> are those that
        require, in addition to self-reflection, some outward manifestation of
        those thoughts (i.e., behavior). These three activities are the use of
        character strengths, acts of kindness towards others, and volunteering
        in the community. They can be viewed as actions oriented towards seeking
        what is good in oneself (character / virtue), for others (acts of
        kindness), and for the community (volunteering).
      </p>
    );
  }
  if (type === RELATIONSHIPS_EXERCISE) {
    return (
      <p>
        In addition to positive psychology interventions, considerable research
        has shown that various{' '}
        <span className='font-bold'>relational and institutional </span>
        commitments contribute profoundly to different aspects of flourishing
        and well-being. Work, marriage, and religious community can all
        positively promote not only happiness, but also health, meaning and
        purpose, character, and a sense of having good relationships.
      </p>
    );
  }
  return (
    <p>
      <span className='font-bold'>Psychological Distress</span> activites make
      use of three different book and workbook interventions that address
      depression, anxiety, and forgiveness. They are essentially oriented
      towards the good in removing or recovering from what are sometimes
      conceived of as three central negative emotions: sadness, fear, and anger.
    </p>
  );
};

const Card = ({ activity, selected, handleSelect, ...props }) => {
  // const activities = exercise.activities
  //   .map((activity) => activity.header.replace(' Activity', ''))
  //   .join(', ');
  const isSignedIn = useSelector((state: AppState) => state.account.isSignedIn);
  const quotation = TEXT[activity.type]?.quotation;
  const slug = getUrlFromType(activity.type, true);

  return (
    <div
      role='button'
      className={`${
        selected ? 'ring ring-burgundy shadow2xl' : ''
      } flex-shrink-0 mx-auto flex flex-col bg-white text-center rounded-lg  overflow-hidden w-card-small md:w-card-large `}
      // style={{ width: 387 }}
      onClick={() => handleSelect(activity)}>
      <div
        className='relative h-56 md:h-48 bg-center bg-cover flex-shrink-0 flex flex-col justify-center'
        style={{ backgroundImage: `url(${activity.img})` }}
        {...props}>
        <div className='absolute h-full w-full bg-black opacity-40' />
        {/* <img className='h-48 w-full object-cover' src={activity.img} alt='' /> */}
        {selected && (
          <p className='relative p-3 text-xs leading-160 text-white whitespace-pre-wrap'>
            {quotation}
          </p>
        )}
      </div>
      <div className='flex-1  p-6 flex flex-col justify-between'>
        <div className='flex-1'>
          <h1 className='my-2 text-2xl leading-160 border-b'>
            {activity.header}
          </h1>

          <p className='mt-3 text-sm leading-160 text-not-black whitespace-pre-wrap'>
            {activity.desc}
          </p>
        </div>
      </div>
      {/* TODO: only show button for signed-in users and make it a Link element */}

      {selected && (
        <Link
          to={
            isSignedIn ? `/app/activities/${slug}` : `/about/activities/${slug}`
          }
          className={`${
            !selected ? 'bg-burgundy text-white' : 'bg-white text-burgundy'
          } block m-5 py-3 wfull border border-burgundy text-sm rounded-full`}>
          Go to Activity
        </Link>
      )}
      {/* <button
        className={`${
          !!selected ? 'bg-burgundy text-white' : 'bg-white'
        } block m-5 py-1 wfull border border-burgundy text-sm rounded-full`}
        onClick={() => handleSelect(activity)}>
        {selected ? 'Selected' : 'Select'}
      </button> */}
    </div>
  );
};
