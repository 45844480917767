export const CHANGE_EMAIL_REQUESTED = 'CHANGE_EMAIL_REQUESTED';
export const CHANGE_EMAIL_SUCCEEDED = 'CHANGE_EMAIL_SUCCEEDED';
export const CHANGE_EMAIL_FAILED = 'CHANGE_EMAIL_FAILED';

export type ChangeEmailAction = {
  type: typeof CHANGE_EMAIL_REQUESTED;
  payload: {
    email: string;
    password: string;
  };
};

export const changeEmailAction = ({
  email,
  password,
}: {
  email: string;
  password: string;
}): ChangeEmailAction => ({
  type: CHANGE_EMAIL_REQUESTED,
  payload: { email, password },
});
