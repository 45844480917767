import { httpsCallable } from 'firebase/functions';
import { FirebaseClient } from '.';
import { FetchLatestAction } from '../stores/other/fetch_latest_action';

export async function doFetchLatest(action: FetchLatestAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: FETCH LATEST RESOURCES');
    console.log(action);
    console.groupEnd();
  }

  try {
    const result = await httpsCallable(
      FirebaseClient.functions,
      'fetchLatest',
    )();
    const sessions = result.data;
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: FETCH LATEST SUCCEEDED');
      console.log('Active Sessions', sessions);
      console.groupEnd();
    }
    return sessions;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERRROR: FETCH LATEST FAILED');
      console.error(error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
}
