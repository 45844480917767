import { sendPasswordResetEmail } from 'firebase/auth';
import { FirebaseClient } from '../firebase';

export const doResetPassword = async ({ email }: { email: string }) => {
  console.group('FIREBASE: RESET PASSWORD');
  console.groupEnd();

  try {
    await sendPasswordResetEmail(FirebaseClient.auth, email);
    console.group('FIREBASE: RESET PASSWORD');
    console.groupEnd();
    return { hasError: false };
  } catch (error) {
    console.group('FIREBASE ERROR: CREATE ACCOUNT WITH EMAIL FAILED');
    console.error('Error: ', error);
    console.groupEnd();
    return { hasError: true, error };
  }
};
