/**
 * MEASURE SPECIFIC TEXT *******************************************************
 */

import {
  CHARACTER_STRENGTHS_MEASURE,
  FLOURISHING_MEASURE,
  GRATITUDE_MEASURE,
  MEANING_MEASURE,
} from '../stores/measure/measure_type';

export const MEASURE_TEXT = {
  [FLOURISHING_MEASURE]: {
    title: 'Flourishing Measure',
    about: `The Flourishing Measure helps you to identify your level of flourishing. It includes six domains: Happiness and Life Satisfaction, Mental and Physical Health, Meaning and Purpose, Character and Virtue, Close Social Relationships, and Financial and Material Stability. The questions were selected primarily from among existing questions that have received some empirical validation and that are widely used in the well-being literature.`,
    href: '/measures/flourishing',
    iconBurgundy: '/icons/flourishing-burgundy.svg',
    icon: 'flourishing.svg',
    iconWhite: '/images/flourishing-measure-icon-white.svg',
    img: 'https://images.unsplash.com/photo-1458014854819-1a40aa70211c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',

    instructions: `The flourishing measure is the main measure that helps track your overall level of flourishing. The measure consists of two questions in each of six domains (Happiness and Life Satisfaction, Mental and Physical Health, Meaning and Purpose, Character and Virtue, Close Social Relationships, and Financial and Material Stability).`,

    prompt: `Take a few moments to collect yourself and to think about your life over the past several weeks. When you are ready, you may proceed below to answer the 12 questions about flourishing. Responses are in the range from 0-10.`,
    refs: [
      {
        text: `Website: https://hfh.fas.harvard.edu/measuring-flourishing`,
        link: 'https://hfh.fas.harvard.edu/measuring-flourishing',
      },
      {
        text: `VanderWeele, T.J. (2017). On the promotion of human flourishing. Proceedings of the National Academy of Sciences, U.S.A., 31:8148-8156.`,
        link: 'https://www.pnas.org/content/114/31/8148',
      },
    ],
  },
  [MEANING_MEASURE]: {
    title: 'Meaning Measure',
    about: `The Comprehensive Measure of Meaning (CMM) incorporates results of philosophical discussion into prior psychology frameworks around meaning. The Comprehensive Measure of Meaning principally makes use of a wide variety of items, or their adaptation, already in use from previous scales, but categorizes these in ways consistent with important distinctions derived from the philosophical literature. `,

    href: '/measures/meaning',
    iconBurgundy: '/icons/meaning-burgundy.svg',
    iconWhite: '/images/meaning-measure-icon-white.svg',
    icon: 'meaning.svg',
    img: 'https://images.unsplash.com/photo-1590220689040-e46f55817a2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2251&q=80',

    instructions: `The Comprehensive Measure of Meaning assess different aspects of one’s sense of meaning and purpose in life. `,
    prompt: `Take a few moments to collect yourself and to think about your life over the past several weeks. When you are ready, you may proceed below to answer the questions below. Your responses will range from 1 (strongly disagree) to 7 (strongly agree). `,
    refs: [
      {
        text: `Website: https://hfh.fas.harvard.edu/other-well-being-measures`,
        link: '',
      },
      {
        text: `Hanson, J.A. and VanderWeele, T.J. (2020). The Comprehensive Measure of Meaning: psychological and philosophical foundations. In: M. Lee, L.D. Kubzansky, and T.J. VanderWeele (Eds.). Measuring Well-Being: Interdisciplinary Perspectives from the Social Sciences and the Humanities. Oxford University Press, forthcoming.`,
        link: '',
      },
    ],
  },
  [GRATITUDE_MEASURE]: {
    title: 'Gratitude Measure',
    about: `The Gratitude Measure comprises a simple survey that can be completed in just a few minutes and has been used across hundreds of research papers.`,
    href: '/measures/gratitude',
    icon: 'gratitude.svg',
    iconBurgundy: '/icons/gratitude-burgundy.svg',
    iconWhite: '/images/gratitude-measure-icon-white.svg',
    img: 'https://images.unsplash.com/photo-1564515419027-d6b6b7bfe8fb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1275&q=80',

    instructions: `Please take a few moments to clear your mind. When you are ready please proceed below to answer the questions. `,

    prompt: `Spend a few moments thinking about the past several days and weeks and what you have been grateful for as well as your general sense of gratitude. When ready, you may answer the questions below. Responses are in the range from 1 (strongly disagree) to 7 (strongly agree). `,

    refs: [
      // {
      //   text: `Website: http://www.midss.org/content/gratitude-questionaire-gq-6`,
      //   link: 'http://www.midss.org/content/gratitude-questionaire-gq-6',
      // },
      {
        text: `Gray, S. A., Emmons, R. A., & Morrison, A. (2001 August). Distinguishing gratitude from indebtedness in affect and action tendencies. Poster presented at the annua l mee ting of the American Psychological Association, San Francisco, CA.`,
        link: 'https://www.midss.org/content/gratitude-questionaire-gq-6',
      },
      {
        text: `McCullough, M. E., Emmons, R. A., & Tsang, J. (2002). The grateful disposition: A conceptual and empirical  topo graphy. Journal of Personality and Social Psychology, 82, 112-127. https://doi.org/10.1037/0022-3514.86.2.295`,
        link: 'https://content.apa.org/doi/10.1037/0022-3514.82.1.112',
      },
      {
        text: `McCullough, M. E., Tsang, J., & Emmons, R. A. (2004). Gratitude in intermediate affective terrain: Links of grateful moods to individual differences and daily emotiona l exp erience. Journal of Personality and Social Psychology, 86, 295-309. https://doi.org/10.1037/0022-3514.86.2.295`,
        link: 'https://doi.org/10.1037/0022-3514.86.2.295',
      },
    ],
  },
  [CHARACTER_STRENGTHS_MEASURE]: {
    title: 'Character Strengths Measure',
    about: `The Character Strength measure identifies the degree of each of the twenty-four character strengths. These character strengths are cross-cultural and everyone has some degree of each of them. These strengths are clustered around six virtue categories: Courage (bravery, honesty, zest, perseverance), Humanity (kindness, love, social intelligence), Justice (fairness, leadership, teamwork), Temperance (forgiveness, humility, self-regulation, prudence), Transcendence (appreciation of beauty and excellence, gratitude, hope, humor, spirituality), and Wisdom (creativity, curiosity, judgment, love of learning, perspective).`,
    href: '/measures/character-strengths',
    icon: 'character.svg',
    iconBurgundy: '/icons/character-burgundy.svg',
    iconWhite: '/images/character-measure-icon-white.svg',
    img: 'https://images.unsplash.com/photo-1521220546621-cf34a1165c67?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2555&q=80',

    link: 'https://www.viacharacter.org/character-strengths',

    instructions: `The Character Strengths measure identifies your top five character strengths as well as the degree of each strength.`,
    prompt: `To find your character strengths you will need to visit an external website where you will also be required to create an account before being able to take the needed survey.`,

    refs: [
      {
        text: `Website: https://www.viacharacter.org/character-strengths`,
        link: 'https://www.viacharacter.org/character-strengths',
      },
      {
        text: `Research Findings: https://www.viacharacter.org/research/findings`,
        link: 'https://www.viacharacter.org/research/findings',
      },
    ],
  },
};

export const CHARACTER_STRENGTHS_OPTIONS = [
  'Appreciation of beauty and excellence',
  'Bravery',
  'Citizenship',
  'Creativity',
  'Curiosity',
  'Fairness',
  'Forgiveness',
  'Gratitude',
  'Honesty',
  'Hope',
  'Humility',
  'Humor',
  'Judgment',
  'Kindness',
  'Leadership',
  'Love',
  'Love of learning',
  'Perseverance',
  'Perspective',
  'Prudence',
  'Self-regulation',
  'Social intelligence',
  'Spirituality',
  // 'Teamwork',
  'Zest',
].map((s, i) => ({ key: i, text: s, value: i }));

function makeFlourishingAnswers(a1, a2) {
  return [
    { key: 0, text: `0 - ${a1}`, value: 0 },
    { key: 1, text: '1', value: 1 },
    { key: 2, text: '2', value: 2 },
    { key: 3, text: '3', value: 3 },
    { key: 4, text: '4', value: 4 },
    { key: 5, text: '5', value: 5 },
    { key: 6, text: '6', value: 6 },
    { key: 7, text: '7', value: 7 },
    { key: 8, text: '8', value: 8 },
    { key: 9, text: '9', value: 9 },
    { key: 10, text: `10 - ${a2}`, value: 10 },
  ];
}

export const FLOURISHING_MEASURE_SURVEY_QUESTIONS = [
  {
    d: 'Happiness and Life Satisfaction',
    q: 'Overall, how satisfied are you with life as a whole these days?',
    a: makeFlourishingAnswers('Not Satisfied at all', 'Completely Satisfied'),
  },
  {
    d: 'Happiness and Life Satisfaction',
    q: 'In general, how happy or unhappy do you usually feel?',
    a: makeFlourishingAnswers('Extremely Unhappy', 'Extremely Happy'),
  },
  {
    d: 'Mental and Physical Health',
    q: 'In general, how would you rate your physical health?',
    a: makeFlourishingAnswers('Poor', 'Excellent'),
  },
  {
    d: 'Mental and Physical Health',
    q: 'How would you rate your overall mental health?',
    a: makeFlourishingAnswers('Poor', 'Excellent'),
  },
  {
    d: 'Meaning and Purpose',
    q: 'Overall, to what extent do you feel the things you do in your life are worthwhile?',
    a: makeFlourishingAnswers('Not at All Worthwhile', 'Completely Worthwhile'),
  },
  {
    d: 'Meaning and Purpose',
    q: 'I understand my purpose in life?',
    a: makeFlourishingAnswers('Strongly Disagree', 'Strongly Agree'),
  },
  {
    d: 'Character and Virtue',
    q: 'I always act to promote good in all circumstances, even in difficult and challenging situations?',
    a: makeFlourishingAnswers('Not True of Me', 'Completely True of Me'),
  },
  {
    d: 'Character and Virtue',
    q: 'I am always able to give up some happiness now for greater happiness later?',
    a: makeFlourishingAnswers('Not True of Me', 'Completely True of Me'),
  },
  {
    d: 'Close Social relationships',
    q: 'I am content with my friendships and relationships?',
    a: makeFlourishingAnswers('Strongly Disagree', 'Strongly Agree'),
  },
  {
    d: 'Close Social relationships',
    q: 'My relationships are as satisfying as I would want them to be?',
    a: makeFlourishingAnswers('Strongly Disagree', 'Strongly Agree'),
  },
  {
    d: 'Financial and Material Stability',
    q: 'How often do you worry about being able to meet normal monthly living expenses?',
    a: makeFlourishingAnswers('Worry All of the Time', 'Do Not Ever Worry'),
  },
  {
    d: 'Financial and Material Stability',
    q: 'How often do you worry about safety, food, or housing?',
    a: makeFlourishingAnswers('Worry All of the Time', 'Do Not Ever Worry'),
  },
];

export const GRATITUDE_MEANING_MEASURE_SURVEY_ANSWERS = [
  { key: 0, text: '1 - strongly disagree', value: 1 },
  { key: 1, text: '2 - disagree', value: 2 },
  { key: 2, text: '3 - slightly disagree', value: 3 },
  { key: 3, text: '4 - neither', value: 4 },
  { key: 4, text: '5 - slight agree', value: 5 },
  { key: 5, text: '6 - agree', value: 6 },
  { key: 6, text: '7 - strongly agree', value: 7 },
];

export const GRATITUDE_MEASURE_SURVEY_QUESTIONS = [
  'I have so much in life to be thankful for.',
  'If I had to list everything that I felt grateful for, it would be a very long list.',
  `When I look at the world, I don't see much to be grateful for.`,
  `I am grateful to a wide variety of people.`,
  `As I get older I find myself more able to appreciate the people, events, and situations that have been part of my life history.`,
  `Long amounts of time can go by before I feel grateful to something or someone.`,
].map((s) => ({ q: s, a: GRATITUDE_MEANING_MEASURE_SURVEY_ANSWERS }));

export const MEANING_MEASURE_SURVEY_QUESTIONS = [
  `I have a clear understanding of the ultimate meaning of life.`,
  `The meaning of life in the world around us is evident to me.`,
  `I have a framework that allows me to understand or make sense of human life.`,
  `I understand my life's meaning.`,
  `I can make sense of the things that happen in my life.`,
  `I have a philosophy of life that helps me understand who I am.`,
  `I am living the kind of meaningful life I want to live.`,
  `Living is deeply fulfilling.`,
  `I feel like I have found a really significant meaning in my life.`,
  `The things that I do are important to other people.`,
  `I have accomplished much in life as a whole.`,
  `I make a significant contribution to society.`,
  `I have been aware of an all-encompassing and consuming purpose toward which my life has been directed.`,
  `I have a sense of mission or calling.`,
  `I have a mission in life that gives me a sense of direction.`,
  `I have a sense of direction and purpose in my life.`,
  `I can describe my life's purposes.`,
  `My current aims match with my future aspirations.`,
  `In my life I have very clear goals and aims.`,
  `I have goals in life that are very important to me.`,
  `I have definite ideas of things I want to do.`,
].map((s) => ({ q: s, a: GRATITUDE_MEANING_MEASURE_SURVEY_ANSWERS }));

export const QUESTIONS = {
  [FLOURISHING_MEASURE]: FLOURISHING_MEASURE_SURVEY_QUESTIONS,
  [GRATITUDE_MEASURE]: GRATITUDE_MEASURE_SURVEY_QUESTIONS,
  [MEANING_MEASURE]: MEANING_MEASURE_SURVEY_QUESTIONS,
};

export const MAX_SCORES = {
  [FLOURISHING_MEASURE]: 10 * 12,
  [MEANING_MEASURE]: 7 * 21,
  [GRATITUDE_MEASURE]: 7 * 6,
};
