/**
 * AppActivity Page
 * - shown when SignedIn and Activity is not Active
 *
 */
import { RouterProps, RouteComponentProps } from 'react-router';
import { TEXT } from '../../text/activities';
import { ErrorBoundary } from '../../router/ErrorBoundary';
import { ACTIVITY_IMAGES } from '../../assets/activity_images';
import { getUrlFromType } from '../../utils/get_url_from_type';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { newSessionAction } from '../../stores/session/new_session_action_creators';
import { useEffect, useState } from 'react';
import { getTypeFromUrl } from '../../utils/get_type_from_url';
import { ACTIVITIES, GRATITUDE_ACTIVITY } from '../../stores/activity/activity';
import { AppState } from '../../types';
import { DisclosurePanel } from '../about/Activities';
import { useMutation } from 'react-query';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  UserCredential,
} from 'firebase/auth';
import { FirebaseClient } from '../../firebase';
import { Formik, Form, Field } from 'formik';
import { SIGN_IN_WITH_EMAIL_SUCCEEDED } from '../../auth/sign_in_with_email_action';
import { Spinner } from '../../components/Spinner';
import { signInWithGoogle } from '../../stores/account';
import { SIGN_IN_SUCCESS } from '../../stores/account/account_types';

type Props = {} & RouterProps & RouteComponentProps<{ type: string }>;

export const AboutActivity = (props: Props) => {
  const dispatch = useDispatch();
  const params = props.match.params;
  const type = getTypeFromUrl(params.type, props.match.path) || '';
  // console.log('type', type);

  const isSignedIn = useSelector((state: AppState) => state.account.isSignedIn);

  // const { data: session, isLoading } = useActiveSession({ type });
  // console.log('session', session);

  const activity = ACTIVITIES[type];
  const { title } = activity;
  const {
    instructions,
    exercise,
    hasBaseline,
    header,
    general,
    quotation,
    science,
  } = TEXT[type];

  const activityUrl = getUrlFromType(type, true);
  const toUrl = hasBaseline
    ? `/app/activities/${activityUrl}/new/baseline`
    : `/app/activities/${activityUrl}/new/reflection`;

  const img = ACTIVITY_IMAGES[type];

  useEffect(() => {
    dispatch(newSessionAction({ type }));
  }, [dispatch, type]);

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{header}</title>
      </Helmet>
      <div className='relative min-h-screen'>
        <div
          className='relative h-96 w-full bg-center bg-cover'
          style={{ backgroundImage: `url(${img})` }}>
          <div className='absolute h-full w-full bg-black opacity-50' />
          <div className='h-64 flex flex-col justify-center'>
            <p className='relative px-2 xl:px-0 mx-auto max-w-7xl text-white text-xs leading-160 md:text-base md:leading-160 text-center whitespace-pre-wrap'>
              {quotation}
            </p>
          </div>
        </div>
        <div className='relative z-10 px-4 md:px-20 py-10 -mt-32 mx-auto max-w-7xl grid grid-cols-1 place-item-center gap-8 bg-white text-center'>
          <header>
            <h2 className='text-burgundy text-sm leading-160'>{exercise}</h2>
            <h1 className='text-burgundy text-5xl leading-160'>{header}</h1>
            <h3 className='text-burgundy text-sm leading-160'>
              Duration: 6 weeks
            </h3>
            <h3 className='text-burgundy text-sm leading-160'>
              {' '}
              Frequency:{' '}
              {type === GRATITUDE_ACTIVITY
                ? '3 times per week'
                : 'once per week'}
            </h3>
          </header>
          <div className='space-y-5'>
            <p className='text-base leading-160 whitespace-pre-line'>
              {general}
            </p>
            {/* <p className='text-base leading-160 whitespace-pre-line'>
              {instructions}
            </p> */}
          </div>
          {!!isSignedIn && (
            <Link
              className='mx-auto my-10 px-36 py-2 border border-burgundy text-burgundy rounded-full'
              to={toUrl}>
              Start Activity
            </Link>
          )}

          <section className='relative px-5 py-20 mx-auto max-w-7xl text-center space-y-5 overflow-x-auto'>
            <h2>Science</h2>
            <ul className='space-y-3'>
              {science.map((text, i) => {
                return (
                  <li className='text-sm text-not-black' key={i}>
                    {text}
                  </li>
                );
              })}
            </ul>
          </section>
          <div className='py-32' />
        </div>
        <div className='-mt-96'>{!isSignedIn && <CTA />}</div>
      </div>

      <section>
        <DisclosurePanel
          title='Overview'
          type={type}
          section='instructions'
          defaultOpen={true}
        />
        <DisclosurePanel
          title='Getting Started'
          type={type}
          section='gettingStarted'
        />
        <DisclosurePanel title='First Entry' type={type} section='firstEntry' />
        <DisclosurePanel
          title='Weekly Instructions'
          type={type}
          section='weeklyInstructions'
        />
        <DisclosurePanel
          title='Science Behind the Activity'
          type={type}
          section='science'
        />
      </section>
      <div className='py-14' />
    </ErrorBoundary>
  );
};

interface Values {
  email: string;
  password: string;
}

// TODO: make this the general component for sign-in/sign-up and error handling for forgotten passwords, etc; creating a new password would be done via a different set of screens/views

export const CTA = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');

  // TODO: create account via email
  const createAccountWithEmailMutation = useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      const response = await createUserWithEmailAndPassword(
        FirebaseClient.auth,
        email,
        password,
      );
      return response;
    },
    {
      onSuccess: (response: UserCredential) => {
        if (!response?.user?.emailVerified) {
          sendEmailVerification(response.user);
        }
        dispatch({ type: SIGN_IN_WITH_EMAIL_SUCCEEDED, payload: response });
        dispatch({ type: SIGN_IN_SUCCESS, payload: response });
      },
      onError: (error: any) => {
        console.error('onError', error);
        // FIXME: finish this pattern
        setError(error?.message || error);
      },
    },
  );

  // TODO: sign in via email
  const emailSignInMutation = useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      const response = await signInWithEmailAndPassword(
        FirebaseClient.auth,
        email,
        password,
      );
      // TODO: account reducer stuff
      console.log('Sign in with email', response);

      return response;
    },
    {
      onSuccess: (response) => {
        console.log('onSuccess', response);
        dispatch({ type: SIGN_IN_WITH_EMAIL_SUCCEEDED, payload: response });
        dispatch({ type: SIGN_IN_SUCCESS, payload: response });
      },
      onError: (error: any) => {
        console.error('onError', error);
        // FIXME: finish this pattern
        setError(error?.message || error);
      },
    },
  );

  return (
    <div className='relative pb-10 lg:h-800'>
      {/* TODO: only show this on the sign-in/up pages not as a CTA */}

      {/* White Form  */}
      <div className='relative h700 z-10 pt-24 mx-4 lg:mx-auto max-w-4xl grid grid-cols-1  place-items-center text-burgundy'>
        <div className='px-9 py-10 bg-white w-full h-full lg:p-20 shadow'>
          <h1 className='text-3xl leading-9 lg:text-6xl text-left'>
            Sign up and start flourishing
          </h1>
          <div className='pt-5' />
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={(values: Values) => {
              const { email, password } = values;

              createAccountWithEmailMutation.mutate({ email, password });
            }}>
            <Form className='space-y-5' translate='yes'>
              <div className='md:space-y-5'>
                <label
                  htmlFor='email'
                  className='block text-xs md:text-base leading-160 text-left'>
                  Email address
                </label>
                <div className='mt-1 rounded-full shadow-sm'>
                  <Field
                    id='email'
                    name='email'
                    type='email'
                    placeholder='hello@flourishing.app'
                    autoComplete='username'
                    required
                    className='appearance-none block w-full px-3 py-2 border border-burgundy rounded-full placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                  />
                </div>
              </div>

              <div className='md:space-y-5'>
                <label
                  htmlFor='password'
                  className='block text-xs md:text-base leading-160 text-left'>
                  Password
                </label>
                <div className='mt-1 rounded-full shadow-sm'>
                  <Field
                    id='password'
                    name='password'
                    type='password'
                    autoComplete='current-password'
                    required
                    className='appearance-none block w-full px-3 py-2 border border-burgundy rounded-full placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                  />
                </div>
              </div>
              {/* <button type='submit'>Submit</button> */}
              {error.length > 0 && <div>{error}</div>}
              <button
                type='submit'
                className='mt-2 w-full flex justify-center py-2 px-4 border border-transparent text-xs md:text-base leading-160 rounded-full text-white bg-burgundy'>
                {(createAccountWithEmailMutation.isLoading ||
                  emailSignInMutation.isLoading) && <Spinner />}
                Sign up
              </button>
            </Form>
          </Formik>
          {/* Continue with Google */}
          <div className='mt-6'>
            <div className='relative'>
              <div className='relative flex justify-center'>
                <span className='px-2 text-xs md:text-base leading-160 bg-white'>
                  Or
                </span>
              </div>
            </div>
            <div className='mt-4'>
              <button
                className='relative flex items-center bg-white px-2 rounded-full border border-burgundy w-full'
                onClick={() => dispatch(signInWithGoogle())}>
                <img
                  className='absolute left-2 p-2 h-10 w-10'
                  src='/google.svg'
                  alt='Google Logo'
                />
                <p className='flex-grow p-2 text-xs md:text-base leading-160 text-burgundy'>
                  Continue with Google
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
