export const SIGN_IN_WITH_EMAIL_REQUESTED = 'SIGN_IN_WITH_EMAIL_REQUESTED';
export const SIGN_IN_WITH_EMAIL_SUCCEEDED = 'SIGN_IN_WITH_EMAIL_SUCCEEDED';
export const SIGN_IN_WITH_EMAIL_FAILED = 'SIGN_IN_WITH_EMAIL_FAILED';

export type SignInAccountWithEmailAction = {
  type: typeof SIGN_IN_WITH_EMAIL_REQUESTED;
  payload: {
    email: string;
    password: string;
  };
};

export const signInAccountWithEmailAction = ({
  email,
  password,
}: {
  email: string;
  password: string;
}): SignInAccountWithEmailAction => ({
  type: SIGN_IN_WITH_EMAIL_REQUESTED,
  payload: { email, password },
});
