import { ChevronDoubleDownIcon } from '@heroicons/react/outline';
import React from 'react';
import { Link } from 'react-router-dom';

const Dot = () => (
  <div className='flex-shrink-0 bg-burgundy mx-4 h-10 w-10 rounded-full' />
);

const Text = ({ text }: { text: string }) => (
  <p
    className='flex-shrink-0 text-xs text-center text-white py-3 bg-burgundy rounded-full whitespace-nowrap'
    style={{ width: 250 }}>
    {text}
  </p>
);

export const Hero = () => {
  return (
    <div
      className='relative text-burgundy bg-not-white overflow-hidden'
      // style={{ minHeight: 744 }}
    >
      <div className='absolute bottom-10 w-full'>
        <div
          className='flex justify-center'
          onClick={() => window.scrollTo({ top: 600, behavior: 'smooth' })}>
          <ChevronDoubleDownIcon className='h-5 text-burgundy' />
        </div>
      </div>
      <div className='mx-auto max-w-7xl pt-4 lg:pt-10 pb-20 grid grid-cols-1 lg:grid-cols-12 place-items-center'>
        <div className='relative col-span-5'>
          <img
            className='h-24 w-24 md:h-96 md:w-96 lg:h-500 lg:w-500 mx-auto my-auto'
            src={'/images/hero-logo.png'}
            alt='Human flourishing program logo'
          />
        </div>
        <div className='col-span-7 space-y-8'>
          {/* Desktop Header */}
          <header className='hidden md:block py-10 md:py-0 px-4 space-y-8'>
            <h3 className='mt-3 text-sm text-center lg:text-left'>
              Brought to you by{' '}
              <a
                className='font-bold'
                href='https://hfh.fas.harvard.edu/'
                target='_blank'
                rel='noopener noreferrer'>
                The Human Flourishing Program
              </a>{' '}
              at
              <br />
              Harvard University's Institute for Quantitative Social Science.
            </h3>
            {/*  */}
            <h1 className='text-6xl sm:text-7xl text-center lg:text-left'>
              The Flourishing App
            </h1>
            {/*  */}
            <h3 className='text-center lg:text-left'>
              The App that helps you with
            </h3>
          </header>
          {/* Mobile Header */}
          <header className='md:hidden py10 px-4'>
            <h1
              className='text-center'
              style={{ fontSize: 52, lineHeight: '62px' }}>
              The <br /> Flourishing <br /> App
            </h1>
            <h3 className='mt-14 text-sm text-center leading-160'>
              Brought to you by{' '}
              <a
                className='font-bold'
                href='https://hfh.fas.harvard.edu/'
                target='_blank'
                rel='noopener noreferrer'>
                The Human Flourishing Program
              </a>{' '}
              at Harvard University's Institute for Quantitative Social Science.
            </h3>
            {/*  */}
          </header>

          {/* Desktop View */}
          <div className='hidden md:grid grid-cols-1 lg:place-items-start text-white text-xs leading-5 overflow-hidden'>
            <div className='mt-10 flex items-center justify-center'>
              <Text text='Happiness and Life Satisfaction' />
              <Dot />
              <Text text='Mental and Physical Health' />
              <Dot />
            </div>

            <div className='mt-10 flex justify-center items-center'>
              <Dot />
              <Text text='Meaning and Purpose' />
              <Dot />
              <Text text='Close Social Relationships' />
            </div>

            <div className='mt-10 flex justify-center items-center'>
              <Text text='Financial and Material Stability' />
              <Dot />
              <Text text='Character and Virtue' />
              <Dot />
            </div>
          </div>
          {/* Mobile View */}
          {/* <div className='relative md:hidden'>
            <div className='mt-10 flex flex-wrap items-center justify-center'>
              <Text text='Happiness and Life Satisfaction' />
              <Dot />
            </div>
            <div className='mt-10 flex flex-wrap items-center justify-center'>
              <Dot />
              <Text text='Mental and Physical Health' />
            </div>
            <div className='mt-10 flex justify-center items-center'>
              <Text text='Meaning and Purpose' />
              <Dot />
            </div>
            <div className='mt-10 flex justify-center items-center'>
              <Dot />
              <Text text='Close Social Relationships' />
            </div>
            <div className='mt-10 flex justify-center items-center'>
              <Text text='Financial and Material Stability' />
              <Dot />
            </div>
            <div className='mt-10 flex justify-center items-center'>
              <Dot />
              <Text text='Character and Virtue' />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
