import { signInWithEmailAndPassword } from 'firebase/auth';
import { FirebaseClient } from '../firebase';

export const doSignInWithEmail = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  console.group('FIREBASE: SIGN IN WITH EMAIL REQUESTED');
  console.groupEnd();

  try {
    const userCredential = await signInWithEmailAndPassword(
      FirebaseClient.auth,
      email,
      password,
    );
    console.group('FIREBASE: SIGN IN WITH EMAIL SUCCEEDED');
    console.groupEnd();
    return userCredential;
  } catch (error) {
    console.group('FIREBASE ERROR: SIGN IN WITH EMAIL FAILED');
    console.log('Error: ', error.code);
    console.groupEnd();
    return { hasError: true, error: error.code };
  }
};
