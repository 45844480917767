import {
  CalendarIcon,
  LockOpenIcon,
  CheckCircleIcon,
  LockClosedIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { Session, Entry } from '../../../stores/session/session_types';
import { checkIsAvailable } from '../../../utils/date_checks';
import { getUrlFromType } from '../../../utils/get_url_from_type';

export const Entries = ({
  session,
  status,
}: {
  session: Session;
  status: string;
}) => {
  let entries: Entry[] = [];
  if (status === 'available') {
    entries = checkIsAvailable(session);
  }
  if (status === 'completed') {
    entries = session.entries.filter((e: Entry) => e.completed);
  }
  if (status === 'scheduled') {
    // TODO: need to mark if something has been missed
    const available = checkIsAvailable(session);
    entries = session.entries
      .filter((e) => !e.completed)
      .filter((e) => {
        return available.filter((a) => e.uuid === a.uuid).length === 0;
      });
  }

  const slug = getUrlFromType(session.type, true);

  // Need to show 'Missed' as well
  if (status === 'scheduled' || status === 'missed') {
    return (
      <div className='mt-2 mb-2'>
        <header>
          <h3 className='py-2 text-lg font-bold capitalize'>
            {status} Entries
          </h3>
        </header>
        <ul className='mt-0'>
          {entries.map((entry: Entry) => (
            <li key={entry.uuid} className='m-2'>
              <EntryListItem session={session} entry={entry} status={status} />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className='mt-2 mb-2'>
      <header>
        <h3 className='py-2 text-lg font-bold capitalize'>{status} Entries</h3>
      </header>
      <div className='mt-0 '>
        {entries.map((entry: Entry) => (
          <Link
            key={entry.uuid}
            className='m-2'
            to={`/app/activities/${slug}/sessions/${session.id}/entries/${
              entry.uuid
            }/${status === 'available' ? 'new' : ''}`}>
            <EntryListItem session={session} entry={entry} status={status} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export const EntryListItem = ({
  session,
  entry,
  status,
}: {
  session: Session;
  entry: Entry;
  status: string;
}) => {
  if (status === 'scheduled') {
    //check to see if it was missed
    // if (Date.now() > entry.scheduledDate) {
    //   status = 'missed';
    // }
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log('Entry Card status: ', status);
  }
  // http://localhost:3000/app/activities/gratitude/sessions/veQsqL4FxNUdy7THtxwX/entries/3cbe2f80-1830-4816-94d0-6b51b13cd1b2/new
  // const title = 'Entry';
  // const slug = getUrlFromType(session.type, true);
  // console.log('sessionId', session.id);
  // const to = `/app/activities/${slug}/sessions/${session.id}/entries/${
  //   entry.uuid
  // }/${status === 'available' ? 'new' : ''}`;

  return (
    <div className='p-0 flex items-center justify-between shadow hover:shadow-md'>
      <div className='flex items-center space-x-5'>
        <div className='h-14 w-14 bg-burgundy text-white flex items-center justify-center'>
          {entry.entryNumber + 1}
        </div>
        <h2 className='hidden md:block text-burgundy'>Entry</h2>
        <div className='flex items-center'>
          <CalendarIcon className='h-6 w-6 text-burgundy' />
          <span className='ml-2 text-sm leading-160 font-bold'>
            {new Date(entry.scheduledDate).toLocaleDateString()}
          </span>
        </div>
      </div>
      {/*  */}
      <div className='mr-10 flex items-center space-x-10'>
        <div className='flex items-center space-x-2'>
          {status === 'available' ? (
            <>
              <span className='hidden md:block'>Status: Available</span>
              <LockOpenIcon className='h-6 w-6 text-burgundy border border-burgundy rounded-full p-1' />
            </>
          ) : status === 'completed' ? (
            <>
              <span className='hidden md:block'>Status: Completed</span>
              <CheckCircleIcon className='h-6 w-6 text-burgundy' />
            </>
          ) : (
            <>
              <span className='hidden md:block'>Status: Locked</span>
              <LockClosedIcon className='h-6 w-6 bg-burgundy text-white border border-burgundy rounded-full p-1' />
            </>
          )}
        </div>
        {/* If Scheduled or Completed, otherwise just show the locked icon */}

        {status === 'available' || status === 'completed' ? (
          <>
            <p className='hidden lg:block px-5 py-1 border border-burgundy text-burgundy rounded-full'>
              Go to Entry
            </p>
            <ChevronRightIcon className='block lg:hidden h-5 text-burgundy' />
          </>
        ) : null}
      </div>
    </div>
  );
};
