import React, { Component } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AppState } from '../types';
import { CreditsPage } from '../views/Credits';
import { AccountPage } from '../views/Account';
import { CompletedBaselineEntry } from '../views/activities/completed/CompletedActivitySessionBaselineEntry';
import { HomePage } from '../views/Home';
import { PrivacyPolicy } from '../views/Privacy';
import { WelcomePage } from '../views/Welcome';
import { ActivitiesAbout } from '../views/about/Activities';
import { ExercisesAbout } from '../views/about/ExercisesAbout';
import { ActivityAbout } from '../views/about/deprecated/ActivityAbout';
import { MeasuresAbout } from '../views/about/Measures';
import { MeasureAbout } from '../views/about/MeasureAbout';
import { Dashboard } from '../views/dashboard/Dashboard';
import { AppActivityContainer } from '../views/activities/AppActivityContainer';
import { NewActivitySessionEntry } from '../views/activities/active/NewActivitySessionEntry';
import { NewActivitySessionInstructions } from '../views/activities/intake/deprecated/NewActivityInstructions';
import { NewActivitySessionBaseline } from '../views/activities/intake/NewActivitySessionBaseline';
import { NewActivitySessionReflection } from '../views/activities/intake/NewActivitySessionReflection';
import { NewActivitySessionMeasures } from '../views/activities/intake/NewActivitySessionMeasures';
import { CompletedActivitySessionEntry } from '../views/activities/completed/CompletedActivitySessionEntry';
import { NewActivitySessionSchedule } from '../views/activities/intake/NewActivitySessionSchedule';
import { HistoryPage } from '../views/v2/history/HistoryPage';
import { CompletedActivitySession } from '../views/activities/completed/CompletedActivitySession';
import { CompletedBaselineReflection } from '../views/activities/completed/CompletedActivitySessionBaselineReflection';
import { Updates } from '../views/Updates';
import { ResetPassword } from '../views/auth/ResetPassword';
import { AuthActions } from '../views/AuthActions';
import { Science } from '../views/about/Science';
import { AnonMeasure } from '../views/measures/AnonMeasure';
import { CompletedAnonMeasure } from '../views/measures/CompletedAnonMeasure';
import { CompletedMeasure } from '../views/measures/CompletedMeasureContainer';
import { SignInUpPage } from '../views/auth/SignInUpPage';
import { NewActivityMeasureForm } from '../views/activities/intake/NewActivityMeasureForm';
import { AppMeasure } from '../views/measures/AppMeasure';
import { AboutActivity } from '../views/activities/AboutActivity';
import { Support } from '../views/Support';

const mapStateToProps = (state: AppState) => ({ account: state.account });
const mapDispatchToProps = {};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux {}

class _Router extends Component<Props> {
  state = {
    hasError: false,
  };

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    if (process.env.NODE_ENV !== 'production') {
      console.group('ROUTER ERROR: COMPONENT DID CATCH');
      console.error(error, info);
      console.groupEnd();
    }
  }

  render() {
    // const { account } = this.props;
    if (process.env.NODE_ENV !== 'production') {
      console.group('ROUTER');
      // console.log(this.props);
      console.groupEnd();
    }

    return (
      <Switch>
        <Route exact path='/' component={HomePage} />

        <Route exact path='/__/auth/action' component={AuthActions} />

        <Route path='/credits' component={CreditsPage} />
        <Route path='/partners' component={CreditsPage} />
        <Route path='/privacy' component={PrivacyPolicy} />
        <Route path='/welcome' component={WelcomePage} />
        <Route path='/updates' component={Updates} />
        <Route path='/support' component={Support} />
        <Route path='/science' component={Science} />

        {/* Marketing/About Pages  */}
        <Route exact path='/about' component={WelcomePage} />
        <Route exact path='/about/activities' component={ActivitiesAbout} />
        <Route
          exact
          path='/about/activities/:type'
          component={AboutActivity}
          // component={ActivityAbout}
        />
        <Route
          exact
          path='/about/exercises/:exercise'
          component={ExercisesAbout}
        />
        <Route exact path='/about/measures' component={MeasuresAbout} />
        <Route exact path='/about/measures/:measure' component={MeasureAbout} />
        <Route
          exact
          path='/about/measures/:measure/new'
          component={AnonMeasure}
        />

        {/* Completed ANON Measures */}
        <Route
          exact
          path='/anon/results/:slug/:id'
          component={CompletedAnonMeasure}
        />

        {/* Auth Pages */}
        <Route exact path='/sign-in' component={SignInUpPage} />
        <Route exact path='/sign-up' component={SignInUpPage} />
        <Route exact path='/reset-password' component={ResetPassword} />

        <PrivateRoute>
          <Route exact path='/dashboard' component={Dashboard} />
          <Route path='/account' component={AccountPage} />

          {/* ACTIVITY ------------------------------------------------------- */}
          {/* TODO: this should be a sub-router */}
          <Route
            exact
            path='/app/activities/:type'
            component={AppActivityContainer}
          />
          {/* New Activity Session Routes */}
          {/* TODO: have one controller/parent that takes the 'new' and then has subrouting */}
          <Route
            exact
            path='/app/activities/:type/new'
            component={NewActivitySessionInstructions}
          />

          <Route
            exact
            path='/app/activities/:type/new/baseline'
            component={NewActivitySessionBaseline}
          />
          <Route
            exact
            path='/app/activities/:type/new/reflection'
            component={NewActivitySessionReflection}
          />
          <Route
            exact
            path='/app/activities/:type/new/measures/'
            component={NewActivitySessionMeasures}
          />
          <Route
            exact
            path='/app/activities/:activity/new/measures/:measure'
            component={NewActivityMeasureForm}
            // component={NewMeasureEntry}
          />
          <Route
            exact
            path='/app/activities/:type/new/schedule'
            component={NewActivitySessionSchedule}
          />
          {/* New Activity Session Entries --------------------------------- */}
          <Route
            exact
            path='/app/activities/:type/sessions/:sid/entries/:eid/new'
            component={NewActivitySessionEntry}
          />
          {/* Completed Activity Session Views ----------------------------- */}
          <Route
            exact
            path='/app/activities/:type/sessions/:sid'
            component={CompletedActivitySession}
          />
          <Route
            exact
            path='/app/activities/:type/sessions/:sid/baseline'
            component={CompletedBaselineEntry}
          />
          <Route
            exact
            path='/app/activities/:type/sessions/:sid/reflection'
            component={CompletedBaselineReflection}
          />
          <Route
            exact
            path='/app/activities/:type/sessions/:sid/measures/:measure/:id'
            component={CompletedMeasure}
          />
          <Route
            exact
            path='/app/activities/:type/sessions/:sid/entries/:eid'
            component={CompletedActivitySessionEntry}
          />
          {/* SESSION HISTORY ------------------------------------------------ */}
          <Route path='/history' component={HistoryPage} />

          {/* MEASURES ------------------------------------------------------- */}
          <Route exact path='/app/measures' component={MeasuresAbout} />
          <Route
            exact
            path='/app/measures/:measure/'
            component={MeasureAbout}
          />
          <Route
            exact
            path='/app/measures/:measure/new'
            // component={NewMeasureEntry}
            component={AppMeasure}
          />
          <Route
            exact
            path='/app/measures/:slug/entries/:id'
            component={CompletedMeasure}
          />
        </PrivateRoute>

        <Route path='/:slug'>
          <Redirect to='/' />
        </Route>
      </Switch>
    );
  }
}

export const Router = connector(_Router);

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state: AppState) => state.account.isSignedIn);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
