import { ACTIVITY_TYPE } from '../activity/activity';

export const FETCH_SESSIONS_REQUESTED = 'FETCH_SESSIONS_REQUESTED';
export const FETCH_SESSIONS_SUCCEEDED = 'FETCH_SESSIONS_SUCCEEDED';
export const FETCH_SESSIONS_FAILED = 'FETCH_SESSIONS_FAILED';

export type FetchSessionsActionPayload = {
  type?: ACTIVITY_TYPE;
  limit?: number;
  offset?: string;
  startedAt?: string;
  orderBy?: string;
};
export type FetchSessionsAction = {
  type: typeof FETCH_SESSIONS_REQUESTED;
  payload: FetchSessionsActionPayload;
};
export const fetchSessionsAction = (payload): FetchSessionsAction => ({
  type: FETCH_SESSIONS_REQUESTED,
  payload,
});
