import { UserCredential } from 'firebase/auth';
import { isNull, isUndefined } from 'lodash';
import { CREATE_ACCOUNT_WITH_EMAIL_SUCCEEDED } from '../../auth/create_account_with_email_action';
import {
  SIGN_IN_WITH_EMAIL_FAILED,
  SIGN_IN_WITH_EMAIL_SUCCEEDED,
} from '../../auth/sign_in_with_email_action';
import firebase from '../../firebase/firebase_client';
import {
  AccountState,
  DELETE_ACCOUNT_SUCCEEDED,
  SIGN_IN_GOOGLE_FAILED,
  SIGN_IN_GOOGLE_SUCCEEDED,
  SIGN_OUT_REQUESTED,
} from './account_types';
import { AUTH_STATE_CHANGED, USER_SYNC_SUCCEEDED } from './auth_state_action';
import { CHANGE_EMAIL_SUCCEEDED } from './change_email_action';
import { FETCH_ACCOUNT_SUCCEEDED } from './fetch_account';
import { WEEKLY_EMAILS_SUCCEEDED } from './weekly_email_reminders_action';

const initialState: AccountState = {
  uid: '',
  fullname: '',
  email: '',
  isEmailVerified: false,
  photoUrl: '',
  isSignedIn: false,
  type: '',
  allowAppNotification: false,
  signInError: {
    hasError: false,
    error: '',
  },
  weeklyEmailReminders: false,
};
export { initialState as accountInitialState };

export const accountReducer = (
  state: AccountState = initialState,
  action: any,
): AccountState => {
  switch (action.type) {
    case FETCH_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
        isEmailVerified: action.payload.emailVerified,
      };
    //
    case AUTH_STATE_CHANGED:
    case USER_SYNC_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('AUTH STATE CHANGE REDUCER');
        console.log(action.payload);
        console.groupEnd();
      }
      //make sure local state matches firebase server return values
      state = {
        ...state,
        email: action.payload.email,
        isEmailVerified: action.payloadisEmailVerified,
      };

      return state;
    //
    case WEEKLY_EMAILS_SUCCEEDED:
      return { ...state, weeklyEmailReminders: action.payload };
    //
    case CHANGE_EMAIL_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('CHANGE EMAIL SUCCEEDED REDUCER');
        console.log(
          action.payload,
          state,
          action.payload.email === state.email,
        );
        console.groupEnd();
      }
      //always update using firebase cache
      //if email changed, update email
      state = {
        ...state,
        email: firebase.auth.currentUser?.email || action.payload.email,
        isEmailVerified:
          firebase.auth.currentUser?.emailVerified || state.isEmailVerified,
      };
      // if (action.payload.email !== state.email) {
      //   state = {
      //     ...state,
      //     email: action.payload.email,
      //     isEmailVerified: action.payload.isEmailVerified,
      //   };
      // }
      return state;
    //
    case DELETE_ACCOUNT_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: DELETE ACCOUNT SUCCEEDED');
        console.groupEnd();
      }
      return initialState;

    // EMAIL -------------------------------------------------------------------
    case CREATE_ACCOUNT_WITH_EMAIL_SUCCEEDED:
    case SIGN_IN_WITH_EMAIL_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: EMAIL ACCOUNT SUCCEEDED');
        console.groupEnd();
      }
      const emailUser = action.payload as UserCredential;
      if (isUndefined(emailUser.user) || isNull(emailUser.user)) return state;

      return {
        ...state,
        email: emailUser.user.email || '', //TODO: add email verified field
        isEmailVerified: emailUser.user.emailVerified || false,
        fullname: emailUser.user.displayName || '',
        isSignedIn: true,
        photoUrl: emailUser.user.photoURL || '',
        type: 'EMAIL_ACCOUNT',
        uid: emailUser.user.uid,
        allowAppNotification: true,
        signInError: {
          hasError: false,
          error: '',
        },
        // weeklyEmailReminders: false,
      };

    case SIGN_IN_WITH_EMAIL_FAILED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER ERROR: SIGN WITH EMAIL ACCOUNT FAILED');
        console.log(action);
        console.groupEnd();
      }
      return {
        ...state,
        email: '',
        isEmailVerified: false,
        fullname: '',
        isSignedIn: false,
        photoUrl: '',
        type: '',
        uid: '',
        allowAppNotification: false,
        signInError: {
          hasError: true,
          error: action.payload,
        },
        // weeklyEmailReminders: false,
      };

    // GOOGLE ------------------------------------------------------------------
    case SIGN_IN_GOOGLE_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: SIGN IN VIA GOOGLE SUCCEEDED');
        console.log(state, action);
        console.groupEnd();
      }
      const googleUser = action.payload as UserCredential;
      if (googleUser.user === null) return state;

      return {
        ...state,
        email: googleUser.user.email || '',
        isEmailVerified: googleUser.user.emailVerified,
        fullname: googleUser.user.displayName || '',
        isSignedIn: true,
        photoUrl: googleUser.user.photoURL || '',
        type: 'GOOGLE_ACCOUNT',
        uid: googleUser.user.uid,
        allowAppNotification: true,
        signInError: {
          hasError: false,
          error: '',
        },
        // weeklyEmailReminders: false,
      };

    case SIGN_IN_GOOGLE_FAILED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: SIGN IN VIA GOOGLE FAILED');
        console.error(SIGN_IN_GOOGLE_FAILED, state, action);
        console.groupEnd();
      }
      return initialState; //FIXME: why?

    // SIGN OUT-----------------------------------------------------------------
    case SIGN_OUT_REQUESTED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: SIGN OUT REQUESTED');
        console.groupEnd();
      }
      return initialState;

    // NB: this is handled in the top most reducer
    // case SIGN_OUT_SUCCEEDED:

    default:
      return state;
  }
};
