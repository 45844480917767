import { all, call, put, takeEvery } from 'redux-saga/effects';
import { doCreateEntry } from '../../firebase';
import {
  CreateEntryAction,
  CREATE_ENTRY_FAILED,
  CREATE_ENTRY_REQUESTED,
  CREATE_ENTRY_SUCCEEDED,
} from './create_entry_action';
import { Session } from './session_types';

function* createEntrySaga(action: CreateEntryAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('SAGA: CREATE ENTRY REQUESTED');
    console.log(action);
    console.groupEnd();
  }
  try {
    const result: Session = yield call(doCreateEntry, action);
    if (process.env.NODE_ENV !== 'production') {
      console.group('SAGA: CREATE ENTRY SUCCEEDED');
      console.log('RESULT', result);
      console.groupEnd();
    }

    yield put({ type: CREATE_ENTRY_SUCCEEDED, payload: result });
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('SAGA ERROR: CREATE ENTRY FAILED');
      console.error('### ERRROR ###', error);
      console.groupEnd();
    }
    yield put({ type: CREATE_ENTRY_FAILED, message: error.message });
  }
  console.groupEnd();
}

export function* watchEntries() {
  yield takeEvery(CREATE_ENTRY_REQUESTED, createEntrySaga);
}

export function* entriesSaga() {
  if (process.env.NODE_ENV !== 'production') {
    console.log('running entries saga');
  }
  yield all([watchEntries()]);
}
