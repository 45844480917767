import React from 'react';
import { Helmet } from 'react-helmet';

export const Support = () => {
  return (
    <div className='relative pb-10 overflow-hidden'>
      <Helmet>
        <title>Support</title>
      </Helmet>
      <header className='py-20 bg-white'>
        <div className='mx-auto max-w-7xl px-4 xl:px-0'>
          <h1 className='text-5xl leading-160'>
            The <span className='text-burgundy'>Flourishing App</span> Support
          </h1>
        </div>
      </header>
      <main className='px-4 xl:px-0 overflow-hidden'>
        <div className='relative max-w-7xl mx-auto py-16'>
          <p>
            Support questions can be directed to flourishingapp at gmail dot com
          </p>
        </div>
      </main>
    </div>
  );
};
