export const SEND_EMAIL_VERIFICATION_REQUESTED =
  'SEND_EMAIL_VERIFICATION_REQUESTED';
export const SEND_EMAIL_VERIFICATION_SUCCEEDED =
  'SEND_EMAIL_VERIFICATION_SUCCEEDED';
export const SEND_EMAIL_VERIFICATION_FAILED = 'SEND_EMAIL_VERIFICATION_FAILED';

export type SendEmailVerificationAction = {
  type: typeof SEND_EMAIL_VERIFICATION_REQUESTED;
  payload: {};
};

export const sendEmailVerificationAction = (): SendEmailVerificationAction => ({
  type: SEND_EMAIL_VERIFICATION_REQUESTED,
  payload: {},
});
