import { httpsCallable } from 'firebase/functions';
import { FetchMeasureAction } from '../stores/measure/fetch_measure_action';
import FirebaseClient from './firebase_client';

/**
 * Fetch a Measure by id.
 * Returns whole Measure object
 * @param action
 */
export async function doFetchMeasure(action: FetchMeasureAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: FETCH MEASURE REQUESTED');
    console.log(action);
    console.groupEnd();
  }
  try {
    const result = (await httpsCallable(
      FirebaseClient.functions,
      'fetchMeasure',
    )(JSON.stringify({ id: action.payload.id }))) as any;
    if (result.data.hasError) throw new Error(result.data);
    const measure = result.data;
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: FETCH MEASURE ENTRY SUCCEEDED');
      console.log(measure);
      console.groupEnd();
    }
    return measure;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: FETCH MEASURE ENTRY FAILED');
      console.error(error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
}
