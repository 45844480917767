export type MetaState = {
  readonly cookieBannerSeen: boolean;
  readonly entryInProgress: boolean;
  readonly intakeInProgress: boolean;
  readonly lastSessionResult: number;
  readonly lastMeasureResult: number;
  readonly historyShowPref: string;
  readonly nSessions: number;
  readonly nDoneSessions: number;
  readonly nMeasures: number;
};

export const createMetaState = () => ({
  cookieBannerSeen: false,
  entryInProgress: false,
  intakeInProgress: false,
  lastSessionResult: 0,
  lastMeasureResult: 0,
  historyShowPref: 'activity',
  nSessions: 0,
  nDoneSessions: 0,
  nMeasures: 0,
});

// export type MetaAction =
//   | CreateTempEntryAction
//   | UpdateTempEntryAction
//   | DeleteTempEntryAction
//   | NewEntryAction;
