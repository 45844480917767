import React from 'react';
import { Link } from 'react-router-dom';

export const BackToSessionButton = ({
  toUrl,
  text,
  className,
}: {
  toUrl: string;
  text?: string;
  className?: string;
}) => {
  return (
    <span className={`inline-flex rounded-md shadow-sm ${className}`}>
      <Link to={toUrl}>
        <button
          type='button'
          className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-crimson hover:bg-red-200 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150'>
          {text || 'Back to session'}
        </button>
      </Link>
    </span>
  );
};
