/**
 * Main NavBar
 *
 * TODO:
 *  - set home icon to a smaller version of the logo
 *    - need to make this smaller logo in illustrator
 * TODO:
 * - use the semantic-ui menus embedded within the tailwind navbar
 *
 * TODO: put the cookie/tos banner here since this gets rendered all the time
 *
 */
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

// import { PolicyBanner } from '../components/PolicyBanner';
import { AppState } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../stores/account/account_sign_out_action';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Popover, Transition } from '@headlessui/react';

const NavLink = ({ to, text }) => (
  <Link
    to={to}
    className='ml-8 inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-burgundy hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 transition duration-150 ease-in-out'>
    {text}
  </Link>
);

export const DesktopNavBar = () => {
  const [leftMenuOpen, setLeftMenuOpen] = useState(false);
  const [profileDisplayOpen, setProfileDisplayOpen] = useState(false);

  const account = useSelector((state: AppState) => state.account);
  const { isSignedIn } = account;
  // const user = FirebaseClient.auth.currentUser;
  // const handleLeftMenuClick

  // TODO: move about to the right, after History
  return (
    // <nav className='bg-white shadow'>
    <nav className='hidden lg:block fixed z-30 w-full mb-4 bg-not-white shadow font-spartan'>
      <div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
        <div className='relative flex justify-between h-16'>
          <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
            {/* <!-- Mobile menu button --> */}
            <button
              className='inline-flex items-center justify-center p-2 rounded-md text-burgundy hover:text-red-200 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out'
              aria-label='Main menu'
              aria-expanded='false'
              onClick={() => setLeftMenuOpen(!leftMenuOpen)}>
              {/* <!-- Icon when menu is closed. --> */}
              {/* <!-- Menu open: "hidden", Menu closed: "block" --> */}
              <svg
                className={` ${leftMenuOpen ? 'hidden' : 'block'} h-6 w-6`}
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                />
              </svg>
              {/* <!-- Icon when menu is open. --> */}
              {/* <!-- Menu open: "block", Menu closed: "hidden" --> */}
              <svg
                className={` ${leftMenuOpen ? 'block' : 'hidden'} h-6 w-6`}
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div>
          <div className='flex-1 flex items-center justify-center sm:items-stretch sm:justify-start'>
            <div className='flex-shrink-0 flex items-center'>
              <Link to='/'>
                {/* FIXME: what is going on here? */}
                <img
                  className='block h-10 w-auto'
                  src='/logo.svg'
                  alt='Flourishing app logo blac letter f a green background'
                />
              </Link>
            </div>
            {/* desktop layout */}
            <div className='hidden sm:ml-6 sm:flex'>
              <NavLink to='/about/activities' text='Activities' />
              <NavLink to='/about/measures' text='Measures' />
              {isSignedIn && <NavLink to='/history' text='History' />}
              {isSignedIn && <NavLink to='/about' text='About' />}
            </div>
          </div>
          <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
            {/* Profile dropdown */}
            {!isSignedIn && (
              <div className='ml-3 relative hidden md:inline-flex items-center'>
                <Link
                  to='/sign-up'
                  className='inline-block items-center justify-center px-8 py-1 border border-burgundy text-sm leading-5 font-medium rounded-full text-burgundy bg-white hover:text-burgundy focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo transition duration-150 ease-in-out'>
                  Sign up
                </Link>
                <Link
                  to='/sign-in'
                  className='inline-block items-center justify-center px-8 py-1 border border-transparent text-sm leading-5 font-medium text-burgundy rounded-md text-hover:bg-red-200 focus:outline-none focus:border-gray-700 focus:shadow-outline-indigo transition duration-150 ease-in-out'>
                  Sign in
                </Link>
              </div>
            )}
            {/* TODO: update this to the popover  */}
            {/* Profile Button */}
            {/* displayed only if signed in*/}
            <div className={`ml-3 ${isSignedIn ? 'relative' : 'hidden'}`}>
              {isSignedIn && <ProfileDropdownPanel account={account} />}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

const links = [
  // { text: 'About', to: '/about' },
  // { text: 'Activities', to: '/about/activities' },
  // { text: 'Measures', to: '/about/measures' },
  { text: 'Account', to: '/account' },
];
/**
 *  Profile dropdown panel, show/hide based on dropdown state.
 *  Entering: "transition ease-out duration-200"
 *    From: "transform opacity-0 scale-95"
 *    To: "transform opacity-100 scale-100"
 *  Leaving: "transition ease-in duration-75"
 *    From: "transform opacity-100 scale-100"
 *    To: "transform opacity-0 scale-95"
 */
const ProfileDropdownPanel = ({ account }) => {
  const dispatch = useDispatch();
  return (
    <Popover className=''>
      {({ open }) => {
        return (
          <>
            <Popover.Button className='rounded-full'>
              <img
                className='h-8 w-8 rounded-full'
                // TODO: change default image here
                src={account.photoUrl || '/media/avatar.svg'}
                alt="User's profile avatar"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'>
              <Popover.Panel className='absolute z-10 w-32 right-0 bg-white'>
                <nav className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                  {links.map((link) => (
                    <Popover.Button
                      key={link.to}
                      as={Link}
                      to={link.to}
                      className='block w-full px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out'>
                      {link.text}
                    </Popover.Button>
                  ))}

                  <button
                    onClick={() => dispatch(signOut())}
                    className='block w-full px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out text-left'>
                    Sign out
                  </button>
                </nav>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};
