import { Link } from 'react-router-dom';

import { MEASURE_TEXT } from '../../text/measures';
import ArrowRightIcon from '@heroicons/react/outline/ArrowRightIcon';

export const AboutMeasures = () => {
  return (
    <div className='pt-36 pb-44'>
      <div>
        <header className='relative px-4 xl:px-0 mx-auto max-w-5xl w-full pt-6 pb-20 text-center'>
          <h1 className='text-4xl tracking-tight leading-10 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl'>
            Measure & track your progress
          </h1>
          <p className='mt-3 text-lg sm:text-xl md:mt-5 text-burgundy'>
            Measures help you to track your level of flourishing and flourishing
            related areas.
            <br />
            Get started today by taking any (or all) of the measures!
          </p>
        </header>

        {/* <div className='mt-12 bg-white'> */}
        <div className='px-5 mx-auto max-w-5xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 place-items-center'>
          {Object.keys(MEASURE_TEXT).map((k) => {
            const measure = MEASURE_TEXT[k];
            return (
              <div key={measure.href} className='py-2 lg:py-0'>
                <MeasureCard key={measure.href} measure={measure} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const MeasureCard = ({
  measure,
}: {
  measure: { title: string; about: string; href: string; iconWhite: string };
}) => {
  // console.log('measure', measure);

  return (
    <div className='bg-burgundy h-48 w-48 grid grid-cols-1 place-items-center text-gray-100 text-center transform transition hover:ease-in-out hover:duration-300 shadow-xl hover:shadow-2xl'>
      <img className='p-4 h-20' src={`${measure.iconWhite}`} alt='' />
      <h2 className='-mt-3 px-6 text-sm leading-6'>{measure.title}</h2>
      <Link
        className='grid grid-cols-1 place-items-center -mb-10 bg-white h-14 w-14 rounded-full'
        to={`/about${measure.href}`}>
        <ArrowRightIcon className='p-1 h-8 w-8 text-burgundy rounded-full' />
      </Link>
    </div>
  );
};
