import { ArrowLeftIcon } from '@heroicons/react/outline';
import { Helmet } from 'react-helmet';
import { RouterProps } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useSession } from '../../../api/firebase/hooks/use_session';

// import { TEXT } from '../../DEPRECATED_text/activities/text';
import { Session } from '../../../stores/session/session_types';
import { getTitle } from '../../../utils/get_title';
import { getTypeFromUrl } from '../../../utils/get_type_from_url';
import { getUrlFromType } from '../../../utils/get_url_from_type';

type Props = {} & RouterProps &
  RouteComponentProps<{ type: string; sid: string; eid: string }>;

export const CompletedBaselineReflection = (props: Props) => {
  const { match } = props;
  const params = match.params;
  const { sid } = params;
  const type = getTypeFromUrl(props.match.params.type, props.match.path) || '';
  const title = getTitle(type);
  const { data: session, isLoading } = useSession({ id: sid });

  const typeUrl = getUrlFromType(type, true);
  const to = session?.isActive
    ? `/app/activities/${typeUrl}`
    : `/app/activities/${typeUrl}/sessions/${session?.id}`;

  const reflection = (session as Session)?.baselineReflection || '';

  if (isLoading) {
    return (
      <div className='grid grid-cols-1 place-items-center pt-10'>
        Loading...
      </div>
    );
  }
  return (
    <div className='bg-gray-100 min-h-screen'>
      <Helmet>
        <title>Completed Baseline</title>
      </Helmet>
      <main className='mx-auto max-w-7xl px-2 py-0 sm:p-0 '>
        <header className='py-10 flex space-x-5 items-center'>
          <Link
            to={to}
            className='inline-block bg-white rounded-full shadow cursor-pointer'>
            <ArrowLeftIcon className='p-2 h-12 w-12 text-burgundy' />
          </Link>
          <div className='h-12 w-12 flex justify-center items-center bg-burgundy text-white'>
            R
          </div>
          <h2>Baseline Reflection</h2>
        </header>
        {/*  */}
        <div className='py-20 bg-white text-center'>
          <div className='mx-auto max-w-5xl'>
            <h2 className='text-sm'>
              {new Date(session.startedAt).toDateString()}
            </h2>
            <h1 className='text-burgundy text-5xl'>
              {title} Baseline Reflection
            </h1>

            <div className='mt-10'>
              <p>
                As part of the activities, we encourage you to write down your
                expectations for this activity.
                <br />
                You have come to this activity and exercise for some reason,
                please take a few minutes to think deeply about what that reason
                was and is and what you would like to get out of this exercise.
                When you are ready, please take a few moments to write down some
                of your thoughts. You will be able to come back to these later.
              </p>
              {/* <h2>Response</h2> */}
            </div>
            {/* FIXME: check for array or string */}
            <div className='py-20 w-full bg-gray-50'>
              <p className='mt-10'>{reflection}</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
