import { Entry } from './session_types';

export const CREATE_ENTRY_REQUESTED = 'CREATE_ENTRY_REQUESTED';
export const CREATE_ENTRY_SUCCEEDED = 'CREATE_ENTRY_SUCCEEDED';
export const CREATE_ENTRY_FAILED = 'CREATE_ENTRY_FAILED';

export type CreateEntryAction = {
  type: typeof CREATE_ENTRY_REQUESTED;
  payload: {
    id: string;
    entry: Entry;
  };
};

export const createEntryAction = ({
  id,
  entry,
}: {
  id: string;
  entry: Entry;
}): CreateEntryAction => ({
  type: CREATE_ENTRY_REQUESTED,
  payload: {
    id,
    entry,
  },
});
