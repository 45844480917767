export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_SUCCEEDED = 'RESET_PASSWORD_SUCCEEDED';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export type ResetPasswordAction = {
  type: typeof RESET_PASSWORD_REQUESTED;
  payload: {
    email: string;
  };
};

export const resetPasswordAction = ({
  email,
}: {
  email: string;
}): ResetPasswordAction => ({
  type: RESET_PASSWORD_REQUESTED,
  payload: { email },
});
