import {
  ANXIETY_ACTIVITY,
  BEST_SELF_ACTIVITY,
  CHARACTER_STRENGTHS_ACTIVITY,
  DEPRESSION_ACTIVITY,
  FORGIVENESS_ACTIVITY,
  GRATITUDE_ACTIVITY,
  JOB_CRAFTING_ACTIVITY,
  KINDNESS_ACTIVITY,
  MARRIAGE_ACTIVITY,
  RELIGION_ACTIVITY,
  SAVORING_ACTIVITY,
  VOLUNTEER_ACTIVITY,
} from '../stores/activity/activity';
import {
  CHARACTER_STRENGTHS_MEASURE,
  FLOURISHING_MEASURE,
  GRATITUDE_MEASURE,
  MEANING_MEASURE,
} from '../stores/measure/measure_type';

const mapActivity = new Map([
  [ANXIETY_ACTIVITY, 'anxiety'],
  [BEST_SELF_ACTIVITY, 'best-self'],
  [CHARACTER_STRENGTHS_ACTIVITY, 'character-strengths'],
  [DEPRESSION_ACTIVITY, 'depression'],
  [FORGIVENESS_ACTIVITY, 'forgiveness'],
  [GRATITUDE_ACTIVITY, 'gratitude'],
  [JOB_CRAFTING_ACTIVITY, 'job-crafting'],
  [KINDNESS_ACTIVITY, 'kindness'],
  [MARRIAGE_ACTIVITY, 'marriage'],
  [RELIGION_ACTIVITY, 'religion'],
  [SAVORING_ACTIVITY, 'savoring'],
  [VOLUNTEER_ACTIVITY, 'volunteer'],
]);
const mapMeasure = new Map([
  [FLOURISHING_MEASURE, 'flourishing'],
  [CHARACTER_STRENGTHS_MEASURE, 'character-strengths'],
  [GRATITUDE_MEASURE, 'gratitude'],
  [MEANING_MEASURE, 'meaning'],
]);

export function getUrlFromType(type: string, isActivity: boolean) {
  return isActivity ? mapActivity.get(type) : mapMeasure.get(type);
}
