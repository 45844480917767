import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  RouterProps,
  RouteComponentProps,
  useHistory,
} from 'react-router';

import {
  NEW_SESSION_SCHEDULE_HEADER,
  NEW_SESSION_SCHEDULE_HEADER_IF_GRATITUDE,
} from '../../../text/in_app_text';
import { GRATITUDE_ACTIVITY } from '../../../stores/activity/activity';
import { AppState } from '../../../types';
import { getUrlFromType } from '../../../utils/get_url_from_type';
import { makeScheduledEntries } from '../../../utils/make_scheduled_entries';
import { getTypeFromUrl } from '../../../utils/get_type_from_url';
import { Field, Form, Formik } from 'formik';
import { getTitle } from '../../../utils/get_title';
import { Helmet } from 'react-helmet';
import { ACTIVITY_IMAGES } from '../../../assets/activity_images';
import { useMutation } from 'react-query';
import { httpsCallable } from 'firebase/functions';
import { FirebaseClient } from '../../../firebase';
import { Spinner } from '../../../components/Spinner';
import { Stepper } from './Stepper';
import { TEXT } from '../../../text/activities';

type Props = {} & RouterProps &
  RouteComponentProps<{ type: string; sid: string; eid: string }>;

export const NewActivitySessionSchedule = (props: Props) => {
  const history = useHistory();
  const type = getTypeFromUrl(props.match.params.type, props.match.path) || '';
  const title = getTitle(type);

  const numberOfDays = type === GRATITUDE_ACTIVITY ? 3 : 1;
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState<any>();

  const { hasBaseline } = TEXT[type];

  const headerText =
    type === GRATITUDE_ACTIVITY
      ? NEW_SESSION_SCHEDULE_HEADER_IF_GRATITUDE
      : NEW_SESSION_SCHEDULE_HEADER;

  const newSession = useSelector((state: AppState) => state.newSession);
  const network = useSelector((state: AppState) => state.network);
  const typeUrl = getUrlFromType(newSession.type, true);

  const img = ACTIVITY_IMAGES[type];

  const validate = (value) => {
    console.log(value);
  };

  const mutation = useMutation(
    async (days: number[]) => {
      if (days.length === numberOfDays) {
        newSession.entries = makeScheduledEntries(days);
        newSession.scheduledDaysOfTheWeek = days;
        console.log('newSession', newSession);

        const response = await httpsCallable(
          FirebaseClient.functions,
          'createSession_v2',
        )({ session: newSession });
        // returns data: {session, measures}

        return response.data;
        // const createdSession: Session = result.data;
      } else {
        // console.error('Incorrect number of days', days, numberOfDays);
        throw new Error('Incorrect number of days');
      }
    },
    {
      onSuccess: (data) => {
        history.push(`/app/activities/${typeUrl}`);
      },
      onError: (error) => {
        console.error(error);
        setError(`${error}`);
      },
    },
  );

  if (completed && network.createSessionIsFinished) {
    return <Redirect push to={`/app/activities/${typeUrl}`} />;
  }

  if (newSession.type === '') {
    return <Redirect to='/' />;
  }

  return (
    <div className='relative min-h-screen pb-32 overflow-hidden'>
      <Helmet>
        <title>Baseline Measures</title>
      </Helmet>
      <img
        className='h-96 w-full object-cover object-center'
        src={img}
        alt=''
      />
      <div className='relative z-10 px-4 md:px-20 py-10 mx-auto max-w-7xl grid grid-cols-1 place-item-center -mt-32 bg-white text-center'>
        <header className='space-y-5'>
          <h1 className='text-burgundy text-5xl leading-160'>
            {title} Activity Schedule
          </h1>
          <p className='text-sm leading-160 whitespace-pre-line'>
            {headerText}
          </p>
        </header>

        <Formik
          initialValues={{
            days: [],
          }}
          onSubmit={async (values) => {
            // setDays(values.days.map((day) => parseInt(day)));
            mutation.mutate(values.days.map((day) => parseInt(day)));
          }}>
          {({ values, errors, isValidating, touched }) => {
            return (
              <Form className='mt-10' translate='yes'>
                <div
                  className='flex flex-wrap justify-between flex-col space-y-4 lg:space-y-0 lg:flex-row items-center'
                  id='checkbox-group'
                  role='group'
                  aria-labelledby='checkbox-group'>
                  {[
                    'Sunday',
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                  ].map((day, i) => {
                    const isChecked = (values.days as string[]).includes(
                      `${i}`,
                    );
                    const disabled =
                      values.days.length > numberOfDays - 1 && !isChecked;
                    return (
                      <label
                        key={day}
                        className={`${
                          isChecked
                            ? 'bg-burgundy text-white'
                            : 'bg-white text-burgundy'
                        } border border-burgundy px-4 py-2 lg:py-0 rounded-full`}>
                        <Field
                          className='hidden'
                          type='checkbox'
                          name='days'
                          value={i}
                          checked={isChecked}
                          validate={validate}
                          disabled={disabled}
                        />
                        <span className=''>{day}</span>
                      </label>
                    );
                  })}
                </div>
                {error && (
                  <p className='mt-10 text-sm text-red-500 leading-160'>
                    {error}
                  </p>
                )}
                <div className='mt-10 flex justify-center'>
                  <button
                    type='submit'
                    className='inline-flex justify-center py-2 px-20 border border-burgundy text-sm leading-5 font-medium rounded-full text-burgundy'>
                    {mutation.isLoading && <Spinner color='text-burgundy' />}
                    Complete
                  </button>
                </div>
                <div className='mt-5' />
                <Stepper step='schedule' hasBaseline={hasBaseline} />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
