import { Measure } from './measure_type';

export const CREATE_ANON_MEASURE_REQUESTED = 'CREATE_ANON_MEASURE_REQUESTED';
export const CREATE_MEASURE_REQUESTED = 'CREATE_MEASURE_REQUESTED';
export const CREATE_MEASURE_SUCCEEDED = 'CREATE_MEASURE_SUCCEEDED';
export const CREATE_MEASURE_FAILED = 'CREATE_MEASURE_FAILED';

export type CreateMeasureAction = {
  type: typeof CREATE_MEASURE_REQUESTED | typeof CREATE_ANON_MEASURE_REQUESTED;
  payload: Measure;
};

export const createAnonMeasureAction = (
  payload: Measure,
): CreateMeasureAction => ({
  type: CREATE_ANON_MEASURE_REQUESTED,
  payload,
});

export const createMeasureAction = (payload: Measure): CreateMeasureAction => ({
  type: CREATE_MEASURE_REQUESTED,
  payload,
});
