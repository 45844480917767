export const FETCH_SESSION_REQUESTED = 'FETCH_SESSION_REQUESTED';
export const FETCH_SESSION_SUCCEEDED = 'FETCH_SESSION_SUCCEEDED';
export const FETCH_SESSION_FAILED = 'FETCH_SESSION_FAILED';

export type FetchSessionActionPayload = {
  id: string;
};

export type FetchSessionAction = {
  type: typeof FETCH_SESSION_REQUESTED;
  payload: FetchSessionActionPayload;
};

export const fetchSessionAction = (
  payload: FetchSessionActionPayload
): FetchSessionAction => ({
  type: FETCH_SESSION_REQUESTED,
  payload,
});
