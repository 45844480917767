/**
 * Active Activity Session
 * - show when signed in and activity session is active
 *
 */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { endSessionAction } from '../../../stores/session/end_session_action';
import { Session } from '../../../stores/session/session_types';
import { Redirect } from 'react-router-dom';
import { BaselineViews } from '../completed/CompletedActivitySessionBaselines';
import { checkIsAvailable } from '../../../utils/date_checks';
import { IN_PROGRESS_HEADER, TEXT } from '../../../text/activities';
import { EndSessionEarly } from '../../v2/activities/in_progress/EndSessionEarly';
import { Helmet } from 'react-helmet';
import { ACTIVITY_IMAGES } from '../../../assets/activity_images';

import { Entries } from '../components/Entries';
import { getUrlFromType } from '../../../utils/get_url_from_type';

export const ActiveActivitySession = ({
  session,
  title,
}: {
  session: Session;
  title: string;
}) => {
  const dispatch = useDispatch();
  const { id } = session;
  const [showAlert, setShowAlert] = useState(false);

  const imgUrl =
    ACTIVITY_IMAGES[session.type] ||
    'https://images.unsplash.com/photo-1545945774-73922eb27813?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80';

  const handleEndSessionAlert = () => {
    setShowAlert(true);
  };

  const handleEndSession = () => {
    dispatch(endSessionAction({ id, stoppedAt: Date.now() }));
  };

  // Computed data
  const nCompletedEntries = session.entries.reduce((acc, entry) => {
    return entry.completed ? acc + 1 : acc;
  }, 0);
  const nTotalEntries = session.entries.length;

  const activityData = TEXT[session.type];
  const quotation = activityData.quotation;
  const general = activityData.general.split('\n')[0].trim();

  const nEntries = session.entries.length;
  const progress = nCompletedEntries / nEntries;

  const showBaseline =
    session.baseline.length > 0 ||
    session.baselineReflection.length > 0 ||
    session.baselineMeasures.length > 0;

  // TODO: useEffect to end session if all entries are completed or if no longer possible to do any entries
  useEffect(() => {
    //check if final entry is completed
    const done = session.entries.reduce(
      (acc, entry) => (entry.completed ? acc + 1 : acc),
      0,
    );
    if (process.env.NODE_ENV !== 'production') {
      console.log('All done: ', done);
    }
    if (done === session.entries.length) {
      //if all entries are completed, end session
      // dispatch(endSessionAction({ id }));
      if (process.env.NODE_ENV !== 'production') {
        console.log('End Session. All entries are completed.');
      }
      dispatch(endSessionAction({ id, stoppedAt: Date.now() }));
      return;
    }

    //check to see if any upcoming entries are possible
    //if lastEntryDate is more than one week older than today, end session
    //nb. i can't just check upcoming? b/c i might not be past the final entry date. but(!) i can do a simple date check and then check upcoming
    const today = Date.now();
    const lastEntryDate =
      session.entries[session.entries.length - 1].scheduledDate;
    if (today > lastEntryDate) {
      const available = checkIsAvailable(session);
      if (available.length === 0) {
        //end the session b/c no more entries are possible
        if (process.env.NODE_ENV !== 'production') {
          console.log('End Session. No more entries are possible.');
        }
        dispatch(endSessionAction({ id, stoppedAt: Date.now() }));
        return;
      }
    }
  }, [dispatch, id, session]);

  // Check to see if completed
  if (session.completed) {
    const slug = getUrlFromType(session?.type, true);
    const to = `/app/activities/${slug}/sessions/${session?.id}`;
    return <Redirect to={to} />;
  }

  return (
    <div className='relative pb-10 overflow-hidden'>
      <Helmet>
        <title>Activity In Progress</title>
      </Helmet>

      {/* Header Image */}
      <header
        className='relative h-96 w-full bg-center bg-cover'
        style={{ backgroundImage: `url(${imgUrl})` }}>
        <div className='absolute h-full w-full bg-black opacity-50'></div>
        {/* quotation should be centered in the header image and not shown for mobile views */}
        <h2 className='hidden md:block relative pt10 p-4 xl:px-0 text-center mx-auto max-w-7xl text-white whitespace-pre-wrap'>
          {quotation}
        </h2>
      </header>

      <div className='relative z-10 -mt-48 mx-auto max-w-7xl bg-white overflow-hidden'>
        <main className='px-4 xl:px-0 py-16 mx-auto max-w-5xl'>
          <header className='space-y-5'>
            <h2 className='text-sm'>
              Status:{' '}
              <span className='font-bold'>
                {session.isActive ? 'in progress' : 'completed'}
              </span>
            </h2>
            <h1 className='text-5xl text-burgundy'>{title} Activity</h1>
            <h2 className='text-sm'>
              Started On:{' '}
              <span className='font-bold'>
                {new Date(session.startedAt).toDateString()}
              </span>
            </h2>
            <p className='text-sm'>
              {nCompletedEntries}/{nTotalEntries} Entries Completed
            </p>
            <div
              id='progress'
              className='mt-2 overflow-hidden bg-gray-50 h-4 mb-4 flex rounded-full'>
              <div
                style={{ width: `${100 * progress}%` }}
                className='shadow-none flex flex-col bg-burgundy rounded-full'
              />
            </div>
          </header>

          <section className='my-8 space-y-5'>
            {/* <p className='text-sm'>
              {general}
            </p> */}
            <p className='text-sm leading-160'>{IN_PROGRESS_HEADER}</p>
          </section>

          <section className='space-y-5'>
            <Entries session={session} status='available' />
            {showBaseline && <BaselineViews session={session} />}
            <Entries session={session} status='completed' />
            <Entries session={session} status='scheduled' />
          </section>
          <div className='mt-32'>
            <EndSessionEarly id={session.id} />
          </div>
        </main>
      </div>
    </div>
  );
};
