import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../../../types';
import { getTitle } from '../../../utils';
import { updateNewSessionAction } from '../../../stores/session/new_session_action_creators';
import { Session } from '../../../stores/session/session_types';
import { RouterProps, RouteComponentProps, Redirect } from 'react-router';
import { getTypeFromUrl } from '../../../utils/get_type_from_url';
import { getUrlFromType } from '../../../utils/get_url_from_type';
import { Form, Formik, Field } from 'formik';
import {
  COMMON_ACTIVITY_REFLECTION_INSTRUCTIONS,
  TEXT,
} from '../../../text/activities';
import { ACTIVITY_IMAGES } from '../../../assets/activity_images';
import { Helmet } from 'react-helmet';
import { Stepper } from './Stepper';

type Props = {} & RouterProps &
  RouteComponentProps<{ type: string; cmd: string }>;

const validateText = (text: string) => {
  let error;
  if (text.length < 1) {
    error = 'Too few words';
  }
  return error;
};

export const NewActivitySessionReflection = (props: Props) => {
  const [text, setText] = useState('');
  const [completed, setCompleted] = useState(false);
  const newSession = useSelector((state: AppState) => state.newSession);
  const dispatch = useDispatch();
  const params = props.match.params;
  const type = getTypeFromUrl(params.type, props.match.path) || '';

  const activityUrl = getUrlFromType(type, true);

  const title = getTitle(type);
  const { hasBaseline } = TEXT[type];

  useEffect(() => {
    if (text.length > 0) {
      const updatedSession: Session = {
        ...newSession,
        baselineReflection: text,
      };
      dispatch(updateNewSessionAction(updatedSession));
      setCompleted(true);
    }
  }, [dispatch, newSession, text]);

  if (newSession.type === '') {
    return <Redirect push to='/' />;
  }

  const img = ACTIVITY_IMAGES[type];

  if (completed) {
    return <Redirect push to={`/app/activities/${activityUrl}/new/measures`} />;
  }

  return (
    <div className='relative min-h-screen pb-32'>
      <Helmet>
        <title>New Reflection</title>
      </Helmet>
      {/* <Helmet><title></title></Helmet> */}
      <img
        className='h-96 w-full object-cover object-center'
        src={img}
        alt=''
      />
      <div className='relative z-10 px-4 md:px-20 py-10 mx-auto max-w-7xl grid grid-cols-1 place-item-center -mt-32 bg-white text-center'>
        <header className='space-y-5'>
          <h1 className='text-burgundy text-5xl leading-160'>
            {title} Activity Reflection
          </h1>
          <p className='text-sm leading-160 whitespace-pre-line'>
            {COMMON_ACTIVITY_REFLECTION_INSTRUCTIONS}
          </p>
        </header>

        <Formik
          initialValues={{ response: newSession.baselineReflection || '' }}
          onSubmit={({ response }) => {
            setText(response);
          }}>
          {({ errors, touched, isValidating }) => (
            <Form className='py-10' translate='yes'>
              <Field
                name='response'
                as='textarea'
                className='form-textarea'
                validate={validateText}
              />
              {/* TODO: display word count */}
              {errors.response && touched.response && (
                <div className='py-10 text-red-700'>{errors.response}</div>
              )}
              {/*  */}
              <div className='mt-10 flex justify-center'>
                <button
                  type='submit'
                  className='inline-flex justify-center py-2 px-20 border border-burgundy text-sm leading-5 font-medium rounded-full text-burgundy'>
                  Save & Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <Stepper step='reflection' hasBaseline={hasBaseline} />
      </div>
    </div>
  );
};
