export const UPDATE_NAV_HEADER = 'UPDATE_NAV_HEADER';

export type NavState = {
  header: string;
};

export type UpdateNavPayload = {
  header: string;
};

export type UpdateNavAction = {
  type: string;
  payload: UpdateNavPayload;
};
