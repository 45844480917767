import * as uuid from 'uuid';
import { Measure } from '../stores/measure/measure_type';

export const createCompletedMeasure = ({
  type,
  startedAt,
  responses,
  campaign = 'default'
}): Measure => ({
  id: '',
  isBaseline: false,
  isIntake: false,
  sessionId: '',
  cuuid: uuid.v4(),
  type,
  completed: true,
  startedAt,
  stoppedAt: Date.now(),
  payload: { responses },
  campaign
});
