/**
 * Create the Firebase client and freeze it to use a (pseudo) singleton pattern
 * NB: this is now using the new v9 firebase interfaces
 * TODO: port this new interface to the mobile app
 */
import { Analytics, getAnalytics } from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';
import { Functions, getFunctions } from 'firebase/functions';
import { FirebaseStorage, getStorage } from 'firebase/storage';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DOMAIN_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_GA_MEASUREMENT_ID,
};

export interface FireBaseClientInterface {
  app: FirebaseApp;
  auth: Auth;
  db: Firestore;
  functions: Functions;
  storage: FirebaseStorage;
  analytics: Analytics;
}

class FirebaseClient implements FireBaseClientInterface {
  app: FirebaseApp;
  auth: Auth;
  db: Firestore;
  functions: Functions;
  storage: FirebaseStorage;
  analytics: Analytics;

  constructor() {
    console.log('config', config, process.env);

    this.app = initializeApp(config);
    this.auth = getAuth(this.app);
    this.db = getFirestore(this.app);
    this.functions = getFunctions(this.app);
    this.storage = getStorage(this.app);
    this.analytics = getAnalytics(this.app);
  }
}

// Use freeze for a singleton pattern
const instance = new FirebaseClient();
Object.freeze(instance);

export default instance;
