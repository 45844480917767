import { all, call, put, takeEvery } from 'redux-saga/effects';
import { doSignInWithGoogle, doSignOut } from '../../auth';
import { doChangeEmail } from '../../auth/change_email';
import { doCreateAccountWithEmail } from '../../auth/create_account_with_email';
import {
  CreateAccountWithEmailAction,
  CREATE_ACCOUNT_WITH_EMAIL_FAILED,
  CREATE_ACCOUNT_WITH_EMAIL_REQUESTED,
  CREATE_ACCOUNT_WITH_EMAIL_SUCCEEDED,
} from '../../auth/create_account_with_email_action';
import { doDeleteAccount } from '../../auth/delete_account';
import { doResetPassword } from '../../auth/reset_password';
import {
  ResetPasswordAction,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_SUCCEEDED,
} from '../../auth/reset_password_action';
import { doSignInWithEmail } from '../../auth/sign_in_with_email';
import {
  SignInAccountWithEmailAction,
  SIGN_IN_WITH_EMAIL_FAILED,
  SIGN_IN_WITH_EMAIL_REQUESTED,
  SIGN_IN_WITH_EMAIL_SUCCEEDED,
} from '../../auth/sign_in_with_email_action';
import { doVerifyEmail } from '../../auth/verify_email';
import { doChangePassword } from '../../firebase/change_password';
import { doFetchAccount } from '../../firebase/fetch_account';
import { setWeeklyEmailReminders } from '../../firebase/set_weekly_email_reminders';
import { doSyncUser } from '../../firebase/sync_user';
import {
  DELETE_ACCOUNT_FAILED,
  DELETE_ACCOUNT_REQUESTED,
  DELETE_ACCOUNT_SUCCEEDED,
  SIGN_IN_GOOGLE_FAILED,
  SIGN_IN_GOOGLE_REQUESTED,
  SIGN_IN_GOOGLE_SUCCEEDED,
  SIGN_IN_SUCCESS,
  SIGN_OUT_FAILED,
  SIGN_OUT_REQUESTED,
  SIGN_OUT_SUCCEEDED,
} from './account_types';
import {
  AuthStateAction,
  USER_SYNC_FAILED,
  USER_SYNC_REQUESTED,
  USER_SYNC_SUCCEEDED,
} from './auth_state_action';
import {
  ChangeEmailAction,
  CHANGE_EMAIL_FAILED,
  CHANGE_EMAIL_REQUESTED,
  CHANGE_EMAIL_SUCCEEDED,
} from './change_email_action';
import {
  ChangePasswordAction,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_REQUESTED,
  CHANGE_PASSWORD_SUCCEEDED,
} from './change_password_action';
import {
  FetchAccountAction,
  FETCH_ACCOUNT_FAILED,
  FETCH_ACCOUNT_REQUESTED,
  FETCH_ACCOUNT_SUCCEEDED,
} from './fetch_account';
import {
  SendEmailVerificationAction,
  SEND_EMAIL_VERIFICATION_FAILED,
  SEND_EMAIL_VERIFICATION_REQUESTED,
  SEND_EMAIL_VERIFICATION_SUCCEEDED,
} from './send_email_verification_action';
import {
  WeeklyEmailRemindersAction,
  WEEKLY_EMAILS_FAILED,
  WEEKLY_EMAILS_REQUESTED,
  WEEKLY_EMAILS_SUCCEEDED,
} from './weekly_email_reminders_action';

// TODO: rename this and associated functions to syncUser?
function* syncUser(action: AuthStateAction) {
  try {
    const result = yield call(doSyncUser, action.payload);
    if (result.hasError) {
      yield put({ type: USER_SYNC_FAILED, payload: result.error });
    } else {
      yield put({ type: USER_SYNC_SUCCEEDED, payload: result });
    }
  } catch (error) {
    yield put({ type: USER_SYNC_FAILED, payload: error.message });
  }
}

function* changePassword(action: ChangePasswordAction) {
  try {
    const result = yield call(doChangePassword, action.payload);
    if (result.hasError) {
      yield put({ type: CHANGE_PASSWORD_FAILED, payload: result.error });
    } else {
      yield put({ type: CHANGE_PASSWORD_SUCCEEDED, payload: result });
    }
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_FAILED, payload: error.message });
  }
}

function* changeEmail(action: ChangeEmailAction) {
  try {
    const result = yield call(doChangeEmail, action.payload);
    if (result.hasError) {
      yield put({ type: CHANGE_EMAIL_FAILED, payload: result.error });
    } else {
      yield put({ type: CHANGE_EMAIL_SUCCEEDED, payload: action.payload });
    }
  } catch (error) {
    yield put({ type: CHANGE_EMAIL_FAILED, payload: error.message });
  }
}

function* createAccountWithEmail(action: CreateAccountWithEmailAction) {
  try {
    const result = yield call(doCreateAccountWithEmail, action.payload);
    if (result.hasError && result.hasError === true) {
      yield put({
        type: CREATE_ACCOUNT_WITH_EMAIL_FAILED,
        payload: result.error,
      });
    } else {
      yield put({ type: CREATE_ACCOUNT_WITH_EMAIL_SUCCEEDED, payload: result });
      yield put({ type: SIGN_IN_SUCCESS, payload: result });
    }
  } catch (error) {
    yield put({ type: CREATE_ACCOUNT_WITH_EMAIL_FAILED, payload: error });
  }
}

function* deleteAccount() {
  try {
    const results = yield call(doDeleteAccount);
    if (results.hasError && results.hasError === true) {
      yield put({ type: DELETE_ACCOUNT_FAILED, payload: results.error });
    } else {
      yield put({ type: DELETE_ACCOUNT_SUCCEEDED, payload: results });
      yield put({ type: 'RESET_APP' });
    }
  } catch (error) {
    yield put({ type: DELETE_ACCOUNT_FAILED, message: error.message });
  }
}

function* resetPassword(action: ResetPasswordAction) {
  try {
    const result = yield call(doResetPassword, action.payload);
    if (result.hasError) {
      yield put({ type: RESET_PASSWORD_FAILED, payload: result.error });
    } else {
      yield put({ type: RESET_PASSWORD_SUCCEEDED, payload: result });
    }
  } catch (error) {
    yield put({ type: RESET_PASSWORD_FAILED, payload: error });
  }
}

function* fetchAccount(action: FetchAccountAction) {
  try {
    const result = yield call(doFetchAccount);
    if (result.hasError) {
      yield put({ type: FETCH_ACCOUNT_FAILED, payload: result.error });
    } else {
      yield put({ type: FETCH_ACCOUNT_SUCCEEDED, payload: result });
    }
  } catch (error) {
    yield put({ type: FETCH_ACCOUNT_FAILED, payload: error.message });
  }
}

function* sendVerificationEmail(action: SendEmailVerificationAction) {
  try {
    const result = yield call(doVerifyEmail);
    if (result.hasError) {
      yield put({
        type: SEND_EMAIL_VERIFICATION_FAILED,
        payload: result.error,
      });
    } else {
      yield put({ type: SEND_EMAIL_VERIFICATION_SUCCEEDED, payload: result });
    }
  } catch (error) {
    yield put({ type: SEND_EMAIL_VERIFICATION_FAILED, payload: error });
  }
}

function* signInWithEmail(action: SignInAccountWithEmailAction) {
  try {
    const result = yield call(doSignInWithEmail, action.payload);
    if (result.hasError) {
      yield put({ type: SIGN_IN_WITH_EMAIL_FAILED, payload: result.error });
    } else {
      yield put({ type: SIGN_IN_WITH_EMAIL_SUCCEEDED, payload: result });
      yield put({ type: SIGN_IN_SUCCESS, payload: result });
    }
  } catch (error) {
    yield put({ type: SIGN_IN_WITH_EMAIL_FAILED, payload: error });
  }
}

function* signInWithGoogle() {
  try {
    const result = yield call(doSignInWithGoogle);
    if (result.hasError) {
      yield put({ type: SIGN_IN_GOOGLE_FAILED, payload: result.error });
    } else {
      yield put({ type: SIGN_IN_GOOGLE_SUCCEEDED, payload: result });
      yield put({ type: SIGN_IN_SUCCESS, payload: result });
    }
  } catch (error) {
    yield put({ type: SIGN_IN_GOOGLE_FAILED, payload: error });
  }
}

function* signOut() {
  try {
    const result = yield call(doSignOut);
    if (result.hasError) {
      yield put({ type: SIGN_OUT_FAILED, payload: result.error });
    } else {
      yield put({ type: SIGN_OUT_SUCCEEDED, payload: result });
    }
  } catch (error) {
    yield put({ type: SIGN_OUT_FAILED, payload: error });
  }
}

function* weeklyEmailReminders(action: WeeklyEmailRemindersAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('SAGA: WEEKLY EMAIL REMINDERS REQUESTED?');
    console.log(action);
    console.groupEnd();
  }
  try {
    const result = yield call(setWeeklyEmailReminders, action);
    if (process.env.NODE_ENV !== 'production') {
      console.group('SAGA: WEEKLY EMAIL REMINDERS SUCCEEDED');
      console.log('RESULT', result);
      console.groupEnd();
    }

    yield put({ type: WEEKLY_EMAILS_SUCCEEDED, payload: result });
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('SAGA ERROR: WEEKLY EMAIL REMINDERS FAILED');
      console.log('### ERRROR ###', error);
      console.groupEnd();
    }
    yield put({ type: WEEKLY_EMAILS_FAILED, message: error.message });
  }
}

export function* watchAccount() {
  // yield takeEvery(AUTH_STATE_CHANGED, syncUser);
  yield takeEvery(USER_SYNC_REQUESTED, syncUser);
  yield takeEvery(CHANGE_EMAIL_REQUESTED, changeEmail);
  yield takeEvery(CHANGE_PASSWORD_REQUESTED, changePassword);
  yield takeEvery(CREATE_ACCOUNT_WITH_EMAIL_REQUESTED, createAccountWithEmail);
  yield takeEvery(DELETE_ACCOUNT_REQUESTED, deleteAccount);
  yield takeEvery(FETCH_ACCOUNT_REQUESTED, fetchAccount);
  yield takeEvery(RESET_PASSWORD_REQUESTED, resetPassword);
  yield takeEvery(SEND_EMAIL_VERIFICATION_REQUESTED, sendVerificationEmail);
  yield takeEvery(SIGN_IN_GOOGLE_REQUESTED, signInWithGoogle);
  yield takeEvery(SIGN_OUT_REQUESTED, signOut);
  yield takeEvery(SIGN_IN_WITH_EMAIL_REQUESTED, signInWithEmail);
  yield takeEvery(WEEKLY_EMAILS_REQUESTED, weeklyEmailReminders);
}

export function* accountSaga() {
  if (process.env.NODE_ENV !== 'production') {
    console.log('running account saga');
  }
  yield all([watchAccount()]);
}
