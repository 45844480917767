import { ACTIVITY_TYPE } from '../activity/activity';

export const FETCH_CURRENT_SESSION_REQUESTED =
  'FETCH_CURRENT_SESSION_REQUESTED';
export const FETCH_CURRENT_SESSION_SUCCEEDED =
  'FETCH_CURRENT_SESSION_SUCCEEDED';
export const FETCH_CURRENT_SESSION_FAILED = 'FETCH_CURRENT_SESSION_FAILED';

export type FetchCurrentSessionActionPayload = {
  type: ACTIVITY_TYPE;
};
export type FetchCurrentSessionAction = {
  type: typeof FETCH_CURRENT_SESSION_REQUESTED;
  payload: FetchCurrentSessionActionPayload;
};

export const fetchCurrentSessionAction = (
  payload: FetchCurrentSessionActionPayload
): FetchCurrentSessionAction => ({
  type: FETCH_CURRENT_SESSION_REQUESTED,
  payload,
});
