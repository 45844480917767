/**
 * TODO: here is a case where tests would be good!
 */
import {
  ANXIETY_ACTIVITY,
  BEST_SELF_ACTIVITY,
  CHARACTER_STRENGTHS_ACTIVITY,
  DEPRESSION_ACTIVITY,
  FORGIVENESS_ACTIVITY,
  GRATITUDE_ACTIVITY,
  JOB_CRAFTING_ACTIVITY,
  KINDNESS_ACTIVITY,
  MARRIAGE_ACTIVITY,
  RELIGION_ACTIVITY,
  SAVORING_ACTIVITY,
  VOLUNTEER_ACTIVITY,
} from '../stores/activity/activity';
import {
  CHARACTER_STRENGTHS_MEASURE,
  COMMUNITY_MEASURE,
  FLOURISHING_MEASURE,
  GRATITUDE_MEASURE,
  MEANING_MEASURE,
} from '../stores/measure/measure_type';

export const mapActivity = new Map([
  ['anxiety', ANXIETY_ACTIVITY],
  ['best-self', BEST_SELF_ACTIVITY],
  ['character-strengths', CHARACTER_STRENGTHS_ACTIVITY],
  ['depression', DEPRESSION_ACTIVITY],
  ['forgiveness', FORGIVENESS_ACTIVITY],
  ['gratitude', GRATITUDE_ACTIVITY],
  ['job-crafting', JOB_CRAFTING_ACTIVITY],
  ['kindness', KINDNESS_ACTIVITY],
  ['marriage', MARRIAGE_ACTIVITY],
  ['religion', RELIGION_ACTIVITY],
  ['savoring', SAVORING_ACTIVITY],
  ['volunteer', VOLUNTEER_ACTIVITY],
]);

export const mapMeasure = new Map([
  ['character-strengths', CHARACTER_STRENGTHS_MEASURE],
  ['community', COMMUNITY_MEASURE],
  ['flourishing', FLOURISHING_MEASURE],
  ['gratitude', GRATITUDE_MEASURE],
  ['meaning', MEANING_MEASURE],
]);

/**
 * TODO: use regex instead
 * @param slug
 * @param path
 */
export function getTypeFromUrl(slug: string, path: string) {
  const isActivity = path.includes('activities');
  const type = isActivity ? mapActivity.get(slug) : mapMeasure.get(slug);
  return type;
}
