import { all, call, put } from 'redux-saga/effects';

export const REGISTER_FOR_PUSH_NOTIFICATIONS_SUCCEEDED =
  'REGISTER_FOR_PUSH_NOTIFICATIONS_SUCCEEDED';

function* registerForPushNotifications() {
  console.group('SAGA: REGISTER_FOR_PUSH_NOTIFICATIONS');
  console.groupEnd();
  try {
    const results = yield call(registerForPushNotifications);
    if (process.env.NODE_ENV !== 'production') {
      console.group('SAGA: REGISTER FOR PUSH NOTIFICATIONS SUCCEEDED');
      console.log(results);
      console.groupEnd();
    }
    yield put({
      type: REGISTER_FOR_PUSH_NOTIFICATIONS_SUCCEEDED,
      payload: results,
    });
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('SAGA ERROR: REGISTER FOR PUSH NOTIFICATIONS FAILED');
      console.error(error);
      console.groupEnd();
    }
    // alert('Delete account failed. Please contact support.');
    // yield put({ type: DELETE_ACCOUNT_FAILED, message: error.message });
  }
}

export function* watchAccount() {
  // yield takeEvery(SIGNIN_SUCCESS, registerForPushNotifications);
}

export function* notificationSaga() {
  if (process.env.NODE_ENV !== 'production') {
    console.log('running account saga');
  }
  yield all([watchAccount()]);
}
