/**
 * Page for landing firebase auth action cycles
 *
 * https://hfprogram.firebaseapp.com/__/auth/action?mode=action&oobCode=code
 */
import React from 'react';
import { RouteComponentProps } from 'react-router';

type Props = {} & RouteComponentProps;

export const AuthActions = (props: Props) => {
  //get the action from the URL
  if (process.env.NODE_ENV !== 'production') {
    console.group('AUTH ACTIONS');
    console.log(props);
    console.log(props.match.params);

    console.groupEnd();
  }

  return <div>auth actions</div>;
};
