import {
  BEST_SELF_ACTIVITY,
  BOOK_RESOURCES_ACTIVITY,
  CHARACTER_STRENGTHS_ACTIVITY,
  COMMUNITY_COMMITMENTS_ACTIVITY,
  GRATITUDE_ACTIVITY,
  JOB_CRAFTING_ACTIVITY,
  KINDNESS_ACTIVITY,
  SAVORING_ACTIVITY,
} from '../stores/activity/activity';

const activitySet = new Set([
  GRATITUDE_ACTIVITY,
  SAVORING_ACTIVITY,
  BEST_SELF_ACTIVITY,
  KINDNESS_ACTIVITY,
  CHARACTER_STRENGTHS_ACTIVITY,
  JOB_CRAFTING_ACTIVITY,
  COMMUNITY_COMMITMENTS_ACTIVITY,
  BOOK_RESOURCES_ACTIVITY,
]);

export function isActivity(object: any): boolean {
  return activitySet.has(object.type);
}
