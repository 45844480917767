import { isEmpty, unionWith } from 'lodash';
import { FETCH_HISTORY_SUCCEEDED } from '../other/fetch_history_action';
import { FETCH_LATEST_SUCCEEDED } from '../other/fetch_latest_action';
import { CREATE_ENTRY_SUCCEEDED } from './create_entry_action';
import { CREATE_SESSION_SUCCEEDED } from './create_section_action';
import { END_SESSION_SUCCEEDED } from './end_session_action';
import { FETCH_ACTIVE_SESSIONS_SUCCEEDED } from './fetch_active_sessions_action';
import { FETCH_CURRENT_SESSION_SUCCEEDED } from './fetch_current_session_action';
import { FETCH_SESSION_SUCCEEDED } from './fetch_session_action';
import { Session, SessionsState } from './session_types';

export function sessionsReducer(
  state: SessionsState | undefined,
  action: any,
): SessionsState {
  if (typeof state === 'undefined') {
    return [];
  }

  switch (action.type) {
    //
    case CREATE_ENTRY_SUCCEEDED: {
      // Returns an updated session with the new entry in it
      // Update the local store with the returned updated session
      return state.map((session: Session) => {
        if (session.id !== action.payload.id) {
          return session;
        }
        return action.payload;
      });
    }

    case CREATE_SESSION_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: CREATE SESSION SUCCEEDED');
        console.groupEnd();
      }
      // Returns the full created Session
      // There should be no session with the same id locally, but check anyway
      console.log(action);

      return unionWith(
        [action.payload.session],
        state,
        (server, local) => server.id === local.id,
      );

    case END_SESSION_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: END SESSION SUCCEEDED');
        console.groupEnd();
      }
      return state.map((session) => {
        if (session.id === action.payload.id) {
          return {
            ...session,
            isActive: false,
            stoppedAt: action.payload.stoppedAt,
          };
        }
        return session;
      });

    case FETCH_ACTIVE_SESSIONS_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: FETCH ACTIVE SESSIONS SUCCEEDED');
        console.log('Returned Active Sessions: ', action.payload);
        console.groupEnd();
      }
      if (isEmpty(action.payload)) {
        return state;
      }

      return unionWith(
        action.payload,
        state,
        (server, local) => server.id === local.id,
      );

    case FETCH_CURRENT_SESSION_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: FETCH CURRENT SESSION SUCCEEDED');
        console.groupEnd();
      }
      if (action.payload.isActive === false) {
        return state;
      }
      return unionWith(
        [action.payload],
        state,
        (server, local) => server.id === local.id,
      );

    case FETCH_LATEST_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: FETCH LATEST SUCCEEDED');
        console.groupEnd();
      }
      return unionWith(
        action.payload.sessions,
        state,
        (server, local) => server.id === local.id,
      );

    case FETCH_SESSION_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('REDUCER: FETCH A SESSION SUCCEEDED');
        console.groupEnd();
      }
      return unionWith(
        [action.payload],
        state,
        (server, local) => server.id === local.id,
      );

    case FETCH_HISTORY_SUCCEEDED:
      if (process.env.NODE_ENV !== 'production') {
        console.group('SESSION REDUCER: FETCH HISTORY SUCCEEDED');
        console.groupEnd();
      }
      return unionWith(
        action.payload.sessions,
        state,
        (server, local) => server.id === local.id,
      );

    default:
      return state;
  }
}
