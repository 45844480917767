import { httpsCallable } from 'firebase/functions';
import { FetchSessionsAction } from '../stores/session/fetch_sessions_action';
import FirebaseClient from './firebase_client';

/**
 * Fetch an array of Session given some query parameters
 * @param action
 */
export async function doFetchSessions(action: FetchSessionsAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: FETCH SESSIONS REQUESTED');
    console.log(action);
    console.groupEnd();
  }

  try {
    const results = (await httpsCallable(
      FirebaseClient.functions,
      'fetchSessions',
    )(JSON.stringify(action.payload))) as any;
    if (results.data.hasError) throw new Error(results.data);
    const sessions = results.data;
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: FETCH SESSIONS SUCCEEDED');
      console.log(sessions);
      console.groupEnd();
    }
    return sessions;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERRROR: FETCH SESSIONS FAILED');
      console.error(error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
}
