import { httpsCallable } from 'firebase/functions';
import { CreateSessionAction } from '../stores/session/create_section_action';
import { Session } from '../stores/session/session_types';
import FirebaseClient from './firebase_client';

/**
 * Creates a new Session on the server.
 * Errors if the Session.type is already active
 * Returns the fully created Session object
 * @param action
 */
export async function doCreateSession(action: CreateSessionAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: CREATE SESSION REQUESTED?');
    console.groupEnd();
  }

  try {
    const result = (await httpsCallable(
      FirebaseClient.functions,
      'createSession',
    )(JSON.stringify(action.payload))) as any;
    const createdSession: Session = result.data;

    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: CREATE SESSION SUCCEEDED');
      console.groupEnd();
    }

    return createdSession;
    //
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: CREATE SESSION FAILED');
      console.error('Error writing entry document: ', error);
      console.groupEnd();
    }

    return { hasError: true, error };
  }
}
