import React from 'react';

export const DateItem = (props: any) => (
  <div className='inline'>{new Date(props.date).toDateString()}</div>
);

export const ScheduledForDateItem = (props: any) => (
  <div className='inline'>{new Date(props.date).toDateString()}</div>
);

export const StoppedAtDateItem = (props: any) => (
  <div className='inline'>{new Date(props.date).toDateString()}</div>
);

export const TakenOnDateItem = (props: any) => (
  <div className='inline'>{new Date(props.date).toDateString()}</div>
);
