export const FETCH_MEASURES_REQUESTED = 'FETCH_MEASURES_REQUESTED';
export const FETCH_MEASURES_SUCCEEDED = 'FETCH_MEASURES_SUCCEEDED';
export const FETCH_MEASURES_FAILED = 'FETCH_MEASURES_FAILED';

export type FetchMeasuresActionPayload = {
  type: string;
  limit?: number;
  offset?: string;
  orderBy?: string;
};

export type FetchMeasuresAction = {
  type: typeof FETCH_MEASURES_REQUESTED;
  payload: FetchMeasuresActionPayload;
};

export const fetchMeasuresAction = (
  payload: FetchMeasuresActionPayload
): FetchMeasuresAction => ({
  type: FETCH_MEASURES_REQUESTED,
  payload,
});
