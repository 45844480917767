import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { endSessionAction } from '../../../../stores/session/end_session_action';
import { WarningModal } from './WarningModal';

export const EndSessionEarly = ({ id }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (confirm) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Will close the session');
      }
      // TODO: use ReactQuery
      dispatch(endSessionAction({ id, stoppedAt: Date.now() }));
    }
  }, [confirm, dispatch, id]);

  if (showWarning) {
    return (
      <WarningModal
        header='End early'
        text='Are you sure you want to end this activity early? All of your answers will be saved, but you will not be able to restart this same session.'
        handleCancel={setShowWarning}
        handleConfirm={setConfirm}
      />
    );
  }

  return (
    <div className=''>
      <div className=''>
        <h3 className='text-lg leading-6 font-medium text-gray-900'>
          End activity early
        </h3>
        <div className='mt-2 max-w-xl'>
          <p className='text-xs leading-160'>
            Ending this activity will save your data. You can always start a new
            session in the future but cannot restart this session.
          </p>
        </div>
        <div className='mt-5'>
          <button
            type='button'
            className='inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-burgundy border-burgundy sm:text-sm sm:leading-5 hover:shadow-xl'
            onClick={() => setShowWarning(true)}>
            End activity early
          </button>
        </div>
      </div>
    </div>
  );
};
