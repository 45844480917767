import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { useLocation } from 'react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { store, persistor } from './stores/store';
import { Footer } from './nav/NewFooter';
import { ScrollToTop } from './router/ScrollToTop';
import { PolicyBanner } from './components/PolicyBanner';
import { Router } from './router/router';
import FirebaseClient from './firebase/firebase_client';
import { logEvent } from 'firebase/analytics';
import { DesktopNavBar } from './nav/DesktopNavBar';
import { MobileNavBar } from './nav/MobileNavBar';

const queryClient = new QueryClient();

function PageViews() {
  let location = useLocation();

  React.useEffect(() => {
    logEvent(FirebaseClient.analytics, 'page_view', {
      page_location: location.pathname,
      page_path: window.location.pathname,
    });
  }, [location]);

  return <></>;
}

export const App = () => {
  console.group('DESIGNED AND DEVELOPED BY Donald E Frederick');
  console.log(
    'Please see https://defrederick.com/ for additional information. Thanks for peeking at the source code :)',
  );
  console.groupEnd();

  const [user, setUser] = useState(FirebaseClient.auth?.currentUser);

  useEffect(() => {
    return FirebaseClient.auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('The user is logged in', user, typeof user);
        // TODO: need to update the redux account store
        setUser(user);
      } else {
        setUser(null);
        console.log('The user is not logged in', user);
      }
    });
  }, []);
  // TODO: push this to the redux store
  // const seen = localStorage.getItem('policy-banner-seen');
  // console.log('Policy Banner', seen);

  return (
    <BrowserRouter>
      <PageViews />
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <PersistGate persistor={persistor}>
            <DesktopNavBar />
            <MobileNavBar />

            <PolicyBanner />
            <div className='relative min-h-screen lg:pt-16'>
              {/* {process.env.NODE_ENV === 'production' && <ScrollToTop />} */}
              <ScrollToTop />
              <Router />
            </div>
            <Footer />
          </PersistGate>
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  );
};
