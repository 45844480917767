import { filter } from 'lodash';

export const ANXIETY_ACTIVITY = 'ANXIETY_ACTIVITY';
export const BEST_SELF_ACTIVITY = 'BEST_SELF_ACTIVITY';
export const BOOK_RESOURCES_ACTIVITY = 'BOOK_RESOURCES_ACTIVITY';
export const CHARACTER_STRENGTHS_ACTIVITY = 'CHARACTER_STRENGTHS_ACTIVITY';
export const COMMUNITY_COMMITMENTS_ACTIVITY = 'COMMUNITY_COMMITMENTS_ACTIVITY';
export const DEPRESSION_ACTIVITY = 'DEPRESSION_ACTIVITY';
export const FORGIVENESS_ACTIVITY = 'FORGIVENESS_ACTIVITY';
export const GRATITUDE_ACTIVITY = 'GRATITUDE_ACTIVITY';
export const JOB_CRAFTING_ACTIVITY = 'JOB_CRAFTING_ACTIVITY';
export const KINDNESS_ACTIVITY = 'KINDNESS_ACTIVITY';
export const MARRIAGE_ACTIVITY = 'MARRIAGE_ACTIVITY';
export const RELIGION_ACTIVITY = 'RELIGION_ACTIVITY';
export const SAVORING_ACTIVITY = 'SAVORING_ACTIVITY';
export const VOLUNTEER_ACTIVITY = 'VOLUNTEER_ACTIVITY';
export const COGNITIVE_ACTIVITY = 'COGNITIVE_ACTIVITY';
export const BEHAVORIAL_ACTIVITY = 'BEHAVIORAL_ACTIVITY';
export const RELATIONAL_ACTIVITY = 'RELATIONAL_ACTIVITY';
export const WORKBOOK_ACTIVITY = 'WORKBOOK_ACTIVITY';

export type ACTIVITY_TYPE =
  | typeof ANXIETY_ACTIVITY
  | typeof BEST_SELF_ACTIVITY
  | typeof CHARACTER_STRENGTHS_ACTIVITY
  | typeof DEPRESSION_ACTIVITY
  | typeof FORGIVENESS_ACTIVITY
  | typeof GRATITUDE_ACTIVITY
  | typeof JOB_CRAFTING_ACTIVITY
  | typeof KINDNESS_ACTIVITY
  | typeof MARRIAGE_ACTIVITY
  | typeof RELIGION_ACTIVITY
  | typeof SAVORING_ACTIVITY
  | typeof VOLUNTEER_ACTIVITY
  | typeof COGNITIVE_ACTIVITY
  | typeof BEHAVORIAL_ACTIVITY
  | typeof RELATIONAL_ACTIVITY;

export const ACTIVITIES = {
  ANXIETY_ACTIVITY: {
    cat: WORKBOOK_ACTIVITY,
    catTitle: 'Workbook Exercise',
    title: 'Anxiety',
    type: ANXIETY_ACTIVITY,
    order: 2,
    img: 'anxiety.svg',
    urlSlug: 'anxiety',
  },
  BEST_SELF_ACTIVITY: {
    cat: COGNITIVE_ACTIVITY,
    catTitle: 'Cognitive Exercise',
    title: 'Best Self',
    type: BEST_SELF_ACTIVITY,
    order: 3,
    img: 'self.svg',
    urlSlug: 'best-self',
  },
  CHARACTER_STRENGTHS_ACTIVITY: {
    cat: BEHAVORIAL_ACTIVITY,
    catTitle: 'Behavioral Exercise',
    title: 'Character Strengths',
    type: CHARACTER_STRENGTHS_ACTIVITY,
    order: 1,
    img: 'character.svg',
    urlSlug: 'character-strengths',
  },
  DEPRESSION_ACTIVITY: {
    cat: WORKBOOK_ACTIVITY,
    catTitle: 'Workbook Exercise',
    title: 'Depression',
    type: DEPRESSION_ACTIVITY,
    order: 1,
    img: 'depression.svg',
    urlSlug: 'depression',
  },
  FORGIVENESS_ACTIVITY: {
    cat: WORKBOOK_ACTIVITY,
    catTitle: 'Workbook Exercise',
    title: 'Forgiveness',
    type: FORGIVENESS_ACTIVITY,
    order: 3,
    img: 'forgiveness.svg',
    urlSlug: 'forgiveness',
  },
  GRATITUDE_ACTIVITY: {
    cat: COGNITIVE_ACTIVITY,
    catTitle: 'Cognitive Exercise',
    title: 'Gratitude',
    type: GRATITUDE_ACTIVITY,
    order: 1,
    img: 'gratitude.svg',
    urlSlug: 'gratitude',
  },
  JOB_CRAFTING_ACTIVITY: {
    cat: RELATIONAL_ACTIVITY,
    catTitle: 'Relational Activity',
    title: 'Job Crafting',
    type: JOB_CRAFTING_ACTIVITY,
    order: 1,
    img: 'jobcrafting.svg',
    urlSlug: 'job-crafting',
  },
  KINDNESS_ACTIVITY: {
    cat: BEHAVORIAL_ACTIVITY,
    catTitle: 'Behavioral Exercise',
    title: 'Kindness',
    type: KINDNESS_ACTIVITY,
    order: 2,
    img: 'kindness.svg',
    urlSlug: 'kindness',
  },
  MARRIAGE_ACTIVITY: {
    cat: RELATIONAL_ACTIVITY,
    catTitle: 'Relational Activity',
    title: 'Marriage',
    type: MARRIAGE_ACTIVITY,
    order: 3,
    img: 'marriage.svg',
    urlSlug: 'marriage',
  },
  RELIGION_ACTIVITY: {
    cat: RELATIONAL_ACTIVITY,
    catTitle: 'Relational Activity',
    title: 'Religious Attendance',
    type: RELIGION_ACTIVITY,
    order: 2,
    img: 'religion.svg',
    urlSlug: 'religion',
  },
  SAVORING_ACTIVITY: {
    cat: COGNITIVE_ACTIVITY,
    catTitle: 'Cognitive Exercise',
    title: 'Savoring',
    type: SAVORING_ACTIVITY,
    order: 2,
    img: 'savoring.svg',
    urlSlug: 'savoring',
  },
  VOLUNTEER_ACTIVITY: {
    cat: BEHAVORIAL_ACTIVITY,
    catTitle: 'Behavioral Exercise',
    title: 'Volunteering',
    type: VOLUNTEER_ACTIVITY,
    order: 3,
    img: 'volunteer.svg',
    urlSlug: 'volunteer',
  },
};

export const COGNITIVE_ACTIVITIES = filter(
  ACTIVITIES,
  (a) => a.cat === COGNITIVE_ACTIVITY,
).sort((a, b) => a.order - b.order);

export const BEHAVORIAL_ACTIVITIES = filter(
  ACTIVITIES,
  (a) => a.cat === BEHAVORIAL_ACTIVITY,
).sort((a, b) => a.order - b.order);

export const RELATIONAL_ACTIVITIES = filter(
  ACTIVITIES,
  (a) => a.cat === RELATIONAL_ACTIVITY,
).sort((a, b) => a.order - b.order);

export const WORKBOOK_ACTIVITIES = filter(
  ACTIVITIES,
  (a) => a.cat === WORKBOOK_ACTIVITY,
).sort((a, b) => a.order - b.order);

export const ALL_ACTIVITIES = [
  ...COGNITIVE_ACTIVITIES,
  ...BEHAVORIAL_ACTIVITIES,
  ...RELATIONAL_ACTIVITIES,
  ...WORKBOOK_ACTIVITIES,
];
