import { httpsCallable } from 'firebase/functions';
import { CreateMeasureAction } from '../stores/measure/create_measure_action';
import FirebaseClient from './firebase_client';

/**
 * Creates a new Measure on the server
 * Returns the newly created Measure
 * @param action
 */
export async function doCreateAnonMeasure(action: CreateMeasureAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: CREATE ANON MEASURE REQUESTED');
    console.log(action);
    console.groupEnd();
  }

  try {
    const result = await httpsCallable(
      FirebaseClient.functions,
      'createAnonMeasure',
    )(JSON.stringify(action.payload));
    // if (result.data.hasError) throw new Error(result.data);

    const createdMeasure = result.data;

    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: CREATE MEASURE SUCCEEDED');
      console.log('result', result.data);
      console.groupEnd();
    }

    return createdMeasure;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: CREATE MEASURE FAILED');
      console.error('Error writing entry document: ', error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
}
