export const FETCH_MEASURE_REQUESTED = 'FETCH_MEASURE_REQUESTED';
export const FETCH_MEASURE_STARTED = 'FETCH_MEASURE_STARTED';
export const FETCH_MEASURE_SUCCEEDED = 'FETCH_MEASURE_SUCCEEDED';
export const FETCH_MEASURE_FAILED = 'FETCH_MEASURE_FAILED';

export type FetchMeasureAction = {
  type: typeof FETCH_MEASURE_REQUESTED;
  payload: {
    id: string;
  };
};

export const fetchMeasureAction = (payload: {
  id: string;
}): FetchMeasureAction => ({
  type: FETCH_MEASURE_REQUESTED,
  payload,
});
