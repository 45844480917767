import {
  CHARACTER_STRENGTHS_MEASURE,
  FLOURISHING_MEASURE,
  GRATITUDE_MEASURE,
  MEANING_MEASURE,
} from '../stores/measure/measure_type';

const measureSet = new Set([
  FLOURISHING_MEASURE,
  GRATITUDE_MEASURE,
  CHARACTER_STRENGTHS_MEASURE,
  MEANING_MEASURE,
]);

export function isMeasure(object: any): boolean {
  return measureSet.has(object.type);
}
