import { WELCOME_PAGE_DOMAINS_DESCRIPTION } from '../text/in_app_text';
import { Hero } from '../components/Hero';
import { ActivitiesCTA } from './about/ActivitiesCTA';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@heroicons/react/outline/ArrowRightIcon';
import { AboutMeasures } from './about/MeasuresAboutHomePage';
import { SignInUpForm } from '../components/SignInUp';
import { useSelector } from 'react-redux';
import { AppState } from '../types';

export const WelcomePage = () => {
  const isSignedIn = useSelector((state: AppState) => state.account.isSignedIn);

  return (
    <div className='relative text-burgundy'>
      <Helmet>
        <title>Flourishing App</title>
      </Helmet>

      <Hero />

      <main id='flourishing' className='relative overflow-hidden'>
        {/* FLOURISHING DEFINITION */}
        <section className='relative bg-not-gray'>
          {/* need an absolute container to put the image in */}
          <div className='hidden lg:block absolute inset-0 pr-20 h-600 w-1/2  object-contain lg:object-contain'>
            <img
              className='absolute objectcover right-4'
              src='/images/face-600.png'
              alt='smiling bw white'
            />
          </div>
          <div className='h-500 mx-auto max-w-7xl grid grid-cols-1 lg:grid-cols-12 place-items-center text-center'>
            <div className='lg:col-start-7 lg:col-span-4'>
              {/* show desktop */}
              <div
                className='mb-10 sm:hidden w-full bg-cover bg-center grid grid-cols-1 place-items-center text-center'
                style={{
                  backgroundImage: 'url(/images/photosynthesis-mobile.png)',
                  height: 121,
                }}>
                <h1 className='text-white text-6xl'>Flourishing</h1>
              </div>
              {/* mobile */}
              <img
                className='hidden sm:block mx-auto w-400'
                src='/images/flo_3x.png'
                alt='Flourishing text with a forest cutout'
              />

              <p className='px-4 xl:px-0 text-lg text-burgundy'>
                is living in{' '}
                <span className='font-bold'>
                  a state in which all aspects of a person's life are good
                </span>
              </p>
              <div className='px-4 xl:px-0 mt-5 text-xs'>
                <p className='font-bold text-sm text-burgundy leading-160'>
                  Prof Tyler J. VanderWeele, Ph.D.
                </p>
                <p className='text-burgundy leading-160'>
                  Director, Program on Human Flourishing
                </p>
                <p className='text-burgundy leading-160'>
                  The Institute for Quantitative Social Science
                </p>
                <p className='text-burgundy leading-160'>Harvard University</p>
              </div>
              {/*  */}
              <Link
                className='relative mt-5 inline-flex items-center p-2 pl-7 md:pl-11 w-72 md:w-80 text-center border border-burgundy rounded-full hover:shadow-2xl'
                role='button'
                to='/about/measures/flourishing'>
                <span className='whitespace-no-wrap'>
                  Measure your Flourishing
                </span>
                <ArrowRightIcon className='absolute right-2 h-5' />
              </Link>
            </div>
          </div>
        </section>

        {/* SIX DOMAINS OF FLOURISHING SECTION */}
        <section className='relative bg-not-white  text-burgundy'>
          <div className='pt-24' />
          <h1 className='text-5xl text-center leading-160'>
            Six Domains of Flourishing
          </h1>
          <div className='md:pt-24' />

          <div className=''>
            {/*   */}
            <div className='relative z-10 p-2 md:col-start-7 md:col-span-6'>
              <ul className='mt-5 mx-auto max-w-7xl grid grid-cols-1 lg:grid-cols-2 gap-0 md:gap-8'>
                {WELCOME_PAGE_DOMAINS_DESCRIPTION.map((item, i) => {
                  return (
                    <li className='block p-6' key={item.key}>
                      <div className='flex items-top space-x-4 lg:space-x-6'>
                        <img
                          className='w-16 h-16 lg:w-20 lg:h-20'
                          src={`/images/domain-${i + 1}.png`}
                          alt=''
                        />
                        <div className=''>
                          <h2 className='text-burgundy text-3xl font-kenoky leading-160'>
                            {item.header}
                          </h2>
                          <p className='text-gray-500 text-sm leading-160'>
                            {item.desc}
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className='pt-32 md:pt-56' />
        </section>

        <ActivitiesCTA about={true} />

        <AboutMeasures />

        {!isSignedIn && <SignInUpForm type='sign-up' isCTA={true} />}
      </main>
      {/* <div className='pb-56' /> */}
    </div>
  );
};
