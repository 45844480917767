import { httpsCallable } from 'firebase/functions';
import FirebaseClient from './firebase_client';

/**
 * Needed in order to keep the firestore user data in sync with the firebase
 * auth user data
 * @param action
 */
export async function doSyncUser(user) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: SYNC USER REQUESTED');
    // console.log(user);
    console.groupEnd();
  }

  try {
    const result = (await httpsCallable(
      FirebaseClient.functions,
      'syncUser',
    )()) as any;
    if (result.data.hasError) throw new Error(result.data);

    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: SYNC USER SUCCEEDED');
      console.groupEnd();
    }

    return result;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: SYNC USER FAILED');
      console.error('Error ', error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
}
