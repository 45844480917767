/**
 * Page for creating a new Activity Session Entry
 */
import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouterProps, useHistory } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

import { ACTIVITY_TEXT } from '../../../text/in_app_text';
import { createEntryAction } from '../../../stores/session/create_entry_action';
import { fetchSessionAction } from '../../../stores/session/fetch_session_action';
import { Entry } from '../../../stores/session/session_types';
import { AppState } from '../../../types';
import { getTitle } from '../../../utils';
import { getTypeFromUrl } from '../../../utils/get_type_from_url';
import { getUrlFromType } from '../../../utils/get_url_from_type';
import { ScheduledForDateItem } from '../../../components/DateItem';
import { EntryPageContainer } from '../../../components/containers/EntryPageContainer';
import { Helmet } from 'react-helmet';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useActiveSession } from '../../../api/firebase/hooks/use_active_session';
import { useSession } from '../../../api/firebase/hooks/use_session';
import { useMutation } from 'react-query';
import { httpsCallable } from 'firebase/functions';
import { FirebaseClient } from '../../../firebase';
import { Spinner } from '../../../components/Spinner';

type Props = {} & RouterProps &
  RouteComponentProps<{ type: string; sid: string; eid: string }>;

/**
 *
 * TODO: persist the text resposne so users don't accidentally lose it
 *
 */
export const NewActivitySessionEntry = (props: Props) => {
  const history = useHistory();
  const [startedAt] = useState(Date.now());
  const params = props.match.params;
  const type = getTypeFromUrl(params.type, props.match.path) || '';
  const { sid, eid } = params;

  const slug = getUrlFromType(type, true);
  const title = getTitle(type);

  const { data: session, isLoading } = useSession({ id: sid });

  const instructions = ACTIVITY_TEXT[type].newEntryInstructions;

  const entry = session?.entries.find((entry: Entry) => entry.uuid === eid) as
    | Entry
    | undefined;

  const validateText = (text: string) => {
    let error; // = `Word count: ${text.split(' ').length}`;
    if (text.length < 1) {
      error = 'Too few words';
    }
    return error;
  };

  const mutation = useMutation(
    async (text: string) => {
      const completedEntry: Entry = {
        ...(entry as Entry),
        startedAt,
        completed: true,
        stoppedAt: Date.now(),
        payload: { responses: [text] },
      };
      const response = await httpsCallable(
        FirebaseClient.functions,
        'createEntry_v2',
      )({ entry: completedEntry, sessionId: session?.id });

      return response;
    },
    {
      onSuccess: (response) => {
        // TODO: go to completed entry

        const slug = getUrlFromType(session.type, true);
        const to = `/app/activities/${slug}/sessions/${session.id}/entries/${entry?.uuid}/new`;

        history.push(to);
      },
    },
  );

  if (isLoading) {
    return (
      <div className='grid grid-cols-1 place-items-center pt-10'>
        Loading...
      </div>
    );
  }

  // TODO: if no entry, return a 404 / error
  // @ts-ignore
  const entryNumber = entry?.entryNumber + 1;

  return (
    <div className='bg-gray-100 min-h-screen'>
      <Helmet>
        <title>New Entry</title>
      </Helmet>
      <main className='mx-auto max-w-7xl px-2 py-0 sm:p-0 '>
        {/*  */}
        <header className='py-10 flex space-x-5 items-center'>
          <Link
            to={`/app/activities/${slug}`}
            className='inline-block bg-white rounded-full shadow cursor-pointer'>
            <ArrowLeftIcon className='p-2.5 h-12 w-12 text-burgundy' />
          </Link>
          <div className='h-12 w-12 flex justify-center items-center bg-burgundy text-white'>
            {entryNumber}
          </div>
          <h2>{title} Entry</h2>
        </header>
        {/*  */}
        <div className='py-20 bg-white text-center'>
          <div className='px-4 xl:px-0 mx-auto max-w-5xl'>
            <ScheduledForDateItem date={entry?.scheduledDate} />
            <h1 className='text-burgundy text-5xl leading-160'>
              {title} Entry
            </h1>

            <p className='mt-5 text-sm leading-160'>{instructions}</p>
            {/* <h2>Response</h2> */}
            {/* TODO: include a couple of checkbox/radio/yes/no questions. have you been doing this each day? etc. do this Sunday */}

            <Formik
              initialValues={{ response: '' }}
              onSubmit={(values) => {
                // FIXME: there might be a race condition here. can just put the logic tied to 'text' if the field is blank then don't submit
                mutation.mutate(values.response);
              }}>
              {({ values, errors, touched, isValidating }) => (
                <Form className='mt-8' translate='yes'>
                  {/* <label>Frequency</label>
            <Field
              name='frequency'
              as='select'
              className='block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
              validate={validateText}>
              {[
                [0, 'Never'],
                [1, 'Once a week'],
              ].map((v) => (
                <option key={v[0]} value={v[0]}>
                  {v[1]}
                </option>
              ))}
            </Field>
            <label>
              Since your last entry, on a scale of 0-10 how do you feel you are
              improving?
            </label> */}
                  {/* TODO: then put the writing prompt next. */}
                  {/* <br /> */}
                  <Field
                    name='response'
                    as='textarea'
                    className='form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    validate={validateText}
                  />
                  {/* TODO: display word count */}
                  {errors.response && touched.response && (
                    <p>{errors.response}</p>
                  )}
                  {!errors.response && (
                    <p className='mt-5 text-xs leading-160'>
                      Character count: {values.response.length}/10000
                    </p>
                  )}
                  {/*  */}
                  <div className='mt-8 '>
                    <div className='flex justify-center'>
                      <button
                        type='submit'
                        className='w-96 py-2 border border-burgundy text-burgundy rounded-full'>
                        {mutation.isLoading && (
                          <Spinner color='text-burgundy' />
                        )}
                        Save entry
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </main>
    </div>
  );
};
