import { httpsCallable } from 'firebase/functions';
import { CreateEntryAction } from '../stores/session/create_entry_action';
import { Session } from '../stores/session/session_types';
import FirebaseClient from './firebase_client';

/**
 * Creates a new Session.Entry on the server
 * Returns the full Session with the new Entry included and updated fields
 * @param action
 */
export async function doCreateEntry(action: CreateEntryAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: CREATE ENTRY REQUESTED');
    console.log(action);
    console.groupEnd();
  }

  try {
    const result = (await httpsCallable(
      FirebaseClient.functions,
      'createEntry',
    )(JSON.stringify(action.payload))) as any;
    if (result.data.hasError) throw new Error(result.data);
    const updatedSession: Session = result.data;

    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: CREATE ENTRY SUCCEEDED');
      console.groupEnd();
    }

    return updatedSession;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: CREATE ENTRY FAILED');
      console.error('Error writing entry document: ', error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
}
