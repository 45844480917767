import { ChevronRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import {
  Measure,
  CHARACTER_STRENGTHS_MEASURE,
} from '../../../stores/measure/measure_type';
import { getTitle } from '../../../utils';
import { getUrlFromType } from '../../../utils/get_url_from_type';

export const BaselineViews = ({ session }) => {
  const baseUrl = `/app/activities/${getUrlFromType(
    session.type,
    true,
  )}/sessions/${session.id}`;
  return (
    <div>
      <header className='pb-5'>
        <h3 className='py-2 text-lg font-bold capitalize'>
          Completed Baseline Entries and Measures
        </h3>

        <p className='text-sm'>
          Baseline entries and measures that you completed when you started this
          activity.
        </p>
      </header>

      <ul className='space-y-2'>
        {Object.keys(session.baseline).length > 0 && (
          <Link to={`${baseUrl}/baseline`}>
            <BaselineListItem toUrl={`${baseUrl}/baseline`} type='baseline' />
          </Link>
        )}

        {session.baselineReflection.length > 0 && (
          <Link to={`${baseUrl}/reflection`}>
            <BaselineListItem
              toUrl={`${baseUrl}/reflection`}
              type='reflection'
            />
          </Link>
        )}

        {session.baselineMeasures.map((measure: Measure) => {
          const title = `${getTitle(measure.type)}`;
          const to = `${baseUrl}/measures/${getUrlFromType(
            measure.type,
            false,
          )}/${measure.id}`;
          return (
            <Link to={to} key={measure?.id}>
              <BaselineListItem key={measure.type} type={title} toUrl={to} />
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

const BaselineListItem = ({ toUrl, type }) => {
  return (
    <div className='my-2.5 flex items-center justify-between shadow hover:shadow-md'>
      <div className='flex items-center space-x-5'>
        <div className='h-14 w-14 bg-burgundy text-white flex items-center justify-center capitalize'>
          {type[0]}
        </div>
        <h2 className='text-burgundy capitalize'>{type}</h2>
      </div>
      {/*  */}
      <div className='mr-10 flex items-center space-x-10'>
        <p className='hidden md:block px-5 py-1 border border-burgundy text-burgundy rounded-full'>
          Go to Entry
        </p>
        <ChevronRightIcon className='md:hidden h-5 text-burgundy' />
      </div>
    </div>
  );
};

/**
 * @deprecated
 * @param param0
 * @returns
 */
export const BaselineItem = ({ type, measure, toUrl }) => {
  let img = '';
  let header = '';
  // TODO: there should be more icons here?
  switch (type) {
    case CHARACTER_STRENGTHS_MEASURE:
      img = '/media/character.svg';
      header = 'Character Strengths';
      break;
    default:
      img = '/media/baseline.svg';
  }
  return (
    <Link to={toUrl}>
      <li className='col-span-1 flex flex-col text-center bg-white rounded-lg shadow'>
        <div className='flex-1 flex flex-col p-8'>
          <img
            className='w-32 h-32 flex-shrink-0 mx-auto bg-white'
            src={img}
            alt=''
          />
          <h3 className='mt-6 text-gray-900 text-sm leading-5 font-medium'>
            {header}
          </h3>
          {measure && (
            <dl className='mt-1 flex-grow flex flex-col overflow-hidden'>
              <dd className='mt-3 flex flex-wrap justify-center'>
                {measure &&
                  measure.type === CHARACTER_STRENGTHS_MEASURE &&
                  measure.payload.responses.map((response) => {
                    return (
                      <span
                        key={response}
                        className='px-2 py-1 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full'>
                        {response}
                      </span>
                    );
                  })}
              </dd>
            </dl>
          )}
        </div>
        {/* Button */}
        <ViewMoreBtn />
      </li>
    </Link>
  );
};

const ViewMoreBtn = () => {
  return (
    <div className='border-t border-gray-200'>
      <div className='-mt-px flex'>
        <div className='w-0 flex-1 flex border-r border-gray-200'>
          <div className='relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150'>
            <span className='ml-3'>View more</span>
          </div>
        </div>
      </div>
    </div>
  );
};
