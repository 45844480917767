import { collection, getDocs, query, where } from 'firebase/firestore';
import { useQuery } from 'react-query';
import { FirebaseClient } from '../../../firebase';
import { Session } from '../../../stores/session/session_types';
import { unpackSnapshotArray } from '../../../utils/unpack_snapshot_array';

export const useActivityHistory = () => {
  return useQuery(
    ['sessions'],
    async () => {
      const uid = FirebaseClient.auth.currentUser?.uid;
      if (!uid) throw new Error('User not logged in.');

      const ref = collection(FirebaseClient.db, '/users', uid, '/sessions');
      const q = query(ref, where('isActive', '==', false));
      const snapshots = await getDocs(q);

      if (snapshots.size === 0) {
        return [];
      }

      let data = unpackSnapshotArray({ snapshots }) as Session[];
      data = data.sort((a, b) => b.stoppedAt - a.stoppedAt);
      // console.log('data', data);

      return data;
    },
    { enabled: !!FirebaseClient.auth.currentUser?.uid },
  );
};
