import { httpsCallable } from 'firebase/functions';
import { FetchRecentMeasuresAction } from '../stores/measure/fetch_recent_measures_action';
import FirebaseClient from './firebase_client';

/**
 * Fetches the most recent measure from each of the Measures
 * @param action
 */
export async function doFetchRecentMeasures(action: FetchRecentMeasuresAction) {
  // TODO: create middleware logger
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: FETCH RECENT MEASURES REQUESTED');
    console.log(action);
    console.groupEnd();
  }

  try {
    const results = (await httpsCallable(
      FirebaseClient.functions,
      'fetchRecentMeasures',
    )()) as any;
    if (results.data.hasError) throw new Error(results.data);
    const measures = results.data;
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: FETCH RECENT MEASURES SUCCEEDED');
      console.log(measures);
      console.groupEnd();
    }
    return measures;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: FETCH RECENT MEASURES FAILED');
      console.error(error);
      console.groupEnd();
    }
    return { hasError: true, error, action };
  }
}
