import { NavState, UpdateNavAction, UPDATE_NAV_HEADER } from '../../types/nav';

export const navReducer = (state, action: UpdateNavAction): NavState => {
  if (typeof state === 'undefined') {
    return { header: '' };
  }

  if (action.type === UPDATE_NAV_HEADER) {
    return {
      header: action.payload.header,
    };
  }

  return state;
};
