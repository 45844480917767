import React from 'react';
import Helmet from 'react-helmet';

/**
 * TODO: rename this b/c I now use it in ActivitySession Intake pages
 * A container to wrap/style the Entry Pages
 * @param param0
 */
export const EntryPageContainer = ({
  header,
  subheader,
  helmet,
  children,
}: {
  header: string;
  subheader: string;
  helmet?: string;
  children: any;
}) => {
  return (
    <div>
      {helmet && (
        <Helmet>
          <title>{helmet}</title>
        </Helmet>
      )}
      <div className='bg-white overflow-hidden max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8'>
        <div className='max-w-3xl mx-auto'>
          <div className='text-base max-w-prose lg:max-w-none'>
            <p className='text-base leading-6 text-crimson font-semibold tracking-wide uppercase'>
              {subheader}
            </p>
            <h1 className='mt-2 mb-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10'>
              {header}
            </h1>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
