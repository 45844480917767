import React from 'react';
import { Helmet } from 'react-helmet';
import { RouterProps, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { CallToAction } from '../../components/CallToAction';
import { EXERCISE_GROUPS } from '../../text/activities';

type Props = {} & RouterProps & RouteComponentProps<{ exercise: string }>;

/**
 * @deprecated
 */
export const ExercisesAbout = (props: Props) => {
  const { exercise } = props.match.params;
  const data = EXERCISE_GROUPS[exercise];

  if (process.env.NODE_ENV !== 'production') {
    console.group('RENDER: EXERCISE ABOUT');
    console.log('exercise prop', exercise, data);
    console.groupEnd();
  }

  if (typeof data == 'undefined') {
    // TODO: redirect
    return <div />;
  }

  return (
    <div className='relative pt-4 sm:pt-16 pb-20'>
      <Helmet>
        {/* TODO: exercise name */}
        <title>About - Exercises</title>
      </Helmet>
      {/* Header */}
      <div className='relative'></div>
      <div className='relative'>
        <div className='relative max-w-7xl mx-auto'>
          <div className='text-center'>
            <h2 className='text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10'>
              {data.header}
            </h2>
            <p className='mt-3 max-w-2xl mx-auto text-xl whitespace-pre-line leading-7 text-gray-500 sm:mt-4'>
              {data.desc}
            </p>
          </div>
          <div className='mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none'>
            {data.activities.map((activity, i) => {
              return (
                <div
                  key={i}
                  className='flex flex-col rounded-lg shadow-lg overflow-hidden'>
                  <div className='flex-shrink-0'>
                    <Link to={`/about${activity.href}`}>
                      <img
                        className='h-48 w-full object-cover'
                        src={activity.img}
                        alt=''
                      />
                    </Link>
                  </div>
                  <div className='flex-1 bg-white p-6 flex flex-col justify-between'>
                    <div className='flex-1'>
                      <Link to={`/about${activity.href}`} className='block'>
                        <h3 className='mt-2 text-xl leading-7 font-semibold text-gray-900'>
                          {activity.header}
                        </h3>
                        <p className='mt-3 text-base leading-6 text-gray-500'>
                          {activity.desc}
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <CallToAction />
    </div>
  );
};
