import { GRATITUDE_MEASURE, Measure } from '../../stores/measure/measure_type';
import { MEASURE_TEXT, QUESTIONS } from '../../text/measures';

/**
 * Standard Result for Gratitude and Meaning Measures
 * @param param0
 * @returns
 */
export const MeasureResult = ({ measure }: { measure: Measure }) => {
  const questions = QUESTIONS[measure.type];

  const text = MEASURE_TEXT[measure.type];
  // const score = measure.payload.responses.reduce((v, s) => v + s, 0);
  let score = measure.payload.responses.reduce((sum: number, v) => {
    const val = typeof v === 'string' ? 0 : v;
    return sum + val;
  }, 0);

  score = (score / measure.payload.responses.length).toFixed(1);

  return (
    <div className='mt-10 mx-auto max-w-7xl'>
      <div className='relative flex items-center justify-center h-24 w-24 bg-burgundy rounded-full shadow'>
        <img className='p-2 h-20 w-20' src={text.iconWhite} alt='' />
      </div>
      <h1 className='text-burgundy text-5xl'>{text?.title} Results</h1>
      <p className='mt-5 text-burgundy'>
        {new Date(measure.stoppedAt).toDateString()}
      </p>
      <p className='mt-10 text-sm'>
        <span className='text-3xl text-burgundy font-bold'>{score}</span> /{' '}
        {/* {MAX_SCORES[measure.type]} */}7
      </p>
      <h2 className='mt-10'>Instructions</h2>
      <p className='mt-5 whitespace-pre-line'>{text.prompt}</p>
      <ul className='mt-10 space-y-5'>
        {measure.payload.responses.map((response, i) => (
          <li className='flex items-center space-x-5' key={i}>
            <p>{questions[i].q}</p>
            <div className='relative flex justify-center items-center h-10 w-10 bg-burgundy text-white text-center leading-160 rounded-full'>
              <span>{response}</span>
            </div>
          </li>
        ))}
      </ul>
      <div className='mt-20 text-burgundy'>
        <h2 className='my-2 font-bold'>Recommended Activities</h2>
        {measure.type === GRATITUDE_MEASURE ? (
          <div>Gratitude Activity</div>
        ) : (
          // FIXME: add activities
          <div>TBD Activities</div>
        )}
      </div>
    </div>
  );
};
