import { Listbox, RadioGroup, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/outline';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Fragment, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BasicLoader } from '../../components/loaders/BasicLoader';
import { Spinner } from '../../components/Spinner';
import { CHARACTER_STRENGTHS_MEASURE } from '../../stores/measure/measure_type';
import {
  CHARACTER_STRENGTHS_OPTIONS,
  MEASURE_TEXT,
  QUESTIONS,
} from '../../text/measures';
import { AppState } from '../../types';
import { createCompletedMeasure } from '../../utils/create_completed_measure';
import { useSearchQuery } from '../../utils/use_search_query';

/**
 * Wrapper for individual forms
 */
export const MeasureForm = ({ type, mutation }) => {
  // determine if anon or signed in
  const [startedAt] = useState(Date.now());
  const queryParams = useSearchQuery();

  const measure = MEASURE_TEXT[type];
  // console.log('measure', type, measure);

  const submit = (responses) => {
    const completedMeasure = createCompletedMeasure({
      type,
      startedAt,
      responses,
      campaign: queryParams.get('campaign') || 'default',
    });
    console.log('Completed', completedMeasure);

    mutation.mutate(completedMeasure);
  };

  return (
    <div className='relative px-5 xl:px-0 mt-10 max-w-5xl'>
      <header className='space-y-7'>
        <img className='h-28' src={measure?.iconBurgundy} alt='' />
        <h1 className='text-burgundy text-5xl leading-160'>{measure.title}</h1>
        <p className='leading-160'>{measure?.instructions}</p>
        <h2 className='text-base leading-160 font-bold'>Instructions</h2>
        <p className='leading-160'>{measure?.prompt}</p>
      </header>
      {/* FORM */}
      {type === CHARACTER_STRENGTHS_MEASURE ? (
        <CharacterStrengthsForm
          handleSubmit={submit}
          isLoading={mutation.isLoading}
        />
      ) : (
        <StandardForm
          type={type}
          handleSubmit={submit}
          isLoading={mutation.isLoading}
        />
      )}
    </div>
  );
};

export const StandardForm = ({
  type,
  handleSubmit,
  kind = 'about',
  isLoading,
}) => {
  const queryParams = useSearchQuery();
  const q = queryParams.get('q');
  const [campaign] = useState(queryParams.get('campaign') || 'default');
  const history = useHistory();
  const questions = QUESTIONS[type];
  const [answers, setAnswers] = useState(new Array(questions.length).fill(''));
  const ref = useRef<HTMLDivElement>(null);

  const questionNumber = q ? parseInt(q) : 0;
  // const [questionNumber, setQuestionNumber] = useState(q ? parseInt(q) : 0);

  console.log(questionNumber, answers);

  const handleKeyDown = (e: any, qn: number, val: number) => {
    if (typeof val !== 'number') return;
    // console.log('key press', e, e.keyCode);
    if (e.code === 'Space' || e.code === 'Enter') {
      const updatedAnswers = [...answers];
      updatedAnswers[qn] = val;
      setAnswers(updatedAnswers);
    }
  };

  const handleKeyUp = (e: any, qn: number, val: number) => {
    if (typeof val !== 'number') return;
    // console.log('key press', e, e.keyCode);
    if (e.code === 'Space' || e.code === 'Enter') {
      handleNext(qn, val);
    }
  };

  const handleMouseDown = (qn: number, val: number) => {
    if (typeof val !== 'number') return;
    const updatedAnswers = [...answers];
    updatedAnswers[qn] = val;
    setAnswers(updatedAnswers);
  };

  const handleMouseUp = (qn: number, val: number) => {
    if (typeof val !== 'number') return;
    const updatedAnswers = [...answers];
    updatedAnswers[qn] = val;
    setAnswers(updatedAnswers);
    handleNext(qn, val);
  };

  const handleNext = (qn: number, val: number) => {
    if (typeof val !== 'number') return;
    if (answers[qn] === '') return;

    if (qn < questions.length - 1) {
      const to = history.location.pathname;
      setTimeout(() => {
        let path = `${to}?q=${qn + 1}`;
        if (campaign) path += `&campaign=${campaign}`;
        history.push(path);
      }, 50);
      ref && ref?.current?.scrollIntoView();
    }
  };

  const handleBack = (qn: number) => {
    if (qn > 0) {
      const to = history.location.pathname;
      let path = `${to}?q=${qn - 1}`;
      if (campaign) path += `&campaign=${campaign}`;
      history.push(path);
      // history.push(`${to}?q=${qn - 1}`);
    }
  };

  const question = questions[questionNumber];

  const progress = (100 * (questionNumber + 1)) / questions.length;

  return (
    <div ref={ref} className='mt-20 max-w-7xl mx-auto'>
      <h2>
        Q {questionNumber + 1}{' '}
        <span className='text-sm'>/{questions.length}</span>
      </h2>
      <div
        id='progress'
        className='mt-2 overflow-hiddern bg-white h-4 mb-4 flex rounded-full'>
        <div
          style={{ width: `${progress}%` }}
          className='shadow-none flex flex-col bg-burgundy rounded-full'
        />
      </div>

      <div className='py-10 space-y-10'>
        <h2>{question.q}</h2>
        <div className='hidden md:block'>
          {/* need to render two rows */}
          {/* Render Buttons (answer values) */}
          {/* TODO: on mobile this needs to become a dropdown/select view */}
          {/* Desktop View */}
          <RadioGroup
            value={answers}
            className='flex justify-between'
            onChange={() => {}}>
            <RadioGroup.Label className='sr-only'>Question</RadioGroup.Label>
            {question.a.map((answer, i) => {
              return (
                <RadioGroup.Option
                  role='button'
                  value={answer.value}
                  className={`${
                    answers[questionNumber] === answer.value
                      ? 'bg-burgundy text-white'
                      : 'bg-white text-burgundy'
                  } h-10 w-10 flex justify-center items-center text-lg text-center shadow rounded-full font-kenoky font-light cursor-pointer`}
                  key={answer.value}
                  onMouseDown={() =>
                    handleMouseDown(questionNumber, answer.value)
                  }
                  onMouseUp={() => handleMouseUp(questionNumber, answer.value)}
                  onKeyDown={(e) =>
                    handleKeyDown(e, questionNumber, answer.value)
                  }
                  onKeyUp={(e) => handleKeyUp(e, questionNumber, answer.value)}>
                  <span className='focus:ring-none active:ring hover:outline-burgundy ing' />
                  <span className='bock'>{answer.value}</span>
                </RadioGroup.Option>
              );
            })}
          </RadioGroup>
          {/* Render anchor text */}
          <div className='mt-5 flex justify-between'>
            <p className='text-burgundy'>{question.a[0].text.split('-')[1]}</p>
            <p className='text-burgundy'>
              {question.a[question.a.length - 1].text.split('-')[1]}
            </p>
          </div>
        </div>

        {/* Mobile View Option 1 */}
        <div className='md:hidden'>
          {/* need to render two rows */}
          {/* Render Buttons (answer values) */}
          {/* TODO: on mobile this needs to become a dropdown/select view */}
          {/* Desktop View */}
          <RadioGroup value={answers} className='space-y-4' onChange={() => {}}>
            <RadioGroup.Label className='sr-only'>Question</RadioGroup.Label>
            {question.a.map((answer, i) => {
              return (
                <RadioGroup.Option
                  role='button'
                  value={answer.value}
                  className={`${
                    answers[questionNumber] === answer.value
                      ? 'bg-burgundy text-white'
                      : 'bg-white text-burgundy'
                  } py-2 flex justify-center items-center text-lg text-center shadow rounded-full font-kenoky font-light cursor-pointer flex-shrink-0`}
                  key={answer.value}
                  onMouseDown={() =>
                    handleMouseDown(questionNumber, answer.value)
                  }
                  onMouseUp={() => handleNext(questionNumber, answer.value)}
                  onKeyDown={(e) =>
                    handleKeyDown(e, questionNumber, answer.value)
                  }
                  onKeyUp={(e) => handleKeyUp(e, questionNumber, answer.value)}>
                  <span className='focus:ring-none active:ring hover:outline-burgundy ing' />
                  {/* <span className='bock'>{answer.value}</span> */}
                  {answer?.text}
                </RadioGroup.Option>
              );
            })}
          </RadioGroup>
        </div>

        {/* Mobile View Option 2 */}
        {/* I'm liking this once, but it should be a modal too */}
        {/* <div className='md:hidden'>
          <Listbox value={answers} onChange={() => {}}>
            <div className='relative'>
              <Listbox.Button className='relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm'>
                <span className='block truncate'>Select a response</span>
                <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                  <SelectorIcon
                    className='w-5 h-5 text-gray-400'
                    aria-hidden='true'
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <Listbox.Options className='absolute w-full py-2 mt-1 overflow-auto text-base bg-not-white rounded-md shadow-lg maxh-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm space-y-4'>
                  {question.a.map((answer, i) => {
                    return (
                      <Listbox.Option
                        role='button'
                        value={answer.value}
                        className={`${
                          answers[questionNumber] === answer.value
                            ? 'bg-burgundy text-white'
                            : 'bg-white text-burgundy'
                        } p-1 mx-4 flex justify-center items-center text-center shadow font-kenoky font-light rounded-full cursor-pointer`}
                        key={answer.value}
                        onMouseDown={() =>
                          handleMouseDown(questionNumber, answer.value)
                        }
                        onMouseUp={() =>
                          handleNext(questionNumber, answer.value)
                        }
                        onKeyDown={(e) =>
                          handleKeyDown(e, questionNumber, answer.value)
                        }
                        onKeyUp={(e) =>
                          handleKeyUp(e, questionNumber, answer.value)
                        }>
                        <span className='focus:ring-none active:ring hover:outline-burgundy' />
                        <span className='bock'>{answer?.text}</span>
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div> */}
        <div className='flex justify-between'>
          {questionNumber > 0 && (
            <button
              className='w-48 border border-burgundy py-2 text-burgundy rounded-full'
              onClick={() => handleBack(questionNumber)}>
              Back
            </button>
          )}
          {questionNumber === questions.length - 1 && (
            <button
              className='w-48 border border-burgundy py-2 text-burgundy rounded-full'
              onClick={() => handleSubmit(answers)}>
              {isLoading && <Spinner color='text-burgundy' />}
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

// FIXME: this validation isn't working in baseline
const validateCharacterStrengths = ({ responses }) => {
  let error;
  if (responses.length !== 5) {
    error = 'Please select five stengths.';
    return { hasError: true, message: error };
  }
  return error;
};

/**
 * Character Strengths New Entry Form
 * TODO: validation to make sure five selected
 *
 */
export const CharacterStrengthsForm = ({ handleSubmit, isLoading }) => {
  const isSignedIn = useSelector((s: AppState) => s.account.isSignedIn);
  return (
    <div className='mt-10'>
      <header className='overflow-x-auto'>
        {/* <h2>Prompt</h2> */}
        {/* <p>{instructions}</p> */}
        {/* <p>{prompt}</p> */}
        <p className=''>
          The test can be found here:{' '}
          <a
            className='underline'
            href='https://www.authentichappiness.sas.upenn.edu/questionnaires/brief-strengths-test'
            target='_blank'
            rel='noopener noreferrer'>
            https://www.authentichappiness.sas.upenn.edu/questionnaires/brief-strengths-test
          </a>
          .
        </p>
      </header>
      {/* TODO: only need to show the form for signed-in users */}
      {!isSignedIn ? (
        <p className='py-10 leading-160'>
          If you would like to save a record of your results here, please first
          log in or create an account and return to this page.
        </p>
      ) : (
        <Formik
          initialValues={{
            responses: [],
          }}
          onSubmit={(values, actions) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(values);
            }
            // TODO: debounce this
            handleSubmit(values.responses);
          }}
          validate={validateCharacterStrengths}>
          {({ values, errors, touched, isValidating }) => {
            if (process.env.NODE_ENV !== 'production') {
              // console.log(`Values:`, values);
              console.log(`Errors:`, errors);
            }
            return (
              <Form className='mt-10 py-10' translate='yes'>
                <header>
                  <h2 className='text-base text-burgundy font-bold leading-160'>
                    Character Strengths
                  </h2>
                  <p className='text-sm leading-160'>
                    Please select your five (5) strengths
                  </p>
                </header>
                <div className='mt-10' />
                <div id='checkbox-group' />
                <div role='group' aria-labelledby='checkbox-group'>
                  <FieldArray name='responses'>
                    {({ insert, remove, push }) => (
                      <ol className='list-none flex flex-wrap'>
                        {CHARACTER_STRENGTHS_OPTIONS.map((strength) => {
                          const checked = (
                            values.responses as string[]
                          ).includes(strength.text);
                          const disabled =
                            values.responses.length === 5 && !checked;
                          return (
                            <li key={strength.key} className='m-4 w-300'>
                              <label
                                className={`${
                                  checked
                                    ? 'bg-burgundy text-white'
                                    : 'bg-white text-burgundy'
                                } flex justify-center items-center space-x-2  border border-burgundy rounded-full`}>
                                <Field
                                  className='hidden'
                                  type='checkbox'
                                  name='responses'
                                  value={strength.text}
                                  checked={checked}
                                  disabled={disabled}
                                />
                                <span className='py-2 text-sm leading-160'>
                                  {strength.text}
                                </span>
                              </label>
                            </li>
                          );
                        })}
                        {/* <div className='text-red-400'>
                        <ErrorMessage
                          name={`responses`}
                          render={() => <span>Please select five strengths.</span>}
                        />
                      </div> */}
                      </ol>
                    )}
                  </FieldArray>
                </div>

                {!errors?.responses && touched && (
                  <div className='my-5 flex justify-center text-red-500'>
                    <span>'Please select five strengths'</span>
                  </div>
                )}

                <div className='mt-10 flex justify-center'>
                  <button className='w-48 border border-burgundy py-2 text-burgundy rounded-full'>
                    {isLoading && <Spinner color='text-burgundy' />}
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
