import { spawn } from 'redux-saga/effects';
import { accountSaga } from './account/account_sagas';
import { measureSaga } from './measure/measure_sagas';
import { notificationSaga } from './notifications/notification_sagas';
import { otherSaga } from './other/other_sagas';
import { entriesSaga } from './session/entries_saga';
import { sessionsSaga } from './session/sessions_sagas';

export default function* root() {
  yield spawn(accountSaga);
  yield spawn(entriesSaga);
  yield spawn(measureSaga);
  yield spawn(sessionsSaga);
  yield spawn(otherSaga);
  yield spawn(notificationSaga);
}
