import { httpsCallable } from 'firebase/functions';
import { FetchHistoryAction } from '../stores/other/fetch_history_action';
import FirebaseClient from './firebase_client';

export async function doFetchHistory(action: FetchHistoryAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: FETCH HISTORY REQUESTED');
    console.log(action);
    console.groupEnd();
  }

  try {
    const result = await httpsCallable(
      FirebaseClient.functions,
      'fetchHistory',
    )(JSON.stringify(action.payload));
    const results = result.data;
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: FETCH HISTORY SUCCEEDED');
      console.log(result);
      console.log(results);
      console.groupEnd();
    }
    return results;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: FETCH HISTORY FAILED');
      console.error(error);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
}
