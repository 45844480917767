import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Redirect, RouterProps, useParams } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ACTIVITY_IMAGES } from '../../../assets/activity_images';
import { NextStep } from '../../../components/buttons/NextStep';
import { EntryPageContainer } from '../../../components/containers/EntryPageContainer';
import { TEXT } from '../../../text/activities';

import { AppState } from '../../../types';
import { getTypeFromUrl } from '../../../utils/get_type_from_url';
import { getUrlFromType } from '../../../utils/get_url_from_type';

/**
 *
 * @param props
 * @returns
 */
export const IntakeContainer = (props: any) => {
  const newSession = useSelector((state: AppState) => state.newSession);
  const params = useParams<{ type: string }>();
  // const params = props.match.params;
  const type = getTypeFromUrl(params.type, props.match.path) || '';

  // if (typeof type === 'undefined') {
  //   console.log(404, type, params);
  //   console.groupEnd();
  //   return <Redirect to='/404' />;
  // }
  const activityUrl = getUrlFromType(type, true);

  const { instructions, header, hasBaseline } = TEXT[type];
  const toUrl = hasBaseline
    ? `/app/activities/${activityUrl}/new/baseline`
    : `/app/activities/${activityUrl}/new/reflection`;

  // if (newSession.type === '') {
  //   return <Redirect to='/' />;
  // }
  const img = ACTIVITY_IMAGES[type];

  // TODO: need to call new Session? could call this on the load of the instructions page
  // const handleBegin = () => {
  //   dispatch(newSessionAction({ type }));
  // };

  // Instructions

  // Baseline (if required)

  // Reflection

  // Measures

  // Schedule / Day Selection

  // Reroute to active session view

  return (
    // <div header={header} subheader='New Activity Session'>
    <div className='relative bg-gray-100 min-h-screen pb-32'>
      {/* <Helmet><title></title></Helmet> */}
      <img
        className='h-96 w-full object-cover object-center'
        src={img}
        alt=''
      />

      <div className='relative z-10 px-20 py-10 h-96 mx-auto max-w-7xl grid grid-cols-1 place-item-center -mt-32 bg-white text-center'>
        <h1 className='text-burgundy text-5xl'>{header}</h1>
        <p className='whitespace-pre-line leading-7 text-gray-500'>
          {instructions}
        </p>
        INSTRUCTIONS
        {props.children}
        <div>
          <Link
            className='mx-auto px-36 py-2 border border-burgundy text-burgundy rounded-full'
            to={toUrl}>
            Next Step
          </Link>
        </div>
      </div>
    </div>
  );
};
