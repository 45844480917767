import { fill, values } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';

import * as uuid from 'uuid';

import { CHARACTER_STRENGTHS_OPTIONS, QUESTIONS } from '../../../text/measures';
import { createMeasureAction } from '../../../stores/measure';
import {
  FLOURISHING_MEASURE,
  Measure,
  MEANING_MEASURE,
  GRATITUDE_MEASURE,
  CHARACTER_STRENGTHS_MEASURE,
} from '../../../stores/measure/measure_type';
import { updateNewSessionAction } from '../../../stores/session/new_session_action_creators';
import { AppState } from '../../../types';
import { getUrlFromType } from '../../../utils/get_url_from_type';
import { Field, Form, Formik, FieldArray, ErrorMessage } from 'formik';
import { RouterProps } from 'react-router';
import { EntryPageContainer } from '../../../components/containers/EntryPageContainer';
import { getTitle } from '../../../utils/get_title';
import { MEASURE_TEXT } from '../../../text/measures';
import { BasicLoader } from '../../../components/loaders/BasicLoader';
import { useMutation } from 'react-query';

type Props = RouterProps &
  RouteComponentProps<{ activity?: string; measure: string }>;

const mapMeasure = new Map([
  ['character-strengths', CHARACTER_STRENGTHS_MEASURE],
  ['flourishing', FLOURISHING_MEASURE],
  ['gratitude', GRATITUDE_MEASURE],
  ['meaning', MEANING_MEASURE],
]);

export const createCompletedMeasure = ({
  type,
  startedAt,
  responses,
}): Measure => ({
  id: '',
  isBaseline: false,
  isIntake: false,
  sessionId: '',
  cuuid: uuid.v4(),
  type,
  completed: true,
  startedAt,
  stoppedAt: Date.now(),
  payload: { responses },
});

/**
 * Container for handling the logic of new Measure Entries
 * - should be able to combine most of the logic and just have different forms for each individual measure
 * - there are individual forms for each of the measures and these take a onSubmit callback that they use to pass back the response array
 * TODO: need to branch on if this is standalone or part of a new session
 * @deprecated
 */
export const NewMeasureEntry = ({ match }: Props) => {
  const isNewSession = typeof match.params.activity !== 'undefined';

  const newSession = useSelector((state: AppState) => state.newSession);
  const network = useSelector((state: AppState) => state.network);

  const dispatch = useDispatch();
  const [cancelled, setCancelled] = useState(false);
  const [dispatched, setDispatched] = useState(false);
  const [busy, setBusy] = useState(false);
  const [startedAt] = useState(Date.now());
  const [responses, setResponses] = useState([]);

  const type = mapMeasure.get(match.params.measure) || '';

  // if (typeof type === 'undefined') {
  //   return <Redirect to='/404' />;
  // }

  const title = getTitle(type);
  const typeUrl = getUrlFromType(type, false);

  const handleCancel = () => {
    setCancelled(true);
  };

  const handleSubmit = (responses) => {
    // if (process.env.NODE_ENV !== 'production') {
    //   console.log('handling responses', responses);
    // }
    // //unpack responses
    // setResponses(responses);
    // setBusy(true);
  };

  // TODO: need to branch between a measure as part of an activity intake and a standalone measure
  const mutation = useMutation(async () => {
    const completedMeasure = createCompletedMeasure({
      type,
      startedAt,
      responses,
    });

    // if embedded as part of an activity new session process
    if (isNewSession) {
      completedMeasure.isBaseline = true;
      let added = false;
      const updatedBaselineMeasures = newSession.baselineMeasures.filter(
        (measure) => {
          if (measure.type === type) {
            added = true;
            return completedMeasure;
          }
          return measure;
        },
      );
      if (!added) {
        updatedBaselineMeasures.push(completedMeasure);
      }
      const updatedNewSession = {
        ...newSession,
        baselineMeasures: updatedBaselineMeasures,
      };
      dispatch(updateNewSessionAction(updatedNewSession));
      return setDispatched(true);
    }
  });

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('use effect', busy);
      console.log('responses', responses);
      console.log('isNewSession', isNewSession);
    }
    if (busy) return;
    if (responses.length > 0 && !network.createMeasureIsBusy) {
      const completedMeasure = createCompletedMeasure({
        type,
        startedAt,
        responses,
      });

      // if embedded as part of an activity new session process
      if (isNewSession) {
        completedMeasure.isBaseline = true;
        let added = false;
        const updatedBaselineMeasures = newSession.baselineMeasures.filter(
          (measure) => {
            if (measure.type === type) {
              added = true;
              return completedMeasure;
            }
            return measure;
          },
        );
        if (!added) {
          updatedBaselineMeasures.push(completedMeasure);
        }
        const updatedNewSession = {
          ...newSession,
          baselineMeasures: updatedBaselineMeasures,
        };
        dispatch(updateNewSessionAction(updatedNewSession));
        return setDispatched(true);
      }
      // if 'normal' entry
      if (process.env.NODE_ENV !== 'production') {
        console.log('Create Measure Normal', completedMeasure);
      }

      dispatch(createMeasureAction(completedMeasure));
      return setDispatched(true);
    }
  }, [busy, responses, network.createMeasureIsBusy]);

  // FIXME: there's a logic error here with how dispatch works. fix.
  // the major branch has to be isNewSession
  if (isNewSession) {
    if (cancelled) {
      return (
        <Redirect
          push
          to={`/app/activities/${match.params.activity}/new/measures/`}
        />
      );
    }
    if (dispatched) {
      return (
        <Redirect
          push
          to={`/app/activities/${match.params.activity}/new/measures`}
        />
      );
    }
  }

  if (cancelled) {
    return <Redirect push to={`/app/measures/${typeUrl}`} />;
  }

  //FIXME: There's a race condition here / something overly complicated
  if (
    dispatched &&
    network.createMeasureIsBusy === false &&
    network.createMeasureIsFinished === true
  ) {
    return <Redirect push to={`/`} />;
    // return <Redirect to={`/app/measures/${typeUrl}`} />;
  }

  return (
    <EntryPageContainer
      header={`${title} Measure Entry`}
      subheader='New'
      helmet='New Measure'>
      {type === CHARACTER_STRENGTHS_MEASURE ? (
        <CharacterStrengthsForm
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
        />
      ) : (
        <MeasureForm
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          type={type}
        />
      )}
    </EntryPageContainer>
  );
};

function validate(value) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('Value', value);
  }
  let error;
  if (value === '' || value === null) {
    error = 'Please select a response.';
  }
  return error;
}

/**
 *
 */
export const MeasureForm = ({ handleSubmit, handleCancel, type }) => {
  const questions = QUESTIONS[type];
  if (process.env.NODE_ENV !== 'production') {
    console.log(type, questions);
  }

  const { prompt } = MEASURE_TEXT[type];

  return (
    <div className='prose'>
      {/* <h2>Instructions</h2> */}
      {/* <p>{instructions}</p> */}
      <h2>Prompt</h2>
      <p>{prompt}</p>
      <h2>Questions</h2>

      <Formik
        initialValues={{
          responses: new Array(questions.length).fill(''),
        }}
        onSubmit={(values, actions) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Submit values: ${values}`);
          }
          handleSubmit(values.responses.map((r) => parseInt(r)));
        }}>
        {({ values, errors, touched, isValidating }) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Errors', errors, touched, isValidating);
          }
          return (
            <Form translate='yes'>
              <FieldArray name='responses'>
                {({ insert, remove, push }) => (
                  <ol className='list-none'>
                    {/* Enumerate the questions */}
                    {values.responses.map((response, index) => {
                      const { q, a } = questions[index];
                      return (
                        <li key={index} className='py-2'>
                          <label htmlFor={`responses.${index}`}>{q}</label>
                          <br />
                          <Field
                            as='select'
                            name={`responses.${index}`}
                            validate={validate}
                            className=''>
                            <option key='default' value=''>
                              Select
                            </option>
                            {a.map((answer, i) => (
                              <option key={answer.key} value={answer.value}>
                                {answer.text}
                              </option>
                            ))}
                          </Field>
                          <div className='text-red-400'>
                            <ErrorMessage name={`responses.${index}`} />
                          </div>
                        </li>
                      );
                    })}
                  </ol>
                )}
              </FieldArray>
              <span className='inline-flex rounded-md shadow-sm float-right'>
                <button
                  type='reset'
                  onClick={() => handleCancel()}
                  className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-gray bg-white hover:text-gray-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-gray-50 transition ease-in-out duration-150'>
                  Cancel
                </button>
                <button
                  type='submit'
                  className='inline-flex items-center ml-2 px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-crimson hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150'>
                  <BasicLoader type={'createMeasureIsBusy'} />
                  Submit
                </button>
              </span>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

// FIXME: this validation isn't working in baseline
const validateCharacterStrengths = ({ responses }) => {
  let error;
  if (responses.length !== 5) {
    error = 'Please select five stengths.';
    return { resposnes: error };
  }
  return error;
};

/**
 * Character Strengths New Entry Form
 * TODO: validation to make sure five selected
 *
 */
export const CharacterStrengthsForm = ({ handleCancel, handleSubmit }) => {
  // const { instructions, prompt } = MEASURE_TEXT[CHARACTER_STRENGTHS_MEASURE];
  return (
    <div className='prose'>
      <h2>Prompt</h2>
      {/* <p>{instructions}</p> */}
      {/* <p>{prompt}</p> */}
      <p>
        The Character Strengths measure identifies your top five character
        strengths as well as the degree of each strength. To take this please go
        to this website:{' '}
        <a
          href='https://www.authentichappiness.sas.upenn.edu/questionnaires/brief-strengths-test'
          target='_blank'
          rel='noopener noreferrer'>
          https://www.authentichappiness.sas.upenn.edu/questionnaires/brief-strengths-test
        </a>
        . You will need to register in order to take the measure. Once you've
        completed the measure and found your strengths, please return here and
        mark your <em>top five</em> strengths.
      </p>
      <h2>Character Strengths</h2>

      <Formik
        initialValues={{
          responses: [],
        }}
        onSubmit={(values, actions) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(values);
          }
          // TODO: debounce this
          handleSubmit(values.responses);
        }}
        validate={validateCharacterStrengths}>
        {({ values, errors, touched, isValidating }) => {
          if (process.env.NODE_ENV !== 'production') {
            // console.log(`Values:`, values);
            console.log(`Errors:`, errors);
          }
          return (
            <Form translate='yes'>
              <div id='checkbox-group' />
              <div role='group' aria-labelledby='checkbox-group'>
                <FieldArray name='responses'>
                  {({ insert, remove, push }) => (
                    <ol className='list-none'>
                      {CHARACTER_STRENGTHS_OPTIONS.map((strength) => {
                        const checked = (values.responses as string[]).includes(
                          strength.text,
                        );
                        const disabled =
                          values.responses.length === 5 && !checked;
                        return (
                          <div key={strength.key} className='py-2'>
                            <label className=' text-gray-800'>
                              <span className='px-2'>
                                <Field
                                  type='checkbox'
                                  name='responses'
                                  value={strength.text}
                                  checked={checked}
                                  disabled={disabled}
                                />
                              </span>
                              {strength.text}
                            </label>
                          </div>
                        );
                      })}
                      {/* <div className='text-red-400'>
                        <ErrorMessage
                          name={`responses`}
                          render={() => <span>Please select five strengths.</span>}
                        />
                      </div> */}
                    </ol>
                  )}
                </FieldArray>
              </div>

              {values.responses.length < 5 && touched && (
                <div className='text-red-400'>
                  'Please select five strengths'
                </div>
              )}

              <span className='inline-flex rounded-md shadow-sm float-right'>
                <button
                  type='reset'
                  onClick={() => handleCancel()}
                  className='inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-gray bg-white hover:text-gray-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-gray-50 transition ease-in-out duration-150'>
                  Cancel
                </button>
                <button
                  type='submit'
                  className='inline-flex items-center ml-2 px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-crimson hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150'>
                  <BasicLoader type={'createMeasureIsBusy'} />
                  Submit
                </button>
              </span>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
