export const CREATE_ACCOUNT_WITH_EMAIL_REQUESTED =
  'CREATE_ACCOUNT_WITH_EMAIL_REQUESTED';
export const CREATE_ACCOUNT_WITH_EMAIL_SUCCEEDED =
  'CREATE_ACCOUNT_WITH_EMAIL_SUCCEEDED';
export const CREATE_ACCOUNT_WITH_EMAIL_FAILED =
  'CREATE_ACCOUNT_WITH_EMAIL_FAILED';

export type CreateAccountWithEmailAction = {
  type: typeof CREATE_ACCOUNT_WITH_EMAIL_REQUESTED;
  payload: {
    email: string;
    password: string;
  };
};

export const createAccountWithEmailAction = ({
  email,
  password,
}: {
  email: string;
  password: string;
}): CreateAccountWithEmailAction => ({
  type: CREATE_ACCOUNT_WITH_EMAIL_REQUESTED,
  payload: { email, password },
});
