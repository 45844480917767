import React, { useState } from 'react';
import { RouterProps, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { mapMeasure } from '../../utils/get_type_from_url';
import { AppState } from '../../types';
import { MEASURE_TEXT } from '../../text/measures';
import { Helmet } from 'react-helmet';

type Props = {} & RouterProps & RouteComponentProps<{ measure: string }>;

export const MeasureAbout = (props: Props) => {
  const params = props.match.params;
  const type = mapMeasure.get(params.measure);
  // const [responses, setResponses] = useState(false);
  // const [startedAt] = useState(Date.now());
  // const type = getTypeFromUrl(measure, props.match.path);

  const isSignedIn = useSelector((state: AppState) => state.account.isSignedIn);

  // @ts-ignore
  const measure = MEASURE_TEXT[type];

  let to = isSignedIn
    ? `/app/measures/${params.measure}/new`
    : `/about/measures/${params.measure}/new`;

  // Get query params and pass them on to the new measure page
  const query = new URLSearchParams(props.location.search);
  if (!!query) {
    to += `?${query.toString()}`;
  }



  // if (typeof measureData === 'undefined') {
  //   return <Redirect to='/404' />;
  // }

  return (
    <div className='overflow-hidden'>
      <Helmet>
        <title>{measure.title}</title>
      </Helmet>

      <div className='relative min-h-screen bg-burgundy text-white text-center'>
        <main className='max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 space-y-10'>
          {/*  */}
          <img className='h-24 mx-auto' src={measure.iconWhite} alt='' />
          {/*  */}
          <h1 className='text-5xl'>{measure.title}</h1>
          {/*  */}
          <div>
            <p className='max-w-3xl mx-auto text-white leading-160'>
              {measure.about}
            </p>

            <Link
              className='mt-10 inline-block py-2 w-80 bg-white rounded-full text-burgundy'
              to={to}>
              Take Measure
            </Link>

            <h2 className='mt-10 text-xl font-kenoky'>
              Science Behind the Measure
            </h2>
            <ul className='mt-5 mx-auto max-w-3xl space-y-5 text-sm'>
              {measure.refs.map((ref) => {
                return (
                  <li key={ref.text} className='text-white'>
                    <a
                      className=''
                      href={`${ref.link}`}
                      target='_blank'
                      rel='noopener noreferrer'>
                      {ref.text}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </main>
      </div>
    </div>
  );
};

const CharacterStrengthsMeasure = () => {
  return (
    <div>
      <div className='prose text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1'>
        {/* <h2>Instructions/Overview</h2>
        <p>
          The Character Strength measure identifies the degree of each of the
          twenty-four character strengths. These character strengths are
          cross-cultural and everyone has some degree of each of them. These
          strengths are clustered around six virtue categories: Courage
          (bravery, honesty, zest, perseverance), Humanity (kindness, love,
          social intelligence), Justice (fairness, leadership, teamwork),
          Temperance (forgiveness, humility, self-regulation, prudence),
          Transcendence (appreciation of beauty and excellence, gratitude, hope,
          humor, spirituality), and Wisdom (creativity, curiosity, judgment,
          love of learning, perspective). To learn more about the original
          research, please go to{' '}
          <a
            href='https://www.viacharacter.org/character-strengths'
            target='_blank'
            rel='noopener noreferrer'>
            https://www.viacharacter.org/character-strengths{' '}
          </a>
        </p> */}
        <h3>Take it!</h3>
        <p>
          The Character Strengths measure identifies your top five character
          strengths as well as the degree of each strength. To take this please
          go to this website:
          <a
            href='https://www.authentichappiness.sas.upenn.edu/questionnaires/brief-strengths-test'
            target='_blank'
            rel='noopener noreferrer'>
            https://www.authentichappiness.sas.upenn.edu/questionnaires/brief-strengths-test.{' '}
          </a>
          You will need to register in order to take the measure.
        </p>
      </div>
    </div>
  );
};
