/**
 * Manage Users email accounts
 * https://firebase.google.com/docs/auth/web/manage-users
 *
 * FIXME: need to make sure the delete account currently reauthenticates users prior
 */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';

import { RouteComponentProps } from 'react-router';
import { AppState } from '../types';
import { BasicContainer } from '../components/containers/BasicContainer';
import { WarningModal } from './v2/activities/in_progress/WarningModal';
import { deleteAccount } from '../stores/account/delete_account_action';

import firebase from '../firebase/firebase_client';

import { doVerifyEmail } from '../auth/verify_email';
import { setWeeklyEmailRemindersAction } from '../stores/account/weekly_email_reminders_action';
import { BasicLoader } from '../components/loaders/BasicLoader';
import { sendEmailVerificationAction } from '../stores/account/send_email_verification_action';
import { changePasswordAction } from '../stores/account/change_password_action';
import { fetchAccountAction } from '../stores/account/fetch_account';
import { Helmet } from 'react-helmet';

type Props = {} & RouteComponentProps;

export const AccountPage = (props: Props) => {
  const account = useSelector((state: AppState) => state.account);
  const network = useSelector((state: AppState) => state.network);
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const [display, setDisplay] = useState('');

  const [sendVerificationEmail, setSendVerificationEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [doChangePassword, setDoChangePassword] = useState(false);
  const [weeklyEmailReminders, setWeeklyEmailReminders] = useState(
    account.weeklyEmailReminders,
  );

  //way to pull from firebase latest user info?
  const fUser = firebase.auth.currentUser;
  console.log('FIREBASE CURRENT USER', fUser);

  const handleCancel = () => {
    setShowWarning(false);
  };

  const handleConfirm = () => {
    setConfirmed(true);
  };

  // useEffect(() => {
  //   if (!network.changePasswordIsBusy && doChangePassword) {
  //     dispatch(changePasswordAction({ currentPassword, newPassword }));
  //   }
  // }, [doChangePassword, currentPassword, newPassword]);

  useEffect(() => {
    dispatch(fetchAccountAction());
  }, []);

  useEffect(() => {
    if (
      !network.sendVerificationEmailIsBusy &&
      !account.isEmailVerified &&
      sendVerificationEmail &&
      !emailSent
    ) {
      setEmailSent(true);
      // console.log('SENDING EMAIL');
      dispatch(sendEmailVerificationAction());
    }
  }, [emailSent, sendVerificationEmail, account.isEmailVerified]);

  useEffect(() => {
    if (weeklyEmailReminders !== account.weeklyEmailReminders) {
      dispatch(setWeeklyEmailRemindersAction({ status: weeklyEmailReminders }));
    }
  }, [weeklyEmailReminders]);

  useEffect(() => {
    if (confirmed) {
      dispatch(deleteAccount());
    }
  }, [confirmed]);

  return (
    <div>
      <Helmet>
        <title>Account</title>
      </Helmet>
      {showWarning && (
        <WarningModal
          header=''
          text='Are you sure you want to permanently delete this account?'
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />
      )}
      <header className='px-5 py-20 max-w-7xl mx-auto bg-white'>
        <h1 className='text-5xl text-burgundy leading-160'>Account</h1>
        <p className='text-sm leading-160'>
          Here, you'll find information about your account, activity reminder
          settings, etc.
        </p>
      </header>

      <main className='py-10 bg-gray-100'>
        <div className='px-4 xl:px-0 mx-auto max-w-7xl'>
          <dl>
            <DItem title={'Full name'} data={account.fullname} />
            <DItem title={'Email'} data={account.email} />

            {/* Email Verified  */}
            <section className='py-10 flex justify-between border-b'>
              <dt className=''>Email verified</dt>
              {/* if not true, show button */}
              <dd className=''>
                <div className='relative flex items-start'>
                  <div className='flex items-center h-5'>
                    {account.isEmailVerified ? (
                      <input
                        id='comments'
                        type='checkbox'
                        className='form-checkbox h-4 w-4 text-burgundy transition duration-150 ease-in-out'
                        checked={account.isEmailVerified}
                        onChange={() => {}}
                      />
                    ) : (
                      // TODO: need a pop-up that says email sent
                      // TODO: need a loader
                      <button
                        className='bg-white border border-burgundy px-2 py-1 rounded-lg text-burgundy'
                        onClick={() => setSendVerificationEmail(true)}>
                        <BasicLoader type={'sendVerificationEmailIsBusy'} />
                        {sendVerificationEmail
                          ? 'Verification email sent'
                          : 'Resend verification email'}
                      </button>
                    )}
                  </div>
                </div>
              </dd>
            </section>

            {/* ACTIVITY REMINDERS */}
            {/* Only possible if email is verified */}
            {/* TODO: this needs a loader signal */}
            <section className='py-10 flex justify-between border-b'>
              <dt className=''>
                <span>Activity reminders</span>
                <span className='block text-sm leading-160'>
                  Get weekly email reminders of activities in progress at the
                  start of the week.
                </span>
              </dt>
              <dd className=''>
                <div className='relative flex items-start'>
                  <div className='flex items-center h-5'>
                    {/* TODO: use Formik here too */}
                    <input
                      id='comments'
                      type='checkbox'
                      className='form-checkbox h-4 w-4 text-burgundy transition duration-150 ease-in-out'
                      disabled={!account.isEmailVerified}
                      checked={
                        account.isEmailVerified && account.weeklyEmailReminders
                      }
                      onChange={() =>
                        setWeeklyEmailReminders(!weeklyEmailReminders)
                      }
                    />
                  </div>
                  <div className='ml-3 text-sm leading-5'>
                    <label
                      htmlFor='comments'
                      className={` ${
                        !account.isEmailVerified
                          ? 'text-gray-700'
                          : 'text-gray-100'
                      }font-medium`}></label>
                  </div>
                </div>
              </dd>
            </section>
            {/*  */}
            <DItem title={'Account type'} data={account.type} />
            {/*  */}
          </dl>

          {/* Created On: */}
          {/* Last Signed In:  */}

          {/*  */}

          {account.type === 'EMAIL_ACCOUNT' && (
            <div className='my-10 flex justify-center'>
              <button
                className='ml-2 py-2 px-4 rounded-full bg-white border border-burgundy text-burgundy text-sm leading-6'
                onClick={() =>
                  display === 'password'
                    ? setDisplay('')
                    : setDisplay('password')
                }>
                Change password
              </button>
            </div>
          )}

          {display === 'password' && (
            <div className='prose mb-20 mt-4 p-2 border'>
              <Formik
                initialValues={{ currentPassword: '', newPassword: '' }}
                onSubmit={(values) => {
                  // setCurrentPassword(values.currentPassword);
                  // setNewPassword(values.newPassword);
                  // setDoChangePassword(true);
                  dispatch(
                    changePasswordAction({ currentPassword, newPassword }),
                  );
                }}>
                {({ values, errors, touched, isValidating }) => (
                  <Form translate='yes'>
                    <label>Current Password</label>
                    <br />
                    <Field
                      id='currentPassword'
                      name='currentPassword'
                      type='password'
                      placeholder='************'
                      autoComplete='password'
                      required
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    />

                    <label>New password</label>
                    <br />
                    <Field
                      id='newPassword'
                      name='newPassword'
                      type='password'
                      placeholder='************'
                      required
                      className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'
                    />

                    <div className='p-2 flex justify-end'>
                      <button
                        type='reset'
                        className='bg-white border border-burgundy text-burgundy py-1 px-4 rounded-lg'
                        onClick={() => setDisplay('')}>
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='bg-burgundy text-white ml-2 py-1 px-4 rounded-lg'>
                        <BasicLoader type={'changePasswordIsBusy'} />
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}

          <div className='mt-10 flex justify-center'>
            <button
              className='px-10 py-2 round-lg bg-white text-sm text-burgundy border border-burgundy rounded-full'
              aria-label='delete account button'
              onClick={() => setShowWarning(!showWarning)}>
              Delete Account
            </button>
          </div>
          {/*  */}
        </div>
      </main>
    </div>
  );
};

const DItem = ({ title, data }) => (
  <div className='py-10 flex justify-between border-b'>
    <dt className=''>{title}</dt>
    <dd className='text-burgundy'>{data}</dd>
  </div>
);
