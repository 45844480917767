import { doc, getDoc } from 'firebase/firestore';
import { useQuery } from 'react-query';
import { FirebaseClient } from '../../../firebase';
import { unpackSnapshot } from '../../../utils/unpack_snapshot';

export const useSession = ({ id }: { id: string }) => {
  return useQuery(
    ['sessions', { id }],
    async () => {
      const uid = FirebaseClient.auth.currentUser?.uid;
      if (!uid) throw new Error('User not logged in.');

      const snapshot = await getDoc(
        doc(FirebaseClient.db, '/users', uid, '/sessions', id),
      );

      const data = unpackSnapshot({ snapshot });
      return data;
    },
    { enabled: !!id },
  );
};
