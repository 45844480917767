import { httpsCallable } from 'firebase/functions';
import { EndSessionAction } from '../stores/session/end_session_action';
import FirebaseClient from './firebase_client';

export async function doEndSession(action: EndSessionAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: END ACTIVITY SESSION REQUESTED');
    console.log(action);
    console.groupEnd();
  }

  try {
    const result = await httpsCallable(
      FirebaseClient.functions,
      'endSession',
    )(JSON.stringify(action.payload));
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: END ACTIVITY SESSION SUCCEEDED');
      console.log(result);
      console.groupEnd();
    }

    return result;
    //
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: END ACTIVITY SESSION FAILED');
      console.error('Error writing entry document: ', error);
    }

    return { hasError: true, error };
  }
}
