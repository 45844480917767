import FirebaseClient from '../firebase/firebase_client';

export async function doSignOut() {
  try {
    await FirebaseClient.auth.signOut();
    return { hasError: false };
  } catch (error) {
    return { hasError: true, error };
  }
}
