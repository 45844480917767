import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  CHARACTER_STRENGTHS_MEASURE,
  FLOURISHING_MEASURE,
} from '../../../stores/measure/measure_type';
import { AppState } from '../../../types';
import { RouterProps, RouteComponentProps, Redirect } from 'react-router';
import { getUrlFromType } from '../../../utils/get_url_from_type';
import { NEW_SESSION_ABOUT_MEASURES_HEADER } from '../../../text/in_app_text';
import { getTitle } from '../../../utils';
import { checkFlourishing } from '../../../utils/date_checks';
import { filterLatestMeasures } from '../../../utils/filter_latest_measures';
import { CHARACTER_STRENGTHS_ACTIVITY } from '../../../stores/activity/activity';
import { Helmet } from 'react-helmet';
import { ACTIVITY_IMAGES } from '../../../assets/activity_images';
import { MEASURE_TEXT } from '../../../text/measures';
import { Transition } from '@headlessui/react';
import { ArrowRightIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { Stepper } from './Stepper';
import { TEXT } from '../../../text/activities';

type Props = {} & RouterProps &
  RouteComponentProps<{ type: string; measure?: string }>;

export const NewActivitySessionMeasures = (props: Props) => {
  const newSession = useSelector((state: AppState) => state.newSession);
  const measures = useSelector((state: AppState) => state.measures);
  const type = newSession.type;

  const title = getTitle(type);
  const typeUrl = getUrlFromType(type, true);
  const img = ACTIVITY_IMAGES[type];

  const { hasBaseline } = TEXT[type];

  //Filter measures for just the latest
  let latest = filterLatestMeasures(measures).map((m) => ({
    ...m,
    taken: false,
  }));
  if (process.env.NODE_ENV !== 'production') {
    console.log('LATEST', latest);
  }

  //Check to see if measure has been taken and is in newSession
  newSession.baselineMeasures.forEach((measureTaken) => {
    latest = latest.map((m) => {
      if (measureTaken.type === m.type) {
        return { ...m, taken: true };
      }
      return m;
    });
  });

  //If Character Strengths, don't so as already done in baseline
  if (type === CHARACTER_STRENGTHS_ACTIVITY) {
    latest = latest.filter(
      (measure) => measure.type !== CHARACTER_STRENGTHS_MEASURE,
    );
  }

  // First, get past flourishing measures sorted by most recent
  const flourishing = useSelector((state: AppState) =>
    state.measures
      .filter((measure) => measure.type === FLOURISHING_MEASURE)
      .sort((a, b) => b.startedAt - a.startedAt),
  );
  if (process.env.NODE_ENV !== 'production') {
    console.log(flourishing.map((f) => new Date(f.startedAt)).toLocaleString());
  }

  let suggestRetakeFlourishing = false;
  if (flourishing && flourishing.length > 0) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(
        flourishing.map((f) => new Date(f.startedAt)).toLocaleString(),
      );
      console.log(new Date(flourishing[0].startedAt).toLocaleString());
    }

    suggestRetakeFlourishing = checkFlourishing(flourishing[0]);
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log(`retake? ${suggestRetakeFlourishing}`);
  }

  if (newSession.type === '') {
    return <Redirect to='/' />;
  }

  return (
    <div className='relative min-h-screen pb-32 overflow-hidden'>
      <Helmet>
        <title>Baseline Measures</title>
      </Helmet>
      <img
        className='h-96 w-full object-cover object-center'
        src={img}
        alt=''
      />
      <div className='relative z-10 px-4 md:px-20 py-10 mx-auto max-w-7xl grid grid-cols-1 place-item-center -mt-32 bg-white text-center'>
        <header className='space-y-5'>
          <h1 className='text-burgundy text-5xl leading-160'>
            {title} Activity Measures
          </h1>
          <p className='text-sm leading-160 whitespace-pre-line'>
            {NEW_SESSION_ABOUT_MEASURES_HEADER}
          </p>
        </header>

        <ul className='my-10 mx-auto max-w-5xl grid grid-cols-1 lg:grid-cols-4 gap-8 place-items-top bg-transparent'>
          {latest.map((m) => (
            <ListItem key={m.type} measure={m} activitySlug={typeUrl} />
          ))}
        </ul>
        <div className='mt-10 flex justify-center'>
          <Link
            to={`/app/activities/${typeUrl}/new/schedule`}
            className='inline-flex justify-center py-2 px-20 border border-burgundy text-sm leading-5 font-medium rounded-full text-burgundy'>
            Save & Next
          </Link>
        </div>
        <div className='mt-5' />
        <Stepper step='measures' hasBaseline={hasBaseline} />
      </div>
    </div>
  );
};

const ListItem = ({ measure, activitySlug }) => {
  const title = getTitle(measure.type);
  const typeUrl = getUrlFromType(measure.type, false);
  const [state, setState] = useState('closed');

  const m = MEASURE_TEXT[measure.type];
  // const title = m.title.replace(' Measure', ' \nMeasure');
  const to = `/app/activities/${activitySlug}/new/measures/${typeUrl}`;
  return (
    <li className='relative h-full'>
      <div className='bg-burgundy min-h-400 w-48 grid grid-cols-1 place-items-center text-gray-100 text-center shadow-xl hover:shadow-2xl'>
        <img
          role='button'
          className='absolute top-2 right-2'
          src={
            state === 'closed'
              ? `/icons/info-transparent-bg.svg`
              : `/icons/info-white-bg.svg`
          }
          alt=''
          onClick={() => setState(state === 'closed' ? 'open' : 'closed')}
        />

        <img className='h-20 my-10' src={`${m.iconWhite}`} alt='' />
        <h2 className='mb-5 text-base leading-6 whitespace-pre-wrap'>
          {title} Measure
        </h2>

        {/* Last Taken / Take / Took */}
        {measure.taken ? (
          <div>
            <CheckCircleIcon className='h-10 w-10 text-white' />
          </div>
        ) : typeof measure.measure === 'undefined' ? (
          'Never taken'
        ) : (
          <p className='py-5 text-sm text-white'>
            Last taken: <br />{' '}
            {new Date(measure.measure.stoppedAt).toDateString()}
          </p>
        )}

        <Transition
          show={state === 'open'}
          enter='transform transition duration-500 ease-in bg-burgundy text-white'
          enterFrom='translate -translate-y-4 opacity-100 text-burgundy'
          enterTo='translate translate-y-0 opacity-100 text-white'
          leave='transform transition duration-500 ease-in-out'
          leaveFrom='translate translate-y-0 opacity-100 text-white'
          leaveTo='translate -translate-y-10 opacity-0 text-burgundy'>
          <p className='p-2 text-xs leading-5 text-white'>{m.about}</p>
        </Transition>

        <Link
          to={to}
          className='grid grid-cols-1 place-items-center -mb-10 bg-white h-14 w-14 rounded-full'>
          <ArrowRightIcon className='h-8 w-8 text-burgundy rounded-full' />
        </Link>
      </div>
    </li>
  );
};
