import { httpsCallable } from 'firebase/functions';
import { FetchActiveSessionsAction } from '../stores/session/fetch_active_sessions_action';
import FirebaseClient from './firebase_client';

/**
 * Fetch all active sessions from Firebase.
 * Primarily used for the Home and Activities Screens to fetch and sync active sessions
 * @param action
 */
export async function doFetchActiveSessions(action: FetchActiveSessionsAction) {
  if (process.env.NODE_ENV !== 'production') {
    console.group('FIREBASE: FETCH ACTIVE SESSION REQUESTED');
    console.log(action);
    console.groupEnd();
  }

  try {
    const results = await httpsCallable(
      FirebaseClient.functions,
      'fetchActiveSessions',
    )();
    const sessions = results.data;

    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE: FETCH ACTIVE SESSIONS SUCCEEDED');
      console.log('fetch results ', results);
      console.log('Active Sessions', sessions);
      console.groupEnd();
    }
    //
    return sessions;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.group('FIREBASE ERROR: FETCH ACTIVE SESSION FAILED');
      console.error('Error', error, action);
      console.groupEnd();
    }
    return { hasError: true, error };
  }
}
