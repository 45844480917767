export const ACTIVITY_IMAGES = {
  GRATITUDE_ACTIVITY:
    'https://images.unsplash.com/photo-1545945774-73922eb27813?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
  SAVORING_ACTIVITY:
    'https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80',
  BEST_SELF_ACTIVITY:
    'https://images.unsplash.com/photo-1548351021-639a60ea3cab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=cropt&w=1650&q=80',
  CHARACTER_STRENGTHS_ACTIVITY:
    'https://images.unsplash.com/photo-1534445967719-8ae7b972b1a5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80',
  KINDNESS_ACTIVITY:
    'https://images.unsplash.com/photo-1588776049188-b27693b3f966?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1097&q=80',
  VOLUNTEER_ACTIVITY:
    'https://images.unsplash.com/photo-1593113646773-028c64a8f1b8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
  // VOLUNTEER_ACTIVITY: `https://images.unsplash.com/photo-1469571486292-0ba58a3f068b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80`,
  JOB_CRAFTING_ACTIVITY:
    'https://images.unsplash.com/photo-1593303608004-0090c7a76ffd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=955&q=80',
  MARRIAGE_ACTIVITY:
    'https://images.unsplash.com/photo-1503314885798-a70f8f9028d3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
  RELIGION_ACTIVITY:
    'https://images.unsplash.com/photo-1589707181684-24a34853641d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
  DEPRESSION_ACTIVITY:
    'https://images.unsplash.com/photo-1574254706427-213d446e2f2b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80',
  ANXIETY_ACTIVITY:
    'https://images.unsplash.com/photo-1542662803-2f01b9f541db?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
  FORGIVENESS_ACTIVITY:
    'https://images.unsplash.com/photo-1505455184862-554165e5f6ba?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
};

// const imgMeaning = require('../assets/meaning.svg');
// const imgHappiness = require('../assets/happiness.svg');
// const imgHealth = require('../assets/health.svg');
// const imgVirtue = require('../assets/virtue.svg');
// const imgSocial = require('../assets/social.svg');
// const imgFinancial = require('../assets/financial.svg');

export const DOMAIN_ICONS = [
  '/media/happiness.svg',
  '/media/health.svg',
  '/media/meaning.svg',
  '/media/virtue.svg',
  '/media/social.svg',
  '/media/financial.svg',
  // imgHappiness,
  // imgHealth,
  // imgMeaning,
  // imgVirtue,
  // imgSocial,
  // imgFinancial,
];
