export const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED';
export const CHANGE_PASSWORD_SUCCEEDED = 'CHANGE_PASSWORD_SUCCEEDED';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export type ChangePasswordAction = {
  type: typeof CHANGE_PASSWORD_REQUESTED;
  payload: {
    currentPassword: string;
    newPassword: string;
  };
};

export const changePasswordAction = ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string;
  newPassword: string;
}): ChangePasswordAction => ({
  type: CHANGE_PASSWORD_REQUESTED,
  payload: { currentPassword, newPassword },
});
