import {
  ANXIETY_ACTIVITY,
  BEST_SELF_ACTIVITY,
  BOOK_RESOURCES_ACTIVITY,
  CHARACTER_STRENGTHS_ACTIVITY,
  COMMUNITY_COMMITMENTS_ACTIVITY,
  DEPRESSION_ACTIVITY,
  FORGIVENESS_ACTIVITY,
  GRATITUDE_ACTIVITY,
  JOB_CRAFTING_ACTIVITY,
  KINDNESS_ACTIVITY,
  MARRIAGE_ACTIVITY,
  RELIGION_ACTIVITY,
  SAVORING_ACTIVITY,
  VOLUNTEER_ACTIVITY,
} from '../stores/activity/activity';
import {
  CHARACTER_STRENGTHS_MEASURE,
  FLOURISHING_MEASURE,
  GRATITUDE_MEASURE,
  MEANING_MEASURE,
} from '../stores/measure/measure_type';

/**
 * Return the {title} of an activity to use for displays
 * @param type
 * FIXME: use a map instead
 */
export function getTitle(type: string) {
  switch (type) {
    case FORGIVENESS_ACTIVITY:
      return 'Forgiveness';

    case MARRIAGE_ACTIVITY:
      return 'Marriage';

    case VOLUNTEER_ACTIVITY:
      return 'Volunteer';

    case RELIGION_ACTIVITY:
      return 'Religion';

    case DEPRESSION_ACTIVITY:
      return 'Depression';

    case ANXIETY_ACTIVITY:
      return 'Anxiety';

    case GRATITUDE_ACTIVITY:
      return 'Gratitude';

    case GRATITUDE_MEASURE:
      return 'Gratitude';

    case SAVORING_ACTIVITY:
      return 'Savoring';

    case BEST_SELF_ACTIVITY:
      return 'Best Possible Self';

    case KINDNESS_ACTIVITY:
      return 'Kindness';

    case CHARACTER_STRENGTHS_ACTIVITY:
      return 'Character Strengths';

    case CHARACTER_STRENGTHS_MEASURE:
      return 'Character Strengths';

    case JOB_CRAFTING_ACTIVITY:
      return 'Job Crafting';

    case COMMUNITY_COMMITMENTS_ACTIVITY:
      return 'Community Commitments';

    case BOOK_RESOURCES_ACTIVITY:
      return 'Book Resources';

    case MEANING_MEASURE:
      return 'Meaning of Life';

    case FLOURISHING_MEASURE:
      return 'Flourishing';

    default:
      return '';
  }
}
