import { collection, getDocs, query, where } from 'firebase/firestore';
import { useQuery } from 'react-query';
import { FirebaseClient } from '../../../firebase';
import { unpackSnapshotArray } from '../../../utils/unpack_snapshot_array';

export const useActiveSession = ({ type }: { type: string }) => {
  return useQuery(
    ['activeSessions', { type }],
    async () => {
      const uid = FirebaseClient.auth.currentUser?.uid;
      if (!uid) throw new Error('User not logged in.');

      const ref = collection(FirebaseClient.db, '/users', uid, '/sessions');
      const q = query(
        ref,
        where('isActive', '==', true),
        where('type', '==', type),
      );
      const snapshots = await getDocs(q);

      // console.log('snapshots', snapshots.size);
      if (snapshots.size === 0) {
        return [];
      }
      // FIXME: may need to sort this if we somehow have more than one active session

      const data = unpackSnapshotArray({ snapshots });
      // console.log('data', data);
      // TODO: only return the current active
      return data[0];
    },
    { enabled: !!type },
  );
};
