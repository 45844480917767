import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouterProps } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { DateItem } from '../../../components/DateItem';
import { fetchSessionAction } from '../../../stores/session/fetch_session_action';
import { AppState } from '../../../types';
import { CompletedEntries } from '../../v2/activities/completed/CompletedSessionActivityEntries';
import { BaselineViews } from './CompletedActivitySessionBaselines';
import { ActivityPageContainer } from '../../../components/containers/ActivityPageContainer';
import { BasicContainer } from '../../../components/containers/BasicContainer';
import {
  COMPLETED_HEADER,
  IN_PROGRESS_HEADER,
  TEXT,
} from '../../../text/activities';
import { Entries } from '../components/Entries';
import { Helmet } from 'react-helmet';
import { EndSessionEarly } from '../../v2/activities/in_progress/EndSessionEarly';
import { ACTIVITY_IMAGES } from '../../../assets/activity_images';
import { useSession } from '../../../api/firebase/hooks/use_session';
import { ACTIVITIES } from '../../../stores/activity/activity';
import { getTypeFromUrl } from '../../../utils/get_type_from_url';

type Props = {} & RouterProps &
  RouteComponentProps<{ type: string; sid: string }>;

export const CompletedActivitySession = (props: Props) => {
  // TODO: use hooks useParams
  const { sid } = props.match.params;
  const type = getTypeFromUrl(props.match.params.type, props.match.path) || '';

  const { data: session, isLoading, isError } = useSession({ id: sid });

  const activity = ACTIVITIES[type];
  const { title } = activity;

  if (isError) {
    return <Redirect to='/404' />;
  }

  if (isLoading) {
    return (
      <div className='pt-10 grid grid-cols-1 place-items-center'>
        Loading ...
      </div>
    );
  }

  const activityData = TEXT[session?.type];
  const imgUrl =
    ACTIVITY_IMAGES[session?.type] ||
    'https://images.unsplash.com/photo-1545945774-73922eb27813?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80';

  // Computed data
  const nCompletedEntries = session?.entries.reduce((acc, entry) => {
    return entry.completed ? acc + 1 : acc;
  }, 0);
  const nTotalEntries = session?.entries.length;

  const quotation = activityData?.quotation;
  const general = activityData?.general.split('\n')[0].trim();

  const nEntries = session?.entries.length;
  const progress = nCompletedEntries / nEntries;

  return (
    <div className='relative bg-not-gray min-h-screen'>
      <Helmet>
        <title>{/* TODO: */}</title>
      </Helmet>
      {/* Header Image */}
      <header
        className='relative h-96 w-full bg-center bg-cover'
        style={{ backgroundImage: `url(${imgUrl})` }}>
        <div className='absolute h-full w-full bg-black opacity-50' />
        <h2 className='hidden md:block relative mx-auto max-w-xl lg:max-w-7xl pt-10 text-sm text-white text-center whitespace-pre-wrap'>
          {quotation}
        </h2>
      </header>

      <div className='relative -mt-48 py-10 mx-auto max-w-7xl bg-white'>
        <main className='px-4 xl:px-0 mx-auto max-w-5xl'>
          <header className='space-y-5'>
            <h2>
              Status:{' '}
              <strong> {session.isActive ? 'in progress' : 'completed'}</strong>
            </h2>
            <h1 className='text-5xl text-burgundy leading-160'>
              {title} Activity
            </h1>
            <dl className='py-4'>
              <div className='flex space-x-2 text-sm leading-160'>
                <dt>Started:</dt>
                <dd className='font-bold'>
                  <DateItem date={session.startedAt} />
                </dd>
              </div>

              <div className='flex space-x-2 text-sm leading-160'>
                <dt>Finished:</dt>
                <dd className='font-bold'>
                  <DateItem date={session.stoppedAt} />
                </dd>
              </div>
            </dl>
          </header>

          <section className='py-4'>
            <p className='text-sm leading-160'>
              {nCompletedEntries}/{nTotalEntries} Entries Completed
            </p>
            <div
              id='progress'
              className='mt-2 overflow-hiddern bg-not-gray h-4 mb-4 flex rounded-full'>
              <div
                style={{ width: `${100 * progress}%` }}
                className='shadow-none flex flex-col bg-burgundy rounded-full'
              />
            </div>
          </section>

          <section>
            <Entries session={session} status='completed' />
            <BaselineViews session={session} />
          </section>
        </main>
      </div>
    </div>
  );
};
